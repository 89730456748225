// @ts-nocheck
import { forwardRef } from 'react';
const SvgReceipt = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 68 98"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path fill="currentColor" d="M50.5312 27.5625H16.8438V30.625H50.5312V27.5625Z" />
    <path fill="currentColor" d="M42.875 36.75H16.8438V39.8125H42.875V36.75Z" />
    <path fill="currentColor" d="M50.5312 36.75H45.9375V39.8125H50.5312V36.75Z" />
    <path fill="currentColor" d="M42.875 42.875H16.8438V45.9375H42.875V42.875Z" />
    <path fill="currentColor" d="M50.5312 42.875H45.9375V45.9375H50.5312V42.875Z" />
    <path fill="currentColor" d="M42.875 49H16.8438V52.0625H42.875V49Z" />
    <path fill="currentColor" d="M50.5312 49H45.9375V52.0625H50.5312V49Z" />
    <path fill="currentColor" d="M42.875 55.125H16.8438V58.1875H42.875V55.125Z" />
    <path fill="currentColor" d="M50.5312 55.125H45.9375V58.1875H50.5312V55.125Z" />
    <path fill="currentColor" d="M42.875 61.25H16.8438V64.3125H42.875V61.25Z" />
    <path fill="currentColor" d="M50.5312 61.25H45.9375V64.3125H50.5312V61.25Z" />
    <path fill="currentColor" d="M38.2812 71.9688H16.8438V75.0312H38.2812V71.9688Z" />
    <path fill="currentColor" d="M50.5312 71.9688H44.4062V75.0312H50.5312V71.9688Z" />
    <path
      fill="currentColor"
      d="M59.7188 0H58.1875H9.1875H7.65625C3.43459 0 0 3.43459 0 7.65625C0 11.8779 3.43459 15.3125 7.65625 15.3125V96.4688C7.65625 97.0001 7.93187 97.4932 8.38206 97.7718C8.83531 98.0505 9.39881 98.075 9.87197 97.8392L15.3125 95.1182L20.753 97.8377C21.1833 98.0536 21.6917 98.0536 22.1235 97.8377L27.5625 95.1182L33.003 97.8377C33.4333 98.0536 33.9417 98.0536 34.3735 97.8377L39.8125 95.1182L45.253 97.8377C45.6833 98.0536 46.1917 98.0536 46.6235 97.8377L52.0625 95.1182L57.503 97.8377C57.7189 97.9464 57.9532 98 58.1875 98C58.4677 98 58.7464 97.9234 58.9929 97.7718C59.4431 97.4916 59.7188 97.0001 59.7188 96.4688V15.3125C63.9404 15.3125 67.375 11.8779 67.375 7.65625C67.375 3.43459 63.9404 0 59.7188 0ZM3.0625 7.65625C3.0625 5.12356 5.12356 3.0625 7.65625 3.0625V12.25C5.12356 12.25 3.0625 10.1889 3.0625 7.65625ZM56.6562 93.9912L52.747 92.0373C52.3167 91.8214 51.8083 91.8214 51.3765 92.0373L45.9375 94.7568L40.497 92.0373C40.2826 91.9286 40.0468 91.875 39.8125 91.875C39.5782 91.875 39.3424 91.9286 39.128 92.0373L33.6875 94.7568L28.247 92.0373C27.8167 91.8214 27.3083 91.8214 26.8765 92.0373L21.4375 94.7568L15.997 92.0373C15.5667 91.8214 15.0583 91.8214 14.6265 92.0373L10.7188 93.9912V3.0625H56.6562V93.9912ZM59.7188 12.25V3.0625C62.2514 3.0625 64.3125 5.12356 64.3125 7.65625C64.3125 10.1889 62.2514 12.25 59.7188 12.25Z"
    />
    <path
      fill="currentColor"
      d="M33.6875 24.5C37.9092 24.5 41.3438 21.0654 41.3438 16.8438C41.3438 12.6221 37.9092 9.1875 33.6875 9.1875C29.4658 9.1875 26.0312 12.6221 26.0312 16.8438C26.0312 21.0654 29.4658 24.5 33.6875 24.5ZM33.6875 12.25C36.2202 12.25 38.2812 14.3111 38.2812 16.8438C38.2812 19.3764 36.2202 21.4375 33.6875 21.4375C31.1548 21.4375 29.0938 19.3764 29.0938 16.8438C29.0938 14.3111 31.1548 12.25 33.6875 12.25Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgReceipt);
export default ForwardRef;
