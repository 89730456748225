import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toast';
import { Paginated } from '@use-gateway/types';

type PaginatedCallback<T> = (page: number, size: number) => Promise<Paginated<T>>;

export function usePaginated<T>(
  callback: PaginatedCallback<T>,
  size = 25,
  dependences: Array<unknown> = []
) {
  const [pending, setPending] = useState(false);
  const [page, setPage] = useState(1);
  const [itemsOnPage, setItemsOnPage] = useState(0);
  const [itemsTotal, setItemsTotal] = useState(0);
  const [items, setItems] = useState<Array<T>>([]);
  const [raw, setRaw] = useState<ReturnType<typeof callback>>();
  const itemsOffset = useMemo(() => (page - 1) * size, [page]);
  const pagesTotal = useMemo(() => Math.ceil(itemsTotal / size), [itemsTotal]);

  const canGoPrev = page > 1;
  const canGoNext = page < pagesTotal;

  const goPrev = () => {
    if (canGoPrev) setPage(page - 1);
  };
  const goNext = () => {
    if (canGoNext) setPage(page + 1);
  };

  const fetchItems = async (newPage?: number) => {
    setPending(true);

    try {
      const res = await callback(newPage ?? page, size);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setRaw(res);
      setItems(res.items);
      setPage(res.page);
      setItemsTotal(res.total);
      setItemsOnPage(res.items.length);
    } catch (e) {
      toast.error(e.message);
    }

    setPending(false);
  };

  useEffect(() => {
    fetchItems();
  }, [size, page, ...dependences]);

  return {
    raw,
    items,
    page,
    itemsOnPage,
    itemsTotal,
    itemsOffset,
    pagesTotal,
    canGoPrev,
    canGoNext,
    pending,
    goPrev,
    goNext,
    setPage,
    fetchItems,
  };
}
