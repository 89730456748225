// @ts-nocheck
import { forwardRef } from 'react';
const SvgCarretRight = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M14.7522 12.277L10.5007 8.45062C10.3076 8.27687 10 8.41388 10 8.67361L10 16.3264C10 16.5861 10.3076 16.7231 10.5007 16.5494L14.7522 12.723C14.8846 12.6038 14.8846 12.3962 14.7522 12.277Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCarretRight);
export default ForwardRef;
