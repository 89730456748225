// @ts-nocheck
import { forwardRef } from 'react';
const SvgUsdcBep20 = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 64 64"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_3630_36498)">
      <path
        fill="#2775CA"
        d="M32 64C49.7334 64 64 49.7334 64 32C64 14.2666 49.7334 0 32 0C14.2666 0 0 14.2666 0 32C0 49.7334 14.2666 64 32 64Z"
      />
      <path
        fill="white"
        d="M40.8 37.0666C40.8 32.4 38 30.8 32.4 30.1335C28.4 29.6 27.6 28.5335 27.6 26.6666C27.6 24.7997 28.9335 23.6 31.6 23.6C34 23.6 35.3335 24.4 36 26.4C36.1335 26.8 36.5335 27.0666 36.9335 27.0666H39.0666C39.6 27.0666 40 26.6666 40 26.1335V26C39.4666 23.0666 37.0666 20.8 34 20.5335V17.3335C34 16.8 33.6 16.4 32.9335 16.2666H30.9335C30.4 16.2666 30 16.6666 29.8666 17.3335V20.4C25.8666 20.9335 23.3335 23.6 23.3335 26.9335C23.3335 31.3335 26 33.0666 31.6 33.7335C35.3335 34.4 36.5335 35.2 36.5335 37.3335C36.5335 39.4669 34.6666 40.9335 32.1335 40.9335C28.6666 40.9335 27.4666 39.4666 27.0666 37.4666C26.9335 36.9335 26.5335 36.6666 26.1335 36.6666H23.8666C23.3335 36.6666 22.9335 37.0666 22.9335 37.6V37.7335C23.4666 41.0666 25.6 43.4666 30 44.1335V47.3335C30 47.8666 30.4 48.2666 31.0666 48.4H33.0666C33.6 48.4 34 48 34.1335 47.3335V44.1335C38.1335 43.4666 40.8 40.6666 40.8 37.0666Z"
      />
      <path
        fill="white"
        d="M25.2 51.0666C14.8 47.3335 9.4666 35.7335 13.3335 25.4666C15.3335 19.8666 19.7335 15.6001 25.2 13.6001C25.7335 13.3335 26 12.9335 26 12.2666V10.4001C26 9.86662 25.7335 9.46662 25.2 9.3335C25.0666 9.3335 24.8 9.3335 24.6666 9.46662C12 13.4666 5.0666 26.9335 9.0666 39.6001C11.4666 47.0666 17.2 52.8001 24.6666 55.2001C25.2 55.4666 25.7335 55.2001 25.8666 54.6666C26 54.5335 26 54.4001 26 54.1335V52.2666C26 51.8666 25.6 51.3335 25.2 51.0666ZM39.3335 9.46662C38.8 9.20006 38.2666 9.46662 38.1335 10.0001C38 10.1335 38 10.2666 38 10.5335V12.4001C38 12.9335 38.4 13.4666 38.8 13.7335C49.2 17.4666 54.5335 29.0666 50.6666 39.3335C48.6666 44.9335 44.2666 49.2001 38.8 51.2001C38.2666 51.4666 38 51.8666 38 52.5335V54.4001C38 54.9335 38.2666 55.3335 38.8 55.4666C38.9335 55.4666 39.2 55.4666 39.3335 55.3335C52 51.3335 58.9335 37.8666 54.9335 25.2001C52.5335 17.6001 46.6666 11.8666 39.3335 9.46662Z"
      />
      <g clipPath="url(#clip1_3630_36498)">
        <path
          fill="#F0B90B"
          fillRule="evenodd"
          d="M55 46C59.9709 46 64 50.0291 64 55C64 59.9709 59.9709 64 55 64C50.0291 64 46 59.9709 46 55C46 50.0291 50.0291 46 55 46Z"
          clipRule="evenodd"
        />
        <path
          fill="white"
          d="M50.9464 55L50.9529 57.3798L52.975 58.5697V59.963L49.7695 58.0829V54.3041L50.9464 55ZM50.9464 52.6202V54.007L49.7688 53.3103V51.9236L50.9464 51.2269L52.1299 51.9236L50.9464 52.6202ZM53.8195 51.9236L54.9972 51.2269L56.1806 51.9236L54.9972 52.6202L53.8195 51.9236Z"
        />
        <path
          fill="white"
          d="M51.7974 56.8865V55.4933L52.975 56.1899V57.5767L51.7974 56.8865ZM53.8195 59.0688L54.9971 59.7654L56.1805 59.0688V60.4555L54.9971 61.1522L53.8195 60.4555V59.0688ZM57.8695 51.9236L59.0471 51.2269L60.2305 51.9236V53.3103L59.0471 54.007V52.6202L57.8695 51.9236ZM59.0471 57.3798L59.0536 55L60.2313 54.3034V58.0822L57.0257 59.9623V58.569L59.0471 57.3798Z"
        />
        <path fill="white" d="M58.2027 56.8866L57.025 57.5767V56.1899L58.2027 55.4933V56.8866Z" />
        <path
          fill="white"
          d="M58.2027 53.1134L58.2091 54.5067L56.1813 55.6966V58.0822L55.0036 58.7723L53.826 58.0822V55.6966L51.7981 54.5067V53.1134L52.9808 52.4168L54.9964 53.6124L57.0243 52.4168L58.2077 53.1134H58.2027ZM51.7974 50.7343L54.9971 48.8478L58.2027 50.7343L57.025 51.431L54.9971 50.2353L52.975 51.431L51.7974 50.7343Z"
        />
      </g>
      <rect width={17} height={17} x={46.5} y={46.5} stroke="white" rx={8.5} />
    </g>
    <defs>
      <clipPath id="clip0_3630_36498">
        <rect width={64} height={64} fill="white" />
      </clipPath>
      <clipPath id="clip1_3630_36498">
        <rect width={18} height={18} x={46} y={46} fill="white" rx={9} />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgUsdcBep20);
export default ForwardRef;
