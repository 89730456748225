import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';

interface ErrorMessageProps {
  error: string;
}

export function ErrorMessage({ error }: ErrorMessageProps) {
  const { t } = useTranslation();

  const content = useMemo(() => {
    const errorKey = `errors:${error}`;
    const translation = t(errorKey, { returnObjects: false, defaultValue: errorKey });
    if (translation === errorKey) {
      return `Error: ${error}`;
    }

    return translation;
  }, [t, error]);

  return <span dangerouslySetInnerHTML={{ __html: content }} />;
}
