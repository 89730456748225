// @ts-nocheck
import { forwardRef } from 'react';
const SvgEyeOff = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M10.6298 15.842L9.84178 18.782L7.91078 18.264L8.69778 15.325C7.51975 14.8905 6.4249 14.2574 5.46078 13.453L3.30778 15.607L1.89278 14.192L4.04678 12.039C2.83086 10.5828 2.01387 8.83578 1.67578 6.96902L2.57578 6.80402C4.04678 10 7.49956 14 12.4998 14C17.5 14 20.5 10.5 22.4238 6.80402L23.3238 6.96802C22.9861 8.83503 22.1695 10.5824 20.9538 12.039L23.1068 14.192L21.6918 15.607L19.5388 13.453C18.5747 14.2574 17.4798 14.8905 16.3018 15.325L17.0888 18.265L15.1578 18.782L14.3698 15.842C13.1321 16.0541 11.8674 16.0541 10.6298 15.842Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgEyeOff);
export default ForwardRef;
