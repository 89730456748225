import { ReactNode } from 'react';
import { down } from 'styled-breakpoints';
import { Columns } from '@bedrock-layout/columns';
import { styled } from '@use-gateway/theme';

const PanelSectionWrapper = styled.section`
  background-color: ${({ theme }) => theme.colors.light[100]};
  border: 1px solid ${({ theme }) => theme.colors.light[80]};
  box-shadow: 0px 12px 98px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-bottom: 24px;

  ${down('sm')} {
    border: none;
    box-shadow: none;
    background-color: transparent;
  }
`;

const PanelSectionHeader = styled.header`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.light[90]};
  border-radius: 12px 12px 0 0;

  ${down('sm')} {
    background-color: transparent;
  }
`;

const PanelSectionTitle = styled.h4`
  padding: 11px 19px 27px;
  margin: 0;

  font-weight: 600;
  font-size: 24px;
  line-height: 1.33;
  color: ${({ theme }) => theme.colors.dark[100]};

  ${down('sm')} {
    font-family: 'Roboto', sans-serif;
    padding: 12px 16px;
    font-size: 14px;
  }
`;

const PanelSectionContent = styled.div``;

interface PanelSectionRowProps {
  isSelected?: boolean;
}

export const PanelSectionRow = styled(Columns)<PanelSectionRowProps>`
  position: relative;
  padding: 16px 20px;
  border-bottom: 1px solid #f2f3f5;
  background-color: ${({ isSelected }) => (isSelected ? '#FAFAFF' : 'transparent')};

  overflow: hidden;

  ${down('sm')} {
    margin: 8px 0;
    padding: 0 8px;
    border-bottom: none;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &:last-child {
    border-bottom: none;
  }

  & > * {
    display: flex;
    align-items: center;
    word-break: break-word;

    & a {
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.colors.primary};
      cursor: pointer;

      &.disabled {
        color: ${({ theme }) => theme.colors.light[20]};
      }

      svg {
        font-size: 24px;
      }
    }

    & > label {
      display: flex;
      align-items: center;

      padding: 12px 28px;
      font-family: 'Nunito', sans-serif;
      font-weight: 600;
      font-size: 20px;
      color: ${({ theme }) => theme.colors.dark[100]};

      svg {
        font-size: 36px;
        margin-right: 16px;
      }

      ${down('md')} {
        font-size: 16px;
        padding: 12px 16px 12px 0;
      }

      ${down('sm')} {
        font-family: 'Roboto', sans-serif;
        padding: 0 8px;
        font-size: 14px;
        font-weight: 400;
      }

      ${down('xs')} {
        font-size: 12px;
      }
    }
  }
`;
interface PanelSectionLoadingWrapperProps {
  isFixed?: boolean;
}
export const PanelSectionLoadingWrapper = styled.div<PanelSectionLoadingWrapperProps>`
  position: ${({ isFixed }) => (isFixed ? 'fixed' : 'absolute')};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;

  backdrop-filter: blur(5px);

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PanelSectionHeaderRow = styled(Columns)`
  padding: 0 42px;

  & > * {
    margin-top: -32px;
    height: 32px;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.dark[100]};
  }
`;

interface PanelSectionPreHeaderRowProps {
  isActive?: boolean;
}

export const PanelSectionPreHeaderRow = styled(Columns)<PanelSectionPreHeaderRowProps>`
  height: 64px;
  padding: 8px 20px;
  background-color: ${({ isActive }) => (isActive ? '#f9f9f9' : 'transparent')};
  border-bottom: 1px solid #f2f3f5;

  ${down('sm')} {
    padding: 8px;
  }

  & > * {
    display: flex;
    align-items: center;
  }

  button {
    height: 48px;
  }
`;

const PanelSectionHeaderActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 10px 16px;

  & > * {
    margin-left: 20px;
  }

  ${down('sm')} {
    & > * {
      margin-left: 0;
    }
  }
`;

export interface PanelSectionProps {
  children?: ReactNode;
  actions?: ReactNode;
  title: string;
}
export function PanelSection({ children, title, actions }: PanelSectionProps) {
  return (
    <PanelSectionWrapper>
      <PanelSectionHeader>
        <PanelSectionTitle>{title}</PanelSectionTitle>
        {actions && <PanelSectionHeaderActions>{actions}</PanelSectionHeaderActions>}
      </PanelSectionHeader>
      <PanelSectionContent>{children}</PanelSectionContent>
    </PanelSectionWrapper>
  );
}
