import styled, { css } from 'styled-components';
import { UnknownFunction } from '@use-gateway/types';

const MobileElementsSwitchWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 8px 40px;
`;

interface MobileElementsSwitchItemProps {
  current?: boolean;
}

const MobileElementsSwitchItem = styled.div<MobileElementsSwitchItemProps>`
  width: 100%;
  padding-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.light[40]};
  text-align: center;
  font-family: 'Nunito';
  font-weight: 600;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.light[40]};

  ${({ current }) =>
    current
      ? css`
          padding-bottom: 7px;
          border-bottom: 2px solid #000;
          color: #000;
        `
      : ''}
`;

interface MobileElementsSwitchProps {
  value: number;
  setValue: UnknownFunction;
  options: string[];
}

export function MobileElementsSwitch({ value, setValue, options }: MobileElementsSwitchProps) {
  const handleClick = (index: number) => {
    setValue(index);
  };
  return (
    <MobileElementsSwitchWrapper>
      {options.map((option, index) => (
        <MobileElementsSwitchItem
          current={value === index}
          key={index}
          onClick={(e) => handleClick(index)}>
          {option}
        </MobileElementsSwitchItem>
      ))}
    </MobileElementsSwitchWrapper>
  );
}
