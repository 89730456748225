// @ts-nocheck
import { forwardRef } from 'react';
const SvgWatch = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M20 12C20 9.7 19 7.7 17.5 6.2L17.2 2.7C17 1.2 15.8 0 14.2 0H9.8C8.3 0 7 1.2 6.8 2.7L6.5 6.2C5 7.6 4 9.7 4 12C4 14.3 5 16.4 6.5 17.8L6.8 21.3C7 22.8 8.3 24 9.8 24H14.1C15.7 24 16.9 22.8 17.1 21.3L17.4 17.9C19 16.4 20 14.3 20 12ZM8.8 2.9C8.8 2.4 9.3 2 9.8 2H14.2C14.7 2 15.1 2.4 15.2 2.9L15.4 4.7C14.3 4.3 13.2 4 12 4C10.8 4 9.7 4.3 8.7 4.7L8.8 2.9ZM6 12C6 8.7 8.7 6 12 6C15.3 6 18 8.7 18 12C18 13.8 17.2 15.5 15.9 16.6C14.9 17.5 13.5 18 12 18C8.7 18 6 15.3 6 12ZM15.2 21.1C15.2 21.6 14.7 22 14.2 22H9.8C9.3 22 8.9 21.6 8.8 21.1L8.6 19.3C9.6 19.8 10.7 20 11.9 20C13.1 20 14.2 19.7 15.2 19.3V21.1Z"
    />
    <path
      fill="currentColor"
      d="M12.8 14.2C13 14.4 13.3 14.5 13.5 14.5C13.7 14.5 14 14.4 14.2 14.2C14.6 13.8 14.6 13.2 14.2 12.8L13 11.6V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V12C11 12.3 11.1 12.5 11.3 12.7L12.8 14.2Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgWatch);
export default ForwardRef;
