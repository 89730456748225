import { format } from 'date-fns';
import { random } from 'lodash-es';
import { create } from 'zustand';
import { api, ResponseError } from '@use-gateway/api';
import {
  CommonEvents,
  PaymentResponseDto,
  PricingItem,
  Steps,
  TimelineItem,
} from '@use-gateway/types';
import { detectStep, money, useEventBus } from '@use-gateway/utils';

const mapStepToInterval: Record<Steps, number | null> = {
  [Steps.Fetching]: 1000,
  [Steps.Open]: 2000,
  [Steps.Viewed]: 2000,
  [Steps.Renewed]: 2000,
  [Steps.Completed]: null,
  [Steps.Pending]: 1000 * 10,
  [Steps.Expired]: null,
  [Steps.Unresolved]: null,
  [Steps.Resolved]: null,
};

type Navigate = (path: string) => void;

interface PaymentStore {
  _navigate: Navigate | null;

  // State
  _wasInit: boolean;
  payment: PaymentResponseDto | null;
  step: Steps;
  formattedDate: string;
  finalRate: string | null;
  connectionCount: number;

  // Actions
  init: (id: string, navigate: Navigate) => void;
  fetch: (paymentId?: string) => Promise<void>;
  afterFetch: () => void;
  updateFinalRate: () => void;
}

export const usePaymentStore = create<PaymentStore>((set, get) => ({
  // State
  _navigate: null,
  _wasInit: false,
  payment: null,
  step: Steps.Fetching,
  formattedDate: '',
  finalRate: null,
  connectionCount: 0,

  // Actions
  init: async (id, navigate) => {
    const { fetch, _wasInit } = get();

    set({ _wasInit: true, _navigate: navigate });
    if (_wasInit) return;

    await fetch(id);
  },
  fetch: async (paymentId) => {
    const { payment, _navigate, connectionCount: count } = get();
    const id = paymentId || (payment?.id as string);
    const { emit } = useEventBus();

    await api
      .getPayment(id)
      .then((payment) => {
        set({ payment });
        get().afterFetch();
      })
      .catch((e: ResponseError) => {
        if (e.name === 'TypeError' && !e.code) {
          if (count > 1) {
            emit(CommonEvents.badConnection);
          } else {
            setTimeout(() => {
              set({ connectionCount: count + 1 });
              get().fetch(paymentId);
            }, 5000);
          }

          return;
        }

        if (_navigate !== null) {
          _navigate('/404');
        }
      });
  },
  afterFetch: () => {
    const { payment, updateFinalRate } = get();

    const step = detectStep(payment?.timeline as Array<TimelineItem>);
    const interval = mapStepToInterval[step];

    if (interval !== null) {
      const partOfInterval = Math.round(interval / 4);
      setTimeout(get().fetch, random(interval - partOfInterval, interval + partOfInterval, false));
    }

    set({
      step,
      formattedDate: format(new Date(payment?.created_at as string), 'dd.MM.yy HH:mm'),
    });

    updateFinalRate();
  },

  updateFinalRate: () => {
    const { step, payment } = get();

    if (step !== Steps.Completed) {
      set({ finalRate: null });
    } else {
      const crypto = payment?.transactions[0].value.crypto as PricingItem;
      const local = payment?.transactions[0].value.local as PricingItem;
      const localPerCrypto = local.amount / crypto.amount;

      set({
        finalRate: `1 ${crypto.currency} = ` + money(localPerCrypto, local.currency).format(),
      });
    }
  },
}));
