import { formatISO } from 'date-fns';
import { DateRange, TransactionsResponse } from '@use-gateway/types';
import { Api } from '..';
import { ResponseError } from '../utils/response-error';

const basePath = '/transactions';

export interface GetTransactionsOptions {
  search?: string;
  dateRange?: DateRange;
  is_unresolved?: boolean;
}
export async function getTransactions(
  this: Api,
  page = 1,
  size = 25,
  options: GetTransactionsOptions = {}
) {
  const { search, dateRange, is_unresolved } = options;

  const query = new URLSearchParams({
    page: page.toString(),
    size: size.toString(),
    ...(search ? { search } : {}),
    ...(dateRange
      ? {
          from_date: formatISO(dateRange[0]),
          to_date: formatISO(dateRange[1]),
        }
      : {}),
    ...(typeof is_unresolved !== 'undefined' ? { is_unresolved: is_unresolved.toString() } : {}),
  }).toString();

  return this.get<TransactionsResponse>(`${basePath}/?${query}`);
}

export async function getTransactionsReport(
  this: Api,
  options: GetTransactionsOptions = {}
): Promise<string | void> {
  const { search, dateRange } = options;

  const query = new URLSearchParams({
    ...(search ? { search } : {}),
    ...(dateRange
      ? {
          from_date: formatISO(dateRange[0]),
          to_date: formatISO(dateRange[1]),
        }
      : {}),
  }).toString();

  const res = (await this.fetch(`${basePath}/report?` + query, {
    method: 'GET',
    raw: true,
  })) as Response;

  // TODO: унифицировать обработку ошибок с методом fetch
  if (res.status >= 200 && res.status <= 299) {
    return await res.text();
  } else {
    if (this.catchTechnicalWorks(res)) return;

    const clone = res.clone();
    const error = new ResponseError(undefined);
    await error.addResponse(clone);

    if (error.code === 401 && this._unauthorizedCallback) {
      this._unauthorizedCallback();
      return;
    }

    throw error;
  }
}
