import { useMemo } from 'react';
import getConfig from 'next/config';
import Link from 'next/link';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { ProjectNames } from '@use-gateway/types';
import changePayLogo from './assets/change-pay-logo.svg';
import logo from './assets/logo.svg';

const LogoWrapper = styled.div`
  position: relative;
  font-size: 0;
  cursor: pointer;

  a {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

const MainLogo = styled.img`
  width: 217px;
  height: 41px;
  pointer-events: none;

  ${down('sm')} {
    width: 200px;
    height: 37.7px;
  }
`;

const ChangePayLogo = styled.img`
  width: 217px;
  height: 41px;
  pointer-events: none;
`;

const { publicRuntimeConfig } = getConfig();
const { PROJECT_NAME } = publicRuntimeConfig;

export function Logo() {
  const currentLogo = useMemo(() => {
    if (PROJECT_NAME === ProjectNames.ChangePay)
      return <ChangePayLogo src={changePayLogo} alt="ChangePay" />;
    return <MainLogo src={logo} alt="UseGateway" />;
  }, []);

  return (
    <LogoWrapper>
      <Link href="/">
        <a />
      </Link>
      {currentLogo}
    </LogoWrapper>
  );
}
