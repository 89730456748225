// @ts-nocheck
import { forwardRef } from 'react';
const SvgCloud = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_2128_24012)">
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M16.74 10H18C19.3261 10 20.5978 10.5268 21.5355 11.4645C22.4732 12.4021 23 13.6739 23 15C23 16.3261 22.4732 17.5979 21.5355 18.5355C20.5978 19.4732 19.3261 20 18 20H9C7.50347 20.0011 6.03664 19.5824 4.76618 18.7916C3.49571 18.0007 2.47256 16.8693 1.81297 15.526C1.15339 14.1826 0.88382 12.6812 1.0349 11.1924C1.18599 9.70348 1.75166 8.28683 2.66765 7.10338C3.58364 5.91993 4.81321 5.01714 6.21665 4.49759C7.62009 3.97804 9.14112 3.86256 10.6069 4.16428C12.0727 4.46599 13.4245 5.1728 14.5086 6.2044C15.5928 7.23599 16.3659 8.551 16.74 10Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_2128_24012">
        <rect width={24} height={24} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgCloud);
export default ForwardRef;
