import { useMemo } from 'react';
import { Inline } from '@bedrock-layout/inline';
import { DateTime, DoublePrice, Status, Button } from '@use-gateway/components';
import { IconFileText } from '@use-gateway/icons';
import { Breakpoints, styled, useBreakpoints } from '@use-gateway/theme';
import {
  CommonEvents,
  DepositsEvents,
  IncomingTransactionDto,
  InvoicesEvents,
  PaymentsEvents,
  WithdrawalsEvents,
} from '@use-gateway/types';
import { precisionByCurrency, useEventBus } from '@use-gateway/utils';
import {
  TableCard,
  TableCell,
  TableRow,
  TableMobileName,
  TableMobileMeta,
  TableMobileActions,
  TableMobileStatus,
} from '../table';

const resolvedStatuses = ['Completed', 'Replenished', 'Resolved', 'Paid'];

const DetailsLink = styled.a`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;

interface TransactionsRowProps {
  transaction: IncomingTransactionDto;
}

export function TransactionsRow({ transaction }: TransactionsRowProps) {
  const { isUp, mapBreakpoints } = useBreakpoints();
  const { emit } = useEventBus();
  const columns = mapBreakpoints({ xs: 3, sm: 17 });

  const handleResolve = () => {
    if (transaction.payment) emit(PaymentsEvents.openPaymentResolve, transaction.payment?.id);
    if (transaction.deposit) emit(DepositsEvents.openDepositResolve, transaction.deposit?.id);
    if (transaction.invoice) emit(InvoicesEvents.openInvoiceResolve, transaction.invoice?.id);
  };

  const handleRefund = () => {
    emit(WithdrawalsEvents.openWithdrawal, transaction.value.crypto.currency);
  };

  const handleView = () => {
    emit(CommonEvents.openTransactionMetaData, transaction);
  };

  const name = transaction.payment?.name || transaction.invoice?.name || transaction.deposit?.name;
  const ableToResolve = !resolvedStatuses.includes(transaction.affect_status?.status);
  const statusColor = useMemo(() => {
    if (resolvedStatuses.includes(transaction.affect_status?.status)) return 'success';
    return 'error';
  }, [transaction]);

  if (isUp(Breakpoints.md))
    return (
      <TableRow columns={columns}>
        <TableCell span={2}>
          <DateTime date={transaction.confirmed_at} size={'sm'} />
        </TableCell>

        <TableCell className={'name'} span={3}>
          {name}
          <br />
          {transaction.deposit && `ID ${transaction.deposit.user_id}`}
        </TableCell>

        <TableCell span={4}>
          <DoublePrice
            primary={transaction.value.crypto}
            secondary={transaction.value.local}
            maxPrecision={precisionByCurrency(transaction.value.crypto.currency)}
            align="start"
          />
        </TableCell>

        <TableCell span={3}>
          <Status status={statusColor}>{transaction.affect_status?.status}</Status>
        </TableCell>

        <TableCell span={3}>
          {ableToResolve && <button onClick={handleResolve}>Resolve</button>}
          <button onClick={handleRefund}>Refund</button>
        </TableCell>

        <TableCell align="center" span={2}>
          <DetailsLink onClick={handleView}>
            <IconFileText />
          </DetailsLink>
        </TableCell>
      </TableRow>
    );

  return (
    <TableCard gutter={'sm'}>
      <Inline style={{ justifyContent: 'space-between' }}>
        <TableMobileName>
          {name}
          <DetailsLink onClick={handleView} style={{ fontSize: '20px' }}>
            <IconFileText />
          </DetailsLink>
        </TableMobileName>
        <TableMobileStatus
          unresolved={!resolvedStatuses.includes(transaction.affect_status?.status)}>
          {transaction.affect_status?.status}
        </TableMobileStatus>
      </Inline>
      <DoublePrice
        primary={transaction.value.crypto}
        secondary={transaction.value.local}
        maxPrecision={precisionByCurrency(transaction.value.crypto.currency)}
        size={'xs'}
        align="center"
      />
      <Inline style={{ justifyContent: 'space-between' }}>
        <TableMobileMeta gutter={0}>
          <DateTime date={transaction.confirmed_at} size={'sm'} inline />

          {transaction.deposit && <div>ID {transaction.deposit.user_id}</div>}
        </TableMobileMeta>
        <TableMobileActions>
          {ableToResolve && (
            <Button size={'xs'} variant={'link'} onClick={handleResolve}>
              Resolve
            </Button>
          )}
          <Button size={'xs'} onClick={handleRefund}>
            Refund
          </Button>
        </TableMobileActions>
      </Inline>
    </TableCard>
  );
}
