import { ChangeEvent, ChangeEventHandler, useState } from 'react';
import { styled } from '@use-gateway/theme';
import { styledTransition } from '@use-gateway/utils';

const ToggleWrapper = styled.label`
  position: relative;

  width: 36px;
  height: 22px;
  border-radius: 11px;
  background-color: ${({ theme }) => theme.colors.light[80]};

  cursor: pointer;

  ${styledTransition(['background-color'], 150)}

  &::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.light[100]};
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.16);

    ${styledTransition(['transform', 'box-shadow'], 150)}
  }

  &.active {
    background-color: ${({ theme }) => theme.colors.primary};

    &::before {
      transform: translateX(14px);
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0), 0px 0px 1px rgba(0, 0, 0, 0);
    }
  }

  input {
    display: none;
  }
`;

export interface ToggleProps {
  defaultValue?: boolean;
  onChange?: (value: boolean, e: ChangeEvent<HTMLInputElement>) => void;
}
export function Toggle({ defaultValue, onChange }: ToggleProps) {
  const [innerValue, setInnerValue] = useState(defaultValue ?? false);

  const handleToggle: ChangeEventHandler<HTMLInputElement> = (e) => {
    setInnerValue(e.currentTarget.checked);
    if (onChange) onChange(e.currentTarget.checked, e);
  };

  return (
    <ToggleWrapper className={innerValue ? 'active' : ''}>
      <input type={'checkbox'} checked={innerValue} onChange={handleToggle} />
    </ToggleWrapper>
  );
}
