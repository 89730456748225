import { format, parseISO } from 'date-fns';
import styled from 'styled-components';
import { Size } from '@use-gateway/types';

const DateTimeWrapper = styled.span<{ size?: Size }>`
  font-size: ${({ size }) => {
    if (size === 'lg') return '20px';
    if (size === 'sm') return '14px';
    return '16px';
  }};
`;

export interface DateTimeProps {
  date: string;
  inline?: boolean;
  size?: Size;
}
export function DateTime({ date, size, inline }: DateTimeProps) {
  const parsed = parseISO(date);

  if (inline)
    return <DateTimeWrapper size={size}>{format(parsed, 'HH:mm MMM dd, yyyy')}</DateTimeWrapper>;

  return (
    <DateTimeWrapper size={size}>
      {format(parsed, 'dd.MM.yy')}
      <br />
      {format(parsed, 'HH:mm')}
    </DateTimeWrapper>
  );
}
