import { ReactNode, useEffect } from 'react';
import { down } from 'styled-breakpoints';
import { Columns } from '@bedrock-layout/columns';
import { IconX } from '@use-gateway/icons';
import { Breakpoints, styled } from '@use-gateway/theme';
import { Size, UnknownFunction } from '@use-gateway/types';
import { styledTransition } from '@use-gateway/utils';
import { Button } from './button';
import { Portal } from './portal';
import { Scroll } from './scroll';

const MODAL_ROOT_ID = 'modal-root';

const ModalRootWrapper = styled.div`
  z-index: 2500;
  background-color: rgba(0, 0, 0, 0.25);
`;

export function ModalRoot() {
  return <ModalRootWrapper id={MODAL_ROOT_ID} />;
}

export interface ModalContainerProps {
  noBottomBar?: boolean;
}
export const ModalContainer = styled.div`
  width: 100vw;
  height: 100vh;

  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 64px 0;
  overflow-y: auto;

  backdrop-filter: blur(3px);
  z-index: 2500;

  ${down(Breakpoints.sm)} {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.55);
  }
`;

const ModalWrapper = styled.div<{ size?: Size }>`
  position: relative;

  display: flex;
  flex-direction: column;

  overflow: hidden;
  padding: 32px;
  background-color: ${({ theme }) => theme.colors.light[100]};
  box-sizing: border-box;
  height: fit-content;

  ${({ size }) => {
    if (size === 'lg') return 'width: 900px;';
    if (size === 'sm') return 'width: 350px;';
    return 'width: 526px;';
  }}

  max-width: calc(100% - 62px);

  box-shadow: 12px 12px 98px rgba(0, 0, 0, 0.18);
  border-radius: 32px;

  z-index: 2500;

  & ${Columns} {
    label {
      display: flex;
      align-items: center;
    }
  }

  ${down(Breakpoints.sm)} {
    box-sizing: border-box;
    overflow: hidden;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    max-width: 100%;
    max-height: 90vh;
    padding: 0;
    border-radius: 24px 24px 0 0;
    background-color: ${({ theme }) => theme.colors.light[100]};
  }
`;

const ModalHeader = styled.header`
  position: relative;
  padding-bottom: 32px;
  padding-right: 32px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  ${down(Breakpoints.sm)} {
    z-index: 500;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 70px;
    padding: 8px 48px 8px 24px;

    background-color: ${({ theme }) => theme.colors.bgBlur};
    backdrop-filter: blur(5px);
  }
`;

const ModalTitle = styled.h3`
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.dark[100]};

  ${down(Breakpoints.sm)} {
    font-weight: 700;
    font-size: 22px;
    line-height: 1;
  }
`;

const ModalHeaderActions = styled.div`
  align-self: flex-end;
  display: flex;

  ${down(Breakpoints.sm)} {
    align-self: center;
  }
`;

interface ModalContentProps {
  padding?: string;
}

const ModalContent = styled(Scroll)<ModalContentProps>`
  max-height: calc(80vh - 210px);
  color: ${({ theme }) => theme.colors.dark[100]};

  ${down(Breakpoints.sm)} {
    box-sizing: border-box;
    max-height: calc(90vh - 34px);
    padding: ${({ padding }) => (padding ? padding : '70px 24px 24px')};
  }

  & > hr {
    height: 1px;
    background: #f2f3f5;
    margin: 16px -32px;
    border: none;
  }
`;

export const ModalText = styled.div`
  position: relative;
  margin-bottom: 32px;
  padding: 0 18px;

  &:last-child {
    margin-bottom: 0;
  }

  & > p {
    margin: 0 0 16px;
    line-height: 1.25;
    letter-spacing: -0.01em;
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${down('sm')} {
    padding: 0;
  }
`;

export const ModalCongratulationsImg = styled.div`
  width: 80px;
  height: 80px;
  margin: 0 auto 20px;
  background-repeat: no-repeat;
  background-size: 100% auto;
  font-size: 80px;
  color: ${({ theme }) => theme.colors.dark[100]};
`;

const ModalButtons = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  padding: 24px 0 0;

  ${down('sm')} {
    box-sizing: border-box;
    align-items: center;
    bottom: 0;
    height: 82px;
    padding: 8px 24px;
  }
`;

const ModalClose = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  font-size: 20px;
  cursor: pointer;

  ${styledTransition(['transform'])}

  ${down('sm')} {
    top: 26px;
    right: 24px;
  }

  &:hover {
    transform: scale(1.2);
  }
`;

export const ModalLoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;

  backdrop-filter: brightness(2) blur(20px);
  box-shadow: inset 0 0 16px 10px #fff;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export interface ModalProps {
  children: ReactNode;
  title?: string;
  headerActions?: ReactNode;
  backText?: string;
  backVisible?: boolean;
  nextText?: string;
  nextVisible?: boolean;
  nextDisabled?: boolean;
  size?: Size;
  paddingContent?: string;
  onNext?: UnknownFunction;
  onBack?: UnknownFunction;
  onClose?: UnknownFunction;
}
export function Modal({
  children,
  title,
  headerActions,
  backText,
  backVisible = true,
  nextText,
  nextVisible = true,
  nextDisabled,
  size,
  paddingContent,
  onNext,
  onBack,
  onClose,
}: ModalProps) {
  const handleClose = () => {
    if (onClose) onClose();
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <Portal targetId={MODAL_ROOT_ID}>
      <ModalContainer onClick={handleClose}>
        <ModalWrapper size={size} onClick={(event) => event.stopPropagation()}>
          <ModalHeader>
            {title && <ModalTitle>{title}</ModalTitle>}
            {headerActions && <ModalHeaderActions>{headerActions}</ModalHeaderActions>}
            <ModalClose onClick={handleClose}>
              <IconX />
            </ModalClose>
          </ModalHeader>
          <ModalContent padding={paddingContent}>{children}</ModalContent>
          {((onBack && backVisible) || (onNext && nextVisible)) && (
            <ModalButtons>
              {onBack && backVisible && (
                <Button variant={'secondary'} onClick={onBack}>
                  {backText ? backText : 'Cancel'}
                </Button>
              )}
              {onNext && nextVisible && (
                <Button onClick={onNext} disabled={nextDisabled}>
                  {nextText ? nextText : 'Next'}
                </Button>
              )}
            </ModalButtons>
          )}
        </ModalWrapper>
      </ModalContainer>
    </Portal>
  );
}
