import { ReactNode } from 'react';
import { down } from 'styled-breakpoints';
import { styled } from '@use-gateway/theme';

const InfoCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 16px 36px 16px 36px;
  background: ${({ theme }) => theme.colors.light[80]};
  border-radius: 12px;
  height: 100%;
  box-sizing: border-box;

  ${down('md')} {
    align-items: flex-start;
    flex-direction: column;
    gap: 14px;
  }

  ${down('sm')} {
    padding: 0;
    border-radius: 0;
    background-color: transparent;
  }
`;

const InfoCardData = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 4px;
`;

const InfoCardDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: ${({ theme }) => theme.colors.dark[40]};
`;

const InfoCardActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  margin-right: -18px;
`;

export interface InfoCardProps {
  data: string | ReactNode;
  description: string;
  actions?: ReactNode;
}
export function InfoCard({ data, description, actions }: InfoCardProps) {
  return (
    <InfoCardWrapper>
      <div>
        <InfoCardData>{data}</InfoCardData>
        <InfoCardDescription>{description}</InfoCardDescription>
      </div>
      {actions && <InfoCardActions>{actions}</InfoCardActions>}
    </InfoCardWrapper>
  );
}
