// @ts-nocheck
import { forwardRef } from 'react';
const SvgTestimonials = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path fill="currentColor" d="M7.5 12H16.5V13.5H7.5V12ZM7.5 7.5H16.5V9H7.5V7.5Z" />
    <path
      fill="currentColor"
      d="M12 22.5L7.368 20.0303C6.0475 19.3277 4.94323 18.2789 4.1737 16.9963C3.40416 15.7137 2.99842 14.2457 3 12.75V3C3.0004 2.6023 3.15856 2.221 3.43978 1.93978C3.721 1.65856 4.1023 1.5004 4.5 1.5H19.5C19.8977 1.5004 20.279 1.65856 20.5602 1.93978C20.8414 2.221 20.9996 2.6023 21 3V12.75C21.0016 14.2457 20.5958 15.7137 19.8263 16.9963C19.0568 18.2789 17.9525 19.3277 16.632 20.0303L12 22.5ZM4.5 3V12.75C4.49876 13.9739 4.83083 15.1749 5.46058 16.2243C6.09032 17.2737 6.99396 18.1318 8.0745 18.7065L12 20.7997L15.9255 18.7073C17.0062 18.1325 17.9099 17.2743 18.5396 16.2248C19.1694 15.1752 19.5014 13.974 19.5 12.75V3H4.5Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgTestimonials);
export default ForwardRef;
