// @ts-nocheck
import { forwardRef } from 'react';
const SvgPayments = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 22 22"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M4.18846 13L4.72731 14.0173C4.35808 14.3656 4.15385 14.7449 4.15385 15.1297C4.15385 16.5518 6.95423 17.8942 10.5 17.8942C14.0458 17.8942 16.8462 16.5518 16.8462 15.1297C16.8462 14.7449 16.6408 14.3668 16.2727 14.0184L16.8219 13.0088C17.5662 13.606 18 14.3292 18 15.1297C18 17.3712 14.6008 19 10.5 19C6.39923 19 3 17.3712 3 15.1297C3 14.3247 3.43846 13.5993 4.18846 13V13Z"
    />
    <path
      fill="currentColor"
      d="M4.18846 9L4.72731 10.1869C4.35808 10.5945 4.15385 11.037 4.15385 11.4847C4.15385 13.1438 6.95423 14.7099 10.5 14.7099C14.0458 14.7099 16.8462 13.1438 16.8462 11.4847C16.8462 11.0358 16.6408 10.5945 16.2727 10.1882L16.8219 9.01032C17.5662 9.70697 18 10.5507 18 11.4847C18 14.0997 14.6008 16 10.5 16C6.39923 16 3 14.0997 3 11.4847C3 10.5455 3.43846 9.69923 4.18846 9V9Z"
    />
    <path
      fill="currentColor"
      d="M10.5 12C6.39923 12 3 10.3166 3 8C3 5.68343 6.39923 4 10.5 4C14.6008 4 18 5.68343 18 8C18 10.3166 14.6008 12 10.5 12ZM10.5 10.8571C14.0458 10.8571 16.8462 9.46971 16.8462 8C16.8462 6.53029 14.0458 5.14286 10.5 5.14286C6.95423 5.14286 4.15385 6.53029 4.15385 8C4.15385 9.46971 6.95423 10.8571 10.5 10.8571Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPayments);
export default ForwardRef;
