import { useEffect, useState } from 'react';
import { throttle } from 'lodash-es';
import { createTheme, down, only, up, between } from 'styled-breakpoints';
import { useTheme } from 'styled-components';
import { isBrowser } from '@use-gateway/utils';

export enum Breakpoints {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

export const BreakpointsOrder: Array<Breakpoints> = [
  Breakpoints.xs,
  Breakpoints.sm,
  Breakpoints.md,
  Breakpoints.lg,
];

export type Breakpoint = Breakpoints.xs | Breakpoints.sm | Breakpoints.md | Breakpoints.lg;

export const breakpoints = createTheme({
  [Breakpoints.xs]: '360px',
  [Breakpoints.sm]: '768px',
  [Breakpoints.md]: '992px',
  [Breakpoints.lg]: '1200px',
});

export const useBreakpoints = () => {
  const [screenSize, setScreenSize] = useState(0);
  const theme = useTheme();

  const handleResize = throttle(() => setScreenSize(window.innerWidth), 500);

  useEffect(() => {
    if (isBrowser()) {
      handleResize();
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (isBrowser()) window.removeEventListener('resize', handleResize);
    };
  }, []);

  const matches = (media: string) => {
    if (isBrowser()) return window.matchMedia(media.replace('@media ', '')).matches;
    return false;
  };

  const isUp = (bp: Breakpoints) => matches(up(bp)({ theme }));
  const isDown = (bp: Breakpoints) => matches(down(bp)({ theme }));
  const isOnly = (bp: Breakpoints) => matches(only(bp)({ theme }));
  const isBetween = (start: Breakpoints, end: Breakpoints) =>
    matches(between(start, end)({ theme }));

  const mapBreakpoints = <T>(map: Partial<Record<Breakpoints, T>>): T => {
    const sorted = BreakpointsOrder.map((breakpoint) => ({
      breakpoint,
      value: map[breakpoint],
    })).filter((item) => item.value);

    if (!isBrowser) return sorted[0].value as T;

    const current = sorted.find((item, index) => {
      const isFirst = index === 0;
      const isLast = index === sorted.length - 1;
      const isInTheMiddle = !isFirst && !isLast;

      if (isFirst && (isDown(sorted[1].breakpoint) || isOnly(item.breakpoint))) return true;
      if (isLast && isUp(item.breakpoint)) return true;
      if (isInTheMiddle && isBetween(item.breakpoint, sorted[index + 1].breakpoint)) return true;

      return false;
    });

    return (current?.value ?? sorted[0].value) as T;
  };

  return {
    screenSize,
    isUp,
    isDown,
    isOnly,
    isBetween,
    mapBreakpoints,
  };
};
