// @ts-nocheck
import { forwardRef } from 'react';
const SvgScissors = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6 4C4.89543 4 4 4.89543 4 6C4 7.10457 4.89543 8 6 8C7.10457 8 8 7.10457 8 6C8 4.89543 7.10457 4 6 4ZM2 6C2 3.79086 3.79086 2 6 2C8.20914 2 10 3.79086 10 6C10 8.20914 8.20914 10 6 10C3.79086 10 2 8.20914 2 6Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6 16C4.89543 16 4 16.8954 4 18C4 19.1046 4.89543 20 6 20C7.10457 20 8 19.1046 8 18C8 16.8954 7.10457 16 6 16ZM2 18C2 15.7909 3.79086 14 6 14C8.20914 14 10 15.7909 10 18C10 20.2091 8.20914 22 6 22C3.79086 22 2 20.2091 2 18Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.7071 3.29289C21.0976 3.68342 21.0976 4.31658 20.7071 4.70711L8.82711 16.5871C8.43659 16.9776 7.80342 16.9776 7.4129 16.5871C7.02237 16.1966 7.02237 15.5634 7.4129 15.1729L19.2929 3.29289C19.6834 2.90237 20.3166 2.90237 20.7071 3.29289Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.7623 13.7735C14.1524 13.3827 14.7856 13.3821 15.1765 13.7722L20.7065 19.2922C21.0973 19.6824 21.0979 20.3156 20.7077 20.7065C20.3176 21.0973 19.6844 21.0979 19.2935 20.7077L13.7635 15.1877C13.3727 14.7976 13.3721 14.1644 13.7623 13.7735Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.4129 7.4129C7.80342 7.02237 8.43659 7.02237 8.82711 7.4129L12.7071 11.2929C13.0976 11.6834 13.0976 12.3166 12.7071 12.7071C12.3166 13.0976 11.6834 13.0976 11.2929 12.7071L7.4129 8.82711C7.02237 8.43659 7.02237 7.80342 7.4129 7.4129Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgScissors);
export default ForwardRef;
