import { ReactNode } from 'react';
import type { FieldError } from 'react-hook-form';
import { SystemStatus } from './system-status';

export type RuntimeEnvironment = 'production' | 'sandbox' | 'development';

export type UnknownFunction<T = unknown> = (...args: Array<unknown>) => T;

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

interface ErrorLocation extends Array<unknown> {
  0: string;
  1: number;
}

export interface ErrorResponse {
  detail:
    | string
    | Array<{
        loc: ErrorLocation;
        msg: string;
        type?: string;
      }>;
}

export interface FieldState {
  invalid: boolean;
  isDirty: boolean;
  isTouched: boolean;
  error?: FieldError | undefined;
}

export enum Language {
  en = 'en',
}
export const LanguageName: Record<Language, string> = {
  [Language.en]: 'English',
};

export type Size = 'xs' | 'sm' | 'md' | 'lg';

export interface Paginated<T> {
  items: Array<T>;
  page: number;
  size: number;
  total: number;
}

export type Align = 'start' | 'center' | 'end';

export interface ComponentProps {
  children?: ReactNode;
}

export interface PageProps {
  systemStatus: SystemStatus | null;
}

export interface DateRange extends Array<Date> {
  0: Date;
  1: Date;
}

export enum ProjectNames {
  UseGateway = 'UseGateway',
  ChangePay = 'ChangePay',
  WhiteLabel = 'WhiteLabel',
}
