// @ts-nocheck
import { forwardRef } from 'react';
const SvgNavigation2 = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 1C12.419 1 12.7935 1.26117 12.9383 1.65429L19.9383 20.6543C20.0836 21.0487 19.9668 21.4918 19.646 21.7633C19.3252 22.0349 18.8688 22.0768 18.5039 21.8682L12 18.1518L5.49614 21.8682C5.13121 22.0768 4.67484 22.0349 4.35401 21.7633C4.03317 21.4918 3.91636 21.0487 4.06166 20.6543L11.0617 1.65429C11.2065 1.26117 11.581 1 12 1ZM6.88738 18.7697L11.5039 16.1318C11.8113 15.9561 12.1887 15.9561 12.4961 16.1318L17.1126 18.7697L12 4.89264L6.88738 18.7697Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgNavigation2);
export default ForwardRef;
