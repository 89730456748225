import getConfig from 'next/config';
import { RuntimeEnvironment } from '@use-gateway/types';

const { publicRuntimeConfig } = getConfig();
const { RUNTIME_ENV, BASE_URL } = publicRuntimeConfig;

const isDev = process.env.NODE_ENV === 'development';

export function getEnvProtocol(): string {
  if (isDev) return 'http';
  return 'https';
}

export function getBaseDomain(): string {
  const baseDomain: Record<RuntimeEnvironment, string> = {
    production: 'usegateway.net',
    development: 'usegateway.app',
    sandbox: 'usegateway.online',
  };

  if (process.env.NODE_ENV === 'development') {
    return 'localhost';
  }

  return baseDomain[RUNTIME_ENV];
}

export function getLandingDomain(): string {
  const baseDomain = getBaseDomain();
  const panelDomain: Record<RuntimeEnvironment, string> = {
    production: `${baseDomain}`,
    development: `landing-dev.${baseDomain}`,
    sandbox: `${baseDomain}`,
  };

  if (isDev) return `localhost:3001`;

  return panelDomain[RUNTIME_ENV];
}

export function getPayDomain(): string {
  const baseDomain = getBaseDomain();
  const panelDomain: Record<RuntimeEnvironment, string> = {
    production: `pay.${baseDomain}`,
    development: `pay-dev.${baseDomain}`,
    sandbox: `pay.${baseDomain}`,
  };

  if (isDev) return `localhost:3001`;

  return panelDomain[RUNTIME_ENV];
}

export function getPanelDomain(): string {
  const baseDomain = getBaseDomain();
  const panelDomain: Record<RuntimeEnvironment, string> = {
    production: `panel.${baseDomain}`,
    development: `panel-dev.${baseDomain}`,
    sandbox: `panel.${baseDomain}`,
  };

  if (isDev) return `localhost:3001`;

  return panelDomain[RUNTIME_ENV];
}

export function getLoginUrl() {
  return `${getEnvProtocol()}://${getPanelDomain()}/login/`;
}

export function getRegisterUrl() {
  return `${getEnvProtocol()}://${getPanelDomain()}/register/`;
}

export function getGuideUrl() {
  return `${getEnvProtocol()}://${getLandingDomain()}/guide/`;
}

export function getEnvBaseURL() {
  if (BASE_URL) return BASE_URL;
  return `${getEnvProtocol()}://${getLandingDomain()}`;
}
