// @ts-nocheck
import { forwardRef } from 'react';
const SvgBusdBep20 = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 64 64"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_3630_36491)">
      <path
        fill="#F0B90B"
        fillRule="evenodd"
        d="M32 0C49.6744 0 64 14.3256 64 32C64 49.6744 49.6744 64 32 64C14.3256 64 0 49.6744 0 32C0 14.3256 14.3256 0 32 0Z"
        clipRule="evenodd"
      />
      <g clipPath="url(#clip1_3630_36491)">
        <path
          fill="#F0B90B"
          fillRule="evenodd"
          d="M55 46C59.9709 46 64 50.0291 64 55C64 59.9709 59.9709 64 55 64C50.0291 64 46 59.9709 46 55C46 50.0291 50.0291 46 55 46Z"
          clipRule="evenodd"
        />
        <path
          fill="white"
          d="M50.9464 55L50.9529 57.3798L52.975 58.5697V59.963L49.7695 58.0829V54.3041L50.9464 55ZM50.9464 52.6202V54.007L49.7688 53.3103V51.9236L50.9464 51.2269L52.1299 51.9236L50.9464 52.6202ZM53.8195 51.9236L54.9972 51.2269L56.1806 51.9236L54.9972 52.6202L53.8195 51.9236Z"
        />
        <path
          fill="white"
          d="M51.7974 56.8865V55.4933L52.975 56.1899V57.5767L51.7974 56.8865ZM53.8195 59.0688L54.9971 59.7654L56.1805 59.0688V60.4555L54.9971 61.1522L53.8195 60.4555V59.0688ZM57.8695 51.9236L59.0471 51.2269L60.2305 51.9236V53.3103L59.0471 54.007V52.6202L57.8695 51.9236ZM59.0471 57.3798L59.0536 55L60.2313 54.3034V58.0822L57.0257 59.9623V58.569L59.0471 57.3798Z"
        />
        <path fill="white" d="M58.2027 56.8866L57.025 57.5767V56.1899L58.2027 55.4933V56.8866Z" />
        <path
          fill="white"
          d="M58.2027 53.1134L58.2091 54.5067L56.1813 55.6966V58.0822L55.0036 58.7723L53.826 58.0822V55.6966L51.7981 54.5067V53.1134L52.9808 52.4168L54.9964 53.6124L57.0243 52.4168L58.2077 53.1134H58.2027ZM51.7974 50.7343L54.9971 48.8478L58.2027 50.7343L57.025 51.431L54.9971 50.2353L52.975 51.431L51.7974 50.7343Z"
        />
      </g>
      <rect width={17} height={17} x={46.5} y={46.5} stroke="white" rx={8.5} />
      <g clipPath="url(#clip2_3630_36491)">
        <path
          fill="white"
          stroke="white"
          d="M31.9992 8.10132L37.9206 14.1653L23.0102 29.0757L17.0889 23.1544L31.9992 8.10132Z"
        />
        <path
          fill="white"
          stroke="white"
          d="M40.9883 17.0903L46.9096 23.1544L23.0102 47.0538L17.0889 41.1324L40.9883 17.0903Z"
        />
        <path
          fill="white"
          stroke="white"
          d="M14.0212 26.0793L19.9425 32.1434L14.0212 38.0647L8.09985 32.1434L14.0212 26.0793Z"
        />
        <path
          fill="white"
          stroke="white"
          d="M49.9773 26.0793L55.8986 32.1434L31.9992 56.0428L26.0779 50.1214L49.9773 26.0793Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_3630_36491">
        <rect width={64} height={64} fill="white" />
      </clipPath>
      <clipPath id="clip1_3630_36491">
        <rect width={18} height={18} x={46} y={46} fill="white" rx={9} />
      </clipPath>
      <clipPath id="clip2_3630_36491">
        <rect width={48} height={48.1441} fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgBusdBep20);
export default ForwardRef;
