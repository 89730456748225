// @ts-nocheck
import { forwardRef } from 'react';
const SvgFilter = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M7 8H18M9.1978 12.5H15.7945M11.4 17H13.5945"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgFilter);
export default ForwardRef;
