/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { DeepPartial } from '@use-gateway/types';
import { breakpoints } from './breakpoints';
import { GatewayTheme } from './model';

export const changePayTheme: DeepPartial<GatewayTheme> = {
  ...breakpoints,
  colors: {
    get primary() {
      return this.accent![100];
    },
    get secondary() {
      return this.light![20];
    },
    get accent() {
      return this.info;
    },
    bg: '#000',
    btn: '#ffffff',
    bgBlur: 'rgba(83, 86, 107, 0.73)',
    dark: {
      100: '#ffffff',
      80: '#F2F3F5',
      40: '#E9EAEF',
      20: '#EEEEEE',
    },
    light: {
      100: '#191919',
      90: '#292929',
      80: '#292929',
      70: '#8E90A7',
      60: '#777777',
      40: '#B2B3C2',
      20: '#AAAAAA',
    },
    success: {
      100: '#4BB34B',
      90: '#5dba5d',
      80: '#6ec26e',
      70: '#81c981',
      60: '#93d193',
      50: '#a5d9a5',
      40: '#b7e0b7',
      30: '#c9e8c9',
      20: '#dbefdb',
      10: '#edf7ed',
    },
    danger: {
      100: '#E64646',
      90: '#e85858',
      80: '#eb6a6a',
      70: '#ed7d7d',
      60: '#f09090',
      50: '#f2a2a2',
      40: '#f5b5b5',
      30: '#f7c7c7',
      20: '#fadada',
      10: '#fcecec',
    },
    warning: {
      100: '#FFC107',
      90: '#ffc71f',
      80: '#ffcd38',
      70: '#ffd351',
      60: '#ffd96a',
      50: '#ffe083',
      40: '#ffe69b',
      30: '#ffecb4',
      20: '#fff2cd',
      10: '#fff8e6',
    },
    info: {
      100: '#ff0000',
      90: '#191919',
      80: '#292929',
      70: '#292929',
      60: '#8E90A7',
      50: '#777777',
      40: '#B2B3C2',
      30: '#AAAAAA',
      20: '#dadeff',
      10: '#eceeff',
    },
  },
  shadows: {},
  borderRadius: {
    xs: '4px',
    sm: '8px',
    md: '12px',
    lg: '24px',
    xl: '32px',
  },
};
