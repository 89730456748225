import { NextApiRequest, NextApiResponse } from 'next';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const { SENTRY_DSN } = publicRuntimeConfig;

let SENTRY_HOST: string | undefined;
let SENTRY_PROJECT_ID: string | undefined;

try {
  const { host, pathname } = new URL(SENTRY_DSN);
  SENTRY_HOST = host;
  SENTRY_PROJECT_ID = pathname.substring(1);
} catch (_error) {
  SENTRY_HOST = undefined;
  SENTRY_PROJECT_ID = undefined;
}

// Set knownProjectIds to an array with your Sentry project IDs which you
// want to accept through this proxy.
const knownProjectIds = [SENTRY_PROJECT_ID];

export async function sentryTunnel(req: NextApiRequest, res: NextApiResponse) {
  try {
    const envelope = req.body;
    const pieces = envelope.split('\n');
    const header = JSON.parse(pieces[0]);
    // DSNs are of the form `https://<key>@o<orgId>.ingest.sentry.io/<projectId>`
    const { host, pathname } = new URL(header.dsn);
    // Remove leading slash
    const projectId = pathname.substring(1);

    if (host !== SENTRY_HOST) {
      throw new Error(`invalid host: ${host}`);
    }

    if (!knownProjectIds.includes(projectId)) {
      throw new Error(`invalid project id: ${projectId}`);
    }

    const sentryIngestURL = `https://${SENTRY_HOST}/api/${SENTRY_PROJECT_ID}/envelope/`;
    const sentryResponse = await fetch(sentryIngestURL, { method: 'POST', body: envelope });

    // Relay response from Sentry servers to front end
    sentryResponse.headers.forEach((value, key) => res.setHeader(key, value));
    res.status(sentryResponse.status).send(sentryResponse.body);
  } catch (e) {
    console.error('tunnel error', e);
    return res.status(400).json({
      status: 'invalid request',
      details: e.message,
    });
  }
}
