// @ts-nocheck
import { forwardRef } from 'react';
const SvgCpu = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6 5C5.44772 5 5 5.44772 5 6V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V6C19 5.44772 18.5523 5 18 5H6ZM3 6C3 4.34315 4.34315 3 6 3H18C19.6569 3 21 4.34315 21 6V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 9C8 8.44771 8.44771 8 9 8H15C15.5523 8 16 8.44771 16 9V15C16 15.5523 15.5523 16 15 16H9C8.44771 16 8 15.5523 8 15V9ZM10 10V14H14V10H10Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9 0C9.55229 0 10 0.447715 10 1V4C10 4.55228 9.55229 5 9 5C8.44771 5 8 4.55228 8 4V1C8 0.447715 8.44771 0 9 0Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15 0C15.5523 0 16 0.447715 16 1V4C16 4.55228 15.5523 5 15 5C14.4477 5 14 4.55228 14 4V1C14 0.447715 14.4477 0 15 0Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9 19C9.55229 19 10 19.4477 10 20V23C10 23.5523 9.55229 24 9 24C8.44771 24 8 23.5523 8 23V20C8 19.4477 8.44771 19 9 19Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15 19C15.5523 19 16 19.4477 16 20V23C16 23.5523 15.5523 24 15 24C14.4477 24 14 23.5523 14 23V20C14 19.4477 14.4477 19 15 19Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19 9C19 8.44771 19.4477 8 20 8H23C23.5523 8 24 8.44771 24 9C24 9.55229 23.5523 10 23 10H20C19.4477 10 19 9.55229 19 9Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19 14C19 13.4477 19.4477 13 20 13H23C23.5523 13 24 13.4477 24 14C24 14.5523 23.5523 15 23 15H20C19.4477 15 19 14.5523 19 14Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M0 9C0 8.44771 0.447715 8 1 8H4C4.55228 8 5 8.44771 5 9C5 9.55229 4.55228 10 4 10H1C0.447715 10 0 9.55229 0 9Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M0 14C0 13.4477 0.447715 13 1 13H4C4.55228 13 5 13.4477 5 14C5 14.5523 4.55228 15 4 15H1C0.447715 15 0 14.5523 0 14Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCpu);
export default ForwardRef;
