import { Modal, ModalProps } from './modal';

export function PrivacyPolicyModal({ onClose }: Partial<ModalProps>) {
  return (
    <Modal title="Privacy policy" size={'lg'} onClose={onClose}>
      <h3>1. Terms and Definitions</h3>

      <p>
        1.1. The User&apos;s personal information (Personal Information) is the information the User
        provides about himself/herself when registering (creating an account) or while using the
        Service, that includee the User&apos;s personal data as well as the data automatically
        transmitted to the Service during its use by the software installed on the User&apos;s
        device, including IP address, cookies, information about the User&apos;s browser (or other
        program used to access the services), technical characteristics of hardware and software
        used by the User, date and time of access to the services, addresses of requested web-pages
        (URL) and other similar information.
      </p>
      <p>
        1.2. User is a private individual, a Web user engaging UseGateway Service for his own
        personal purposes, as well as any other private individual for the benefit of whom the user
        is acting.
      </p>
      <p>
        1.3. UseGateway Service / Service is a service which allows Users to use digital payment
        facilities and execute funds transfers without opening a bank account. The copyright holder
        of the Service is Trendpay LLC, ID №404613245, Krtsanisi district, Mtkvari str., N6,
        Tbilisi, Georgia.
      </p>

      <h3>2. Assumption of Privacy Policy</h3>

      <p>
        2.1. The Privacy Policy defines the way the UseGateway Service collects, processes and
        stores Users&apos; Personal Information, as well as its legal aspects and administration of
        such information. The UseGateway Service is committed to treat Users&apos; Personal
        Information with the utmost care and diligence to protect it.
      </p>
      <p>
        2.2. This Privacy Policy applies to the{' '}
        <a href={'https://usegateway.net/'} target={'_blank'} rel="noreferrer">
          Usegateway.net
        </a>
        ,{' '}
        <a href={'http://usegateway.group/'} target={'_blank'} rel="noreferrer">
          Usegateway.group
        </a>
        , and{' '}
        <a href={'https://usegateway.app/'} target={'_blank'} rel="noreferrer">
          Usegateway.app
        </a>
        , including subdomains.
      </p>
      <p>
        2.3. This Privacy Policy is applicable to information that the UseGateway Service may
        collect in relation to the User. The Privacy Policy solely applies to the UseGateway
        Service. The UseGateway Service does not control and is not responsible for the
        websites/services of third parties to which the User may access via links available within
        the Service, as well as the User’s information processed by such third parties.
      </p>
      <p>
        2.4. The use of the UseGateway Service signifies the User&apos;s unreserved consent to this
        Privacy Policy and the terms of processing his/her Personal Information specified herein; in
        the event of disagreement with these terms, the User shall refrain from using the UseGateway
        Service.
      </p>
      <p>
        2.5. By accepting the Privacy Policy, the User acknowledges that he or she is over the age
        of 18.
      </p>

      <h3>3. Privacy protection principles</h3>

      <p>
        3.1. In order to fully comply with privacy regulations, the UseGateway Service adheres to
        the following principles to protect privacy of its Users:
      </p>
      <p>3.1.1. Respect the right of Users to access their Personal Information.</p>
      <p>
        3.1.2. The prohibition of renting or selling the User&apos;s Personal Information to the
        third parties.
      </p>
      <p>
        3.1.3. Any Personal Information provided to the Service shall be protected by standard
        protocols and security technologies.
      </p>
      <p>
        3.1.4. The UseGateway Service undertakes to comply with specific principles on the
        processing of Personal Information collected from the EU in accordance with the GDPR.
      </p>
      <p>
        3.1.5. The UseGateway Service collects the Personal Information of Users only for the
        purposes set out herein.
      </p>
      <p>
        3.1.6. The UseGateway Service offers the opportunity to contact the Service regarding
        queries and complaints related to Users’ Personal Information.
      </p>

      <h3>4. Collection and storage of the User&apos;s Personal Information</h3>

      <p>
        4.1. While using the website, the Service, without prior notification, collects the
        following information about the User:
      </p>
      <p>- IP address;</p>
      <p>- selected currency;</p>
      <p>- selected payment methods;</p>
      <p>- email address;</p>
      <p>- Telegram account details;</p>
      <p>- deposit details;</p>
      <p>- invoices issued;</p>
      <p>- e-purses created;</p>
      <p>- payments history;</p>
      <p>- withdrawal history;</p>
      <p>- notifications;</p>
      <p>- Service usage history.</p>
      <p>
        The email address is used for authorization, notifications and communication between the
        User and the Customer Support Team. Telegram account information is used for communication
        with the Customer Support Team.
      </p>
      <p>
        4.2. The collection of technical information is necessary for proper processing of requests
        to the server, correct display of the Service website and to ensure the safety of the
        Service and its Users. Data regarding the User&apos;s browser language allows the Service to
        offer a version of the website which is convenient for the User to use.
      </p>
      <p>
        4.3. Users may connect to the servers of the Service only through HTTPS connection, which
        ensures that the data to be received is encrypted. This data is stored on the servers of
        hosting providers in the Netherlands and Germany, backup copies are stored in encrypted
        copies.
      </p>
      <p>
        4.4. The information collected is stored for three (3) months in the form of a connection
        history. This period is determined by the security requirements and maintenance aspects of
        the Service.
      </p>
      <p>
        4.5. The site connection history, which includes IP address on the primary server, is stored
        for three (3) months, and up to 6 months if it is a backup copy.
      </p>
      <p>4.6. The information about the activation key purchase includes the following:</p>

      <p>- IP address,</p>
      <p>- selected country,</p>
      <p>- selected payment method,</p>
      <p>- e-mail address,</p>
      <p>- order status,</p>
      <p>- the data received from the payment aggregator,</p>
      <p>- date and time of the order creation,</p>
      <p>- date and time of receiving the data from the payment aggregator,</p>
      <p>- number of the issued activation key.</p>

      <p>
        This information is stored within six (6) months and is necessary for order processing and
        administration of the Service.
      </p>
      <p>
        4.7 The data collected may be used to gather statistics to enhance the Service. The
        UseGateway Service uses the collected statistical information only for the purpose of
        monitoring the way Users engage with websites of the Service.
      </p>

      <h3>5. Access to User&apos;s Personal Information by third parties</h3>

      <p>
        5.1. In order to protect against DDoS attacks the UseGateway Service utilizes the{' '}
        <a href={'https://www.cloudflare.com/'} target={'_blank'} rel="noreferrer">
          CloudFlare
        </a>{' '}
        software, which protects against “Denial of Service” attacks, and can also collect
        information about the Service&apos;s visitors.
      </p>
      <p>
        Please find more information about CloudFlare&apos;s Privacy Policy by following this link:
        <br />
        <a href={'https://www.cloudflare.com/privacypolicy'}>
          https://www.cloudflare.com/privacypolicy
        </a>
        .
      </p>
      <p>5.2. The UseGateway Service utilizes MailGun to send mail notifications.</p>
      <p>
        Please find more information about MailGun Privacy Policy by following this link:
        <br />
        <a
          href={'https://www.mailgun.com/legal/privacy-policy/.'}
          target={'_blank'}
          rel="noreferrer">
          https://www.mailgun.com/legal/privacy-policy.
        </a>
      </p>
      <p>
        5.3. The UseGateway Service utilizes Announcekit to notify customers of the latest news.
      </p>
      <p>
        Please find more information about Announcekit&apos;s Privacy Policy by following this link:
        <br />
        <a href={'https://announcekit.app/privacy-policy/'} target={'_blank'} rel="noreferrer">
          https://announcekit.app/privacy-policy
        </a>
        .
      </p>
      <p>5.4. To perform web analytics the UseGateway Service utilizes the Plausible service.</p>
      <p>
        Please find more information on the Plausible Privacy Policy by following this link:
        <br />
        <a href={'https://plausible.io/privacy'} target={'_blank'} rel="noreferrer">
          https://plausible.io/privacy
        </a>
        .
      </p>
      <p>
        5.5. Websites of the Service use web analytics tools. The information they collect is
        determined by our Cookie Policy and other internal regulations.
      </p>

      <h3>6. Security of User Personal Information</h3>

      <p>
        6.1. The UseGateway Service maintains and requires its service providers to maintain
        appropriate physical, technical, and administrative security measures to protect privacy of
        Personal Information that Users entrust to the Service.
      </p>
      <p>
        6.2. Despite the fact the UseGateway Service uses industry-leading measures to protect
        Users&apos; data, the Service may not guarantee that any information the User transmits to
        the Service will be totally secure or that Users&apos; information cannot be accessed,
        disclosed, altered or destroyed by a breach of any standard physical, technical or
        administrative security measures. There is no method of data transmission over the Internet
        or electronic storage that is 100% secure. The Service uses its internal protocol for
        security measures to ensure that unauthorized personnel may not access the databases of the
        Service, applies access restrictions and instructs employees on how to handle Users&apos;
        Personal Information responsibly.
      </p>
      <p>
        6.3. In the event of a breach of Users&apos; Personal Information that may lead to a high
        risk of violating rights of private individuals, the Service will immediately notify Users
        of such breach with a description of its nature, possible consequences of a breach and
        measures taken to counteract it.
      </p>
      <p>
        6.4. The Service shall not notify Users of a breach when technical and organizational
        measures of the Service have eliminated the risk of a breach, or if the high risk to the
        rights and liberties of Users’ Personal Information no longer exists. In such case, the
        Service may issue a public announcement or use a similar measure by which Users may receive
        information concerning the eliminated risks.
      </p>

      <h3>7. Deletion of the User&apos;s Personal Information</h3>

      <p>
        7.1. All the User&apos;s Personal Information is the property of the User. The User has the
        right to request the Service to provide the collected information or request its deletion.
        For this purpose, the User should submit a request to the Customer Support Team at{' '}
        <a href={'mailto:care@usegateway.group'}>care@usegateway.group</a> by using his/her e-mail
        address. The Service will process the request within 14 (fourteen) working days and fulfill
        it.
      </p>
      <p>
        7.2. Once the User’s Personal Information has been completely deleted, further use of the
        Service becomes unavailable. The UseGateway service reserves the right to retain anonymized
        information. This information may be used to analyze statistical data and to prevent
        unlawful activities.
      </p>

      <h3>8. Amendments to the Privacy Policy</h3>

      <p>
        8.1. The UseGateway Service may modify the Privacy Policy from time to time. Changes to
        laws, other regulations and industry standards may require Service to make these amendments,
        or the Service may make adjustments in its business processes which will entail the need to
        change this Policy.
      </p>
      <p>
        8.2 The UseGateway Service provides additional notice in case it amends the privacy policy
        in any way that significantly changes the privacy rights of its Users. The new privacy
        policy becomes effective upon publication.
      </p>
    </Modal>
  );
}
