// @ts-nocheck
import { forwardRef } from 'react';
const SvgAnnouncementSpeaker = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M20 8.51822V12.0511M10.425 6.31017H7.32C5.80786 6.31017 5.05178 6.31017 4.47423 6.59896C3.96619 6.85299 3.55314 7.25834 3.29428 7.7569C3 8.3237 3 9.06567 3 10.5496V11.6095C3 12.4325 3 12.844 3.13702 13.1687C3.31971 13.6015 3.67012 13.9454 4.11117 14.1247C4.44196 14.2591 4.86131 14.2591 5.7 14.2591V18.0128C5.7 18.2178 5.7 18.3204 5.70867 18.4067C5.79284 19.2454 6.46897 19.9089 7.32357 19.9915C7.41157 20 7.51605 20 7.725 20C7.93395 20 8.03843 20 8.12644 19.9915C8.98103 19.9089 9.65716 19.2454 9.74133 18.4067C9.75 18.3204 9.75 18.2178 9.75 18.0128V14.2591H10.425C12.0148 14.2591 13.9595 15.0954 15.4599 15.8981C16.3351 16.3663 16.7728 16.6004 17.0594 16.566C17.3252 16.534 17.5262 16.4169 17.682 16.2031C17.85 15.9727 17.85 15.5115 17.85 14.5892V5.9801C17.85 5.05777 17.85 4.5966 17.682 4.36611C17.5262 4.15238 17.3252 4.03527 17.0594 4.00332C16.7728 3.96887 16.3351 4.20299 15.4599 4.67124C13.9595 5.47386 12.0148 6.31017 10.425 6.31017Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAnnouncementSpeaker);
export default ForwardRef;
