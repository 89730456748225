import { createContext, useContext } from 'react';

interface LandingContext {
  notification: boolean;
  setNotification: (value: boolean) => void;
}

const LandingContext = createContext<LandingContext | null>(null);

export const LandingProvider = LandingContext.Provider;
export const useLanding = () => {
  const { notification, setNotification } = useContext(LandingContext) as LandingContext;

  const closeNotification = () => {
    setNotification(false);
  };

  return { notification, closeNotification };
};
