// @ts-nocheck
import { forwardRef } from 'react';
const SvgRisk = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M6.0836 15.9456C5.91319 16.5952 6.00384 17.2867 6.33567 17.8687C6.66751 18.4507 7.21348 18.8757 7.85397 19.0506L14.6804 20.9129C14.9973 20.9995 15.3279 21.0218 15.6533 20.9786C15.9788 20.9354 16.2926 20.8276 16.5768 20.6613C16.861 20.495 17.11 20.2735 17.3096 20.0095C17.5092 19.7455 17.6554 19.4442 17.7398 19.1229L20.9155 7.05856C21.0863 6.40896 20.996 5.71724 20.6644 5.13497C20.3328 4.5527 19.7869 4.12739 19.1464 3.95223L12.3199 2.08995C12.0029 2.00322 11.6722 1.98075 11.3466 2.02384C11.0211 2.06692 10.7071 2.17471 10.4228 2.34102C10.1384 2.50733 9.88926 2.72889 9.6896 2.99298C9.48994 3.25708 9.3437 3.55852 9.25927 3.88002L6.0836 15.9443V15.9456ZM8.17529 17.8269C7.85523 17.739 7.58254 17.5263 7.41688 17.2352C7.25122 16.9441 7.20609 16.5984 7.29136 16.2737L10.467 4.2094C10.5092 4.0488 10.5823 3.89823 10.6821 3.7663C10.7818 3.63438 10.9063 3.5237 11.0484 3.44061C11.1904 3.35752 11.3472 3.30366 11.5099 3.2821C11.6725 3.26055 11.8377 3.27173 11.9961 3.315L18.8238 5.17728C19.4902 5.35844 19.8865 6.05395 19.709 6.73045L16.5333 18.7935C16.4912 18.9543 16.4182 19.1052 16.3184 19.2373C16.2186 19.3695 16.0941 19.4804 15.9519 19.5636C15.8097 19.6468 15.6526 19.7008 15.4898 19.7223C15.3269 19.7439 15.1615 19.7326 15.003 19.6891L8.17529 17.8269ZM4.74957 12.7303L3.08547 6.43907C2.91358 5.7901 3.00314 5.09853 3.33444 4.51646C3.66574 3.9344 4.21165 3.50951 4.85209 3.33527L4.94711 3.30993C4.81465 3.70398 4.74707 4.11743 4.74707 4.53372V4.77062C4.548 4.92261 4.3983 5.13143 4.31721 5.37026C4.23612 5.60909 4.22733 5.86703 4.29197 6.11095L4.74832 7.83515L4.74957 12.7303ZM5.99858 11.3519V4.53372C5.99858 3.86173 6.26203 3.21727 6.73097 2.74211C7.19991 2.26694 7.83593 2 8.49911 2H8.63164C8.44785 2.27998 8.30407 2.59542 8.21405 2.93874L8.11153 3.32893C7.86107 3.41168 7.6428 3.57248 7.48788 3.78838C7.33296 4.00428 7.2493 4.26425 7.24885 4.53118V6.6025L5.99858 11.3519Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgRisk);
export default ForwardRef;
