// @ts-nocheck
import { forwardRef } from 'react';
const SvgBox = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.5559 2.34511C11.5556 2.34526 11.5553 2.34541 11.555 2.34556L3.55722 6.34445L3.555 6.34556C3.3884 6.42835 3.24819 6.55597 3.15014 6.71408C3.05213 6.87214 3.00014 7.05439 3 7.24037C3 7.2403 3 7.24043 3 7.24037V16.767C2.99871 16.9534 3.04949 17.1364 3.14667 17.2954C3.2437 17.4542 3.38308 17.5827 3.54916 17.6666C3.54889 17.6664 3.54943 17.6667 3.54916 17.6666L11.5472 21.6656C11.6861 21.7351 11.8397 21.7715 11.995 21.7715C12.1504 21.7715 12.3036 21.7353 12.4425 21.6657L20.4428 17.6656L20.445 17.6645C20.6116 17.5817 20.7518 17.4541 20.8499 17.296C20.9479 17.1379 20.9999 16.9555 21 16.7695V7.24056C20.9999 7.05452 20.9479 6.87219 20.8499 6.71408C20.7518 6.55597 20.6116 6.42835 20.445 6.34556L20.4428 6.34445L12.445 2.34556C12.4447 2.34541 12.4444 2.34526 12.4441 2.34511C12.3061 2.27669 12.1541 2.24109 12 2.24109C11.8459 2.24109 11.6939 2.27669 11.5559 2.34511ZM12.89 1.45003L13.335 0.554497C12.9202 0.348361 12.4632 0.241089 12 0.241089C11.5368 0.241089 11.0798 0.348361 10.665 0.554497L10.6628 0.555601L2.665 4.5545C2.66459 4.5547 2.66418 4.55491 2.66377 4.55511C2.16449 4.80349 1.74432 5.18613 1.45042 5.66006C1.15628 6.13439 1.0003 6.68137 1 7.2395L1 16.7569C0.996833 17.3147 1.14923 17.8623 1.44011 18.3383C1.73166 18.8154 2.15072 19.2015 2.65003 19.4531L2.65279 19.4545L10.6525 23.4543C11.0693 23.6629 11.5289 23.7715 11.995 23.7715C12.461 23.7715 12.9205 23.6629 13.3372 23.4545C13.3373 23.4544 13.3371 23.4545 13.3372 23.4545L21.335 19.4556C21.3353 19.4554 21.3357 19.4552 21.336 19.4551C21.8354 19.2067 22.2556 18.824 22.5496 18.35C22.8437 17.8757 22.9997 17.3287 23 16.7706V7.24003C22.9997 6.6819 22.8437 6.13439 22.5496 5.66006C22.2557 5.18614 21.8355 4.80352 21.3363 4.55513C21.3359 4.55492 21.3354 4.55471 21.335 4.5545L13.3372 0.555601L12.89 1.45003Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.42558 5.71281C1.67256 5.21884 2.27324 5.01861 2.76722 5.2656L12 9.88199L21.2328 5.2656C21.7268 5.01861 22.3274 5.21884 22.5744 5.71281C22.8214 6.20679 22.6212 6.80747 22.1272 7.05445L12.4472 11.8945C12.1657 12.0352 11.8343 12.0352 11.5528 11.8945L1.87279 7.05445C1.37881 6.80747 1.17859 6.20679 1.42558 5.71281Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 10C12.5523 10 13 10.4477 13 11V22.76C13 23.3123 12.5523 23.76 12 23.76C11.4477 23.76 11 23.3123 11 22.76V11C11 10.4477 11.4477 10 12 10Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgBox);
export default ForwardRef;
