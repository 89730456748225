// @ts-nocheck
import { forwardRef } from 'react';
const SvgHardDrive = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1 12C1 11.4477 1.44772 11 2 11H22C22.5523 11 23 11.4477 23 12C23 12.5523 22.5523 13 22 13H2C1.44772 13 1 12.5523 1 12Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.24 3L16.76 3C17.3181 3.0003 17.8656 3.15628 18.34 3.45042C18.8139 3.7443 19.1965 4.16444 19.4449 4.66368C19.4451 4.66412 19.4453 4.66456 19.4455 4.665L22.8942 11.5523C22.9638 11.6913 23 11.8446 23 12V18C23 18.7957 22.6839 19.5587 22.1213 20.1213C21.5587 20.6839 20.7957 21 20 21H4C3.20435 21 2.44129 20.6839 1.87868 20.1213C1.31607 19.5587 1 18.7956 1 18V12C1 11.8446 1.03624 11.6913 1.10583 11.5523L4.55447 4.665C4.55471 4.66451 4.55495 4.66403 4.5552 4.66354C4.80358 4.16436 5.18617 3.74427 5.66004 3.45042C6.13437 3.15628 6.68187 3.0003 7.24 3ZM5.45 5.11L6.34417 5.55773L3 12.2364V18C3 18.2652 3.10536 18.5196 3.29289 18.7071C3.48043 18.8946 3.73478 19 4 19H20C20.2652 19 20.5196 18.8946 20.7071 18.7071C20.8946 18.5196 21 18.2652 21 18V12.2364L17.6558 5.55773L17.6545 5.555C17.5717 5.38839 17.4441 5.24818 17.2859 5.15014C17.1279 5.05213 16.9456 5.00013 16.7597 5H7.24034C7.05436 5.00013 6.87211 5.05213 6.71405 5.15014C6.55594 5.24819 6.42832 5.38839 6.34553 5.555L5.45 5.11Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgHardDrive);
export default ForwardRef;
