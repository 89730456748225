// @ts-nocheck
import { forwardRef } from 'react';
const SvgPhoneCall = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_2128_24397)">
      <path
        fill="currentColor"
        d="M20 22.9C19.9 22.9 19.8 22.9 19.7 22.9C16.5 22.5 13.3 21.4 10.6 19.7C8.1 18.1 5.9 15.9 4.3 13.4C2.5 10.7 1.4 7.5 1.1 4.3C1.1 3.5 1.3 2.7 1.8 2.1C2.3 1.5 3 1.1 3.8 1C3.9 1 4 1 4.1 1H7.1C8.6 1 9.9 2.1 10.1 3.6C10.2 4.5 10.4 5.4 10.7 6.2C11.1 7.3 10.8 8.5 10 9.4L9.3 10.1C10.5 11.9 12 13.5 13.9 14.7L14.6 14C15.4 13.2 16.7 12.9 17.8 13.3C18.6 13.6 19.5 13.8 20.4 13.9C21.9 14.1 23 15.4 23 16.9V19.9C23 20.7 22.7 21.5 22.1 22C21.5 22.5 20.8 22.9 20 22.9ZM7.1 3H4.1C3.8 3 3.5 3.2 3.3 3.4C3.2 3.6 3.1 3.8 3.1 4.1C3.4 7 4.4 9.8 6 12.3C7.5 14.6 9.4 16.6 11.7 18C14.2 19.6 17 20.6 19.9 20.9C20.3 20.9 20.5 20.8 20.7 20.6C20.9 20.4 21 20.2 21 19.9V16.9C21 16.4 20.6 16 20.1 15.9C19.1 15.8 18.1 15.5 17.1 15.1C16.7 15 16.3 15 16 15.3L14.7 16.6C14.4 16.9 13.9 17 13.5 16.8C10.8 15.3 8.6 13.1 7.1 10.4C7 10 7.1 9.5 7.4 9.2L8.7 7.9C8.9 7.7 9 7.2 8.9 6.9C8.5 5.9 8.3 4.9 8.1 3.9C8.1 3.4 7.6 3 7.1 3ZM19 10C18.5 10 18.1 9.7 18 9.2C17.7 7.6 16.4 6.4 14.8 6C14.3 5.9 13.9 5.4 14 4.8C14.1 4.3 14.6 3.9 15.2 4C17.6 4.5 19.5 6.3 19.9 8.7C20 9.2 19.7 9.8 19.1 9.9C19.1 9.9 19.1 10 19 10ZM23 9.9C22.5 9.9 22.1 9.5 22 9C21.6 5.3 18.7 2.4 14.9 1.9C14.4 1.8 14 1.3 14 0.8C14 0.3 14.6 -0.1 15.1 -0.1C19.8 0.4 23.4 4 23.9 8.7C24 9.2 23.6 9.7 23 9.8C23.1 9.9 23 9.9 23 9.9Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_2128_24397">
        <rect width={24} height={24} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgPhoneCall);
export default ForwardRef;
