import { RefObject, useEffect, useRef, useState } from 'react';
import { default as NumberFormat, NumberFormatValues } from 'react-number-format';
import styled from 'styled-components';
import { FiatCurrency } from '@use-gateway/types';
import { getMoneyDecemals } from '@use-gateway/utils';
import throttle from 'lodash-es/throttle';

const InputCurrencyWrapper = styled.div`
  position: relative;
  width: 100%;

  label {
    font-family: 'Nunito';
    font-weight: 500;
  }

  input {
    box-sizing: border-box;
    width: 100%;
    height: 80px;
    padding: 0 70px;
    border: none;
    border-bottom: 1px solid #848893;
    text-align: center;
    font-size: 64px;
    outline: none;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

const InputCurrencyName = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  height: 80px;
  font-size: 36px;
`;

interface InputCurrencyProps {
  label?: string;
  defaultValue: number;
  onChange?: (value: number) => void;
  currency: FiatCurrency;
  min: number;
}

export function InputCurrency({
  label,
  currency,
  defaultValue,
  onChange,
  min,
}: InputCurrencyProps) {
  const [innerValue, setInnerValue] = useState(defaultValue ?? 0);
  const [fontSize, setFontSize] = useState(12);
  const amountRef = useRef<HTMLElement>();

  const handleInput = (values: NumberFormatValues) => {
    const rawValue = values.floatValue || 0;
    const value = isNaN(rawValue) ? 0 : rawValue;

    if (onChange) onChange(value);
    setInnerValue(rawValue);
  };

  const calcFontSize = throttle(() => {
    const max = 60;
    const min = 12;

    const $container = amountRef.current as HTMLElement;
    const maxWidth = $container.clientWidth - 140;
    const symbolsCount = innerValue.toString().length;
    const calculatedSize = (maxWidth / symbolsCount) * 1.2;

    if (calculatedSize > max) return setFontSize(max);
    if (calculatedSize < min) return setFontSize(min);

    setFontSize(calculatedSize);
  }, 500);

  useEffect(() => {
    if (defaultValue < min) {
      setInnerValue(min);
      if (onChange) onChange(min);
    }
  }, [min]);

  useEffect(() => {
    calcFontSize();

    window.addEventListener('resize', calcFontSize);

    return () => {
      window.removeEventListener('resize', calcFontSize);
    };
  }, [innerValue]);

  return (
    <InputCurrencyWrapper ref={amountRef as RefObject<HTMLDivElement>}>
      {label && <label>{label}</label>}
      <NumberFormat
        type="text"
        decimalScale={getMoneyDecemals(currency)}
        value={innerValue.toString()}
        onValueChange={handleInput}
        allowNegative={false}
        allowedDecimalSeparators={[',', '.']}
        style={{ fontSize: `${fontSize}px` }}
        className={'amount-input'}
      />
      <InputCurrencyName>{currency}</InputCurrencyName>
    </InputCurrencyWrapper>
  );
}
