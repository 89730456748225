// @ts-nocheck
import { forwardRef } from 'react';
const SvgAlertTriangle = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M3.5 22C3 22 2.5 21.9 2 21.6C0.600002 20.8 0.100002 18.9 0.900002 17.5L9.4 3.29999C9.7 2.89999 10 2.49999 10.4 2.29999C11.1 1.89999 11.9 1.79999 12.7 1.99999C13.5 2.19999 14.1 2.69999 14.6 3.39999L23 17.5C23.3 18 23.4 18.5 23.4 19C23.4 19.8 23.1 20.6 22.5 21.1C22 21.7 21.3 22 20.5 22H3.5ZM11.1 4.39999L2.7 18.5C2.4 19 2.6 19.6 3.1 19.9C3.2 20 3.4 20 3.5 20H20.4C20.7 20 20.9 19.9 21.1 19.7C21.3 19.5 21.4 19.3 21.4 19C21.4 18.8 21.4 18.7 21.3 18.5L12.9 4.39999C12.6 3.89999 12 3.79999 11.5 3.99999C11.3 4.09999 11.2 4.19999 11.1 4.39999Z"
    />
    <path
      fill="currentColor"
      d="M12 14C11.4 14 11 13.6 11 13V8.99999C11 8.39999 11.4 7.99999 12 7.99999C12.6 7.99999 13 8.39999 13 8.99999V13C13 13.6 12.6 14 12 14Z"
    />
    <path
      fill="currentColor"
      d="M12 18C11.7 18 11.5 17.9 11.3 17.7C11.1 17.5 11 17.3 11 17C11 16.9 11 16.7 11.1 16.6C11.2 16.5 11.2 16.4 11.3 16.3C11.4 16.2 11.5 16.1 11.6 16.1C11.8 16 12 16 12.2 16C12.3 16 12.3 16 12.4 16.1C12.5 16.1 12.5 16.1 12.6 16.2C12.6 16.2 12.7 16.3 12.8 16.3C12.9 16.4 13 16.5 13 16.6C13 16.7 13.1 16.9 13.1 17C13.1 17.3 13 17.5 12.8 17.7C12.5 17.9 12.3 18 12 18Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAlertTriangle);
export default ForwardRef;
