import { useCallback, useState } from 'react';
import { FieldValues, Path, UseFormSetError } from 'react-hook-form';
import { ResponseError } from '@use-gateway/api';

export function useFormErrorHandler<T extends FieldValues>(setError: UseFormSetError<T>) {
  const [nonFieldErrors, setNonFieldErrors] = useState<string[]>([]);

  const clearNonFieldErrors = useCallback(() => {
    setNonFieldErrors([]);
  }, []);

  const pushNonFieldError = useCallback((error: string) => {
    setNonFieldErrors((prev) => [...prev, error]);
  }, []);

  const errorHandler = useCallback(
    (res: ResponseError) => {
      if (typeof res.data?.detail === 'string') {
        return setNonFieldErrors([res.data.detail]);
      }

      if (res.fields) {
        return Object.entries(res.fields).forEach(([field, error]) => {
          setError(field as Path<T>, {
            type: 'server',
            message: error.message,
          });
        });
      }

      setNonFieldErrors([res.message || 'Unknown error']);
    },
    [setError]
  );

  return {
    nonFieldErrors,
    pushNonFieldError,
    clearNonFieldErrors,
    errorHandler,
  };
}
