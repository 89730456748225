import { UserDto } from '@use-gateway/types';
import { Api } from '..';

const basePath = '/users';

export async function getCurrnetUser(this: Api) {
  return this.get<UserDto>(`${basePath}/me`);
}

export async function patchCurrnetUser(
  this: Api,
  payload: Partial<UserDto & { password: string }>
) {
  return this.patch<UserDto>(`${basePath}/me`, payload);
}
