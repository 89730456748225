import { CreatePaymentPayload, Paginated, PaymentResponseDto } from '@use-gateway/types';
import { Api } from '..';

const basePath = '/invoices';

export async function getInvoice(this: Api, id: string) {
  return this.get<PaymentResponseDto>(`${basePath}/${id}/`);
}

export async function getInvoiceView(this: Api, id: string) {
  return this.get<PaymentResponseDto>(`${basePath}/${id}/view`);
}

export async function getInvoices(this: Api, page = 1, size = 25) {
  return this.get<Paginated<PaymentResponseDto>>(`${basePath}/?page=${page}&size=${size}`);
}

export async function createInvoice(this: Api, payload: CreatePaymentPayload) {
  return this.post<PaymentResponseDto>(`${basePath}/`, payload);
}

export async function renewInvoice(this: Api, id: string) {
  return this.patch<PaymentResponseDto>(`${basePath}/${id}/renew`);
}

export async function cancelInvoice(this: Api, id: string) {
  return this.patch(`${basePath}/${id}/cancel`);
}

export async function resolveInvoice(this: Api, id: string) {
  return this.patch(`${basePath}/${id}/resolve`);
}
