// @ts-nocheck
import { forwardRef } from 'react';
const SvgPlug = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2 1C1.46957 1 0.960859 1.21071 0.585786 1.58579C0.210714 1.96086 0 2.46957 0 3V16C0 16.5304 0.210713 17.0391 0.585786 17.4142C0.960861 17.7893 1.46957 18 2 18H10C10.5304 18 11.0391 17.7893 11.4142 17.4142C11.7893 17.0391 12 16.5304 12 16V3C12 2.46957 11.7893 1.96086 11.4142 1.58579C11.0391 1.21071 10.5304 1 10 1H2ZM2 3H10V16H2V3ZM17 3C17.5523 3 18 3.44772 18 4V6H20V4C20 3.44772 20.4477 3 21 3C21.5523 3 22 3.44772 22 4V6H23C23.5523 6 24 6.44772 24 7V10C24 11.3261 23.4732 12.5979 22.5355 13.5355C21.8372 14.2339 20.9534 14.7044 20 14.899V23C20 23.5523 19.5523 24 19 24H6C5.44772 24 5 23.5523 5 23V20C5 19.4477 5.44772 19 6 19C6.55228 19 7 19.4477 7 20V22H18V14.899C17.0466 14.7044 16.1628 14.2339 15.4645 13.5355C14.5268 12.5979 14 11.3261 14 10V7C14 6.44772 14.4477 6 15 6H16V4C16 3.44772 16.4477 3 17 3ZM21.1213 12.1213C20.5587 12.6839 19.7957 13 19 13C18.2043 13 17.4413 12.6839 16.8787 12.1213C16.3161 11.5587 16 10.7956 16 10V8H17H21H22V10C22 10.7956 21.6839 11.5587 21.1213 12.1213Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPlug);
export default ForwardRef;
