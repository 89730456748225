/* eslint-disable prettier/prettier */
import { useMemo } from 'react';
import { Inline } from '@bedrock-layout/inline';
import { Button, DateTime, DoublePrice, Status } from '@use-gateway/components';
import { IconFileText } from "@use-gateway/icons";
import { Breakpoints, styled, useBreakpoints } from "@use-gateway/theme";
import { WithdrawalRequestsResponseDto, WithdrawalsEvents } from "@use-gateway/types";
import { useEventBus, precisionByCurrency } from '@use-gateway/utils';
import {
  TableCard,
  TableCell,
  TableMobileActions,
  TableMobileMeta,
  TableMobileName,
  TableRow,
} from '../table';

const DetailsLink = styled.a`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;

interface RequestsRowProps {
  request: WithdrawalRequestsResponseDto;
}

export function RequestsRow({ request }: RequestsRowProps) {
  const { isUp } = useBreakpoints();
  const { emit } = useEventBus();

  const timelineStatus = request.timeline[request.timeline.length - 1].status;
  const status = useMemo(() => {
    if (timelineStatus === 'Received' && request.withdrawal) return 'Paid';
    return timelineStatus;
  }, [timelineStatus, request.withdrawal]);

  const statusColor = useMemo(() => {
    if (status === 'Approved') return 'success';
    if (status === 'Resolved') return 'success';
    if (status === 'Received') return 'warning';
    if (status === 'Paid') return 'pending';
    return 'error';
  }, [request]);

  const handleApprove = async () => {
    emit(WithdrawalsEvents.openRequestApprove, request.id);
  };

  const handleDecline = async () => {
    emit(WithdrawalsEvents.openRequestDecline, request.id);
  };

  const handlePay = () => {
    if (request.type === 'local') emit(WithdrawalsEvents.openPayWithdrawalRequestLocal, request.id);
    if (request.type === 'crypto')
      emit(WithdrawalsEvents.openPayWithdrawalRequestCrypto, request.id);
  };

  const handleView = () => {
    emit(WithdrawalsEvents.openRequestMetaData, request);
  };

  if (isUp(Breakpoints.md))
    return (
      <TableRow columns={17}>
        <TableCell span={2}>
          <DateTime date={request.created_at} size={'sm'} />
        </TableCell>

        <TableCell span={3}>
          <DoublePrice
            primary={request.value}
            maxPrecision={precisionByCurrency(request.value.currency)}
            align="start"
          />
        </TableCell>

        <TableCell span={4}>{request.comment}</TableCell>

        <TableCell span={3}>
          <Status status={statusColor}>{status}</Status>
        </TableCell>

        <TableCell span={3}>
          {!request.withdrawal && (
            <button disabled={status !== 'Received'} onClick={handlePay}>
              Pay
            </button>
          )}
          {request.withdrawal && (
            <button disabled={status !== 'Received' && status !== 'Paid'} onClick={handleApprove}>
              Approve
            </button>
          )}
          <button disabled={status !== 'Received' && status !== 'Paid'} onClick={handleDecline}>
            Decline
          </button>
        </TableCell>

        <TableCell align="center" span={2}>
          {request.withdrawal && (
            <DetailsLink onClick={handleView}>
              <IconFileText />
            </DetailsLink>
          )}
        </TableCell>
      </TableRow>
    );

  return (
    <TableCard gutter={'sm'}>
      <Inline style={{ justifyContent: 'space-between' }}>
        <div>
          <TableMobileName>{request.comment}</TableMobileName>
          <DoublePrice
            primary={request.value}
            maxPrecision={precisionByCurrency(request.value.currency)}
            align="start"
          />
        </div>
        <Inline gutter={'lg'}>
          {request.withdrawal && (
            <DetailsLink onClick={handleView}>
              <IconFileText />
            </DetailsLink>
          )}
          <Status status={statusColor} isColorText>{status}</Status>
        </Inline>
      </Inline>
      <Inline style={{ justifyContent: 'space-between' }}>
        <TableMobileMeta gutter={0}>
          <DateTime date={request.created_at} size={'sm'} inline />
        </TableMobileMeta>
      </Inline>
      {(status === 'Received' || status === 'Paid') && (
        <Inline style={{ justifyContent: 'flex-end' }}>
          <TableMobileActions>
            {!request.withdrawal && (
              <Button size={'xs'} onClick={handlePay}>
                Pay
              </Button>
            )}
            {request.withdrawal && (
              <Button size={'xs'} variant={'link'} onClick={handleApprove}>
                Approve
              </Button>
            )}
            <Button size={'xs'} variant={'link'} onClick={handleDecline}>
              Decline
            </Button>
          </TableMobileActions>
        </Inline>
      )}
    </TableCard>
  );
}
