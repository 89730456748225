// @ts-nocheck
import { forwardRef } from 'react';
const SvgShieldOff = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_2128_24492)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.6502 1.06318C11.8762 0.978771 12.1252 0.978944 12.3511 1.06367L20.3511 4.06367C20.7414 4.21003 21 4.58316 21 5V12.0066C20.9949 12.7832 20.8753 13.5548 20.6451 14.2964C20.4813 14.8239 19.921 15.1188 19.3936 14.9551C18.8661 14.7913 18.5712 14.231 18.7349 13.7036C18.9066 13.1507 18.9959 12.5755 19 11.9966V5.693L11.9993 3.06772L9.18982 4.11682C8.67243 4.31002 8.09639 4.04721 7.90318 3.52982C7.70998 3.01243 7.97279 2.43639 8.49018 2.24318L11.6502 1.06318Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.6679 4.3831C5.85949 4.90109 5.59489 5.47632 5.0769 5.6679L5 5.69634V12C5 14.5536 6.71825 16.7997 8.6585 18.4974C9.60762 19.3279 10.5615 19.9842 11.28 20.4333C11.5626 20.6098 11.8071 20.7534 11.9985 20.8619C13.8252 19.8329 15.4731 18.5124 16.8763 16.9514C17.2456 16.5407 17.8778 16.5071 18.2886 16.8763C18.6993 17.2456 18.7329 17.8778 18.3637 18.2886C16.6845 20.1564 14.688 21.7123 12.4667 22.8844C12.1815 23.0349 11.8412 23.0386 11.5528 22.8944L12 22C11.5528 22.8944 11.5531 22.8946 11.5528 22.8944L11.5492 22.8926L11.5427 22.8894L11.5212 22.8784C11.5032 22.8692 11.4777 22.856 11.4453 22.839C11.3806 22.805 11.2881 22.7556 11.1719 22.6913C10.9397 22.5627 10.6118 22.3741 10.22 22.1292C9.43854 21.6408 8.39238 20.9221 7.3415 20.0026C5.28175 18.2003 3 15.4464 3 12V5C3 4.5815 3.2606 4.20727 3.6531 4.0621L4.3831 3.7921C4.90109 3.60051 5.47632 3.86511 5.6679 4.3831Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L23.7071 22.2929C24.0976 22.6834 24.0976 23.3166 23.7071 23.7071C23.3166 24.0976 22.6834 24.0976 22.2929 23.7071L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_2128_24492">
        <rect width={24} height={24} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgShieldOff);
export default ForwardRef;
