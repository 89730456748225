import { ReactNode } from 'react';
import { styled } from '@use-gateway/theme';
import { Footer } from '../footer';
import { Logo } from '../logo';

const SimpleLayoutWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SimpleLayoutMain = styled.main`
  width: 328px;
  padding: 58px 0;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  form {
    width: 100%;
  }
`;

const SimpleLayoutTitle = styled.h1`
  font-weight: 600;
  font-size: 24px;
  line-height: 1.33;
  text-align: center;

  margin: 36px 0;
`;

export interface SimpleLayoutProps {
  title?: string;
  children: ReactNode;
}
export function SimpleLayout({ children, title }: SimpleLayoutProps) {
  return (
    <SimpleLayoutWrapper>
      <SimpleLayoutMain>
        <Logo />
        {title && <SimpleLayoutTitle>{title}</SimpleLayoutTitle>}

        {children}
      </SimpleLayoutMain>
      <Footer />
    </SimpleLayoutWrapper>
  );
}
