// @ts-nocheck
import { forwardRef } from 'react';
const SvgSmartphone = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M17 23H7C5.3 23 4 21.7 4 20V4C4 2.3 5.3 1 7 1H17C18.7 1 20 2.3 20 4V20C20 21.7 18.7 23 17 23ZM7 3C6.4 3 6 3.4 6 4V20C6 20.6 6.4 21 7 21H17C17.6 21 18 20.6 18 20V4C18 3.4 17.6 3 17 3H7Z"
    />
    <path
      fill="currentColor"
      d="M12 19C11.9 19 11.7 19 11.6 18.9C11.5 18.9 11.4 18.8 11.3 18.7C11.1 18.5 11 18.3 11 18C11 17.9 11 17.7 11.1 17.6C11.2 17.5 11.2 17.4 11.3 17.3C11.7 16.9 12.3 16.9 12.7 17.3C12.9 17.5 13 17.8 13 18C13 18.3 12.9 18.5 12.7 18.7C12.5 18.9 12.3 19 12 19Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSmartphone);
export default ForwardRef;
