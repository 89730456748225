// @ts-nocheck
import { forwardRef } from 'react';
const SvgAtSign = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.4999 1.28845C12.0822 0.724234 9.54474 0.994474 7.30009 2.05523C5.05545 3.11599 3.23565 4.90488 2.13659 7.13102C1.03754 9.35717 0.723873 11.8896 1.24659 14.3167C1.76932 16.7437 3.09768 18.9225 5.0157 20.4988C6.93372 22.0752 9.32859 22.9563 11.8109 22.999C14.2932 23.0417 16.7169 22.2434 18.688 20.7339C19.1265 20.3981 19.2097 19.7705 18.874 19.332C18.5382 18.8935 17.9105 18.8103 17.472 19.1461C15.8593 20.3811 13.8762 21.0342 11.8453 20.9993C9.8143 20.9644 7.85487 20.2434 6.28558 18.9537C4.71629 17.664 3.62944 15.8813 3.20176 13.8956C2.77408 11.9098 3.03072 9.8378 3.92994 8.0164C4.82917 6.19501 6.3181 4.73138 8.15462 3.86348C9.99115 2.99559 12.0673 2.77449 14.0454 3.23612C16.0235 3.69776 17.7873 4.81498 19.05 6.40613C20.3126 7.99727 20.9999 9.9688 21 12.0001V13C21 13.5304 20.7893 14.0391 20.4142 14.4142C20.0392 14.7893 19.5304 15 19 15C18.4696 15 17.9609 14.7893 17.5858 14.4142C17.2107 14.0391 17 13.5304 17 13V7.99999C17 7.44771 16.5523 6.99999 16 6.99999C15.4478 6.99999 15.0002 7.44751 15 7.99963C14.1643 7.37194 13.1256 6.99998 12 6.99998C9.23858 6.99998 7 9.23856 7 12C7 14.7614 9.23858 17 12 17C13.4881 17 14.8244 16.3499 15.7403 15.3182C15.8687 15.4989 16.0128 15.6696 16.1716 15.8284C16.9217 16.5786 17.9391 17 19 17C20.0609 17 21.0783 16.5786 21.8284 15.8284C22.5786 15.0783 23 14.0609 23 13V11.9999C22.9999 9.51726 22.1599 7.10765 20.6166 5.16291C19.0734 3.21817 16.9176 1.85268 14.4999 1.28845ZM9 12C9 10.3431 10.3431 8.99998 12 8.99998C13.6569 8.99998 15 10.3431 15 12C15 13.6568 13.6569 15 12 15C10.3431 15 9 13.6568 9 12Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAtSign);
export default ForwardRef;
