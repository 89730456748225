// @ts-nocheck
import { forwardRef } from 'react';
const SvgMatic = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 39 34"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_18_11)">
      <path
        fill="#8247E5"
        d="M29 10.2C28.3 9.8 27.4 9.8 26.6 10.2L21 13.5L17.2 15.6L11.7 18.9C11 19.3 10.1 19.3 9.3 18.9L5 16.3C4.3 15.9 3.8 15.1 3.8 14.2V9.2C3.8 8.4 4.2 7.6 5 7.1L9.3 4.6C10 4.2 10.9 4.2 11.7 4.6L16 7.2C16.7 7.6 17.2 8.4 17.2 9.3V12.6L21 10.4V7C21 6.2 20.6 5.4 19.8 4.9L11.8 0.2C11.1 -0.2 10.2 -0.2 9.4 0.2L1.2 5C0.4 5.4 0 6.2 0 7V16.4C0 17.2 0.4 18 1.2 18.5L9.3 23.2C10 23.6 10.9 23.6 11.7 23.2L17.2 20L21 17.8L26.5 14.6C27.2 14.2 28.1 14.2 28.9 14.6L33.2 17.1C33.9 17.5 34.4 18.3 34.4 19.2V24.2C34.4 25 34 25.8 33.2 26.3L29 28.8C28.3 29.2 27.4 29.2 26.6 28.8L22.3 26.3C21.6 25.9 21.1 25.1 21.1 24.2V21L17.3 23.2V26.5C17.3 27.3 17.7 28.1 18.5 28.6L26.6 33.3C27.3 33.7 28.2 33.7 29 33.3L37.1 28.6C37.8 28.2 38.3 27.4 38.3 26.5V17C38.3 16.2 37.9 15.4 37.1 14.9L29 10.2Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_18_11">
        <rect width={38.4} height={33.5} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgMatic);
export default ForwardRef;
