import {
  CreateWithdrawalCryptoRequestPayload,
  CreateWithdrawalFiatRequestPayload,
  Paginated,
  PayWithdrawalRequestPayload,
  WithdrawalRequestResponseDto,
  WithdrawalRequestsResponseDto,
} from '@use-gateway/types';
import { Api } from '..';

const basePath = '/withdrawal_requests';

export async function getWithdrawalRequests(this: Api, page = 1, size = 25) {
  return this.get<Paginated<WithdrawalRequestsResponseDto>>(
    `${basePath}/?page=${page}&size=${size}`
  );
}

export async function createWithdrawalCryptoRequest(
  this: Api,
  payload: CreateWithdrawalCryptoRequestPayload
) {
  return this.post(`${basePath}/`, payload);
}

export async function createWithdrawalFiatRequest(
  this: Api,
  payload: CreateWithdrawalFiatRequestPayload
) {
  return this.post(`${basePath}/`, payload);
}

export async function getWithdrawalRequest(this: Api, id: string) {
  return this.get<WithdrawalRequestResponseDto>(`${basePath}/${id}/`);
}

export async function withdrawalRequestApprove(this: Api, id: string, comment = '') {
  return this.post(`${basePath}/${id}/approve`, { comment });
}

export async function withdrawalRequestDecline(this: Api, id: string, comment = '') {
  return this.post(`${basePath}/${id}/decline`, { comment });
}

export async function payWithdrawalRequest(
  this: Api,
  id: string,
  payload: PayWithdrawalRequestPayload
) {
  return this.post(`${basePath}/${id}/pay`, payload);
}
