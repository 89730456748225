// @ts-nocheck
import { forwardRef } from 'react';
const SvgGithub = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_2128_24190)">
      <path
        fill="currentColor"
        d="M16 23C15.4 23 15 22.6 15 22V18.1C15.1 17.3 14.8 16.7 14.3 16.2C14 15.9 13.9 15.5 14.1 15.2C14.2 14.8 14.6 14.6 14.9 14.5C17.8 14.2 20.5 13.2 20.5 8.49999C20.5 7.39999 20.1 6.29999 19.3 5.39999C19 5.09999 19 4.69999 19.1 4.39999C19.4 3.59999 19.4 2.79999 19.2 1.99999C18.7 2.09999 17.9 2.39999 16.6 3.29999C16.4 3.49999 16.1 3.49999 15.8 3.39999C13.7 2.79999 11.4 2.79999 9.3 3.39999C9 3.49999 8.7 3.49999 8.4 3.29999C7.2 2.39999 6.3 2.09999 5.8 1.99999C5.6 2.79999 5.6 3.59999 5.9 4.39999C6 4.79999 6 5.19999 5.7 5.39999C4.9 6.29999 4.5 7.39999 4.5 8.49999C4.5 13.2 7.2 14.2 10.1 14.5C10.5 14.5 10.8 14.8 10.9 15.2C11 15.6 10.9 16 10.7 16.2C10.2 16.7 10 17.3 10 18V18.9V19V21.9C10 22.5 9.6 22.9 9 22.9C8.4 22.9 8 22.5 8 21.9V20.2C5 20.7 3.7 19 2.8 17.9C2.4 17.4 2.1 17 1.7 16.9C1.2 16.8 0.8 16.2 1 15.7C1.1 15.2 1.7 14.8 2.2 15C3.2 15.3 3.8 16 4.4 16.7C5.2 17.8 5.9 18.6 8 18.2V18.1C8 17.5 8.1 16.8 8.3 16.3C5.5 15.7 2.5 13.9 2.5 8.59999C2.5 7.09999 3 5.69999 3.9 4.59999C3.5 3.19999 3.6 1.89999 4.2 0.599988C4.3 0.299988 4.5 0.0999879 4.8 -1.20923e-05C5.2 -0.100012 6.5 -0.300012 9.2 1.39999C11.4 0.899988 13.7 0.899988 15.8 1.39999C18.4 -0.300012 19.8 -0.100012 20.2 -1.20923e-05C20.5 0.0999879 20.7 0.299988 20.8 0.599988C21.3 1.89999 21.4 3.19999 21.1 4.49999C22 5.59999 22.5 6.99999 22.5 8.49999C22.5 14.2 19.1 15.7 16.7 16.3C16.9 16.9 17 17.6 17 18.2V22C17 22.6 16.6 23 16 23Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_2128_24190">
        <rect width={24} height={24} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgGithub);
export default ForwardRef;
