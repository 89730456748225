// @ts-nocheck
import { forwardRef } from 'react';
const SvgArrowDownRight = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.70711 6.29289C7.31658 5.90237 6.68342 5.90237 6.29289 6.29289C5.90237 6.68342 5.90237 7.31658 6.29289 7.70711L14.5858 16H7C6.44772 16 6 16.4477 6 17C6 17.5523 6.44772 18 7 18H16.9993H17C17.001 18 17.002 18 17.003 18C17.1375 17.9996 17.2657 17.9727 17.3828 17.9241C17.498 17.8764 17.6062 17.8063 17.7005 17.7136C17.7049 17.7093 17.7093 17.7049 17.7136 17.7005C17.8901 17.5208 17.9992 17.2746 18 17.003C18 17.002 18 17.001 18 17V16.9993V7C18 6.44772 17.5523 6 17 6C16.4477 6 16 6.44772 16 7V14.5858L7.70711 6.29289Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgArrowDownRight);
export default ForwardRef;
