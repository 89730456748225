// @ts-nocheck
import { forwardRef } from 'react';
const SvgSignOut = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.4 5.6C6.18783 5.6 5.98434 5.68429 5.83431 5.83431C5.68429 5.98434 5.6 6.18783 5.6 6.4V17.6C5.6 17.8122 5.68429 18.0157 5.83431 18.1657C5.98434 18.3157 6.18783 18.4 6.4 18.4H9.6C10.0418 18.4 10.4 18.7582 10.4 19.2C10.4 19.6418 10.0418 20 9.6 20H6.4C5.76348 20 5.15303 19.7471 4.70294 19.2971C4.25286 18.847 4 18.2365 4 17.6V6.4C4 5.76348 4.25286 5.15303 4.70294 4.70294C5.15303 4.25286 5.76348 4 6.4 4H9.6C10.0418 4 10.4 4.35817 10.4 4.8C10.4 5.24183 10.0418 5.6 9.6 5.6H6.4Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.6343 7.43431C14.9467 7.1219 15.4533 7.1219 15.7657 7.43431L19.7657 11.4343C20.0781 11.7467 20.0781 12.2533 19.7657 12.5657L15.7657 16.5657C15.4533 16.8781 14.9467 16.8781 14.6343 16.5657C14.3219 16.2533 14.3219 15.7467 14.6343 15.4343L18.0686 12L14.6343 8.56569C14.3219 8.25327 14.3219 7.74673 14.6343 7.43431Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.8 12C8.8 11.5582 9.15817 11.2 9.6 11.2H19.2C19.6418 11.2 20 11.5582 20 12C20 12.4418 19.6418 12.8 19.2 12.8H9.6C9.15817 12.8 8.8 12.4418 8.8 12Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSignOut);
export default ForwardRef;
