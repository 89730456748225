import { useCallback } from 'react';
import { MetaDetail, Modal, ModalProps } from '@use-gateway/components';
import { WithdrawalRequestsResponseDto } from '@use-gateway/types';

export interface MetaDataModalProps {
  request: WithdrawalRequestsResponseDto;
  onClose: ModalProps['onClose'];
}

export function RequestMetaDataModal({ request, onClose }: MetaDataModalProps) {
  const openLink = useCallback(() => {
    if (request.withdrawal && request.withdrawal.transaction_link)
      window.open(request.withdrawal.transaction_link, '_blank');
  }, [request.withdrawal]);

  if (!request.withdrawal) return null;

  return (
    <Modal
      onClose={onClose}
      title={'Request Details'}
      onBack={onClose}
      nextText={'View request'}
      onNext={request.withdrawal.transaction_link ? openLink : undefined}>
      {request.withdrawal.txid && (
        <MetaDetail variant={'vertical'} label="TxID" value={request.withdrawal.txid} copy />
      )}
    </Modal>
  );
}
