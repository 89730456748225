import { Modal, ModalProps } from './modal';

export function TermsOfServiceModal({ onClose }: Partial<ModalProps>) {
  return (
    <Modal title="Terms of Use" size={'lg'} onClose={onClose}>
      <h3>1. Terms and Definitions</h3>

      <p>
        1.1. Terms of Use (Terms) are these terms of UseGateway Service, which constitute a legally
        binding agreement between the User and the Service.
      </p>
      <p>
        1.2. User is a private individual, a Web user engaging UseGateway Service for his own
        personal purposes, as well as any other private individual for the benefit of whom the user
        is acting.
      </p>
      <p>
        1.3. UseGateway Service / Service is a service which allows Users to use digital payment
        facilities and execute funds transfers without opening a bank account. The copyright holder
        of the Service is Trendpay LLC, ID №404613245, Krtsanisi district, Mtkvari str., N6,
        Tbilisi, Georgia.
      </p>
      <p>1.4. Personal Account (Account) is a User&apos;s personal profile on the Service.</p>

      <h3>2. Assumption of the Terms</h3>

      <p>
        2.1. These Terms shall apply to any access to or use of any services provided by the
        UseGateway Service.
      </p>
      <p>
        2.2. The Terms are applied to the{' '}
        <a href={'https://usegateway.net/'} target={'_blank'} rel="noreferrer">
          Usegateway.net
        </a>
        ,{' '}
        <a href={'http://usegateway.group/'} target={'_blank'} rel="noreferrer">
          Usegateway.group
        </a>
        , and{' '}
        <a href={'https://usegateway.app/'} target={'_blank'} rel="noreferrer">
          Usegateway.app
        </a>
        , including subdomains.
      </p>
      <p>
        2.3. The Terms are applicable solely to the UseGateway Service. The UseGateway Service does
        not control and is not responsible for the websites/services of third parties to which the
        User may access via links available within the Service, as well as the User’s information
        processed by such third parties.
      </p>
      <p>
        2.4. By accessing the Service, registering an Account, the User agrees to be bound by these
        Terms and confirm that he/she has read, understood, and accepted all the provisions of these
        Terms, as well as provisions of Privacy Policy or other legally binding document publicly
        available on the UseGateway Service website. These Terms constitute a legally binding
        agreement between the User and UseGateway. In the event of disagreement with these Terms,
        the User shall refrain from using UseGateway Service.
      </p>
      <p>
        2.5. Minors (people under the age of 18) are not allowed to use the Service. By accepting
        these Terms, the User confirms that he/she is at least 18 years old and that the User has
        the right, authority, legal capacity to lawfully use the Service.
      </p>
      <p>
        2.6. The User is not allowed to use the Service if he/she is located in, or a citizen or
        resident of any state, country, territory, or other jurisdiction where the use of the
        Service is illegal or otherwise violate any applicable laws. The User warrants that he/she
        is not a citizen or resident of any such jurisdiction and that he/she will not use the
        Service while located in any such jurisdiction. By accepting the Terms he User confirms that
        he/she is not in, under the control of, or a national or resident of any country where
        crypto assets transactions are explicitly prohibited.
      </p>
      <p>
        2.7. If the User decides to open an Account on behalf of a company, the User shall represent
        and warrant that he/she is an authorized representative of this legal entity with the
        authority to act as well as that the company agrees to the Terms on its behalf.
      </p>
      <p>
        2.8. By using the Service, the User may authorize it to act as his/her agent in some cases
        and when the User intends to or requests such services in order to provide the UseGateway
        Service to receive, hold and disburse funds on behalf of the User and to take any and all
        actions that are necessary to provide the requested services in comply with applicable laws.
      </p>

      <h3>3. Overview of UseGateway Service and its services</h3>

      <p>3.1. UseGateway Service allows the User to:</p>
      <p>
        3.1.1 Carry out and render payments (Payment) – a tool for online shopping stores, where a
        product or service has a fixed value. This tool can be used when the User tops up his/her
        account with a fixed amount of money (for example, $50/100/150).
      </p>
      <p>
        The User is invoiced for payment when creating the funds transfer. The exchange rate is
        fixed for 60 minutes, during which the payment shall be executed. 60 minutes is the default
        setting, and the User is allowed to change it in his/her Personal Account settings on the
        UseGateway website.
      </p>
      <p>
        During the process of funds transfer, the User may select any cryptocurrency that he/she is
        willing to accept as payment from the list of UseGateway supported cryptocurrencies (BTC,
        ETH, LTC, TRON (TRX), USDT TRC20).
      </p>
      <p>
        The User may find out the information about the transfer of funds, payment and its status
        via Webhook or in Personal Account on the UseGateway website.
      </p>
      <p>
        3.1.2. Deposit – a tool which assumes the automatic creation of e-purses for each User,
        which the User is allowed to replenish at any time for any amount exceeding the specified
        minimum top-up amount.
      </p>
      <p>
        Minimum top-up amount is needed to solve business needs of the User, but also it ensures the
        Service and the User against situations where the User is to pay a significant part of a
        transaction on the network charges.
      </p>
      <p>
        3.2. UseGateway service differs from the ordinary payment services by its use of
        cryptocurrencies, which operate differently from the usual currencies (e.g. dollars, euros,
        tenge, manats, rubles, etc.).
      </p>
      <p>
        3.3. The payment time can take from minutes to several hours depending on the chosen
        cryptocurrency, fee of the remitter and network congestion. Both User and UseGateway Service
        cannot influence this indicator.
      </p>
      <p>
        3.4. All cryptoassets (cryptocurrencies) are different instruments, each of which has its
        own characteristics: the speed of transfer, the stability of the rate, the amount of
        commission, the method of commission payment, etc.
      </p>
      <p>
        3.5. UseGateway Service operates as a non-custodial wallet, which grants User control over
        his crypto-assets. But the User must save, memorize, or write on the paper and keep the seed
        phrase to avoid its loss.
      </p>
      <p>
        3.6. The User is obliged to save the seed phrase. In case of losing the seed phrase the User
        forfeits the ability to access his/her financial assets due to the fact that access to the
        Personal Account will be irrevocably lost. Usegatewy is not responsible for the loss of the
        seed phrase.
      </p>

      <h3>4. Risk disclosure</h3>

      <p>
        4.1. UseGateway Service warns about the risks of operations with digital assets. By using
        the services of UseGateway the User acknowledges and accepts any risks associated with the
        transactions, agrees to be bound by the Terms, and acknowledge and accept the following and
        any other risks.
      </p>
      <p>
        4.2. Exchange rates are of h3 volatility, and extreme swings in price may occur at any time,
        resulting in a potential loss of purchasing power. Digital assets trading may not be
        suitable for all investors. If the User has any concerns, he/she may obtain advice from a
        qualified financial advisor.
      </p>
      <p>
        4.3 Any data, prices, news, opinions, researches, and/or any other information contained on
        the Servise’s website is provided as general market commentary and does not constitute
        investment advice. UseGateway shall not be kept liable for any loss or damage, including but
        not limited to any loss of profit, which may arise directly or indirectly from the use of or
        reliance on such information.
      </p>
      <p>
        4.4. The Service does not guarantee the accuracy or timeliness of the information which may
        be displayed on the UseGateway website and shall not be kept liable for any loss or damage
        that may arise directly or indirectly from the content provided and/or User’s inability to
        access the website by his/her fault, as well as for any delay in or failure of the
        transmission or receipt of any instruction or notifications sent through the Service and its
        website.
      </p>
      <p>
        4.5. By using the Service the User agrees and acknowledges that all and any actions
        (transactions) performed by using Personal Account whether they are conducted on behalf of
        the User himself or on behalf of a legal entity shall be deemed valid and causing legal
        effects as if the actions (transactions) are formalized by a paper document signed by the
        User or on behalf of a legal entity. It is an obligation of the User to keep the Personal
        Account secure from any unauthorized access by the third parties. The Service reminds Users
        not to share login, password, or any other access details with other people or entities. The
        User shall notify immediately the Customer Support Team upon becoming aware of any breach of
        security or unauthorized use of the Personal Account.
      </p>

      <h3>5. Liability</h3>

      <p>
        5.1. UseGateway reserves the right to close Personal Accounts, restrict access to its
        website or any services provided by the Service for any reason and at any time. The Service
        is obliged to return any funds contained in User’s e-purses at the moment of closing
        Personal Account in accordance with the most recent contact information provided by the
        User.
      </p>
      <p>
        5.2. To the maximum extent permitted by applicable law, in no event shall UseGateway be
        liable for any indirect, punitive, incidental, special, consequential, or exemplary damages,
        including, without limitation, damages for loss of business revenues, loss of profits,
        goodwill, data or other intangible losses arising out of or relating to: <br />
        (a) the use of, or inability to use UseGateway website or its services;
      </p>
      <p>(b) any information provided by UseGateway or available from the website;</p>
      <p>(c) any conduct or content of any other User of the Service or third-party; or</p>
      <p>
        (d) the failure to receive in any way the transmission of any data, content or digital
        assets from the User.
      </p>
      <p>
        5.3. UseGateway shall not be held liable under any circumstances for any claim, proceeding,
        damage, loss, expense, or injury resulting from hacking, tampering, or other unauthorized
        access or unlawful use of the Service, the Personal Account or the information, digital
        assets contained therein.
      </p>

      <h3>6. Refunds</h3>

      <p>
        6.1. If the User desires to be entitled to any refunds related to the processing of payments
        with the use of UseGateway Service, he/she shall fulfill the following requirements:
      </p>
      <p>
        (a) the User maintains a clear refund policy and discloses it to its customers or other
        third parties ensuring that his/her activities and actions are in comply with all applicable
        laws and regulations, and with the provisions of these Terms;
      </p>
      <p>
        (b) the obligation to refund has to be occurred due to the breach of rights of the User
        claiming for the refund or due the confirmed mistake of the Service in conducting
        transactions;
      </p>
      <p>
        6.2. The User may determine his/her own refund policy and procedures, provided that they
        comply with all applicable laws and regulations, and with the provisions of these Terms and
        other binding documents of the Service.
      </p>
      <p>
        6.3. UseGateway is entitled to charge additional fees for any refunds not resulting from
        error on the side of the Service, including but not limited to cases of resolving refund
        cases between the Users. In the event when such charges are imposed on the User, he/she is
        not restricted to pass these charges to the customers or other Users with which he/she
        interacts.
      </p>
      <p>
        6.4. Any required conversions or proceedings during the refund process shall be calculated
        at the exchange rate determined by UseGateway.
      </p>

      <h3>7. Fee</h3>

      <p>
        7.1. Amount of the fee is determined according to the User’s tariff and its conditions. The
        default amount of the Fee is one (1) % of all payments, but it may be altered and exceed the
        default amount if the Users register through an affiliate link.
      </p>

      <h3>8. Force Majeure.</h3>

      <p>
        8.1. Neither the Service or the User shall be deemed to have failed to perform their
        obligations under these Terms and shall not hold the other party liable for any termination,
        interruption or delay in performing its obligations due to an earthquake, flood, fire,
        storm, natural disaster, act of God, war, terrorism, armed conflict, strike, lockout or
        boycott, provided that the party relying on such event:
      </p>
      <p>
        (a) provides the other party with a notification thereof promptly and, in any case, within
        ten (10) days of discovery of such event;
      </p>
      <p>
        (b) takes all reasonable and necessary actions in the circumstances to mitigate the force
        majeure upon which such notification is based;
      </p>
      <p>
        (c) and that force majeure circumstances described in this paragraph exceed thirty (30)
        days.
      </p>
      <p>
        <br />
      </p>

      <h3>9. Prohibited use and prohibited businesses</h3>

      <p>
        9.1. <b>Prohibited use.</b> By using the Service, the User confirms that he/she will not use
        the Service to do any of the following:
      </p>
      <p>
        9.1.1. Unlawful Activity – Activity which would violate, or assist in violation of, any law,
        ordinance, or other regulation, in countries where UseGateway conducts business, and also
        activities that aimed to transmit or upload any material to the Website that contains
        viruses, Trojan horses, worms, or any other harmful or deleterious software; attempt to gain
        unauthorized access to the Website, other accounts, computer systems or networks connected
        to the Website, through password mining or any other means.
      </p>
      <p>
        9.1.2. Abusive Activity – Actions which impose an unreasonable or disproportionately large
        load on the infrastructure of the Service, or detrimentally interfere with, intercept, or
        expropriate any system, data, or information; use account information of another User to
        access or use the Website.
      </p>
      <p>
        9.1.3. Fraud – Activity which operates to defraud UseGateway, UseGateway users, or any other
        person; provide any false, inaccurate, or misleading information to UseGateway.
      </p>
      <p>
        9.1.4. Gambling – lotteries; bidding fee auctions; sports forecasting or odds making;
        fantasy sports leagues with cash prizes; Internet gaming; contests; sweepstakes; games of
        chance.
      </p>
      <p>
        9.2. <b>Prohibited businesses.</b> By opening a Personal Account, the User confirms that
        he/she will not use the Service in connection with any of following businesses, activities,
        practices, or items:
      </p>
      <p>9.2.1. Restricted Financial Services – Check cashing, bail bonds, collections agencies.</p>
      <p>
        9.2.2. Counterfeit or Unauthorized Goods – Unauthorized sale or resale of brand name or
        designer products or services; sale of goods or services that are illegally imported or
        exported or which are stolen.
      </p>
      <p>
        9.2.3. Regulated Products and Services: Marijuana dispensaries and related businesses; sale
        of tobacco, e-cigarettes, and e-liquid; online prescription or pharmaceutical services;
        age-restricted goods or services; weapons and munitions; gunpowder and other explosives;
        fireworks and related goods; toxic, flammable, and radioactive materials; products and
        services with varying legal status on a state-by-state basis.
      </p>
      <p>
        9.2.4. Drugs and Drug Paraphernalia – Sale of drugs (narcotics), controlled substances, and
        any equipment designed for making or using drugs, such as bongs, vaporizers, and hookahs.
      </p>
      <p>
        9.2.5. Unfair, Predatory or Deceptive Practices – Sale or resale of a service without added
        benefit to the buyer; resale of government offerings without authorization or added value;
        sites that are determined in the discretion of the Service to be unfair, deceptive, or
        predatory towards consumers and other Users.
      </p>
      <p>9.2.6. Weapons Manufacturers or Vendors.</p>

      <h3>10. Applicable Law and Dispute Resolution</h3>

      <p>
        10.1. The User agrees that the laws of the Georgia, excluding conflict of laws rules, will
        govern these Terms and all matters arising hereunder. The User explicitly agrees to the case
        case being tried in the courts located in the Georgia.
      </p>

      <h3>11. Amendments to the Terms of Use</h3>

      <p>
        11.1. The UseGateway Service may modify the Terms from time to time. Changes to laws, other
        regulations and industry standards may require Service to make these amendments, or the
        Service may make adjustments in its business processes which will entail the need to change
        these Terms.
      </p>
      <p>
        11.2. The UseGateway Service provides additional notice in case it amends the Terms of Use
        in any way that significantly changes the rights of its Users. The new Terms of Use become
        effective upon publication.
      </p>
    </Modal>
  );
}
