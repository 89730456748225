// @ts-nocheck
import { forwardRef } from 'react';
const SvgRequest = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 21 20"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M12.7857 16.3887L11.3057 14.9087L10.5 15.7144L12.7857 18.0001L17.3571 13.4288L16.5514 12.623L12.7857 16.3887Z"
    />
    <path
      fill="currentColor"
      d="M8.78574 16.8572H4.78579V3.14304H9.35716V6.57157C9.35807 6.87439 9.47876 7.16455 9.69289 7.37868C9.90702 7.59281 10.1972 7.71351 10.5 7.71441H13.9285V11.1429H15.0714V6.57157C15.0734 6.49647 15.0591 6.42183 15.0295 6.35277C14.9999 6.28372 14.9557 6.2219 14.9 6.17157L10.9 2.17162C10.8497 2.11582 10.7879 2.07159 10.7188 2.042C10.6498 2.0124 10.5751 1.99814 10.5 2.00019H4.78579C4.48296 2.0011 4.1928 2.1218 3.97867 2.33592C3.76455 2.55005 3.64385 2.84021 3.64294 3.14304V16.8572C3.64385 17.16 3.76455 17.4501 3.97867 17.6643C4.1928 17.8784 4.48296 17.9991 4.78579 18H8.78574V16.8572ZM10.5 3.37161L13.7 6.57157H10.5V3.37161Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgRequest);
export default ForwardRef;
