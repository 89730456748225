import { ReactNode } from 'react';
import { styled } from '@use-gateway/theme';

const LabelWrapper = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;

  font-weight: 400;
  font-size: 16px;
  line-height: 1.25;

  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  color: ${({ theme }) => theme.colors.dark[100]};
`;

export interface LabelProps {
  children: ReactNode;
}
export function Label({ children }: LabelProps) {
  return <LabelWrapper>{children}</LabelWrapper>;
}
