// @ts-nocheck
import { forwardRef } from 'react';
const SvgCloudLightning = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_2128_24761)">
      <path
        fill="currentColor"
        d="M19 17.9C18.5 17.9 18.1 17.6 18 17.1C17.9 16.6 18.2 16 18.8 15.9C21 15.5 22.4 13.3 21.9 11.2C21.5 9.3 19.9 8 18 8H16.7C16.2 8 15.8 7.7 15.7 7.2C14.7 3.5 10.9 1.2 7.2 2.2C5.4 2.7 3.9 3.8 3 5.4C2 7.1 1.7 8.9 2.2 10.8C2.7 12.7 3.9 14.2 5.6 15.2C6.1 15.4 6.3 16 6 16.5C5.7 17 5.1 17.2 4.6 16.9C2.4 15.7 0.9 13.7 0.2 11.3C-0.4 9 -0.0999999 6.5 1.2 4.5C2.4 2.4 4.4 1 6.7 0.400004C11.2 -0.799996 15.9 1.8 17.4 6.1H18C20.8 6.1 23.3 8.1 23.9 10.9C24.6 14.1 22.5 17.3 19.2 18C19.1 17.9 19.1 17.9 19 17.9Z"
      />
      <path
        fill="currentColor"
        d="M11 24C10.8 24 10.6 23.9 10.4 23.8C9.9 23.5 9.8 22.9 10.1 22.4L13.1 18H9C8.6 18 8.3 17.8 8.1 17.5C7.9 17.2 7.9 16.8 8.1 16.5L12.1 10.5C12.4 10 13 9.9 13.5 10.2C14 10.5 14.1 11.1 13.8 11.6L10.8 16H15C15.4 16 15.7 16.2 15.9 16.5C16.1 16.8 16.1 17.2 15.9 17.5L11.9 23.5C11.6 23.8 11.3 24 11 24Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_2128_24761">
        <rect width={24} height={24} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgCloudLightning);
export default ForwardRef;
