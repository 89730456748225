import {
  BnbCoinsList,
  CryptoCurrency,
  EthCoinsList,
  TestCoins,
  TrxCoinsList,
} from '@use-gateway/types';

interface PayloadEnablingCryptocurrencies {
  listCryptocurrencies: Array<CryptoCurrency | TestCoins>;
}

export function enablingCryptocurrencies(payload: PayloadEnablingCryptocurrencies) {
  const resultArray: Array<CryptoCurrency | TestCoins> = [...payload.listCryptocurrencies];
  const resultObj = {};

  for (let i = 0; i < payload.listCryptocurrencies.length; i++) {
    // ~~ BNB
    for (let j = 0; j < BnbCoinsList.length; j++) {
      if (payload.listCryptocurrencies[i] === BnbCoinsList[j]) {
        if (!resultArray.find((cryptocurrency) => cryptocurrency === CryptoCurrency.BNB)) {
          resultArray.push(CryptoCurrency.BNB);
        }
      }
    }
    // ~~ ETH
    for (let j = 0; j < EthCoinsList.length; j++) {
      if (payload.listCryptocurrencies[i] === EthCoinsList[j]) {
        if (!resultArray.find((cryptocurrency) => cryptocurrency === CryptoCurrency.ETH)) {
          resultArray.push(CryptoCurrency.ETH);
        }
      }
    }
    // ~~ TRX
    for (let j = 0; j < TrxCoinsList.length; j++) {
      if (payload.listCryptocurrencies[i] === TrxCoinsList[j]) {
        if (!resultArray.find((cryptocurrency) => cryptocurrency === CryptoCurrency.TRX)) {
          resultArray.push(CryptoCurrency.TRX);
        }
      }
    }
  }

  for (let i = 0; i < resultArray.length; i++) {
    resultObj[resultArray[i]] = true;
  }

  return resultObj;
}

interface PayloadDisablingCryptocurrencies {
  availableCryptocurrencies: Array<CryptoCurrency | TestCoins>;
  includedCryptocurrencies: Array<CryptoCurrency | TestCoins>;
  listCryptocurrencies: Array<CryptoCurrency | TestCoins>;
}

export function disablingCryptocurrencies(payload: PayloadDisablingCryptocurrencies) {
  const resultArray: Array<CryptoCurrency | TestCoins> = [...payload.listCryptocurrencies];
  const resultObj = {};

  for (let i = 0; i < payload.listCryptocurrencies.length; i++) {
    // ~~ BNB
    if (payload.listCryptocurrencies[i] === CryptoCurrency.BNB) {
      for (let j = 0; j < BnbCoinsList.length; j++) {
        if (
          !payload.listCryptocurrencies.find((cryptocurrency) => cryptocurrency === BnbCoinsList[j])
        ) {
          resultArray.push(BnbCoinsList[j]);
        }
      }
    }
    // ~~ ETH
    if (payload.listCryptocurrencies[i] === CryptoCurrency.ETH) {
      for (let j = 0; j < EthCoinsList.length; j++) {
        if (
          !payload.listCryptocurrencies.find((cryptocurrency) => cryptocurrency === EthCoinsList[j])
        ) {
          resultArray.push(EthCoinsList[j]);
        }
      }
    }
    // ~~ TRX
    if (payload.listCryptocurrencies[i] === CryptoCurrency.TRX) {
      for (let j = 0; j < TrxCoinsList.length; j++) {
        if (
          !payload.listCryptocurrencies.find((cryptocurrency) => cryptocurrency === TrxCoinsList[j])
        ) {
          resultArray.push(TrxCoinsList[j]);
        }
      }
    }
  }

  const finalArr: Array<CryptoCurrency | TestCoins> = [];

  for (let i = 0; i < payload.includedCryptocurrencies.length; i++) {
    if (!resultArray.find((item) => item === payload.includedCryptocurrencies[i]))
      finalArr.push(payload.includedCryptocurrencies[i]);
  }

  if (finalArr.length === 0) {
    if (payload.availableCryptocurrencies.find((item) => item === CryptoCurrency.BTC)) {
      finalArr.push(CryptoCurrency.BTC);
    } else {
      finalArr.push(payload.availableCryptocurrencies[0]);
    }
  }

  for (let i = 0; i < payload.availableCryptocurrencies.length; i++) {
    finalArr.find((item) => item === payload.availableCryptocurrencies[i])
      ? (resultObj[payload.availableCryptocurrencies[i]] = true)
      : (resultObj[payload.availableCryptocurrencies[i]] = false);
  }

  return resultObj;
}
