import { isUndefined } from 'lodash-es';
import { down } from 'styled-breakpoints';
import { IconArrowLeft, IconArrowRight } from '@use-gateway/icons';
import { styled } from '@use-gateway/theme';
import { UnknownFunction } from '@use-gateway/types';
import { styledTransition } from '@use-gateway/utils';

const PaginationWrapper = styled.div`
  padding: 8px;
  margin: 8px auto;

  display: flex;
  align-items: center;

  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.01em;

  color: ${({ theme }) => theme.colors.dark[100]};

  ${down('md')} {
    justify-content: flex-end;
    margin: auto auto 0;
    padding: 0;

    position: sticky;
    bottom: 0;
    z-index: 1;

    background: ${({ theme }) => theme.colors.light[100]};
    width: 100%;
    justify-content: center;
    box-shadow: 0 0 25px rgb(0 0 0 / 10%);
    border-radius: 10px;
  }
`;

const Arrow = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 48px;
  height: 48px;
  margin: 0 28px;
  border-radius: 50%;
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.dark[100]};

  cursor: pointer;

  ${styledTransition(['background-color', 'opacity'])}

  svg {
    font-size: 24px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.light[100]};
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export interface PaginationProps {
  current: string | number;
  total: string | number;
  canGoPrev?: boolean;
  canGoNext?: boolean;
  onGoPrev?: UnknownFunction;
  onGoNext?: UnknownFunction;
}
export function Pagination({
  current,
  total,
  canGoPrev,
  canGoNext,
  onGoPrev,
  onGoNext,
}: PaginationProps) {
  const handleGoPrev = () => {
    if (onGoPrev) onGoPrev();
  };
  const handleGoNext = () => {
    if (onGoNext) onGoNext();
  };

  return (
    <PaginationWrapper>
      <Arrow onClick={handleGoPrev} disabled={isUndefined(canGoPrev) ? false : !canGoPrev}>
        <IconArrowLeft />
      </Arrow>
      {current} / {total}
      <Arrow onClick={handleGoNext} disabled={isUndefined(canGoNext) ? false : !canGoNext}>
        <IconArrowRight />
      </Arrow>
    </PaginationWrapper>
  );
}
