// @ts-nocheck
import { forwardRef } from 'react';
const SvgChange = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="#120D26"
      fillRule="evenodd"
      d="M17.4243 5.70711C17.0338 5.31658 17.0338 4.68342 17.4243 4.29289C17.8148 3.90237 18.448 3.90237 18.8385 4.29289L20.8385 6.29289C21.229 6.68342 21.229 7.31658 20.8385 7.70711L18.8385 9.70711C18.448 10.0976 17.8148 10.0976 17.4243 9.70711C17.0338 9.31658 17.0338 8.68342 17.4243 8.29289L17.7172 8H8.13141C6.47455 8 5.13141 9.34315 5.13141 11C5.13141 11.5523 4.68369 12 4.13141 12C3.57912 12 3.13141 11.5523 3.13141 11C3.13141 8.23876 5.36969 6.0003 8.13086 6L8.13141 6H17.7172L17.4243 5.70711ZM6.83852 18.2929C7.22904 18.6834 7.22904 19.3166 6.83852 19.7071C6.44799 20.0976 5.81483 20.0976 5.4243 19.7071L3.4243 17.7071C3.03378 17.3166 3.03378 16.6834 3.4243 16.2929L5.4243 14.2929C5.81483 13.9024 6.44799 13.9024 6.83852 14.2929C7.22904 14.6834 7.22904 15.3166 6.83852 15.7071L6.54562 16L16.13 16C16.1305 16 16.1309 16 16.1314 16C17.7883 16 19.1314 14.6569 19.1314 13C19.1314 12.4477 19.5791 12 20.1314 12C20.6837 12 21.1314 12.4477 21.1314 13C21.1314 15.7609 18.8937 17.9992 16.133 18C16.1325 18 16.1319 18 16.1314 18L6.54562 18L6.83852 18.2929Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgChange);
export default ForwardRef;
