// @ts-nocheck
import { forwardRef } from 'react';
const SvgEth = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 64 64"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_3630_36487)">
      <path
        fill="#627EEA"
        d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z"
      />
      <path fill="white" fillOpacity={0.602} d="M32.9961 8V25.7401L47.99 32.44L32.9961 8Z" />
      <path fill="white" d="M32.9959 8L18 32.44L32.9959 25.7401V8Z" />
      <path
        fill="white"
        fillOpacity={0.602}
        d="M32.9961 43.9362V55.9902L48.0002 35.2322L32.9961 43.9362Z"
      />
      <path fill="white" d="M32.9959 55.9902V43.9342L18 35.2322L32.9959 55.9902Z" />
      <path fill="white" fillOpacity={0.2} d="M32.9961 41.146L47.99 32.44L32.9961 25.744V41.146Z" />
      <path fill="white" fillOpacity={0.602} d="M18 32.44L32.9959 41.146V25.744L18 32.44Z" />
    </g>
    <defs>
      <clipPath id="clip0_3630_36487">
        <rect width={64} height={64} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgEth);
export default ForwardRef;
