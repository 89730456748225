// @ts-nocheck
import { forwardRef } from 'react';
const SvgFilm = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.18 3C3.5283 3 3 3.5283 3 4.18V19.82C3 20.4717 3.5283 21 4.18 21H19.82C20.4717 21 21 20.4717 21 19.82V4.18C21 3.5283 20.4717 3 19.82 3H4.18ZM1 4.18C1 2.42373 2.42373 1 4.18 1H19.82C21.5763 1 23 2.42373 23 4.18V19.82C23 21.5763 21.5763 23 19.82 23H4.18C2.42373 23 1 21.5763 1 19.82V4.18Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7 1C7.55228 1 8 1.44772 8 2V22C8 22.5523 7.55228 23 7 23C6.44772 23 6 22.5523 6 22V2C6 1.44772 6.44772 1 7 1Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17 1C17.5523 1 18 1.44772 18 2V22C18 22.5523 17.5523 23 17 23C16.4477 23 16 22.5523 16 22V2C16 1.44772 16.4477 1 17 1Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1 12C1 11.4477 1.44772 11 2 11H22C22.5523 11 23 11.4477 23 12C23 12.5523 22.5523 13 22 13H2C1.44772 13 1 12.5523 1 12Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1 7C1 6.44772 1.44772 6 2 6H7C7.55228 6 8 6.44772 8 7C8 7.55228 7.55228 8 7 8H2C1.44772 8 1 7.55228 1 7Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1 17C1 16.4477 1.44772 16 2 16H7C7.55228 16 8 16.4477 8 17C8 17.5523 7.55228 18 7 18H2C1.44772 18 1 17.5523 1 17Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 17C16 16.4477 16.4477 16 17 16H22C22.5523 16 23 16.4477 23 17C23 17.5523 22.5523 18 22 18H17C16.4477 18 16 17.5523 16 17Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 7C16 6.44772 16.4477 6 17 6H22C22.5523 6 23 6.44772 23 7C23 7.55228 22.5523 8 22 8H17C16.4477 8 16 7.55228 16 7Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgFilm);
export default ForwardRef;
