import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { IconWallet, IconPayments, IconMenu, IconWithdrawal2 } from '@use-gateway/icons';
import { styled } from '@use-gateway/theme';
import { CommonEvents } from '@use-gateway/types';
import { useEventBus } from '@use-gateway/utils';
import { Link } from '../link';

const BottomBarWrapper = styled.div`
  z-index: 2000;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.light[100]};
`;

const BottomBarButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  font-size: 12px;
  line-height: 1.67;
  color: ${({ theme }) => theme.colors.dark[100]};

  &.active {
    color: ${({ theme }) => theme.colors.primary};
  }

  &.no-active {
    color: ${({ theme }) => theme.colors.dark[100]};
  }

  svg {
    font-size: 24px;
  }
`;

export function BottomBar() {
  const { events } = useRouter();
  const { emit } = useEventBus();
  const [isWithdrawals, setIsWithdrawals] = useState(true);

  const handleHashChange = (url: string) => {
    const hashIndex = url.lastIndexOf('#');
    if (hashIndex !== -1) {
      const newHash = url.substring(hashIndex + 1);
      if (newHash === 'withdrawals') {
        setIsWithdrawals(true);
      } else {
        setIsWithdrawals(false);
      }
    } else {
      setIsWithdrawals(false);
    }
  };

  useEffect(() => {
    handleHashChange(window.location.href);
    const handleRouteChange = (url: string) => handleHashChange(url);
    events.on('hashChangeComplete', handleHashChange);
    events.on('routeChangeComplete', handleRouteChange);

    return () => {
      events.off('hashChangeComplete', handleHashChange);
      events.off('routeChangeComplete', handleRouteChange);
    };
  }, [events]);

  return (
    <BottomBarWrapper>
      <Link href="/wallet/">
        <BottomBarButton className={isWithdrawals ? 'no-active' : ''}>
          <IconWallet />
          Wallet
        </BottomBarButton>
      </Link>
      <Link href="/transactions/">
        <BottomBarButton>
          <IconPayments />
          Transactions
        </BottomBarButton>
      </Link>
      <Link href="/wallet/#withdrawals">
        <BottomBarButton className={isWithdrawals ? '' : 'no-active'}>
          <IconWithdrawal2 />
          Withdrawals
        </BottomBarButton>
      </Link>
      <BottomBarButton onClick={() => emit(CommonEvents.openMobMenu)}>
        <IconMenu />
        Menu
      </BottomBarButton>
    </BottomBarWrapper>
  );
}
