import { useState } from 'react';
import { PadBox } from '@bedrock-layout/padbox';
import { api } from '@use-gateway/api';
import { RequestMetaDataModal } from '@use-gateway/panel/components/modals/request-meta-data-modal';
import { Breakpoints, useBreakpoints } from '@use-gateway/theme';
import { WithdrawalRequestsResponseDto, WithdrawalsEvents } from '@use-gateway/types';
import { useEventBus, usePaginated } from '@use-gateway/utils';
import { RequestsRow } from './row';
import { Pagination } from '../pagination';
import { Spiner } from '../spiner';
import { TableCell, TableContent, TableHeader, TableWrapper } from '../table';

export function RequestsTable() {
  const { subscribe } = useEventBus();
  const { isUp } = useBreakpoints();

  const [modalMetaData, setModalMetaData] = useState<WithdrawalRequestsResponseDto | null>(null);
  subscribe(WithdrawalsEvents.openRequestMetaData, (value: WithdrawalRequestsResponseDto) =>
    setModalMetaData(value)
  );

  const {
    items: requests,
    fetchItems: fetchWithdrawals,
    itemsOffset,
    itemsTotal,
    itemsOnPage,
    pending,
    canGoPrev,
    canGoNext,
    goPrev,
    goNext,
  } = usePaginated((page, size) => api.getWithdrawalRequests(page, size), 10);

  subscribe(WithdrawalsEvents.withdrawalRequestApproved, fetchWithdrawals);
  subscribe(WithdrawalsEvents.withdrawalRequestDeclined, fetchWithdrawals);
  subscribe(WithdrawalsEvents.payWithdrawalRequest, fetchWithdrawals);
  subscribe(WithdrawalsEvents.requestWasCreate, fetchWithdrawals);

  return (
    <>
      {isUp(Breakpoints.md) ? (
        <TableWrapper>
          <TableHeader columns={17}>
            <TableCell span={2}>Date</TableCell>
            <TableCell span={3}>Amount</TableCell>
            <TableCell span={4}>Comments</TableCell>
            <TableCell span={3}>Status</TableCell>
            <TableCell span={3}>Actions</TableCell>
            <TableCell span={2} align="center">
              Details
            </TableCell>
          </TableHeader>
          <TableContent loading={pending}>
            {pending && <Spiner />}
            {!requests.length ? (
              <PadBox padding={'lg'} style={{ textAlign: 'center' }}>
                <p>You don&apos;t have any requests yet</p>
              </PadBox>
            ) : (
              requests.map((request) => <RequestsRow key={request.id} request={request} />)
            )}
          </TableContent>
        </TableWrapper>
      ) : (
        <div>
          {!requests.length && (
            <PadBox padding={'lg'} style={{ textAlign: 'center' }}>
              <p>You don&apos;t have any requests yet</p>
            </PadBox>
          )}
          {requests.map((request) => (
            <RequestsRow key={request.id} request={request} />
          ))}
        </div>
      )}

      {!!requests.length && (
        <Pagination
          current={`${itemsOffset + 1}-${itemsOffset + itemsOnPage}`}
          total={itemsTotal}
          onGoPrev={goPrev}
          onGoNext={goNext}
          canGoPrev={canGoPrev}
          canGoNext={canGoNext}
        />
      )}

      {modalMetaData && (
        <RequestMetaDataModal request={modalMetaData} onClose={() => setModalMetaData(null)} />
      )}
    </>
  );
}
