import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PadBox } from '@bedrock-layout/padbox';
import { api } from '@use-gateway/api';
import {
  StatusFilterModal,
  Spiner,
  Pagination,
  Dropdown,
  StatusDropdown,
} from '@use-gateway/components';
import { IconFilter } from '@use-gateway/icons';
import { Breakpoints, useBreakpoints } from '@use-gateway/theme';
import {
  DateRange,
  DepositsEvents,
  InvoicesEvents,
  PaymentsEvents,
  TransactionsResponse,
} from '@use-gateway/types';
import { useEventBus, usePaginated } from '@use-gateway/utils';
import { TransactionsRow } from './row';
import { TableCell, TableContent, TableHeader, TableWrapper } from '../table';

const TableIcon = styled.div`
  min-width: 24px;
  width: 24px;
  height: 24px;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.dark[40]};
`;

const TableFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  &:hover ${TableIcon} {
    color: ${({ theme }) => theme.colors.accent[100]};
  }
`;

export interface TransactionsTableProps {
  search?: string;
  dateRange?: DateRange;
  onFetched?: (response?: TransactionsResponse) => void;
}
export function TransactionsTable({ search, dateRange, onFetched }: TransactionsTableProps) {
  const { subscribe } = useEventBus();
  const { isUp } = useBreakpoints();

  const [isFilterModal, setIsFilterModal] = useState(false);
  const [isUnresolved, setIsUnresolved] = useState<boolean | undefined>(undefined);

  subscribe(PaymentsEvents.openStatusFilter, () => setIsFilterModal(true));

  const {
    raw: rawResponse,
    items: transactions,
    fetchItems: fetchTransactions,
    itemsOffset,
    itemsTotal,
    itemsOnPage,
    pending,
    canGoPrev,
    canGoNext,
    goPrev,
    goNext,
  } = usePaginated(
    (page, size) =>
      api.getTransactions(page, size, { search, dateRange, is_unresolved: isUnresolved }),
    10
  );

  subscribe(PaymentsEvents.paymentWasResolved, fetchTransactions);
  subscribe(DepositsEvents.depositWasResolved, fetchTransactions);
  subscribe(InvoicesEvents.invoiceWasResolved, fetchTransactions);

  useEffect(() => {
    fetchTransactions(1);
  }, [search, dateRange, isUnresolved]);

  useEffect(() => {
    if (onFetched) onFetched(rawResponse as unknown as TransactionsResponse);
  }, [rawResponse]);

  return (
    <>
      {isUp(Breakpoints.md) ? (
        <TableWrapper>
          <TableHeader columns={17}>
            <TableCell span={2}>Date</TableCell>
            <TableCell span={3}>Product name / ID</TableCell>
            <TableCell span={4}>Amount</TableCell>
            <TableCell span={3}>
              <Dropdown
                toggler={
                  <TableFilter>
                    Status
                    <TableIcon>
                      <IconFilter />
                    </TableIcon>
                  </TableFilter>
                }>
                <StatusDropdown isUnresolved={isUnresolved} setIsUnresolved={setIsUnresolved} />
              </Dropdown>
            </TableCell>
            <TableCell span={3}>Actions</TableCell>
            <TableCell span={2} align="center">
              Details
            </TableCell>
          </TableHeader>
          <TableContent loading={pending}>
            {pending && <Spiner />}
            {!transactions.length ? (
              <PadBox padding={'lg'} style={{ textAlign: 'center' }}>
                {search || typeof isUnresolved === 'boolean' ? (
                  <p>No results found</p>
                ) : (
                  <p>You don&apos;t have any transactions yet</p>
                )}
              </PadBox>
            ) : (
              transactions.map((transaction) => (
                <TransactionsRow key={transaction.id} transaction={transaction} />
              ))
            )}
          </TableContent>
        </TableWrapper>
      ) : (
        <div>
          {!transactions.length ? (
            <PadBox padding={'lg'} style={{ textAlign: 'center' }}>
              {search || typeof isUnresolved === 'boolean' ? (
                <p>No results found</p>
              ) : (
                <p>You don&apos;t have any transactions yet</p>
              )}
            </PadBox>
          ) : (
            transactions.map((transaction) => (
              <TransactionsRow key={transaction.id} transaction={transaction} />
            ))
          )}
        </div>
      )}

      {!!transactions.length && (
        <Pagination
          current={`${itemsOffset + 1}-${itemsOffset + itemsOnPage}`}
          total={itemsTotal}
          onGoPrev={goPrev}
          onGoNext={goNext}
          canGoPrev={canGoPrev}
          canGoNext={canGoNext}
        />
      )}

      {isFilterModal && (
        <StatusFilterModal
          isUnresolved={isUnresolved}
          setIsUnresolved={setIsUnresolved}
          onClose={() => setIsFilterModal(false)}
        />
      )}
    </>
  );
}
