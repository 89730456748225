// @ts-nocheck
import { forwardRef } from 'react';
const SvgAward = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_2128_23906)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 2C8.68629 2 6 4.68629 6 8C6 11.3137 8.68629 14 12 14C15.3137 14 18 11.3137 18 8C18 4.68629 15.3137 2 12 2ZM4 8C4 3.58172 7.58172 0 12 0C16.4183 0 20 3.58172 20 8C20 12.4183 16.4183 16 12 16C7.58172 16 4 12.4183 4 8Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M15.6585 12.8887C16.206 12.816 16.7087 13.201 16.7813 13.7485L17.9913 22.8685C18.0418 23.2491 17.8699 23.625 17.549 23.8358C17.228 24.0466 16.8147 24.055 16.4855 23.8575L12 21.1662L7.51449 23.8575C7.18522 24.0551 6.7719 24.0466 6.45096 23.8358C6.13001 23.625 5.95814 23.249 6.0087 22.8683L7.2187 13.7583C7.29142 13.2109 7.79418 12.826 8.34166 12.8987C8.88914 12.9714 9.274 13.4742 9.20129 14.0217L8.26444 21.0751L11.4855 19.1425C11.8022 18.9525 12.1978 18.9525 12.5145 19.1425L15.7359 21.0753L14.7987 14.0115C14.726 13.464 15.111 12.9613 15.6585 12.8887Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_2128_23906">
        <rect width={24} height={24} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgAward);
export default ForwardRef;
