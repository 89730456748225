import { ReactNode, useMemo } from 'react';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { DoublePrice } from '@use-gateway/components';
import { IconProfilePopover } from '@use-gateway/icons';
import { Breakpoints, useBreakpoints } from '@use-gateway/theme';
import {
  CryptoCurrency,
  FiatCurrency,
  RateDto,
  StableCoinsList,
  TestCoins,
  WalletDto,
  WalletItem,
  WithdrawalsEvents,
} from '@use-gateway/types';
import { precisionByCurrency, useAuth, useEventBus } from '@use-gateway/utils';

const WalletItemWrapper = styled.div`
  width: 100%;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.colors.light[80]};
  background-color: ${({ theme }) => theme.colors.light[100]};

  ${down(Breakpoints.sm)} {
    margin: 8px 0;
    border-radius: 10px;
    border-top: none;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 360px) {
    min-width: 320px;
  }
`;

const WalletItemName = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  width: 100%;
  padding: 0 20px;
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: -0.01em;

  span {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    color: ${({ theme }) => theme.colors.dark[100]};
  }

  .icon {
    width: 36px;
  }

  svg {
    font-size: 36px;
  }

  small {
    position: relative;
    margin-top: -0.4em;
    font-size: 0.6em;
    font-weight: 900;
    color: ${({ theme }) => theme.colors.primary};
  }

  ${down(Breakpoints.sm)} {
    gap: 12px;
    padding: 0 12px;
    font-size: 18px;
  }
`;

const WalletItemRate = styled.div`
  display: flex;
  gap: 6px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.dark[80]};

  ${down(Breakpoints.sm)} {
    gap: 4px;
    font-size: 12px;
  }
`;

const WalletPercentSection = styled.div`
  display: flex;
  justify-content: flex-start;
  min-width: 60px;
  max-width: 60px;
`;

interface RatePercentProps {
  isRising: boolean;
}
const RatePercent = styled.div<RatePercentProps>`
  position: relative;
  margin-right: 10px;
  text-align: right;
  color: ${({ isRising }) => (isRising ? '#61BD4A' : '#E51212')};

  ${down(Breakpoints.sm)} {
    min-width: auto;
    max-width: fit-content;
  }

  &::after {
    content: '';
    position: absolute;
    top: ${({ isRising }) => (isRising ? '-1px' : '5px')};
    right: -14px;
    display: block;
    border: 5px solid transparent;
    border-bottom: 7px solid ${({ isRising }) => (isRising ? '#61BD4A' : '#E51212')};
    transform: rotate(${({ isRising }) => (isRising ? '0deg' : '180deg')});

    ${down(Breakpoints.sm)} {
      top: ${({ isRising }) => (isRising ? '-2px' : '3.5px')};
    }
  }
`;

const WalletItemAmount = styled.div`
  width: 220px;
  min-width: 220px;

  ${down(Breakpoints.sm)} {
    width: auto;
    min-width: auto;
    text-align: right;
    padding-right: 12px;
  }
`;

const WalletItemWithdraw = styled.button`
  display: flex;
  justify-content: flex-end;
  width: 120px;
  min-width: 120px;
  border: none;
  background-color: transparent;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.01em;
  color: ${({ theme }) => theme.colors.accent[100]};
  cursor: pointer;
  transition: 0.1s;

  &:hover {
    transform: scale(1.1);
  }

  &:disabled {
    color: #ccc;
    transform: none;
    cursor: default;
  }
`;

const WalletWithdrawIcon = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 28px;
  height: 28px;
  margin-right: 12px;
  border: none;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.primary};

  background-color: ${({ theme }) => theme.colors.primary};
  font-size: 20px;
  color: #fff;

  &:disabled {
    background-color: #ccc;
    cursor: default;
  }

  svg {
    min-width: 20px;
  }
`;

interface WalletRowProps {
  title: string;
  icon?: ReactNode;
  wallet: WalletDto;
  currency: CryptoCurrency | TestCoins;
  rate: RateDto | null | undefined;
}
export function WalletRow({ title, wallet, currency, icon, rate }: WalletRowProps) {
  const { user } = useAuth();
  const item = wallet[currency] as WalletItem;
  const { isUp, isDown } = useBreakpoints();
  const { emit } = useEventBus();

  const isStableCoin = useMemo(() => {
    return StableCoinsList.includes(currency as CryptoCurrency);
  }, []);

  const handleWithdraw = () => {
    emit(WithdrawalsEvents.openWithdrawal, currency);
  };

  return (
    <WalletItemWrapper>
      <WalletItemName>
        <span className={'icon'}>{icon}</span>
        <div>
          <span dangerouslySetInnerHTML={{ __html: title }} />
          {rate && !isStableCoin && isDown(Breakpoints.sm) && (
            <WalletItemRate>
              <div>${rate.rate}</div>
              <RatePercent isRising={rate.diff_percent >= 0}>{rate.diff_percent}%</RatePercent>
            </WalletItemRate>
          )}
        </div>
      </WalletItemName>
      {rate && !isStableCoin && isUp(Breakpoints.sm) && (
        <WalletItemRate>
          <div>${rate.rate}</div>
          <WalletPercentSection>
            <RatePercent isRising={rate.diff_percent >= 0}>{rate.diff_percent}%</RatePercent>
          </WalletPercentSection>
        </WalletItemRate>
      )}
      <WalletItemAmount>
        <DoublePrice
          primary={{
            amount: item.value,
            currency,
          }}
          secondary={{
            amount: item.local,
            currency: user?.local_currency as FiatCurrency,
          }}
          size={isUp(Breakpoints.sm) ? 'md' : 'xs'}
          maxPrecision={precisionByCurrency(currency)}
          color={item.value === 0 ? 'grey' : undefined}
        />
      </WalletItemAmount>
      {isUp(Breakpoints.sm) ? (
        <WalletItemWithdraw onClick={handleWithdraw} disabled={item.value === 0}>
          Withdraw
        </WalletItemWithdraw>
      ) : (
        <WalletWithdrawIcon onClick={handleWithdraw} disabled={item.value === 0}>
          <IconProfilePopover />
        </WalletWithdrawIcon>
      )}
    </WalletItemWrapper>
  );
}
