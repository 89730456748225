// @ts-nocheck
import { forwardRef } from 'react';
const SvgRadio = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11ZM9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.637 4.22279C6.02758 4.61325 6.02768 5.24642 5.63721 5.637C3.94997 7.32475 3.00214 9.61352 3.00214 12C3.00214 14.3865 3.94997 16.6753 5.63721 18.363C6.02768 18.7536 6.02758 19.3867 5.637 19.7772C5.24642 20.1677 4.61325 20.1676 4.22279 19.777C2.16061 17.7142 1.00214 14.9168 1.00214 12C1.00214 9.0832 2.16061 6.2858 4.22279 4.223C4.61325 3.83242 5.24642 3.83232 5.637 4.22279ZM18.363 4.22279C18.7536 3.83232 19.3867 3.83242 19.7772 4.223C21.8394 6.2858 22.9979 9.0832 22.9979 12C22.9979 14.9168 21.8394 17.7142 19.7772 19.777C19.3867 20.1676 18.7536 20.1677 18.363 19.7772C17.9724 19.3867 17.9723 18.7536 18.3628 18.363C20.05 16.6753 20.9979 14.3865 20.9979 12C20.9979 9.61352 20.05 7.32475 18.3628 5.637C17.9723 5.24642 17.9724 4.61325 18.363 4.22279ZM8.4675 7.04329C8.85781 7.43403 8.85746 8.06719 8.46671 8.4575C8.00183 8.92187 7.63304 9.47331 7.38142 10.0803C7.12979 10.6873 7.00028 11.3379 7.00028 11.995C7.00028 12.6521 7.12979 13.3027 7.38142 13.9097C7.63304 14.5167 8.00183 15.0681 8.46671 15.5325C8.85746 15.9228 8.85781 16.556 8.4675 16.9467C8.07719 17.3375 7.44403 17.3378 7.05329 16.9475C6.40245 16.2974 5.88614 15.5254 5.53387 14.6756C5.1816 13.8258 5.00028 12.9149 5.00028 11.995C5.00028 11.0751 5.1816 10.1642 5.53387 9.31442C5.88614 8.46463 6.40245 7.69261 7.05329 7.0425C7.44403 6.65219 8.07719 6.65255 8.4675 7.04329ZM15.5325 7.05329C15.9228 6.66255 16.556 6.66219 16.9467 7.0525C17.5975 7.70261 18.1139 8.47463 18.4661 9.32442C18.8184 10.1742 18.9997 11.0851 18.9997 12.005C18.9997 12.9249 18.8184 13.8358 18.4661 14.6856C18.1139 15.5354 17.5975 16.3074 16.9467 16.9575C16.556 17.3478 15.9228 17.3475 15.5325 16.9567C15.1422 16.566 15.1425 15.9328 15.5333 15.5425C15.9982 15.0781 16.367 14.5267 16.6186 13.9197C16.8702 13.3127 16.9997 12.6621 16.9997 12.005C16.9997 11.3479 16.8702 10.6973 16.6186 10.0903C16.367 9.48331 15.9982 8.93187 15.5333 8.4675C15.1425 8.07719 15.1422 7.44403 15.5325 7.05329Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgRadio);
export default ForwardRef;
