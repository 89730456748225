// @ts-nocheck
import { forwardRef } from 'react';
const SvgIntercomIcon = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 25"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M12 16.06C10.2529 16.0686 8.53937 15.5795 7.06 14.65C6.97424 14.5952 6.90019 14.5239 6.84215 14.4403C6.7841 14.3568 6.7432 14.2625 6.72181 14.163C6.70042 14.0635 6.69897 13.9607 6.71754 13.8607C6.73611 13.7606 6.77433 13.6652 6.83 13.58C6.88305 13.4938 6.95279 13.419 7.03513 13.3601C7.11747 13.3012 7.21076 13.2593 7.3095 13.237C7.40825 13.2146 7.51046 13.2122 7.61015 13.2299C7.70984 13.2476 7.80498 13.285 7.89 13.34C9.12783 14.0954 10.5499 14.495 12 14.495C13.4501 14.495 14.8722 14.0954 16.11 13.34C16.195 13.285 16.2902 13.2476 16.3899 13.2299C16.4895 13.2122 16.5918 13.2146 16.6905 13.237C16.7892 13.2593 16.8825 13.3012 16.9649 13.3601C17.0472 13.419 17.117 13.4938 17.17 13.58C17.2257 13.6652 17.2639 13.7606 17.2825 13.8607C17.301 13.9607 17.2996 14.0635 17.2782 14.163C17.2568 14.2625 17.2159 14.3568 17.1579 14.4403C17.0998 14.5239 17.0258 14.5952 16.94 14.65C15.4606 15.5795 13.7471 16.0686 12 16.06ZM14 18L19 20L21 21V21.38C21.0012 21.5513 20.9584 21.7201 20.8756 21.8701C20.7928 22.0201 20.6729 22.1463 20.5274 22.2366C20.3818 22.327 20.2155 22.3784 20.0444 22.386C19.8732 22.3936 19.703 22.3571 19.55 22.28L13 20H5.89C5.12352 20 4.38844 19.6955 3.84646 19.1535C3.30448 18.6116 3 17.8765 3 17.11V5.89C3 5.12352 3.30448 4.38844 3.84646 3.84646C4.38844 3.30448 5.12352 3 5.89 3H18.11C19.71 3 21 4.29 21 4.89V21L19 20V6.22C19 6.05979 18.9684 5.90114 18.9071 5.75313C18.8458 5.60511 18.756 5.47062 18.6427 5.35733C18.5294 5.24404 18.3949 5.15418 18.2469 5.09287C18.0989 5.03156 17.9402 5 17.78 5H6.22C6.05979 5 5.90114 5.03156 5.75313 5.09287C5.60511 5.15418 5.47062 5.24404 5.35733 5.35733C5.24404 5.47062 5.15418 5.60511 5.09287 5.75313C5.03156 5.90114 5 6.05979 5 6.22V16.78C5 17.454 5.546 18 6.22 18H14Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgIntercomIcon);
export default ForwardRef;
