import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { styledTransition } from '@use-gateway/utils';

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
`;

const ButtonsSection = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding: 4px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.light[80]};
`;

interface SelectorBackplateProps {
  position: number;
  count: number;
}

const SelectorBackplate = styled.div<SelectorBackplateProps>`
  position: absolute;
  z-index: 1;
  left: 4px;
  top: 4px;
  bottom: 4px;
  right: ${({ count }) => `calc(((100% - 8px) / ${count} * ${count - 1}) + 4px)`};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  background-color: ${({ theme }) => theme.colors.light[100]};

  transform: translateX(calc(100% * ${({ position }) => position}));
  ${styledTransition(['transform'])}
`;

interface BtnProps {
  isActive?: boolean;
}

const Btn = styled.button<BtnProps>`
  z-index: 10;
  width: 100%;
  height: 48px;
  border: none;
  border-radius: 12px;
  background: transparent;
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme, isActive }) => (isActive ? '#120D26' : theme.colors.dark[40])};

  ${styledTransition(['color'])}

  cursor: pointer;
`;

const Mark = styled.p`
  margin: 4px 0 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.dark[40]};
  font-size: 14px;
`;

interface SwitchItemDto {
  value: string | number;
  title: string;
  description?: string;
}

interface SwitchProps {
  items: SwitchItemDto[];
  label?: string;
  value?: string | number;
  setValue?: (value: string | number) => void;
}

export function Switch({ items, label, value, setValue }: SwitchProps) {
  if (items.length === 0) return null;

  const [position, setPosition] = useState(
    items.findIndex((item) => item.value === value) ?? Math.floor(items.length / 2)
  );
  const [currentValue, setCurrentValue] = useState<string | number>(value ?? items[position].value);

  const description: string | null = useMemo(() => {
    const active = items.find((item) => item.value === value);
    if (active?.description) return active.description;
    return null;
  }, [value]);

  const handleClick = (value: string | number, position: number) => {
    setCurrentValue(value);
    if (setValue) setValue(value);
    setPosition(position);
  };

  return (
    <div>
      {label && <Label>{label}</Label>}
      <ButtonsSection>
        <SelectorBackplate position={position} count={items.length} />
        {items.map((item, i) => (
          <Btn
            key={item.value}
            isActive={currentValue === item.value}
            onClick={() => handleClick(item.value, i)}>
            {item.title}
          </Btn>
        ))}
      </ButtonsSection>
      {description && <Mark>{description}</Mark>}
    </div>
  );
}
