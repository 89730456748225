// @ts-nocheck
import { forwardRef } from 'react';
const SvgAlert = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 64 64"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="#E61212"
      d="M9.3333 58.6669C7.99997 58.6669 6.66663 58.4002 5.3333 57.6002C1.59997 55.4669 0.266632 50.4002 2.39997 46.6669L25.0666 8.80023C25.8666 7.73356 26.6666 6.66689 27.7333 6.13356C29.6 5.06689 31.7333 4.80023 33.8666 5.33356C36 5.86689 37.6 7.20023 38.9333 9.0669L61.3333 46.6669C62.1333 48.0002 62.4 49.3336 62.4 50.6669C62.4 52.8002 61.6 54.9336 60 56.2669C58.6666 57.8669 56.8 58.6669 54.6666 58.6669H9.3333ZM29.6 11.7336L7.19997 49.3336C6.39997 50.6669 6.9333 52.2669 8.26663 53.0669C8.5333 53.3336 9.06663 53.3336 9.3333 53.3336H54.4C55.2 53.3336 55.7333 53.0669 56.2666 52.5336C56.8 52.0002 57.0666 51.4669 57.0666 50.6669C57.0666 50.1336 57.0666 49.8669 56.8 49.3336L34.4 11.7336C33.6 10.4002 32 10.1336 30.6666 10.6669C30.1333 10.9336 29.8666 11.2002 29.6 11.7336Z"
    />
    <path
      fill="#E61212"
      d="M32 37.3336C30.4 37.3336 29.3333 36.2669 29.3333 34.6669V24.0002C29.3333 22.4002 30.4 21.3336 32 21.3336C33.6 21.3336 34.6666 22.4002 34.6666 24.0002V34.6669C34.6666 36.2669 33.6 37.3336 32 37.3336Z"
    />
    <path
      fill="#E61212"
      d="M32 48.0002C31.2 48.0002 30.6666 47.7336 30.1333 47.2002C29.6 46.6669 29.3333 46.1336 29.3333 45.3336C29.3333 45.0669 29.3333 44.5336 29.6 44.2669C29.8666 44.0002 29.8666 43.7336 30.1333 43.4669C30.4 43.2002 30.6666 42.9336 30.9333 42.9336C31.4666 42.6669 32 42.6669 32.5333 42.6669C32.8 42.6669 32.8 42.6669 33.0666 42.9336C33.3333 42.9336 33.3333 42.9336 33.6 43.2002C33.6 43.2002 33.8666 43.4669 34.1333 43.4669C34.4 43.7336 34.6666 44.0002 34.6666 44.2669C34.6666 44.5336 34.9333 45.0669 34.9333 45.3336C34.9333 46.1336 34.6666 46.6669 34.1333 47.2002C33.3333 47.7336 32.8 48.0002 32 48.0002Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAlert);
export default ForwardRef;
