// @ts-nocheck
import { forwardRef } from 'react';
const SvgTriangle = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.5282 2.28309C10.9775 2.03014 11.4844 1.89725 12 1.89725C12.5156 1.89725 13.0225 2.03014 13.4718 2.28309C13.9211 2.53604 14.2976 2.90052 14.565 3.34136L14.5679 3.34611L23.0379 17.4861L23.046 17.4999C23.3079 17.9536 23.4465 18.4679 23.448 18.9917C23.4495 19.5155 23.3138 20.0306 23.0544 20.4857C22.7949 20.9408 22.4209 21.3201 21.9694 21.5857C21.518 21.8514 21.0048 21.9942 20.481 21.9999L20.47 22.0001L3.51901 22C2.99521 21.9942 2.48204 21.8514 2.03057 21.5857C1.5791 21.3201 1.20505 20.9408 0.945651 20.4857C0.686247 20.0306 0.550528 19.5155 0.551995 18.9917C0.553461 18.4679 0.692064 17.9536 0.954011 17.4999L0.962134 17.4861L9.43213 3.34612L10.29 3.85998L9.435 3.34136C9.70241 2.90052 10.0789 2.53604 10.5282 2.28309ZM11.1462 4.37663L2.68269 18.5058C2.59751 18.6556 2.55247 18.8249 2.55199 18.9973C2.5515 19.1719 2.59674 19.3436 2.68321 19.4953C2.76967 19.647 2.89436 19.7734 3.04485 19.862C3.19402 19.9498 3.3634 19.9973 3.5364 20H20.4636C20.6366 19.9973 20.806 19.9498 20.9552 19.862C21.1056 19.7734 21.2303 19.647 21.3168 19.4953C21.4033 19.3436 21.4485 19.1719 21.448 18.9973C21.4475 18.8249 21.4025 18.6557 21.3173 18.5059L12.855 4.37862C12.8546 4.37796 12.8542 4.37729 12.8538 4.37663C12.7647 4.23058 12.6397 4.1098 12.4906 4.02586C12.3408 3.94154 12.1719 3.89725 12 3.89725C11.8281 3.89725 11.6592 3.94154 11.5094 4.02586C11.3603 4.1098 11.2353 4.23058 11.1462 4.37663Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgTriangle);
export default ForwardRef;
