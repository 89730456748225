import {
  CreateWebhookPayload,
  WebHookDto,
  WebHooksDtoItem,
  WebhookSecret,
} from '@use-gateway/types';
import { Api } from '..';

const basePath = '/webhooks';

export async function getWebhooks(this: Api, page = 1, size = 100) {
  return this.get<WebHooksDtoItem>(`${basePath}/?page=${page}&size=${size}`);
}

export async function createWebhook(this: Api, payload: CreateWebhookPayload) {
  return this.post<WebHookDto>(`${basePath}/`, payload);
}

export async function getWebhook(this: Api, id: string) {
  return this.get<WebHookDto>(`${basePath}/${id}/`);
}

export async function updateWebhook(this: Api, payload: CreateWebhookPayload, id: string) {
  return this.put(`${basePath}/${id}/`, payload);
}

export async function deleteWebhook(this: Api, id: string) {
  return this.delete(`${basePath}/${id}/`);
}

export async function rotateWebhookSecret(this: Api, id: string) {
  return this.patch<WebhookSecret>(`${basePath}/${id}/secret`);
}

export async function testWebhook(this: Api, event: string) {
  return this.post<string>(`${basePath}/test`, { event });
}
