// @ts-nocheck
import { forwardRef } from 'react';
const SvgArrowMenuLeft = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.6}
      d="M10 6L4 12M4 12L10 18M4 12H19"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgArrowMenuLeft);
export default ForwardRef;
