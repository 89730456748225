// @ts-nocheck
import { forwardRef } from 'react';
const SvgCrop = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_2128_24082)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.13867 3.83509e-05C6.69093 0.00482466 7.13475 0.456403 7.12996 1.00867L7.00001 16.0036C7.00095 16.2675 7.1062 16.5204 7.29289 16.7071C7.48043 16.8946 7.73479 17 8 17H23C23.5523 17 24 17.4477 24 18C24 18.5523 23.5523 19 23 19H8C7.20435 19 6.44129 18.6839 5.87868 18.1213C5.31607 17.5587 5 16.7957 5 16V15.9913L5.13004 0.991334C5.13482 0.43907 5.5864 -0.0047479 6.13867 3.83509e-05Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M16 5C16.7957 5 17.5587 5.31607 18.1213 5.87868C18.6839 6.44129 19 7.20435 19 8V23C19 23.5523 18.5523 24 18 24C17.4477 24 17 23.5523 17 23V8C17 7.73479 16.8946 7.48043 16.7071 7.29289C16.5204 7.1062 16.2675 7.00095 16.0036 7.00001L1.00867 7.12996C0.456403 7.13475 0.00482466 6.69093 3.83509e-05 6.13867C-0.0047479 5.5864 0.43907 5.13482 0.991334 5.13004L16 5Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_2128_24082">
        <rect width={24} height={24} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgCrop);
export default ForwardRef;
