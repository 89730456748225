import { useMemo } from 'react';
import { CryptoCurrency, NetworkNameMap, TestCoins } from '@use-gateway/types';

export function useSafeNetworkName(cryptoCurrency?: CryptoCurrency | TestCoins) {
  return useMemo(() => {
    const rawNetwork = NetworkNameMap[cryptoCurrency || CryptoCurrency.BTC];

    if (/_trc20$/.test(rawNetwork)) return 'tron';
    if (/_bep20$/.test(rawNetwork)) return 'smartchain';

    return rawNetwork;
  }, [cryptoCurrency]);
}
