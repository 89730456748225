import { styled } from '@use-gateway/theme';

export const SpinerWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.primary};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
`;

export function Spiner() {
  return (
    <SpinerWrapper className={'spiner'}>
      <div />
      <div />
      <div />
      <div />
    </SpinerWrapper>
  );
}

const FullScreenSpinerWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.bg};
`;

export function FullScreenSpiner() {
  return (
    <FullScreenSpinerWrapper>
      <Spiner />
    </FullScreenSpinerWrapper>
  );
}

const LoadingSpinnerWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;

  backdrop-filter: blur(5px);

  display: flex;
  justify-content: center;
  align-items: center;
`;

export function LoadingSpinner() {
  return (
    <LoadingSpinnerWrapper>
      <Spiner />
    </LoadingSpinnerWrapper>
  );
}
