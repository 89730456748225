// @ts-nocheck
import { forwardRef } from 'react';
const SvgTrx = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 64 64"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_3630_36497)">
      <path
        fill="#FF060A"
        d="M63.0356 39.7415C58.7614 56.8846 41.3984 67.3175 24.2535 63.0427C7.11547 58.7685 -3.31754 41.4046 0.958462 24.2626C5.23046 7.11758 22.5934 -3.31641 39.7335 0.957598C56.8775 5.23159 67.3095 22.5975 63.0356 39.7415Z"
      />
      <path
        fill="white"
        d="M50.4832 27.0504C48.609 25.3191 46.0164 22.6754 43.9049 20.8005L43.7799 20.713C43.572 20.5459 43.3376 20.415 43.0865 20.3255C37.995 19.3755 14.2994 14.9443 13.8371 15.0005C13.7076 15.0187 13.5838 15.0657 13.4748 15.138L13.3561 15.2318C13.2099 15.3803 13.0989 15.5597 13.0312 15.7568L13 15.838V16.2818V16.3505C15.6676 23.7817 26.2003 48.1251 28.2744 53.8375C28.3993 54.225 28.6367 54.9625 29.0803 55H29.1802C29.4176 55 30.4297 53.6625 30.4297 53.6625C30.4297 53.6625 48.5215 31.7128 50.352 29.3753C50.5889 29.0874 50.7981 28.7777 50.9767 28.4504C51.0223 28.1942 51.0008 27.9307 50.9143 27.6853C50.8278 27.44 50.6793 27.2212 50.4832 27.0504ZM35.0713 29.6066L42.7929 23.2004L47.3221 27.3754L35.0713 29.6066ZM32.0727 29.1878L18.7787 18.288L40.2877 22.2567L32.0727 29.1878ZM33.2721 32.0441L46.8785 29.8503L31.323 48.6001L33.2721 32.0441ZM16.9732 19.3755L30.9607 31.2503L28.9366 48.6126L16.9732 19.3755Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_3630_36497">
        <rect width={64} height={64} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgTrx);
export default ForwardRef;
