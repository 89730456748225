/* eslint-disable @typescript-eslint/ban-ts-comment */

import { GetServerSidePropsContext, Redirect } from 'next/types';
import type { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import * as Sentry from '@sentry/nextjs';
import { api } from '@use-gateway/api';
import nextI18NextConfig from '@use-gateway/config/next/next-i18next.config';
import { SystemStatus, SystemStatusDto } from '@use-gateway/types';
import { checkPageDependencies } from './check-page-dependencies';
import { localeDetect } from './locale-detect';
import { isItTechnicalWorksPage } from './technical-works';

export type SaturateReturn<T = any> = { props: T } | { notFound: true } | { redirect: Redirect };

export interface StaticPropsFactoryOptions<T = Record<string, any>> {
  serverSideTranslations: typeof serverSideTranslations;
  namespaces?: Array<string>;
  dependsOn?: Array<keyof SystemStatusDto>;
  saturate?: (
    ctx: GetServerSidePropsContext,
    systemStatus: SystemStatus
  ) => Promise<SaturateReturn<T>>;
}
export function staticPropsFactory<T>({
  serverSideTranslations,
  namespaces = [],
  dependsOn = [],
  saturate,
}: StaticPropsFactoryOptions<T>) {
  return async function getStaticProps(ctx: GetServerSidePropsContext) {
    const locale = localeDetect(ctx);
    const safeUrl = ctx.resolvedUrl || '/';

    // Устанавливаем контекст до того, как что-либо может сломаться
    Sentry.setContext('page', {
      locale: locale,
      path: ctx.resolvedUrl,
      query: ctx.query,
    });

    const systemStatus = await api.getSystemStatus().catch(() => null);

    if (!checkPageDependencies(systemStatus, dependsOn) && !isItTechnicalWorksPage(safeUrl)) {
      return {
        redirect: {
          destination: `/technical-works?from=${encodeURIComponent(safeUrl)}`,
          permanent: false,
        },
      };
    }

    const translations = await serverSideTranslations(
      locale,
      ['common', ...namespaces],
      nextI18NextConfig
    );
    const result: any = saturate && (await saturate(ctx, systemStatus));

    if (result?.notFound) {
      return {
        notFound: true,
      };
    }

    if (result?.redirect) {
      return {
        redirect: result.redirect,
      };
    }

    return {
      props: {
        ...(result?.props || {}),
        ...translations,
        systemStatus,
      },
    };
  };
}
