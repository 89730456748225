export enum InvoiceStatus {
  Created = 'Created',
  Viewed = 'Viewed',
  Expired = 'Expired',
  Renewed = 'Renewed',
  Paid = 'Paid',
  Unresolved = 'Unresolved',
  Resolved = 'Resolved',
  Closed = 'Closed',
}
