import { useMemo } from 'react';
import getConfig from 'next/config';
import Link from 'next/link';
import styled from 'styled-components';
import { IconLogo, IconChangePayLogo } from '@use-gateway/icons';
import { ProjectNames } from '@use-gateway/types';

const LogoMiniWrapper = styled.div`
  position: relative;
  width: 36px;
  height: 36px;
  font-size: 36px;
  line-height: 1;

  a {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  svg {
    color: ${({ theme }) => theme.colors.info[100]};
  }
`;

const { publicRuntimeConfig } = getConfig();
const { PROJECT_NAME } = publicRuntimeConfig;

export function LogoMini() {
  const currentLogo = useMemo(() => {
    if (PROJECT_NAME === ProjectNames.ChangePay) return <IconChangePayLogo />;
    return <IconLogo />;
  }, []);

  return (
    <LogoMiniWrapper>
      <Link href="/">
        <a />
      </Link>
      {currentLogo}
    </LogoMiniWrapper>
  );
}
