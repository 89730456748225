// @ts-nocheck
import { forwardRef } from 'react';
const SvgBag = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M19.3425 7.47021H4.65746C3.1902 7.47021 2 8.65979 2 10.1277V18.5627C2 20.0306 3.1902 21.2202 4.65746 21.2202H19.3425C20.8104 21.2202 22 20.0306 22 18.5627V10.1277C22 8.65979 20.8104 7.47021 19.3425 7.47021ZM20.75 18.5627C20.75 19.3388 20.1186 19.9702 19.3425 19.9702H4.65746C3.88141 19.9702 3.25 19.3388 3.25 18.5627V10.1277C3.25 9.35162 3.88141 8.72021 4.65746 8.72021H19.3425C20.1186 8.72021 20.75 9.35162 20.75 10.1277V18.5627Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.2 9.79999C13.7574 9.79999 15.02 11.0625 15.02 12.62C15.02 12.9865 14.9499 13.3371 14.8224 13.6587C16.0196 13.9846 16.9 15.0795 16.9 16.38C16.9 17.9374 15.6374 19.2 14.08 19.2C13.3576 19.2 12.6986 18.928 12.2 18.4819C11.7011 18.9285 11.0423 19.2 10.32 19.2C8.76256 19.2 7.5 17.9374 7.5 16.38C7.5 15.0795 8.38028 13.9847 9.57748 13.6588C9.45004 13.3373 9.38 12.9868 9.38 12.62C9.38 11.0625 10.6426 9.79999 12.2 9.79999ZM14.08 14.5C13.5796 14.5 13.125 14.6951 12.7878 15.0144C13.0122 15.4192 13.14 15.885 13.14 16.38C13.14 16.8754 13.0123 17.341 12.7878 17.7457C13.1251 18.0649 13.5797 18.26 14.08 18.26C15.1182 18.26 15.96 17.4183 15.96 16.38C15.96 15.3417 15.1182 14.5 14.08 14.5ZM10.1086 14.5117C9.16984 14.6168 8.44 15.4132 8.44 16.38C8.44 17.4183 9.2817 18.26 10.32 18.26C11.3583 18.26 12.2 17.4183 12.2 16.38C12.2 16.0322 12.106 15.7073 11.9418 15.4283C11.2165 15.3625 10.5705 15.022 10.1086 14.5117ZM12.2 10.74C11.1617 10.74 10.32 11.5817 10.32 12.62C10.32 13.5868 11.0498 14.3832 11.9886 14.4882C12.45 13.9785 13.0962 13.6376 13.8218 13.5716C13.986 13.2927 14.08 12.9678 14.08 12.62C14.08 11.5817 13.2383 10.74 12.2 10.74Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M10.4199 4.9043C10.8261 4.49902 11.3806 4.25031 11.9995 4.25C12.6184 4.25027 13.1732 4.49898 13.5794 4.9043C13.9847 5.31078 14.2337 5.86531 14.234 6.48453H15.484C15.4837 4.55945 13.924 3.00027 11.9995 3C10.0751 3.00027 8.51594 4.55945 8.51562 6.48449H9.76563C9.76594 5.86496 10.0146 5.31047 10.4199 4.9043Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgBag);
export default ForwardRef;
