// @ts-nocheck
import { forwardRef } from 'react';
const SvgGitlab = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_2128_24193)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.55663 2.48164C4.55542 2.47792 4.55425 2.47419 4.55311 2.47045L4.55663 2.48164ZM5.10748 4.17284L6.99918 9.97974C7.13328 10.3914 7.51706 10.67 7.95 10.67H16.05C16.4832 10.67 16.8672 10.391 17.0011 9.97899L18.8868 4.17508L20.7789 9.99899L22.0032 13.6238L12 20.8938L2.03558 13.652L5.10748 4.17284ZM22.1473 13.6983L22.0667 13.5873C22.0777 13.573 22.0912 13.5605 22.1066 13.5505L22.1935 13.6849L22.1473 13.6983ZM22.1705 13.7302L22.65 14.39L22.2134 13.7157L22.1705 13.7302ZM23.2203 15.2117L12.5879 22.9389C12.2374 23.1937 11.7626 23.1937 11.4121 22.9389L0.762099 15.1989L0.757537 15.1956C0.444791 14.9656 0.212807 14.6426 0.0947747 14.2727C-0.0228542 13.9042 -0.0213034 13.5079 0.0991491 13.1403L3.75894 1.85099C3.76459 1.83359 3.77072 1.81635 3.77732 1.79928C3.85704 1.59316 3.98377 1.40849 4.1474 1.25998C4.41068 1.02033 4.75392 0.887482 5.11 0.887482C5.4669 0.887482 5.8109 1.02094 6.07442 1.26164C6.08136 1.26799 6.08822 1.27443 6.09498 1.28097C6.26556 1.44584 6.39206 1.65078 6.46302 1.87701L8.67596 8.66999H15.3234L17.5389 1.85099C17.5446 1.83359 17.5507 1.81635 17.5573 1.79928C17.637 1.59315 17.7638 1.40849 17.9274 1.25998C18.1907 1.02033 18.5339 0.887482 18.89 0.887482C19.2469 0.887482 19.5909 1.02094 19.8544 1.26164C19.8614 1.26799 19.8682 1.27443 19.875 1.28097C20.0457 1.44596 20.1722 1.65107 20.2432 1.87749L22.6792 9.37538L23.9474 13.13C23.9494 13.1359 23.9514 13.1419 23.9533 13.1478C23.9556 13.1553 23.9579 13.1628 23.9601 13.1704C24.0708 13.5504 24.056 13.956 23.9179 14.3269C23.7835 14.6878 23.5394 14.9971 23.2203 15.2117ZM17.9274 1.25998C17.928 1.25943 17.9286 1.25888 17.9292 1.25833L18.6 1.99999L17.9256 1.26164C17.9262 1.26109 17.9268 1.26054 17.9274 1.25998ZM19.2708 2.74165L19.2744 2.73834C19.2732 2.73945 19.272 2.74055 19.2708 2.74165Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_2128_24193">
        <rect width={24} height={24} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgGitlab);
export default ForwardRef;
