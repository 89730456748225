import { Paginated, DepositDto, CreateDepositPayload } from '@use-gateway/types';
import { Api } from '..';

const basePath = '/deposits';

export async function getDeposits(this: Api, page = 1, size = 25) {
  return this.get<Paginated<DepositDto>>(`${basePath}/?page=${page}&size=${size}`);
}

export async function getDeposit(this: Api, id: string) {
  return this.get<DepositDto>(`${basePath}/${id}/`);
}

export async function createDeposit(this: Api, payload: CreateDepositPayload) {
  return this.post<DepositDto>(`${basePath}/`, payload);
}

export async function resolveDeposit(this: Api, id: string) {
  return this.patch(`${basePath}/${id}/resolve`);
}
