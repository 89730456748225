// @ts-nocheck
import { forwardRef } from 'react';
const SvgCodepen = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.455 1.16156C11.7864 0.946148 12.2136 0.946148 12.545 1.16156L22.545 7.66156C22.8288 7.84602 23 8.16152 23 8.5V15.5C23 15.8385 22.8288 16.154 22.545 16.3384L12.545 22.8384C12.2136 23.0539 11.7864 23.0539 11.455 22.8384L1.45501 16.3384C1.17121 16.154 0.999994 15.8385 0.999994 15.5V8.5C0.999994 8.16152 1.17121 7.84602 1.45501 7.66156L11.455 1.16156ZM2.99999 9.04269V14.9573L12 20.8073L21 14.9573V9.04269L12 3.19269L2.99999 9.04269Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 14.5C12.5523 14.5 13 14.9477 13 15.5V22C13 22.5523 12.5523 23 12 23C11.4477 23 11 22.5523 11 22V15.5C11 14.9477 11.4477 14.5 12 14.5Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.18076 7.92654C1.49748 7.47409 2.12101 7.36405 2.57346 7.68077L12 14.2793L21.4265 7.68077C21.879 7.36405 22.5025 7.47409 22.8192 7.92654C23.1359 8.37899 23.0259 9.00252 22.5735 9.31923L12.5735 16.3192C12.2291 16.5603 11.7709 16.5603 11.4265 16.3192L1.42653 9.31923C0.974083 9.00252 0.864048 8.37899 1.18076 7.92654Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.4265 7.68077C11.7709 7.43974 12.2291 7.43974 12.5735 7.68077L22.5735 14.6808C23.0259 14.9975 23.1359 15.621 22.8192 16.0735C22.5025 16.5259 21.879 16.6359 21.4265 16.3192L12 9.72066L2.57346 16.3192C2.12101 16.6359 1.49748 16.5259 1.18076 16.0735C0.864048 15.621 0.974083 14.9975 1.42653 14.6808L11.4265 7.68077Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 1C12.5523 1 13 1.44772 13 2V8.5C13 9.05228 12.5523 9.5 12 9.5C11.4477 9.5 11 9.05228 11 8.5V2C11 1.44772 11.4477 1 12 1Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCodepen);
export default ForwardRef;
