// @ts-nocheck
import { forwardRef } from 'react';
const SvgTag = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1 2C1 1.44772 1.44772 1 2 1H12C12.2652 1 12.5196 1.10536 12.7071 1.29289L21.2992 9.885C21.858 10.4471 22.1716 11.2074 22.1716 12C22.1716 12.7926 21.858 13.5529 21.2992 14.115L20.59 13.41L21.2971 14.1171L14.1275 21.2867C14.1274 21.2868 14.1276 21.2866 14.1275 21.2867C13.849 21.5655 13.5178 21.787 13.1538 21.9379C12.7896 22.0889 12.3992 22.1666 12.005 22.1666C11.6108 22.1666 11.2204 22.0889 10.8562 21.9379C10.4921 21.787 10.1613 21.5658 9.88271 21.2869C9.88264 21.2869 9.88278 21.287 9.88271 21.2869L1.29331 12.7075C1.10552 12.5199 1 12.2654 1 12V2ZM19.8808 12.705C19.8811 12.7047 19.8814 12.7044 19.8817 12.7041C20.0674 12.5168 20.1716 12.2638 20.1716 12C20.1716 11.7362 20.0674 11.4832 19.8817 11.2959C19.8814 11.2956 19.8811 11.2953 19.8808 11.295L11.5858 3H3V11.5854L11.2967 19.8725L11.2975 19.8733C11.3904 19.9663 11.5007 20.04 11.6221 20.0903C11.7435 20.1407 11.8736 20.1666 12.005 20.1666C12.1364 20.1666 12.2665 20.1407 12.3879 20.0903C12.5093 20.04 12.6196 19.9663 12.7125 19.8733L19.8808 12.705Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgTag);
export default ForwardRef;
