// @ts-nocheck
import { forwardRef } from 'react';
const SvgSlack = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_2128_24522)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17.9919 0.882836C20.4044 2.18725 21.8873 4.86609 23.0378 8.71239C24.1896 12.5386 24.4093 15.5809 23.0973 17.9939C21.787 20.4037 19.1108 21.8867 15.2883 23.0375C11.4617 24.1896 8.41922 24.4094 6.00607 23.0973C3.59628 21.787 2.11328 19.1108 0.962461 15.2883C-0.189567 11.4617 -0.409378 8.41921 0.902726 6.00606C2.21301 3.59626 4.88919 2.11326 8.71173 0.962447C12.5346 -0.188484 15.5777 -0.422434 17.9919 0.882836ZM17.0406 2.64215C15.3673 1.73742 13.0054 1.75847 9.28829 2.87754C5.57083 3.99672 3.57201 5.28372 2.65979 6.96143C1.74939 8.63578 1.75958 10.9983 2.87755 14.7117C3.99674 18.4292 5.28374 20.428 6.96144 21.3402C8.63579 22.2506 10.9983 22.2404 14.7117 21.1224C18.4292 20.0033 20.428 18.7163 21.3402 17.0386C22.2506 15.3642 22.2404 13.0017 21.1225 9.28827L21.1219 9.28653C20.0025 5.54341 18.7153 3.54761 17.0406 2.64215Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.2445 5.04446C12.7667 4.8647 13.3358 5.14231 13.5155 5.66452L17.0955 16.0645C17.2753 16.5867 16.9977 17.1558 16.4755 17.3356C15.9533 17.5153 15.3842 17.2377 15.2045 16.7155L11.6245 6.31549C11.4447 5.79328 11.7223 5.22422 12.2445 5.04446Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.52452 6.66445C8.04673 6.48469 8.61579 6.7623 8.79555 7.28451L12.3756 17.6845C12.5553 18.2067 12.2777 18.7758 11.7555 18.9555C11.2333 19.1353 10.6642 18.8577 10.4845 18.3355L6.90446 7.93548C6.7247 7.41327 7.00231 6.84421 7.52452 6.66445Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17.3356 7.52452C17.5153 8.04673 17.2377 8.61579 16.7155 8.79555L6.31549 12.3756C5.79328 12.5553 5.22422 12.2777 5.04446 11.7555C4.8647 11.2333 5.14231 10.6642 5.66452 10.4845L16.0645 6.90446C16.5867 6.7247 17.1558 7.00231 17.3356 7.52452Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.9555 12.2445C19.1353 12.7667 18.8577 13.3358 18.3355 13.5156L7.93548 17.0956C7.41327 17.2753 6.84421 16.9977 6.66445 16.4755C6.48469 15.9533 6.7623 15.3842 7.28451 15.2045L17.6845 11.6245C18.2067 11.4447 18.7758 11.7223 18.9555 12.2445Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_2128_24522">
        <rect width={24} height={24} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgSlack);
export default ForwardRef;
