// @ts-nocheck
import { forwardRef } from 'react';
const SvgCornerUpRight = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.2929 3.29289C14.6834 2.90237 15.3166 2.90237 15.7071 3.29289L20.7071 8.29289C21.0976 8.68342 21.0976 9.31658 20.7071 9.70711L15.7071 14.7071C15.3166 15.0976 14.6834 15.0976 14.2929 14.7071C13.9024 14.3166 13.9024 13.6834 14.2929 13.2929L18.5858 9L14.2929 4.70711C13.9024 4.31658 13.9024 3.68342 14.2929 3.29289Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 10C7.20435 10 6.44129 10.3161 5.87868 10.8787C5.31607 11.4413 5 12.2044 5 13V20C5 20.5523 4.55228 21 4 21C3.44772 21 3 20.5523 3 20V13C3 11.6739 3.52678 10.4021 4.46447 9.46447C5.40215 8.52678 6.67392 8 8 8H20C20.5523 8 21 8.44772 21 9C21 9.55228 20.5523 10 20 10H8Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCornerUpRight);
export default ForwardRef;
