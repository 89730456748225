// @ts-nocheck
import { forwardRef } from 'react';
const SvgLayers = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.5528 1.10557C11.8343 0.964809 12.1657 0.964809 12.4472 1.10557L22.4472 6.10557C22.786 6.27496 23 6.62123 23 7C23 7.37877 22.786 7.72504 22.4472 7.89443L12.4472 12.8944C12.1657 13.0352 11.8343 13.0352 11.5528 12.8944L1.55279 7.89443C1.21401 7.72504 1.00001 7.37877 1.00001 7C1.00001 6.62123 1.21401 6.27496 1.55279 6.10557L11.5528 1.10557ZM4.23607 7L12 10.882L19.7639 7L12 3.11803L4.23607 7Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.10558 16.5528C1.35257 16.0588 1.95324 15.8586 2.44722 16.1056L12 20.882L21.5528 16.1056C22.0468 15.8586 22.6474 16.0588 22.8944 16.5528C23.1414 17.0468 22.9412 17.6474 22.4472 17.8944L12.4472 22.8944C12.1657 23.0352 11.8343 23.0352 11.5528 22.8944L1.55279 17.8944C1.05881 17.6474 0.858589 17.0468 1.10558 16.5528Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.10558 11.5528C1.35257 11.0588 1.95324 10.8586 2.44722 11.1056L12 15.882L21.5528 11.1056C22.0468 10.8586 22.6474 11.0588 22.8944 11.5528C23.1414 12.0468 22.9412 12.6474 22.4472 12.8944L12.4472 17.8944C12.1657 18.0352 11.8343 18.0352 11.5528 17.8944L1.55279 12.8944C1.05881 12.6474 0.858589 12.0468 1.10558 11.5528Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLayers);
export default ForwardRef;
