// @ts-nocheck
import { forwardRef } from 'react';
const SvgWithdrawal2 = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="currentColor"
      strokeLinecap="round"
      d="M16.0581 6.9H19.5C20.6046 6.9 21.5 7.79543 21.5 8.9V16.6C21.5 17.7046 20.6046 18.6 19.5 18.6H5.5C4.39543 18.6 3.5 17.7046 3.5 16.6V8.9C3.5 7.79543 4.39543 6.9 5.5 6.9H8.94186"
    />
    <line
      x1={6.29492}
      x2={18.2549}
      y1={16.3}
      y2={16.3}
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      fill="currentColor"
      d="M13 7C13 6.72386 12.7761 6.5 12.5 6.5C12.2239 6.5 12 6.72386 12 7L13 7ZM12.1464 13.3536C12.3417 13.5488 12.6583 13.5488 12.8536 13.3536L16.0355 10.1716C16.2308 9.97631 16.2308 9.65973 16.0355 9.46447C15.8403 9.2692 15.5237 9.2692 15.3284 9.46447L12.5 12.2929L9.67157 9.46447C9.47631 9.2692 9.15973 9.2692 8.96447 9.46447C8.7692 9.65973 8.7692 9.97631 8.96447 10.1716L12.1464 13.3536ZM12 7L12 13L13 13L13 7L12 7Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgWithdrawal2);
export default ForwardRef;
