// @ts-nocheck
import { forwardRef } from 'react';
const SvgUsdtTrc20 = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 64 64"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="#50AF95"
      d="M31.9999 64C49.6732 64 63.9999 49.6731 63.9999 32C63.9999 14.3269 49.6732 0 31.9999 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 31.9999 64Z"
    />
    <path
      fill="white"
      d="M35.4106 34.9532V34.948C35.1895 34.9635 34.0472 35.0303 31.5057 35.0303C29.4736 35.0303 28.0485 34.9738 27.5443 34.948V34.9532C19.7296 34.6085 13.9007 33.2503 13.9007 31.6195C13.9007 29.9937 19.7347 28.6303 27.5443 28.2856V33.6001C28.0536 33.6362 29.52 33.7237 31.5418 33.7237C33.9701 33.7237 35.1843 33.6207 35.4106 33.6001V28.2908C43.2099 28.6407 49.0234 29.9989 49.0234 31.6247C49.0234 33.2503 43.2048 34.6085 35.4106 34.9583V34.9532ZM35.4106 27.7404V22.9866H46.2917V15.7378H16.6686V22.9866H27.5495V27.7404C18.7057 28.1468 12.0588 29.8959 12.0588 31.995C12.0588 34.094 18.7109 35.8433 27.5495 36.2549V51.4935H35.4158V36.2549C44.244 35.8485 50.8807 34.0991 50.8807 32.0001C50.8807 29.9063 44.244 28.1519 35.4158 27.7455"
    />
    <g clipPath="url(#clip0_3630_36493)">
      <path
        fill="#FF060A"
        d="M63.7288 57.1773C62.5267 61.9988 57.6433 64.933 52.8213 63.7307C48.0012 62.5286 45.0669 57.6451 46.2696 52.8239C47.4711 48.0018 52.3544 45.0673 57.1751 46.2693C61.9968 47.4714 64.9308 52.3556 63.7288 57.1773Z"
      />
      <path
        fill="white"
        d="M60.1984 53.6079C59.6713 53.121 58.9421 52.3775 58.3482 51.8501L58.3131 51.8255C58.2546 51.7785 58.1887 51.7417 58.1181 51.7165V51.7165C56.6861 51.4494 50.0217 50.2031 49.8917 50.2189C49.8553 50.224 49.8204 50.2372 49.7898 50.2576L49.7564 50.2839C49.7153 50.3257 49.6841 50.3762 49.665 50.4316L49.6562 50.4544V50.5792V50.5986C50.4065 52.6886 53.3688 59.5352 53.9522 61.1418C53.9873 61.2508 54.0541 61.4582 54.1788 61.4688H54.2069C54.2737 61.4688 54.5583 61.0926 54.5583 61.0926C54.5583 61.0926 59.6467 54.9192 60.1615 54.2618C60.2281 54.1808 60.287 54.0937 60.3372 54.0017C60.35 53.9296 60.344 53.8555 60.3196 53.7865C60.2953 53.7175 60.2535 53.656 60.1984 53.6079V53.6079ZM55.8638 54.3269L58.0355 52.5251L59.3093 53.6993L55.8638 54.3269ZM55.0204 54.2091L51.2815 51.1435L57.3309 52.2597L55.0204 54.2091ZM55.3578 55.0124L59.1846 54.3954L54.8096 59.6688L55.3578 55.0124ZM50.7737 51.4494L54.7077 54.7892L54.1384 59.6723L50.7737 51.4494Z"
      />
    </g>
    <rect width={17} height={17} x={46.5} y={46.5} stroke="white" rx={8.5} />
    <defs>
      <clipPath id="clip0_3630_36493">
        <rect width={18} height={18} x={46} y={46} fill="white" rx={9} />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgUsdtTrc20);
export default ForwardRef;
