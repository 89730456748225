export enum WithdrawalsEvents {
  openWithdrawal = 'openWithdrawal',
  withdrawalWasCreated = 'withdrawalWasCreated',
  withdrawalRequestApproved = 'withdrawalRequestApproved',
  withdrawalRequestDeclined = 'withdrawalRequestDeclined',
  openPayWithdrawalRequestLocal = 'openPayWithdrawalRequestLocal',
  openPayWithdrawalRequestCrypto = 'openPayWithdrawalRequestCrypto',
  payWithdrawalRequest = 'payWithdrawalRequest',
  openRequestCreate = 'openRequestCreate',
  openRequestApprove = 'openRequestApprove',
  openRequestDecline = 'openRequestDecline',
  requestWasCreate = 'requestWasCreate',
  openRequestMetaData = 'openRequestMetaData',
}
