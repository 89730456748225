// @ts-nocheck
import { forwardRef } from 'react';
const SvgDownloadCloud = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_2128_24106)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.29289 16.2929C7.68342 15.9024 8.31658 15.9024 8.70711 16.2929L12 19.5858L15.2929 16.2929C15.6834 15.9024 16.3166 15.9024 16.7071 16.2929C17.0976 16.6834 17.0976 17.3166 16.7071 17.7071L12.7071 21.7071C12.3166 22.0976 11.6834 22.0976 11.2929 21.7071L7.29289 17.7071C6.90237 17.3166 6.90237 16.6834 7.29289 16.2929Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 11C12.5523 11 13 11.4477 13 12V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V12C11 11.4477 11.4477 11 12 11Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.66758 1.99416C10.0281 1.94523 11.3819 2.20558 12.6272 2.7556C13.8725 3.30562 14.9768 4.13098 15.857 5.16954C16.5675 6.0079 17.117 6.96755 17.4804 7.99999L17.9992 7.99999C19.2743 7.99916 20.5174 8.40459 21.5465 9.15747C22.5759 9.9105 23.3385 10.972 23.7237 12.1878C24.1089 13.4036 24.0967 14.7106 23.6888 15.919C23.2808 17.1274 22.4985 18.1744 21.4552 18.908C21.0034 19.2257 20.3797 19.117 20.062 18.6652C19.7443 18.2134 19.853 17.5897 20.3048 17.272C21.0003 16.7829 21.5219 16.0849 21.7938 15.2793C22.0657 14.4737 22.0739 13.6024 21.8171 12.7919C21.5603 11.9813 21.0519 11.2737 20.3656 10.7716C19.6794 10.2696 18.851 9.99933 18.0008 9.99999H16.74C16.2834 9.99999 15.8848 9.69077 15.7714 9.24854C15.5082 8.22293 15.0158 7.27038 14.3312 6.46261C13.6467 5.65484 12.7878 5.01289 11.8192 4.5851C10.8506 4.1573 9.79761 3.95481 8.73946 3.99286C7.68131 4.03092 6.64557 4.30852 5.71023 4.80479C4.77489 5.30105 3.96432 6.00303 3.33954 6.85789C2.71476 7.71275 2.29204 8.6982 2.10323 9.74007C1.91441 10.7819 1.96441 11.8531 2.24946 12.8728C2.5345 13.8926 3.04718 14.8343 3.74887 15.6273C4.11488 16.0409 4.07629 16.6729 3.6627 17.0389C3.24911 17.4049 2.61712 17.3663 2.25111 16.9527C1.34893 15.9332 0.689783 14.7223 0.323292 13.4112C-0.0431988 12.1001 -0.107481 10.723 0.135283 9.38342C0.378048 8.04388 0.921534 6.77686 1.72483 5.67776C2.52812 4.57865 3.57028 3.67611 4.77286 3.03806C5.97544 2.40001 7.3071 2.04308 8.66758 1.99416Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_2128_24106">
        <rect width={24} height={24} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgDownloadCloud);
export default ForwardRef;
