import copy from 'copy-to-clipboard';
import { toast } from 'react-toast';
import { IconCopy } from '@use-gateway/icons';
import { styled } from '@use-gateway/theme';

const CopyWrapper = styled.span`
  margin-left: 0.5em;
  cursor: pointer;
`;

export interface CopyProps {
  value: string;
}
export function Copy({ value }: CopyProps) {
  const copyValue = () => {
    copy(value);
    toast.info('Text has been copied!');
  };

  return (
    <CopyWrapper onClick={copyValue}>
      <IconCopy />
    </CopyWrapper>
  );
}
