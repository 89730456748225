import { CryptoCurrency, TestCoins } from '@use-gateway/types';
import { Api } from '..';

const basePath = '/constants';

export async function getMinDeposits(this: Api) {
  return this.get<object>(`${basePath}/min_deposits`);
}

export async function getWebhookEvents(this: Api) {
  return this.get<object>(`${basePath}/webhook_events`);
}

export async function getAvailableCryptocurrencies(this: Api) {
  return this.get<Array<CryptoCurrency | TestCoins>>(`${basePath}/cryptocurrencies`);
}
