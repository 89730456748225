import {
  IconColorBnb,
  IconColorBtc,
  IconColorBusdBep20,
  IconColorDaiBep20,
  IconColorDash,
  IconColorDoge,
  IconColorEth,
  IconColorLtc,
  IconColorTrx,
  IconColorUnknownCurrency,
  IconColorUsdcBep20,
  IconColorUsdtBep20,
  IconColorUsdcErc20,
  IconColorUsdtTrc20,
  IconColorXrpBep20,
  IconColorUsdtErc20,
  IconColorUsdcTrc20,
  IconColorOpbnb,
  IconColorTbccBep20,
  IconColorBch,
  IconColorMatic,
  IconColorOpeth,
} from '@use-gateway/icons';
import { Currency, CryptoCurrency } from '@use-gateway/types';

export interface CurrencyIconProps {
  currency: Currency;
}

export function CurrencyIcon({ currency }: CurrencyIconProps) {
  if (currency === CryptoCurrency.BCH) return <IconColorBch />;
  if (currency === CryptoCurrency.BTC) return <IconColorBtc />;
  if (currency === CryptoCurrency.ETH) return <IconColorEth />;
  if (currency === CryptoCurrency.LTC) return <IconColorLtc />;
  if (currency === CryptoCurrency.MATIC) return <IconColorMatic />;
  if (currency === CryptoCurrency.TRX) return <IconColorTrx />;
  if (currency === CryptoCurrency.BNB) return <IconColorBnb />;
  if (currency === CryptoCurrency.OPBNB) return <IconColorOpbnb />;
  if (currency === CryptoCurrency.DASH) return <IconColorDash />;
  if (currency === CryptoCurrency.OPETH) return <IconColorOpeth />;
  if (currency === CryptoCurrency.DOGE) return <IconColorDoge />;
  if (currency === CryptoCurrency.USDT_TRC20) return <IconColorUsdtTrc20 />;
  if (currency === CryptoCurrency.USDC_TRC20) return <IconColorUsdcTrc20 />;
  if (currency === CryptoCurrency.DAI_BEP20) return <IconColorDaiBep20 />;
  if (currency === CryptoCurrency.USDT_BEP20) return <IconColorUsdtBep20 />;
  if (currency === CryptoCurrency.USDC_BEP20) return <IconColorUsdcBep20 />;
  if (currency === CryptoCurrency.BUSD_BEP20) return <IconColorBusdBep20 />;
  if (currency === CryptoCurrency.XRP_BEP20) return <IconColorXrpBep20 />;
  if (currency === CryptoCurrency.TBCC_BEP20) return <IconColorTbccBep20 />;
  if (currency === CryptoCurrency.USDT_ERC20) return <IconColorUsdtErc20 />;
  if (currency === CryptoCurrency.USDC_ERC20) return <IconColorUsdcErc20 />;

  return <IconColorUnknownCurrency />;
}
