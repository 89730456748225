import { down } from 'styled-breakpoints';
import { IconAlertCircle, IconClock, IconExternalLink } from '@use-gateway/icons';
import { Breakpoints, styled, useBreakpoints } from '@use-gateway/theme';
import { WithdrawalResponseDpo } from '@use-gateway/types';
import { precisionByCurrency } from '@use-gateway/utils';
import { DateTime } from '../date-time';
import { DoublePrice } from '../double-price';
import { Status } from '../status';
import { TableCard, TableCell, TableRow } from '../table';

const WithdrawalsInProgress = styled.div`
  color: ${({ theme }) => theme.colors.warning[100]};
`;

const WithdrawalsError = styled.div`
  color: ${({ theme }) => theme.colors.danger[100]};
`;

const DoubleColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 16px;

  ${down('sm')} {
    margin-top: 8px;
  }
`;

const IconsMob = styled.div`
  min-width: 20px;
  font-size: 20px;
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colors.info[100]};
`;

interface WithdrawalsRowProps {
  withdrawal: WithdrawalResponseDpo;
}

export function WithdrawalsRow({ withdrawal }: WithdrawalsRowProps) {
  const { isUp, mapBreakpoints } = useBreakpoints();
  const columns = mapBreakpoints({ xs: 8, sm: 8 });

  const status = (() => {
    if (withdrawal.status === 'Completed') return 'success';
    if (['In progress', 'Unconfirmed'].includes(withdrawal.status)) return 'warning';
    return 'error';
  })();

  const precision = precisionByCurrency(withdrawal.cryptocurrency_code);

  if (isUp(Breakpoints.sm))
    return (
      <TableRow key={withdrawal.id} columns={columns}>
        <TableCell span={1}>
          <DateTime date={withdrawal.created_at} />
        </TableCell>
        <TableCell span={3} style={{ justifyContent: 'center' }}>
          <Status status={status}>{withdrawal.status}</Status>
        </TableCell>
        <TableCell span={3} style={{ justifyContent: 'flex-end' }}>
          <DoublePrice
            primary={{
              amount: withdrawal.value_crypto,
              currency: withdrawal.cryptocurrency_code,
            }}
            secondary={{
              amount: withdrawal.value_fiat,
              currency: withdrawal.currency_code,
            }}
            size={'xs'}
            maxPrecision={precision}
          />
        </TableCell>
        <TableCell span={1} style={{ justifyContent: 'center', fontSize: '20px' }}>
          {withdrawal.status === 'In progress' ? (
            <WithdrawalsInProgress>
              <IconClock />
            </WithdrawalsInProgress>
          ) : withdrawal.transaction_link ? (
            <Link href={withdrawal.transaction_link} target="_blank" rel="noopener noreferrer">
              <IconExternalLink />
            </Link>
          ) : (
            <WithdrawalsError>
              <IconAlertCircle />
            </WithdrawalsError>
          )}
        </TableCell>
      </TableRow>
    );

  return (
    <TableCard gutter={'sm'}>
      <TableRow key={withdrawal.id} columns={24}>
        <TableCell span={6} style={{ padding: 0 }}>
          <DateTime date={withdrawal.created_at} />
        </TableCell>

        <TableCell
          span={isUp(Breakpoints.xs) ? 9 : 18}
          style={{ justifyContent: 'flex-end', padding: isUp(Breakpoints.xs) ? '0 16px' : 0 }}>
          <DoublePrice
            primary={{
              amount: withdrawal.value_crypto,
              currency: withdrawal.cryptocurrency_code,
            }}
            secondary={{
              amount: withdrawal.value_fiat,
              currency: withdrawal.currency_code,
            }}
            size={'xs'}
            maxPrecision={precision}
          />
        </TableCell>

        <TableCell
          span={isUp(Breakpoints.xs) ? 9 : 24}
          style={{ justifyContent: 'center', padding: 0 }}>
          <DoubleColumn>
            <Status status={status}>{withdrawal.status}</Status>

            <IconsMob>
              {withdrawal.status === 'In progress' ? (
                <WithdrawalsInProgress>
                  <IconClock />
                </WithdrawalsInProgress>
              ) : withdrawal.transaction_link ? (
                <a href={withdrawal.transaction_link} target="_blank" rel="noopener noreferrer">
                  <IconExternalLink />
                </a>
              ) : (
                <WithdrawalsError>
                  <IconAlertCircle />
                </WithdrawalsError>
              )}
            </IconsMob>
          </DoubleColumn>
        </TableCell>
      </TableRow>
    </TableCard>
  );
}
