export enum Country {
  any = 'any',
  AZ = 'AZ',
  RU = 'RU',
  GB = 'GB',
  US = 'US',
  TR = 'TR',
  KZ = 'KZ',
  UA = 'UA',
}

// https://en.wikipedia.org/wiki/IETF_language_tag
export const CountryByLanguage: Record<string, Country> = {
  'en-US': Country.US,
  'en-GB': Country.GB,
  ru: Country.RU,
  az: Country.AZ,
  kk: Country.KZ,
  tr: Country.TR,
  uk: Country.UA,
};

export enum ExchangeMethod {
  any = 'any',
  cash = 'cash',
  card = 'card',
  mir = 'mir',
  qiwi = 'qiwi',
  ymon = 'ymon',
  wire = 'wire',
  term = 'term',
  crypto = 'crypto',
}

export interface Exchanger {
  id: string;
  name: string;
  countries: Array<Country>;
  methods: Array<ExchangeMethod>;
  description: string;
  contact_tg?: string;
  contact_vk?: string;
  contact_wa?: string;
  contact_viber?: string;
  contact_email?: string;
  active_time?: string;
}
