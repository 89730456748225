import { useMemo } from 'react';
import { createEffect, createStore, forward } from 'effector';
import { useStore } from 'effector-react';
import { random } from 'lodash-es';
import { api } from '@use-gateway/api';
import { SystemStatus } from '@use-gateway/types';
import { getShortSystemStatus } from './get-short-system-status';
import { isServer } from './is-server';

export const fetchSystemStatusFx = createEffect(() => api.getSystemStatus());
const timerFx = createEffect(() => {
  setTimeout(() => fetchSystemStatusFx(), random(5000, 10000));
});
const $status = createStore<SystemStatus>(null)
  .on(fetchSystemStatusFx.doneData, (_, status) => status)
  .on(fetchSystemStatusFx.failData, () => null);

forward({
  from: fetchSystemStatusFx.finally,
  to: timerFx,
});

if (!isServer()) fetchSystemStatusFx();

export function useSystemStatus() {
  const status = useStore($status);
  const shortStatus = useMemo(() => getShortSystemStatus(status), [status]);
  const statusLevel = useMemo(() => {
    if (shortStatus === 'offline') return 'error';
    if (shortStatus === 'warning') return 'warning';
    return 'success';
  }, [shortStatus]);

  return { status, shortStatus, statusLevel };
}
