import { isServer } from './is-server';

const PREFIX = 'use-gateway-';

class LocalStore {
  _isClient = !isServer();
  _cache: Record<string, string> = {};

  constructor() {
    this._getKey = this._getKey.bind(this);
    this.set = this.set.bind(this);
    this.get = this.get.bind(this);
  }

  _getKey(key: string) {
    return PREFIX + key;
  }

  set(key: string, value: string) {
    this._cache[this._getKey(key)] = value;
    if (this._isClient) localStorage.setItem(this._getKey(key), value);
  }

  get(key: string) {
    if (this._cache[this._getKey(key)]) return this._cache[this._getKey(key)];
    if (this._isClient) return localStorage.getItem(this._getKey(key));
    return undefined;
  }
}

export const localStore = new LocalStore();
