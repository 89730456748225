export { default as IconColorAlert } from './alert'
export { default as IconColorBch } from './bch'
export { default as IconColorBnb } from './bnb'
export { default as IconColorBtc } from './btc'
export { default as IconColorBusdBep20 } from './busd-bep20'
export { default as IconColorChange } from './change'
export { default as IconColorCompleted } from './completed'
export { default as IconColorDaiBep20 } from './dai-bep20'
export { default as IconColorDash } from './dash'
export { default as IconColorDoge } from './doge'
export { default as IconColorEth } from './eth'
export { default as IconColorLtc } from './ltc'
export { default as IconColorMatic } from './matic'
export { default as IconColorOpbnb } from './opbnb'
export { default as IconColorOpeth } from './opeth'
export { default as IconColorTbccBep20 } from './tbcc-bep20'
export { default as IconColorTbtc } from './tbtc'
export { default as IconColorTrx } from './trx'
export { default as IconColorUnknownCurrency } from './unknown-currency'
export { default as IconColorUsdcBep20 } from './usdc-bep20'
export { default as IconColorUsdcErc20 } from './usdc-erc20'
export { default as IconColorUsdcTrc20 } from './usdc-trc20'
export { default as IconColorUsdtBep20 } from './usdt-bep20'
export { default as IconColorUsdtErc20 } from './usdt-erc20'
export { default as IconColorUsdtTrc20 } from './usdt-trc20'
export { default as IconColorUsdt } from './usdt'
export { default as IconColorXrpBep20 } from './xrp-bep20'
