import * as yup from 'yup';
import YupPassword from 'yup-password';

YupPassword(yup);

export const passwordValidation = yup
  .string()
  .password()
  .min(8)
  .max(32)
  .test('only-latin', 'Non-Latin characters are not allowed', (value: string) => {
    // Разрешаем только ACSII символы
    // eslint-disable-next-line no-control-regex
    return !value.match(/[^\x00-\x7F]/i);
  })
  .test('spaces', 'Spaces in the password are not allowed', (value: string) => {
    return !value.match(/\s/);
  })
  .minUppercase(1)
  .minLowercase(1)
  .minNumbers(1)
  .minSymbols(0)
  .required();

export default yup;
