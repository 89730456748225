import { useEffect, useState } from 'react';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { IconArrowUp } from '@use-gateway/icons';

interface ScrollUpWrapper {
  show: boolean;
}

const ScrollUpWrapper = styled.button<ScrollUpWrapper>`
  position: fixed;
  right: 16px;
  bottom: 96px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 1px solid ${({ theme }) => theme.colors.light[80]};
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.light[100]};
  font-size: 16px;
  opacity: ${({ show }) => (show ? '1' : '0')};
  transition: 0.3s;
  pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
  cursor: pointer;

  &:hover {
    box-shadow: 0 1px 6px ${({ theme }) => theme.colors.light[70]};
  }

  &:active {
    transform: scale(0.9);
  }

  ${down('sm')} {
    right: 16px;
    bottom: 96px;
  }

  svg {
    min-width: 16px;
    color: ${({ theme }) => theme.colors.dark[100]};
  }
`;

export function ScrollUp() {
  const [show, setShow] = useState(false);

  const goUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setShow(true);
      } else {
        setShow(false);
      }
    });
  }, []);

  return (
    <ScrollUpWrapper show={show} onClick={goUp}>
      <IconArrowUp />
    </ScrollUpWrapper>
  );
}
