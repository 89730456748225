import React from 'react';
import styled from 'styled-components';
import { styledTransition } from '@use-gateway/utils';

const RadioWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;

  input {
    position: absolute;
    opacity: 0;
  }
`;

interface RadioLabelProps {
  checked: boolean;
}

const RadioLabel = styled.label<RadioLabelProps>`
  position: relative;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    box-sizing: border-box;
    display: block;
    width: 22px;
    height: 22px;
    border: ${({ checked }) => (checked ? '8px solid #485AFF' : '3px solid #b2b3c2')};
    border-radius: 100%;
    margin-right: 12px;
    background-color: ${({ theme }) => theme.colors.light[100]};

    ${styledTransition(['background-color', 'transform', 'opacity'], 250)}
  }

  &:hover &::before {
    background-color: ${({ theme }) => theme.colors.light[70]};
  }
`;

export interface RadioOption {
  value: string;
  label: string;
}

interface RadioProps {
  value: string;
  onChange: (value: string, originalEvent: React.FormEvent<HTMLInputElement>) => void;
  options: RadioOption[];
}

export function Radio({ value, onChange, options }: RadioProps) {
  const handleInput: React.FormEventHandler<HTMLInputElement> = (e) => {
    if (onChange) onChange(e.currentTarget.value, e);
  };

  return (
    <RadioWrapper>
      {options.map((option) => (
        <RadioLabel key={option.value} checked={option.value === value}>
          <input
            type="radio"
            value={option.value}
            checked={option.value === value}
            onChange={handleInput}
          />
          <div>{option.label}</div>
        </RadioLabel>
      ))}
    </RadioWrapper>
  );
}
