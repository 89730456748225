import { default as currencyjs } from 'currency.js';
import { CryptoCurrency, Currency, FiatCurrency, PricingItem, TestCoins } from '@use-gateway/types';

const defaultOptions: currencyjs.Options = {
  decimal: ',',
  pattern: '# !',
  separator: ' ',
};

const currencySettings: Record<Currency, currencyjs.Options> = {
  [FiatCurrency.USD]: {
    ...defaultOptions,
    pattern: '!#',
    symbol: '$',
    precision: 2,
  },
  [FiatCurrency.EUR]: {
    ...defaultOptions,
    pattern: '!#',
    symbol: '€',
    precision: 2,
  },
  [FiatCurrency.RUB]: {
    ...defaultOptions,
    symbol: '₽',
    precision: 2,
  },
  [FiatCurrency.KZT]: {
    ...defaultOptions,
    symbol: '₸',
    precision: 2,
  },
  [FiatCurrency.AZN]: {
    ...defaultOptions,
    symbol: '₼',
    precision: 2,
  },
  [FiatCurrency.UAH]: {
    ...defaultOptions,
    pattern: '!#',
    symbol: '₴',
    precision: 2,
  },
  [FiatCurrency.TRY]: {
    ...defaultOptions,
    pattern: '!#',
    symbol: '₺',
    precision: 2,
  },
  [FiatCurrency.KGS]: {
    ...defaultOptions,
    symbol: 'KGS',
    precision: 2,
  },
  [FiatCurrency.BRL]: {
    ...defaultOptions,
    symbol: 'R$',
    precision: 2,
  },

  [CryptoCurrency.BTC]: {
    ...defaultOptions,
    symbol: 'BTC',
    precision: 8,
  },
  [CryptoCurrency.BCH]: {
    ...defaultOptions,
    symbol: 'BCH',
    precision: 8,
  },
  [CryptoCurrency.LTC]: {
    ...defaultOptions,
    symbol: 'LTC',
    precision: 8,
  },
  [CryptoCurrency.MATIC]: {
    ...defaultOptions,
    symbol: 'MATIC',
    precision: 8,
  },
  [CryptoCurrency.DOGE]: {
    ...defaultOptions,
    symbol: 'DOGE',
    precision: 8,
  },
  [CryptoCurrency.DASH]: {
    ...defaultOptions,
    symbol: 'DASH',
    precision: 8,
  },

  [CryptoCurrency.OPETH]: {
    ...defaultOptions,
    symbol: 'ETH',
    precision: 8,
  },
  [CryptoCurrency.ETH]: {
    ...defaultOptions,
    symbol: 'ETH',
    precision: 18,
  },

  [CryptoCurrency.USDC_ERC20]: {
    ...defaultOptions,
    symbol: 'USDC <small>ERC20</small>',
    precision: 6,
  },

  [CryptoCurrency.USDT_ERC20]: {
    ...defaultOptions,
    symbol: 'USDT <small>ERC20</small>',
    precision: 6,
  },

  [CryptoCurrency.TRX]: {
    ...defaultOptions,
    symbol: 'TRX',
    precision: 6,
  },
  [CryptoCurrency.USDT_TRC20]: {
    ...defaultOptions,
    symbol: 'USDT <small>TRC20</small>',
    precision: 6,
  },
  [CryptoCurrency.USDC_TRC20]: {
    ...defaultOptions,
    symbol: 'USDC <small>TRC20</small>',
    precision: 6,
  },

  [CryptoCurrency.BNB]: {
    ...defaultOptions,
    symbol: 'BNB',
    precision: 18,
  },
  [CryptoCurrency.USDT_BEP20]: {
    ...defaultOptions,
    symbol: 'USDT <small>BEP20</small>',
    precision: 18,
  },
  [CryptoCurrency.USDC_BEP20]: {
    ...defaultOptions,
    symbol: 'USDC <small>BEP20</small>',
    precision: 18,
  },
  [CryptoCurrency.BUSD_BEP20]: {
    ...defaultOptions,
    symbol: 'BUSD <small>BEP20</small>',
    precision: 18,
  },
  [CryptoCurrency.DAI_BEP20]: {
    ...defaultOptions,
    symbol: 'DAI <small>BEP20</small>',
    precision: 18,
  },
  [CryptoCurrency.XRP_BEP20]: {
    ...defaultOptions,
    symbol: 'XRP <small>BEP20</small>',
    precision: 18,
  },
  [CryptoCurrency.TBCC_BEP20]: {
    ...defaultOptions,
    symbol: 'TBCC <small>BEP20</small>',
    precision: 18,
  },

  [CryptoCurrency.OPBNB]: {
    ...defaultOptions,
    symbol: 'BNB',
    precision: 18,
  },

  [TestCoins.TBTC]: {
    ...defaultOptions,
    symbol: 'TBTC',
    precision: 8,
  },
};

export function money(
  value: number | string,
  currency: string = FiatCurrency.USD,
  optionsOverwrite: currencyjs.Options = {}
) {
  const safeCurrency = currency.replace('-', '_');
  const options = Object.assign(
    {},
    currencySettings[safeCurrency] ?? {
      ...defaultOptions,
      symbol: currency,
      precision: 8,
    },
    optionsOverwrite
  );
  const precision = value.toString().split('.')[1]?.length ?? 0;

  if (
    typeof precision !== undefined &&
    (CryptoCurrency[safeCurrency] || TestCoins[safeCurrency]) &&
    (options.precision as number) > precision
  ) {
    options.precision = precision;
  }

  return currencyjs(value, options);
}

export function getMoneyDecemals(currency: string = FiatCurrency.USD) {
  const safeCurrency = currency.replace('-', '_');
  const options = Object.assign(
    {},
    currencySettings[safeCurrency] ?? {
      ...defaultOptions,
      symbol: currency,
      precision: 8,
    }
  );

  return options.precision;
}

export function price(item: PricingItem, options: currencyjs.Options = {}): string {
  return money(item.amount, item.currency, options).format();
}
