import { CreateP2PPayload, P2PAddressDto, P2PDto } from '@use-gateway/types';
import { Api } from '..';

const basePath = '/p2p';

export async function createP2P(this: Api, payload: CreateP2PPayload) {
  return this.post<P2PDto>(`${basePath}/`, payload);
}

export async function getP2P(this: Api, id: string) {
  return this.get<P2PDto>(`${basePath}/${id}/`);
}

export async function retrievesP2PAddress(this: Api, id: string, exchanger_id: string) {
  return this.get<P2PAddressDto>(`${basePath}/${id}/${exchanger_id}`);
}
