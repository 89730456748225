import getConfig from 'next/config';
import styled from 'styled-components';
import { CommonEvents, ProjectNames } from '@use-gateway/types';
import { useEventBus } from '@use-gateway/utils';

export interface FooterProps {
  marginTop?: number;
}

const FooterWrapper = styled.footer<FooterProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: flex-end;
  width: 100%;
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : 'auto')};
  margin-bottom: 28px;
  text-align: center;
  color: ${({ theme }) => theme.colors.dark[100]};

  @media screen and (max-width: 800px) {
    height: auto;
    flex-wrap: wrap;
    justify-content: center;
    padding: 44px 0 24px;
    margin-bottom: 0;

    div {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 1em;
    }

    & > * {
      margin: 1em 0.5em 0 !important;
    }
  }

  & > * {
    margin-right: 18px;

    &:last-child {
      margin-right: 0;
    }
  }

  span {
    color: ${({ theme }) => theme.colors.info[100]};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const { publicRuntimeConfig } = getConfig();
const { PROJECT_NAME } = publicRuntimeConfig;

export function Footer({ marginTop }: FooterProps) {
  const { emit } = useEventBus();

  return (
    <FooterWrapper marginTop={marginTop}>
      <div>
        © {PROJECT_NAME} {new Date().getFullYear()}
      </div>
      {PROJECT_NAME === ProjectNames.UseGateway && (
        <>
          <span onClick={() => emit(CommonEvents.openTermsOfService)}>Terms of Use</span>
          <span onClick={() => emit(CommonEvents.openPrivacyPolicy)}>Privacy policy</span>
          <span onClick={() => emit(CommonEvents.openCookiePolicy)}>Cookies Policy</span>
        </>
      )}
    </FooterWrapper>
  );
}
