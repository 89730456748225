import { CryptoCurrency, Currency, StableCoinsList, TestCoins } from '@use-gateway/types';

export const precisionByCurrency = (currency: Currency) => {
  if ([CryptoCurrency.BTC, TestCoins.TBTC].includes(currency as CryptoCurrency)) {
    return 8;
  }

  if (StableCoinsList.includes(currency as CryptoCurrency)) {
    return 2;
  }

  return 6;
};
