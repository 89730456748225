import { ReactNode } from 'react';
import { styled } from '@use-gateway/theme';

const BageWrapper = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 1em;
  height: 1.5em;
  min-width: 1.5em;
  padding: 0.15em;
  background-color: ${({ theme }) => theme.colors.light[60]};
  margin: 0 0.5em;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export interface BageProps {
  children: ReactNode;
}
export function Bage({ children }: BageProps) {
  return <BageWrapper>{children}</BageWrapper>;
}
