import { Country, ExchangeMethod, Exchanger, Paginated } from '@use-gateway/types';
import { Api } from '..';

const basePath = '/exchangers';

export async function getExchangers(
  this: Api,
  page = 1,
  size = 50,
  options: {
    country?: Country;
    method?: ExchangeMethod;
  } = {}
) {
  const { country, method } = options;

  return this.get<Paginated<Exchanger>>(
    `${basePath}/?` +
      new URLSearchParams({
        page: page.toString(),
        size: size.toString(),
        ...(country ? { country } : {}),
        ...(method ? { method } : {}),
      }).toString()
  );
}

export async function getExchangerCountries(this: Api) {
  return this.get<Array<Country>>(`${basePath}/countries/`);
}

export async function getExchangerMethods(this: Api, country: string) {
  return this.get<Array<ExchangeMethod>>(`${basePath}/methods/?country=${country}`);
}
