/* eslint-disable @typescript-eslint/ban-ts-comment */

import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isServer } from './is-server';

export interface NoticeableContextData {
  newsCount: number;
  isWidgetOpen: boolean;
  isWidgetMounted: boolean;
  toggleWidget: () => void;
}

const WIDGET_ID = 'VBjUVUCNLQj3NX7omTnQ';

const NoticeableContext = createContext<NoticeableContextData>({
  newsCount: 0,
  isWidgetOpen: false,
  isWidgetMounted: false,
  toggleWidget: () => null,
});

interface NoticeableProviderProps {
  children: React.ReactNode;
}
export function NoticeableProvider({ children }: NoticeableProviderProps) {
  const [newsCount, setNewsCount] = useState(0);
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);
  const [isWidgetMounted, setIsWidgetMounted] = useState(false);

  const toggleWidget = useCallback(() => {
    // @ts-ignore
    window.noticeable.do('widget:toggle', WIDGET_ID);
  }, []);

  const value = useMemo<NoticeableContextData>(
    () => ({ newsCount, isWidgetOpen, isWidgetMounted, toggleWidget }),
    [newsCount, isWidgetOpen, isWidgetMounted, toggleWidget]
  );

  useEffect(() => {
    // @ts-ignore
    window.noticeable.on('widget:opened', WIDGET_ID, () => setIsWidgetOpen(true));

    // @ts-ignore
    window.noticeable.on('widget:closed', WIDGET_ID, () => setIsWidgetOpen(false));

    // @ts-ignore
    window.noticeable.on('widget:ready', WIDGET_ID, () => setIsWidgetMounted(true));

    // @ts-ignore
    window.noticeable.on('widget:publication:unseen_count:changed', WIDGET_ID, (event) => {
      setNewsCount(event.detail.value);
    });

    // @ts-ignore
    window.noticeable.render('widget', WIDGET_ID, {
      selector: '#noticeable-widget',
    });
  }, []);

  return <NoticeableContext.Provider value={value}>{children}</NoticeableContext.Provider>;
}

export function useNoticeable() {
  return useContext(NoticeableContext);
}

mountWidget();

function mountWidget() {
  if (isServer()) return;
  if (window.document.getElementById('noticeable-widget')) return;

  const $widget = window.document.createElement('div');
  const $style = window.document.createElement('style');

  $style.innerHTML = `
    #noticeable-widget {
      display: none !important;
    }
  `;

  $widget.id = 'noticeable-widget';
  $widget.style.display = 'none !important';

  window.document.body.appendChild($widget);
  window.document.head.appendChild($style);
}
