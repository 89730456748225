import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { default as QRGenerator } from 'react-qr-code';
import { styled } from '@use-gateway/theme';
import { Checkbox } from './checkbox';

const QRCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
`;

const QRCodeDescription = styled.div`
  width: 200px;
  margin-top: 16px;
  text-align: center;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.light[20]};
`;

const QRCodeCheckbox = styled.div`
  width: 100%;
  margin-top: 16px;
  color: ${({ theme }) => theme.colors.dark[100]};

  p {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.42;
    color: #aaa;
  }
`;

export interface QRCodeProps {
  network: string;
  address: string;
  amount: string;
  description?: string;
}
export function QRCode({ network, address, amount, description }: QRCodeProps) {
  const { t } = useTranslation();
  const [isAmount, setIsAmount] = useState(true);

  return (
    <QRCodeWrapper>
      <QRGenerator
        value={isAmount ? `${network}:${address}?amount=${amount}` : `${address}`}
        size={200}
      />
      {description && <QRCodeDescription>{description}</QRCodeDescription>}

      <QRCodeCheckbox>
        <Checkbox value={isAmount} onChange={setIsAmount}>
          {t('common:include_amount')}
          <p>{t('common:include_warning')}</p>
        </Checkbox>
      </QRCodeCheckbox>
    </QRCodeWrapper>
  );
}
