import { Currency, RateResponseDto, RatesResponseDto } from '@use-gateway/types';
import { Api } from '..';

const basePath = '/rates';

export async function getRate(this: Api, currency: Currency, currency2: Currency) {
  return this.get<RateResponseDto>(`${basePath}/${currency}/${currency2}`);
}

export async function getRates(this: Api, currency: Currency) {
  return this.get<RatesResponseDto>(`${basePath}/${currency}`);
}
