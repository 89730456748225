import { down } from 'styled-breakpoints';
import { css } from 'styled-components';
import { IconCheckCircle, IconClock, IconPlayCircle, IconXCircle } from '@use-gateway/icons';
import { styled } from '@use-gateway/theme';
import { Steps, TimelineItem as Item } from '@use-gateway/types';
import { DateTime } from './date-time';
import { StatusColor } from './status';

export const TimelineWrapper = styled.div`
  display: flex;
  padding: 8px 8px;
  max-width: 100%;
  overflow-x: auto;
`;

interface TimelineIconProps {
  status?: StatusColor;
}
const TimelineIcon = styled.div<TimelineIconProps>`
  font-size: 48px;
  opacity: 0.2;

  ${({ status }) =>
    status === 'error'
      ? css`
          color: #e51212;
        `
      : ''}

  ${({ status }) =>
    status === 'success'
      ? css`
          color: #61bd4a;
        `
      : ''}

  ${down('md')} {
    font-size: 24px;
  }
`;

export const TimelineItemWrapper = styled.div`
  position: relative;

  width: 120px;
  min-width: 120px;
  height: 200px;
  margin-right: 80px;
  padding: 20px 8px 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  background-color: #f8f8f8;
  border-radius: 20px;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: calc(100% + 14px);
    width: 53px;
    height: 8px;
    transform: translateY(-50%);
    background-position: right center;
    background-image: url("data:image/svg+xml,%3Csvg width='53' height='8' viewBox='0 0 53 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M52.3536 4.35355C52.5488 4.15829 52.5488 3.84171 52.3536 3.64645L49.1716 0.464466C48.9763 0.269204 48.6597 0.269204 48.4645 0.464466C48.2692 0.659728 48.2692 0.976311 48.4645 1.17157L51.2929 4L48.4645 6.82843C48.2692 7.02369 48.2692 7.34027 48.4645 7.53553C48.6597 7.7308 48.9763 7.7308 49.1716 7.53553L52.3536 4.35355ZM0 4.5H52V3.5H0V4.5Z' fill='%23B2B3C2'/%3E%3C/svg%3E%0A");
  }

  &:last-of-type::after {
    display: none;
  }

  &:last-of-type ${TimelineIcon} {
    opacity: 1;
  }

  ${down('md')} {
    width: 100px;
    min-width: 100px;
    height: 130px;
    margin-right: 40px;

    &::after {
      width: 30px;
      left: calc(100% + 5px);
    }
  }
`;

const TimelineStatus = styled.h4`
  margin: 0 0 1em;
  height: 40px;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.42;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;
`;

const TimelineDate = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.42;
  opacity: 0.5;

  text-align: center;
`;

export interface TimelineItemProps {
  item: Item;
}
export function TimelineItem({ item }: TimelineItemProps) {
  return (
    <TimelineItemWrapper>
      {(() => {
        if (item.status === Steps.Open)
          return (
            <TimelineIcon>
              <IconPlayCircle />
            </TimelineIcon>
          );

        if (item.status === Steps.Pending)
          return (
            <TimelineIcon>
              <IconClock />
            </TimelineIcon>
          );

        if (item.status === Steps.Completed)
          return (
            <TimelineIcon status={'success'}>
              <IconCheckCircle />
            </TimelineIcon>
          );

        return (
          <TimelineIcon status={'error'}>
            <IconXCircle />
          </TimelineIcon>
        );
      })()}

      <TimelineStatus>{item.status}</TimelineStatus>
      <TimelineDate>
        <DateTime date={item.time} />
      </TimelineDate>
    </TimelineItemWrapper>
  );
}
