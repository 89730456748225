import { useCallback, useMemo } from 'react';
import { default as copyToClipboard } from 'copy-to-clipboard';
import { toast } from 'react-toast';
import { css } from 'styled-components';
import { IconCopy } from '@use-gateway/icons';
import { styled } from '@use-gateway/theme';

type MetaDetailVariant = 'horizontal' | 'vertical';

interface MetaDetailPartialsProps {
  variant: MetaDetailVariant;
}

const MetaDetailWrapper = styled.div<MetaDetailPartialsProps>`
  display: flex;

  ${({ variant }) => {
    if (variant === 'horizontal') {
      return css`
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      `;
    }
    if (variant === 'vertical') {
      return css`
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      `;
    }
    return '';
  }}

  margin: 8px 0;
`;

const MetaDetailLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  color: #8e90a7;
`;

const MetaDetailValue = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;

  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MetaDetailValueWrapper = styled.div<MetaDetailPartialsProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${({ variant }) => (variant === 'horizontal' ? 'auto' : '100%')};
`;

const MetaDetailCopy = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 24px;
  cursor: pointer;
`;

export interface MetaDatilProps {
  label: string;
  value: any;
  variant?: MetaDetailVariant;
  copy?: boolean;
}

export function MetaDetail({ label, value, variant = 'horizontal', copy }: MetaDatilProps) {
  const valueAsString = useMemo(() => {
    if (Array.isArray(value) && typeof value.length[0] === 'string') {
      return value.join(', ');
    }

    if (typeof value === 'object') {
      return JSON.stringify(value);
    }

    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }

    return value.toString();
  }, [value]);

  const handleCopy = useCallback(() => {
    copyToClipboard(valueAsString);
    toast.info('Text has been copied!');
  }, [copy, valueAsString]);

  return (
    <MetaDetailWrapper variant={variant}>
      <MetaDetailLabel>{label}</MetaDetailLabel>
      <MetaDetailValueWrapper variant={variant}>
        <MetaDetailValue>{valueAsString}</MetaDetailValue>
        {copy && (
          <MetaDetailCopy onClick={handleCopy}>
            <IconCopy />
          </MetaDetailCopy>
        )}
      </MetaDetailValueWrapper>
    </MetaDetailWrapper>
  );
}
