import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { down } from 'styled-breakpoints';
import { css } from 'styled-components';
import { uid } from 'uid';
import { IconCheck, IconChevronDown } from '@use-gateway/icons';
import { Breakpoints, styled, useBreakpoints } from '@use-gateway/theme';
import { UnknownFunction } from '@use-gateway/types';
import { styledTransition } from '@use-gateway/utils';
import { Modal } from './modal';

//
// ~~ Styled Components
//

const SelectWrapper = styled.div`
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  border: none;
`;

interface SelectFieldProps {
  error?: boolean;
}
const SelectField = styled.select<SelectFieldProps>`
  appearance: none;

  width: 100%;
  padding: 19px 16px 11px;

  outline: none;
  border: 2px solid;
  border-color: transparent;

  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;

  border-radius: ${({ theme }) => theme.borderRadius.md};
  background-color: ${({ theme }) => theme.colors.light[80]};
  color: ${({ theme }) => theme.colors.dark[100]};

  ${down('sm')} {
    pointer-events: none;
  }

  option {
    padding: 0;
    margin: 0;

    small {
      font-size: 0.5em;
    }
  }

  ${styledTransition(['all'], 150)}

  &:focus {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  &:focus,
  &:hover {
    background-color: ${({ theme }) => theme.colors.light[70]};
  }

  ${({ error }) =>
    error
      ? css`
          border-color: ${({ theme }) => theme.colors.danger[100]} !important;
        `
      : ''}
`;

const SelectChevron = styled.div`
  position: absolute;
  top: 17px;
  right: 12px;
  min-width: 24px;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.dark[40]};
  pointer-events: none;
`;

const SelectLabel = styled.label`
  position: absolute;
  left: 18px;
  font-weight: 400;
  pointer-events: none;

  top: 10px;
  transform: translateY(0);
  font-size: 9px;
  line-height: 1.33;
  color: ${({ theme }) => theme.colors.dark[40]};
`;

interface ItemMobProps {
  isActive: boolean;
}

const ItemMob = styled.div<ItemMobProps>`
  position: relative;

  input {
    display: none;
  }

  small {
    font-weight: 500;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.info[100]};
  }

  label {
    display: flex;
    align-items: flex-end;
    gap: 6px;
    padding: 20px 68px 20px 36px;
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.colors.light[80] : 'transparent'};
    font-weight: 500;
    font-size: 16px;
  }

  svg {
    position: absolute;
    top: 18px;
    right: 36px;
    min-width: 24px;
    font-size: 24px;
    color: ${({ theme }) => theme.colors.info[100]};
  }
`;

//
// ~~ Types
//

export type Options<T extends string = string> = Partial<Record<T, string>>;

interface CommonSelectProps<T extends string> {
  options: Options<T>;
  defautValue?: string;
  label?: string;
  titleMobModal?: string;
  onCloseMobModal?: UnknownFunction;
}

//
// ~~ Main Component
//

export interface SelectProps<T extends string> extends CommonSelectProps<T> {
  error?: string;
  value?: T;
  defautValue?: T;
  onBlur?: (value: T, originalEvent: React.ChangeEvent<HTMLSelectElement>) => void;
  onChange?: (
    value: T,
    originalEvent: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
  ) => void;
}
export function Select<T extends string>({
  label,
  value,
  defautValue,
  options,
  error,
  onBlur,
  onChange,
  titleMobModal,
  onCloseMobModal,
}: SelectProps<T>) {
  const { isDown } = useBreakpoints();
  const id = useMemo(() => uid(), []);

  const [isModal, setIsModal] = useState(false);
  const [innerValue, setInnerValue] = useState<T>(
    (defautValue || value) ?? (Object.values(options)[0] as T)
  );

  const handleSelect: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    setInnerValue(e.currentTarget.value as T);
    if (onChange) onChange(e.currentTarget.value as T, e);
  };

  const handleRadio: React.FormEventHandler<HTMLInputElement> = (e) => {
    setInnerValue(e.currentTarget.value as T);
    if (onChange) onChange(e.currentTarget.value as T, e);
  };

  const handleBlur: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    if (onBlur) onBlur(e.currentTarget.value as T, e);
  };

  useEffect(() => {
    if (value !== undefined) setInnerValue(value);
  }, [value]);

  return (
    <SelectWrapper onClick={() => setIsModal(!isModal)}>
      <SelectField
        id={id}
        onChange={handleSelect}
        onBlur={handleBlur}
        value={innerValue}
        error={!!error}>
        {Object.keys(options).map((value) => {
          return (
            <option
              key={value}
              value={value}
              dangerouslySetInnerHTML={{ __html: options[value].toString() }}
            />
          );
        })}
      </SelectField>
      <SelectChevron>
        <IconChevronDown />
      </SelectChevron>
      {label && <SelectLabel htmlFor={id}>{label}</SelectLabel>}
      {isModal && isDown(Breakpoints.sm) && (
        <Modal
          title={titleMobModal ?? label}
          paddingContent={'70px 0 0'}
          onClose={() => {
            setIsModal(false);
            if (onCloseMobModal) onCloseMobModal();
          }}>
          {Object.keys(options).map((value, i) => {
            return (
              <ItemMob isActive={value === innerValue} key={value}>
                <input id={value} name={id} type={'radio'} value={value} onChange={handleRadio} />
                <label
                  htmlFor={value}
                  id={value}
                  dangerouslySetInnerHTML={{ __html: options[value].toString() }}
                />
                {value === innerValue && <IconCheck />}
              </ItemMob>
            );
          })}
        </Modal>
      )}
    </SelectWrapper>
  );
}

//
// ~~ Controlled component
//

export interface ControlledSelectProps<T extends string> extends CommonSelectProps<T> {
  name: string;
}
export function ControlledSelect<T extends string>({
  name,
  label,
  defautValue,
  options,
  onCloseMobModal,
  titleMobModal,
}: ControlledSelectProps<T>) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defautValue}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
        return (
          <Select
            options={options}
            label={label}
            onChange={(_, e) => onChange(e)}
            onBlur={onBlur}
            defautValue={defautValue as T}
            value={value}
            error={error?.message}
            onCloseMobModal={onCloseMobModal}
            titleMobModal={titleMobModal}
          />
        );
      }}
    />
  );
}
