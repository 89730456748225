import { styled } from '@use-gateway/theme';
import { PricingItem } from '@use-gateway/types';

const DoublePriceInputWrapper = styled.div``;

export interface DoublePriceInputProps {
  primary: PricingItem;
  secondary: PricingItem;
  exchangeRate?: number;
  onChange?: ({ primary, secondary }: { primary: number; secondary: number }) => void;
}
export function DoublePriceInput({ primary, secondary }: DoublePriceInputProps) {
  return <DoublePriceInputWrapper />;
}
