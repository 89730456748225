import { css } from 'styled-components';
import { styled } from '@use-gateway/theme';
import { Align, PricingItem, Size } from '@use-gateway/types';
import { getMoneyDecemals, price } from '@use-gateway/utils';

interface DoublePriceItemProps {
  size: Size;
  align?: Align;
  approximate?: boolean;
  equal?: boolean;
  color?: 'grey';
}
const DoublePriceWrapper = styled.div<DoublePriceItemProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  white-space: nowrap;
  color: ${({ color, theme }) => {
    if (color === 'grey') return '#ccc';
    return theme.colors.dark[100];
  }};

  ${({ align }) => {
    if (align === 'start') return 'align-items: flex-start;';
    if (align === 'center')
      return css`
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
      `;
    return '';
  }}

  small {
    position: relative;
    font-size: 1em;
    color: ${({ theme, color }) => {
      if (color === 'grey') return '#ccc';
      return theme.colors.primary;
    }};
  }

  ${({ equal, size, align }) =>
    (equal || align === 'center') &&
    css`
      font-weight: ${() => {
        if (size === 'xs') return '500 !important';
        return '400 !important';
      }};
      font-size: ${() => {
        if (size === 'lg') return '20px !important';
        if (size === 'xs') return '14px !important';
        return '16px !important';
      }};
    `}
`;

const DoublePricePrimary = styled.div<DoublePriceItemProps>`
  font-weight: ${({ size }) => {
    if (size === 'xs') return '500';
    return '500';
  }};
  font-size: ${({ size }) => {
    if (size === 'lg') return '20px';
    if (size === 'xs') return '14px';
    return '16px';
  }};

  ${({ approximate }) =>
    approximate
      ? css`
          &::before {
            content: '~ ';
          }
        `
      : ''}

  ${({ equal, align }) =>
    (equal || align === 'center') &&
    css`
      font-size: inherit !important;
    `}
`;

const DoublePriceSecondary = styled.div<DoublePriceItemProps>`
  font-size: ${({ size }) => {
    if (size === 'lg') return '16px';
    if (size === 'xs') return '14px';
    return '14px';
  }};
  color: ${({ theme }) => theme.colors.dark[80]};

  ${({ approximate }) =>
    approximate
      ? css`
          &::before {
            content: '~ ';
          }
        `
      : ''}

  ${({ equal, align }) =>
    (equal || align === 'center') &&
    css`
      font-size: inherit !important;
    `}
`;

export const DoublePriceTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  p {
    margin: 0;
  }
`;

export interface DoublePriceProps {
  primary: PricingItem;
  secondary?: PricingItem;
  size?: Size;
  align?: Align;
  approximate?: boolean;
  maxPrecision?: number;
  equal?: boolean;
  color?: 'grey';
}
export function DoublePrice({
  primary,
  secondary,
  size,
  align,
  approximate,
  maxPrecision,
  equal,
  color,
}: DoublePriceProps) {
  const safeSize = size ?? 'md';
  const safeAlign = equal ? 'center' : align;
  const primaryPrecision = Math.min(
    getMoneyDecemals(primary.currency) as number,
    maxPrecision ?? 9999
  );
  const secondaryPrecision = Math.min(
    (getMoneyDecemals(secondary?.currency) as number) ?? 2,
    maxPrecision ?? 9999
  );
  const primaryIncrement = 10 ** -primaryPrecision;
  const secondaryIncrement = 10 ** -secondaryPrecision;

  return (
    <DoublePriceWrapper size={safeSize} align={safeAlign} color={color}>
      <DoublePricePrimary
        size={safeSize}
        align={safeAlign}
        equal={equal}
        dangerouslySetInnerHTML={{
          __html: price(primary, { precision: primaryPrecision, increment: primaryIncrement }),
        }}
        approximate={approximate}
      />
      {(equal || align === 'center') && <span>&nbsp;{equal ? '=' : '~'}&nbsp;</span>}
      {secondary && (
        <DoublePriceSecondary
          size={safeSize}
          align={safeAlign}
          dangerouslySetInnerHTML={{
            __html: price(secondary, {
              precision: secondaryPrecision,
              increment: secondaryIncrement,
            }),
          }}
          approximate={approximate}
        />
      )}
    </DoublePriceWrapper>
  );
}
