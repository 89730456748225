import { Modal, ModalProps } from './modal';

export function CookieModal({ onClose }: Partial<ModalProps>) {
  return (
    <Modal title="Cookies Policy" size={'lg'} onClose={onClose}>
      <h3>1. Terms and Definitions</h3>

      <p>
        1.1. Cookie files (Cookies) are the data with information related to website visits, which
        the web-server sends to the User&apos;s device, aimed to obtain information on User&apos;s
        preferences and other statistical information to simplify the User&apos;s browsing
        experience.
      </p>
      <p>
        1.2. User is a private individual, a Web user engaging UseGateway Service for his own
        personal purposes, as well as any other private individual for the benefit of whom the user
        is acting.
      </p>
      <p>
        1.3. UseGateway Service / Service is a service which allows Users to use digital payment
        facilities and execute funds transfers without opening a bank account. The copyright holder
        of the Service is Trendpay LLC, ID №404613245, Krtsanisi district, Mtkvari str., N6,
        Tbilisi, Georgia.
      </p>

      <h3>2. Use of cookies by the UseGateway service</h3>

      <p>
        2.1. The UseGateway service uses cookies only for the purpose of content enhancement, proper
        operation of the service and evaluation of results, as well as for the purpose of ensuring
        the website is properly displayed and appropriately managed.
      </p>
      <p>
        2.2. The UseGateway Service uses technical cookies, which are necessary for the proper
        operation of the Service and to deliver product scope, including the authentication and
        subsequent authorization of the User in the Service, and to ensure the security of account
        data in carrying out payments.
      </p>
      <p>
        2.3. The UseGateway Service uses analytics cookies, which can identify the User&apos;s
        hardware or software, recognize Users, count their number, and collect information about how
        Users interact with the Service.
      </p>
      <p>
        2.4. The UseGateway Service uses other cookies intended to perform various service functions
        and to improve the User&apos;s experience with the Service, including but not limited to
        helping to memorize page status, selecting a region, and storing Users&apos; personal
        preferences.
      </p>
      <p>
        2.5. The UseGateway service is entitled to use marketing cookies in order to ensure
        pertinency of advertisements and to increase advertising campaigns performance.
      </p>
      <p>
        2.6. The UseGateway Service utilizes the web analytics tools such as Google Analytics to
        process cookies. Third parties may access cookies solely for the purpose of maintaining and
        enhancing the performance of the Service.
      </p>
      <p>
        2.7. The duration of the use of cookies by the UseGateway Service may vary depending on how
        long cookies remain on the User&apos;s device and whether the cookies are
        &quot;persistent&quot; or &quot;temporary&quot;. &quot;Temporary&quot; cookies remain on the
        User&apos;s device until the User quits browser. &quot;Persistent&quot; cookies remain on
        the User&apos;s browser to the extent that they expire or are deleted.
      </p>

      <h3>3. The User&apos;s use of cookies</h3>

      <p>
        3.1. The User&apos;s acceptance of the use of cookies by the UseGateway Service constitutes
        the User&apos;s acknowledgement of and agreement to the provisions of the current Cookie
        Policy.
      </p>
      <p>
        3.2. The User is allowed to disable use of technical cookies in the browser settings, but
        this may result in an incorrect performance of the Service website.
      </p>
      <p>
        3.3. The User is allowed to restrict the collection of cookies by analytical services by
        adjusting the browser settings or by installing special extensions or tools. Such actions
        will not affect the correct performance of the Service.
      </p>
      <p>
        3.4. The User may contact the UseGateway Service Support Team by e-mail{' '}
        <a href={'mailto:support@usegateway.group'}>support@usegateway.group</a> for additional
        information on the cookie policy, and to revoke his/her consent to the operation of cookies.
      </p>
    </Modal>
  );
}
