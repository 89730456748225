// @ts-nocheck
import { forwardRef } from 'react';
const SvgRotateCw = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_2128_24449)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M23 3C23.5523 3 24 3.44772 24 4V10C24 10.5523 23.5523 11 23 11H17C16.4477 11 16 10.5523 16 10C16 9.44772 16.4477 9 17 9H22V4C22 3.44772 22.4477 3 23 3Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.1275 4.08123C11.4102 3.83764 9.66015 4.15881 8.1412 4.99634C6.62225 5.83387 5.41666 7.14239 4.7061 8.72472C3.99553 10.307 3.81849 12.0775 4.20166 13.7692C4.58482 15.4609 5.50743 16.9822 6.83045 18.1039C8.15347 19.2257 9.80523 19.887 11.5368 19.9883C13.2684 20.0896 14.986 19.6254 16.4308 18.6656C17.8756 17.7057 18.9693 16.3024 19.5471 14.6669C19.7311 14.1461 20.3024 13.8731 20.8231 14.0571C21.3439 14.2411 21.6169 14.8124 21.4329 15.3331C20.7106 17.3775 19.3435 19.1317 17.5375 20.3315C15.7315 21.5312 13.5845 22.1115 11.42 21.9849C9.25553 21.8583 7.19083 21.0316 5.53705 19.6294C3.88328 18.2273 2.73002 16.3256 2.25106 14.211C1.77211 12.0963 1.99341 9.88332 2.88162 7.90542C3.76982 5.92751 5.27681 4.29185 7.1755 3.24494C9.07418 2.19802 11.2617 1.79656 13.4084 2.10105C15.55 2.40482 17.5352 3.39475 19.0663 4.92214L23.6856 9.27199C24.0876 9.65061 24.1066 10.2835 23.728 10.6856C23.3494 11.0876 22.7165 11.1066 22.3144 10.728L17.6844 6.36802C17.6771 6.36108 17.6698 6.35404 17.6627 6.34688C16.4365 5.11998 14.8449 4.32483 13.1275 4.08123Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_2128_24449">
        <rect width={24} height={24} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgRotateCw);
export default ForwardRef;
