// @ts-nocheck
import { forwardRef } from 'react';
const SvgRequestMenu = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M4 4.50593C4 4.37175 4.0533 4.24306 4.14818 4.14818C4.24306 4.0533 4.37175 4 4.50593 4H18.672C18.8062 4 18.9349 4.0533 19.0298 4.14818C19.1247 4.24306 19.178 4.37175 19.178 4.50593V16.1424C19.178 16.2088 19.1649 16.2746 19.1395 16.336C19.114 16.3974 19.0768 16.4531 19.0298 16.5001C18.9828 16.5471 18.927 16.5844 18.8657 16.6098C18.8043 16.6352 18.7385 16.6483 18.672 16.6483H15.1811C15.1119 16.6482 15.0434 16.6623 14.9799 16.6897C14.9164 16.7171 14.8592 16.7573 14.8118 16.8077L11.9575 19.8407C11.9102 19.891 11.8531 19.9311 11.7897 19.9585C11.7263 19.9859 11.658 20 11.589 20C11.5199 20 11.4516 19.9859 11.3883 19.9585C11.3249 19.9311 11.2678 19.891 11.2205 19.8407L8.3662 16.8077C8.3188 16.7573 8.26158 16.7171 8.19805 16.6897C8.13453 16.6623 8.06605 16.6482 7.99687 16.6483H4.50593C4.37175 16.6483 4.24306 16.595 4.14818 16.5001C4.0533 16.4052 4 16.2766 4 16.1424V4.50593Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M11.5897 7.3728L12.7913 9.5441L14.9626 10.7457L12.7913 11.9473L11.5897 14.1186L10.3881 11.9473L8.2168 10.7457L10.3881 9.5441L11.5897 7.3728Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgRequestMenu);
export default ForwardRef;
