import { useState } from 'react';
import styled from 'styled-components';
import { Stack } from '@bedrock-layout/stack';
import { Checkbox, Modal, ModalProps, Status } from '@use-gateway/components';

const ItemWrapper = styled.div`
  cursor: pointer;
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 18px 10px 0;
  cursor: pointer;
  pointer-events: none;
`;

interface FilterStatusModalProps extends Partial<ModalProps> {
  isUnresolved?: boolean;
  setIsUnresolved: (status?: boolean) => void;
}

export function StatusFilterModal({
  onClose,
  isUnresolved,
  setIsUnresolved,
}: FilterStatusModalProps) {
  const [currentValue, setCurrentValue] = useState<boolean | undefined>(isUnresolved);

  const handleStatus = (value: boolean) => {
    if (value === true) {
      if (currentValue === undefined) setCurrentValue(false);
      if (currentValue === true) setCurrentValue(false);
      if (currentValue === false) setCurrentValue(undefined);
    }
    if (value === false) {
      if (currentValue === undefined) setCurrentValue(true);
      if (currentValue === true) setCurrentValue(undefined);
      if (currentValue === false) setCurrentValue(true);
    }
  };

  const apply = () => {
    setIsUnresolved(currentValue);
    if (onClose) onClose();
  };

  return (
    <Modal title="Filter by status" onClose={onClose} nextText="Apply" onNext={apply}>
      <Stack gutter="md">
        <ItemWrapper onClick={() => handleStatus(false)}>
          <Item>
            <Checkbox value={currentValue === false || currentValue === undefined} isCheckValue>
              Resolved/Completed
            </Checkbox>
            <Status status="success" />
          </Item>
        </ItemWrapper>

        <ItemWrapper onClick={() => handleStatus(true)}>
          <Item>
            <Checkbox value={currentValue === true || currentValue === undefined} isCheckValue>
              Unresolved
            </Checkbox>
            <Status status="error" />
          </Item>
        </ItemWrapper>
      </Stack>
    </Modal>
  );
}
