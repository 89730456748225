// @ts-nocheck
import { forwardRef } from 'react';
const SvgRotateCcw = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_2128_24445)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1 3C1.55228 3 2 3.44772 2 4V9H7C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11H1C0.447715 11 0 10.5523 0 10V4C0 3.44772 0.447715 3 1 3Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.6054 2.10687C12.7523 1.80481 14.9392 2.20866 16.8366 3.25759C18.7341 4.30651 20.2392 5.94367 21.1253 7.9224C22.0114 9.90113 22.2304 12.1142 21.7493 14.2282C21.2682 16.3423 20.1131 18.2427 18.4581 19.6431C16.803 21.0436 14.7377 21.8682 12.5732 21.9928C10.4087 22.1174 8.26236 21.5352 6.45756 20.3338C4.65276 19.1325 3.28727 17.3772 2.56683 15.3323C2.38331 14.8114 2.65681 14.2403 3.17771 14.0568C3.69861 13.8733 4.26966 14.1468 4.45318 14.6677C5.02953 16.3036 6.12192 17.7079 7.56576 18.6689C9.0096 19.63 10.7267 20.0958 12.4583 19.9961C14.1898 19.8965 15.8421 19.2367 17.1662 18.1164C18.4902 16.996 19.4143 15.4757 19.7992 13.7845C20.184 12.0933 20.0088 10.3228 19.3 8.73979C18.5911 7.15681 17.387 5.84707 15.869 5.00794C14.3511 4.1688 12.6015 3.84571 10.884 4.08736C9.16647 4.32901 7.57399 5.1223 6.34651 6.3477C6.33937 6.35483 6.33213 6.36184 6.32478 6.36875L1.68478 10.7287C1.2823 11.1069 0.649442 11.0873 0.27125 10.6848C-0.106942 10.2823 -0.0872528 9.64943 0.315226 9.27124L4.94449 4.92133C6.47735 3.39581 8.46357 2.4082 10.6054 2.10687Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_2128_24445">
        <rect width={24} height={24} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgRotateCcw);
export default ForwardRef;
