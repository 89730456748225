// @ts-nocheck
import { forwardRef } from 'react';
const SvgBold = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5 4C5 3.44772 5.44772 3 6 3H14C15.3261 3 16.5979 3.52678 17.5355 4.46447C18.4732 5.40215 19 6.67392 19 8C19 9.32608 18.4732 10.5979 17.5355 11.5355C16.5979 12.4732 15.3261 13 14 13H6C5.44772 13 5 12.5523 5 12V4ZM7 5V11H14C14.7956 11 15.5587 10.6839 16.1213 10.1213C16.6839 9.55871 17 8.79565 17 8C17 7.20435 16.6839 6.44129 16.1213 5.87868C15.5587 5.31607 14.7956 5 14 5H7Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5 12C5 11.4477 5.44772 11 6 11H15C16.3261 11 17.5979 11.5268 18.5355 12.4645C19.4732 13.4021 20 14.6739 20 16C20 17.3261 19.4732 18.5979 18.5355 19.5355C17.5979 20.4732 16.3261 21 15 21H6C5.44772 21 5 20.5523 5 20V12ZM7 13V19H15C15.7956 19 16.5587 18.6839 17.1213 18.1213C17.6839 17.5587 18 16.7956 18 16C18 15.2044 17.6839 14.4413 17.1213 13.8787C16.5587 13.3161 15.7956 13 15 13H7Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgBold);
export default ForwardRef;
