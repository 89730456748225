// @ts-nocheck
import { forwardRef } from 'react';
const SvgThumbsDown = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.5641 3.31561C20.3205 3.10653 20.0086 2.99416 19.6877 2.99984L19.67 3H18V12H19.67L19.6877 12.0002C20.0086 12.0058 20.3205 11.8935 20.5641 11.6844C20.793 11.4879 20.9466 11.2188 21 10.9231V4.07686C20.9466 3.78114 20.793 3.5121 20.5641 3.31561ZM16 12.7878L12.3922 20.9054C12.0908 20.8092 11.8136 20.6421 11.5858 20.4142C11.2107 20.0391 11 19.5304 11 19V15C11 14.4477 10.5523 14 10 14H4.34L4.32868 14.0001C4.18372 14.0017 4.04014 13.9718 3.90789 13.9125C3.77564 13.8531 3.65787 13.7657 3.56275 13.6563C3.46763 13.5469 3.39742 13.4181 3.35701 13.2789C3.31664 13.1399 3.30695 12.9938 3.32859 12.8506C3.32856 12.8508 3.32862 12.8504 3.32859 12.8506L4.7087 3.84993C4.74486 3.61148 4.86598 3.39413 5.04974 3.23793C5.23351 3.08173 5.46753 2.99721 5.7087 2.99993L16 3V12.7878ZM19.6619 0.999995C20.4695 0.988062 21.2537 1.27182 21.8667 1.79802C22.4822 2.3263 22.8823 3.06218 22.991 3.86595C22.997 3.91038 23 3.95516 23 4V11C23 11.0448 22.997 11.0896 22.991 11.134C22.8823 11.9378 22.4822 12.6737 21.8667 13.202C21.2537 13.7282 20.4695 14.0119 19.662 14H17.6499L13.9138 22.4061C13.7533 22.7673 13.3952 23 13 23C11.9391 23 10.9217 22.5786 10.1716 21.8284C9.42143 21.0783 9 20.0609 9 19V16H4.34511C3.91236 16.004 3.48384 15.9143 3.08897 15.7371C2.6922 15.559 2.3389 15.2968 2.05354 14.9687C1.76817 14.6405 1.55757 14.2542 1.43631 13.8366C1.31506 13.4189 1.28606 12.9799 1.35132 12.5499L2.7313 3.55006C2.73135 3.54976 2.73126 3.55036 2.7313 3.55006C2.83996 2.83505 3.20339 2.18245 3.75445 1.71405C4.30416 1.24679 5.00379 0.993336 5.72508 0.999995H19.6619Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgThumbsDown);
export default ForwardRef;
