// @ts-nocheck
import { forwardRef } from 'react';
const SvgBnb = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 64 64"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_3630_36490)">
      <path
        fill="#F0B90B"
        fillRule="evenodd"
        d="M32 0C49.6744 0 64 14.3256 64 32C64 49.6744 49.6744 64 32 64C14.3256 64 0 49.6744 0 32C0 14.3256 14.3256 0 32 0Z"
        clipRule="evenodd"
      />
      <path
        fill="white"
        d="M17.5872 32L17.6103 40.4615L24.8 44.6923V49.6461L13.4026 42.9615V29.5256L17.5872 32ZM17.5872 23.5384V28.4692L13.4 25.9923V21.0615L17.5872 18.5846L21.7949 21.0615L17.5872 23.5384ZM27.8026 21.0615L31.9898 18.5846L36.1975 21.0615L31.9898 23.5384L27.8026 21.0615Z"
      />
      <path
        fill="white"
        d="M20.6128 38.7077V33.7538L24.8 36.2307V41.1615L20.6128 38.7077ZM27.8025 46.4666L31.9897 48.9436L36.1974 46.4666V51.3974L31.9897 53.8743L27.8025 51.3974V46.4666ZM42.2025 21.0615L46.3897 18.5846L50.5974 21.0615V25.9923L46.3897 28.4692V23.5384L42.2025 21.0615ZM46.3897 40.4615L46.4128 32L50.6 29.5231V42.959L39.2025 49.6436V44.6897L46.3897 40.4615Z"
      />
      <path fill="white" d="M43.3871 38.7078L39.2 41.1616V36.2308L43.3871 33.7539V38.7078Z" />
      <path
        fill="white"
        d="M43.3872 25.2923L43.4102 30.2461L36.2 34.4769V42.9589L32.0128 45.4128L27.8256 42.9589V34.4769L20.6154 30.2461V25.2923L24.8205 22.8154L31.9872 27.0666L39.1974 22.8154L43.4051 25.2923H43.3872ZM20.6128 16.8333L31.9897 10.1256L43.3872 16.8333L39.2 19.3102L31.9897 15.0589L24.8 19.3102L20.6128 16.8333Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_3630_36490">
        <rect width={64} height={64} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgBnb);
export default ForwardRef;
