// @ts-nocheck
import { forwardRef } from 'react';
const SvgCompleted = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 64 64"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="#61BD4A"
      fillRule="evenodd"
      d="M41.7681 10.0624C37.0407 7.95605 31.7591 7.43422 26.7109 8.57479C21.6628 9.71536 17.1185 12.4572 13.756 16.3914C10.3934 20.3256 8.39263 25.2414 8.05211 30.4056C7.71158 35.5698 9.04952 40.7057 11.8664 45.0474C14.6833 49.3891 18.8281 52.7039 23.6828 54.4974C28.5375 56.291 33.8419 56.4672 38.8049 54.9998C43.768 53.5324 48.1237 50.5 51.2225 46.3548C54.3213 42.2097 55.9971 37.1739 56.0001 31.9984V29.5466C56.0001 28.0739 57.194 26.88 58.6668 26.88C60.1395 26.88 61.3334 28.0739 61.3334 29.5466V32C61.3298 38.3255 59.2815 44.4819 55.4941 49.5482C51.7067 54.6145 46.383 58.3208 40.3171 60.1143C34.2512 61.9078 27.768 61.6924 21.8345 59.5003C15.901 57.3081 10.8351 53.2567 7.39223 47.9502C3.9494 42.6437 2.31414 36.3665 2.73033 30.0547C3.14653 23.7429 5.59189 17.7347 9.7017 12.9262C13.8115 8.11776 19.3656 4.76661 25.5356 3.37259C31.7056 1.97856 38.1609 2.61635 43.9388 5.19082C45.284 5.79024 45.8887 7.36671 45.2892 8.71197C44.6898 10.0572 43.1134 10.6619 41.7681 10.0624Z"
      clipRule="evenodd"
    />
    <path
      fill="#61BD4A"
      fillRule="evenodd"
      d="M60.5515 8.78011C61.5934 9.82098 61.5943 11.5094 60.5534 12.5513L33.8867 39.2447C33.3867 39.7452 32.7083 40.0265 32.0008 40.0267C31.2934 40.0268 30.6148 39.7459 30.1145 39.2456L22.1145 31.2456C21.0731 30.2042 21.0731 28.5158 22.1145 27.4744C23.1559 26.433 24.8444 26.433 25.8858 27.4744L31.9992 33.5878L56.7803 8.78199C57.8211 7.74007 59.5096 7.73923 60.5515 8.78011Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCompleted);
export default ForwardRef;
