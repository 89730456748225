// @ts-nocheck
import { forwardRef } from 'react';
const SvgUsdcErc20 = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 64 64"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_3635_36464)">
      <path
        fill="#2775CA"
        d="M32 64C49.7334 64 64 49.7334 64 32C64 14.2666 49.7334 0 32 0C14.2666 0 0 14.2666 0 32C0 49.7334 14.2666 64 32 64Z"
      />
      <path
        fill="white"
        d="M40.8 37.0666C40.8 32.4 38 30.8 32.4 30.1335C28.4 29.6 27.6 28.5335 27.6 26.6666C27.6 24.7997 28.9335 23.6 31.6 23.6C34 23.6 35.3335 24.4 36 26.4C36.1335 26.8 36.5335 27.0666 36.9335 27.0666H39.0666C39.6 27.0666 40 26.6666 40 26.1335V26C39.4666 23.0666 37.0666 20.8 34 20.5335V17.3335C34 16.8 33.6 16.4 32.9335 16.2666H30.9335C30.4 16.2666 30 16.6666 29.8666 17.3335V20.4C25.8666 20.9335 23.3335 23.6 23.3335 26.9335C23.3335 31.3335 26 33.0666 31.6 33.7335C35.3335 34.4 36.5335 35.2 36.5335 37.3335C36.5335 39.4669 34.6666 40.9335 32.1335 40.9335C28.6666 40.9335 27.4666 39.4666 27.0666 37.4666C26.9335 36.9335 26.5335 36.6666 26.1335 36.6666H23.8666C23.3335 36.6666 22.9335 37.0666 22.9335 37.6V37.7335C23.4666 41.0666 25.6 43.4666 30 44.1335V47.3335C30 47.8666 30.4 48.2666 31.0666 48.4H33.0666C33.6 48.4 34 48 34.1335 47.3335V44.1335C38.1335 43.4666 40.8 40.6666 40.8 37.0666V37.0666Z"
      />
      <path
        fill="white"
        d="M25.2 51.0666C14.8 47.3335 9.4666 35.7335 13.3335 25.4666C15.3335 19.8666 19.7335 15.6001 25.2 13.6001C25.7335 13.3335 26 12.9335 26 12.2666V10.4001C26 9.86662 25.7335 9.46662 25.2 9.3335C25.0666 9.3335 24.8 9.3335 24.6666 9.46662C12 13.4666 5.0666 26.9335 9.0666 39.6001C11.4666 47.0666 17.2 52.8001 24.6666 55.2001C25.2 55.4666 25.7335 55.2001 25.8666 54.6666C26 54.5335 26 54.4001 26 54.1335V52.2666C26 51.8666 25.6 51.3335 25.2 51.0666ZM39.3335 9.46662C38.8 9.20006 38.2666 9.46662 38.1335 10.0001C38 10.1335 38 10.2666 38 10.5335V12.4001C38 12.9335 38.4 13.4666 38.8 13.7335C49.2 17.4666 54.5335 29.0666 50.6666 39.3335C48.6666 44.9335 44.2666 49.2001 38.8 51.2001C38.2666 51.4666 38 51.8666 38 52.5335V54.4001C38 54.9335 38.2666 55.3335 38.8 55.4666C38.9335 55.4666 39.2 55.4666 39.3335 55.3335C52 51.3335 58.9335 37.8666 54.9335 25.2001C52.5335 17.6001 46.6666 11.8666 39.3335 9.46662V9.46662Z"
      />
    </g>
    <g clipPath="url(#clip1_3635_36464)">
      <path
        fill="#627EEA"
        d="M55 64C59.9706 64 64 59.9706 64 55C64 50.0294 59.9706 46 55 46C50.0294 46 46 50.0294 46 55C46 59.9706 50.0294 64 55 64Z"
      />
      <path
        fill="white"
        fillOpacity={0.602}
        d="M55.2802 48.25V53.2394L59.4972 55.1237L55.2802 48.25Z"
      />
      <path fill="white" d="M55.2801 48.25L51.0625 55.1237L55.2801 53.2394V48.25Z" />
      <path
        fill="white"
        fillOpacity={0.602}
        d="M55.2802 58.3571V61.7473L59.5001 55.9091L55.2802 58.3571Z"
      />
      <path fill="white" d="M55.2801 61.7473V58.3565L51.0625 55.9091L55.2801 61.7473Z" />
      <path
        fill="white"
        fillOpacity={0.2}
        d="M55.2802 57.5723L59.4972 55.1237L55.2802 53.2405V57.5723Z"
      />
      <path
        fill="white"
        fillOpacity={0.602}
        d="M51.0625 55.1237L55.2801 57.5723V53.2405L51.0625 55.1237Z"
      />
    </g>
    <rect width={17} height={17} x={46.5} y={46.5} stroke="white" rx={8.5} />
    <defs>
      <clipPath id="clip0_3635_36464">
        <rect width={64} height={64} fill="white" />
      </clipPath>
      <clipPath id="clip1_3635_36464">
        <rect width={18} height={18} x={46} y={46} fill="white" rx={9} />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgUsdcErc20);
export default ForwardRef;
