import { CommonEvents } from './common';
import { DepositsEvents } from './deposit';
import { InvoicesEvents } from './invoice';
import { P2PEvents } from './p2p';
import { PaymentsEvents } from './payments';
import { WebhooksEvents } from './webhooks';
import { WithdrawalsEvents } from './withdrawals';

export const GlobalEvents = {
  ...CommonEvents,
  ...PaymentsEvents,
  ...InvoicesEvents,
  ...P2PEvents,
  ...DepositsEvents,
  ...WebhooksEvents,
  ...WithdrawalsEvents,
};

export * from './common';
export * from './deposit';
export * from './invoice';
export * from './p2p';
export * from './payments';
export * from './webhooks';
export * from './withdrawals';
