// @ts-nocheck
import { forwardRef } from 'react';
const SvgInvoice = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M13.5 9.7C13.5 9.51435 13.4368 9.3363 13.3243 9.20503C13.2117 9.07375 13.0591 9 12.9 9H8.1C7.94087 9 7.78826 9.07375 7.67574 9.20503C7.56321 9.3363 7.5 9.51435 7.5 9.7C7.5 9.88565 7.56321 10.0637 7.67574 10.195C7.78826 10.3262 7.94087 10.4 8.1 10.4H12.9C13.0591 10.4 13.2117 10.3262 13.3243 10.195C13.4368 10.0637 13.5 9.88565 13.5 9.7ZM12.7 12.5C12.7 12.3143 12.6368 12.1363 12.5243 12.005C12.4117 11.8738 12.2591 11.8 12.1 11.8H8.1C7.94087 11.8 7.78826 11.8738 7.67574 12.005C7.56321 12.1363 7.5 12.3143 7.5 12.5C7.5 12.6857 7.56321 12.8637 7.67574 12.995C7.78826 13.1262 7.94087 13.2 8.1 13.2H12.1C12.2591 13.2 12.4117 13.1262 12.5243 12.995C12.6368 12.8637 12.7 12.6857 12.7 12.5ZM12.9 14.6C13.0591 14.6 13.2117 14.6738 13.3243 14.805C13.4368 14.9363 13.5 15.1143 13.5 15.3C13.5 15.4857 13.4368 15.6637 13.3243 15.795C13.2117 15.9263 13.0591 16 12.9 16H8.1C7.94087 16 7.78826 15.9263 7.67574 15.795C7.56321 15.6637 7.5 15.4857 7.5 15.3C7.5 15.1143 7.56321 14.9363 7.67574 14.805C7.78826 14.6738 7.94087 14.6 8.1 14.6H12.9Z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.96154 21H18.9615C19.6348 21 20.2804 20.7381 20.7565 20.272C21.2326 19.8059 21.5 19.1736 21.5 18.5144V13.5433C21.5 13.3635 21.4271 13.191 21.2972 13.0639C21.1674 12.9368 20.9913 12.8654 20.8077 12.8654H17.8077V5.80902C17.8077 4.52285 16.3225 3.77446 15.2535 4.52194L15.092 4.63493C14.7383 4.8808 14.3151 5.01258 13.8813 5.01193C13.4475 5.01128 13.0247 4.87824 12.6717 4.63131C12.0824 4.22066 11.3771 4 10.6538 4C9.93057 4 9.22527 4.22066 8.636 4.63131C8.28303 4.87824 7.86023 5.01128 7.42644 5.01193C6.99264 5.01258 6.56943 4.8808 6.21569 4.63493L6.05415 4.52194C4.98523 3.77446 3.5 4.52194 3.5 5.80902V17.6106C3.5 18.5095 3.8647 19.3716 4.51386 20.0073C5.16303 20.6429 6.04348 21 6.96154 21ZM9.44092 5.734C9.79534 5.48766 10.2192 5.35532 10.6538 5.35532C11.0885 5.35532 11.5124 5.48766 11.8668 5.734C12.4545 6.14536 13.1587 6.36689 13.8811 6.3677C14.6035 6.36851 15.3082 6.14856 15.8969 5.73852L16.0585 5.62554C16.0929 5.60151 16.1335 5.58722 16.1757 5.58424C16.2179 5.58126 16.2602 5.5897 16.2978 5.60864C16.3354 5.62758 16.367 5.65629 16.3891 5.69163C16.4112 5.72698 16.423 5.76759 16.4231 5.80902V18.5144C16.4231 18.9211 16.5228 19.3053 16.7 19.6442H6.96154C6.4107 19.6442 5.88243 19.43 5.49293 19.0486C5.10343 18.6672 4.88462 18.1499 4.88462 17.6106V5.80902C4.88473 5.76759 4.89647 5.72698 4.91857 5.69163C4.94066 5.65629 4.97226 5.62758 5.0099 5.60864C5.04754 5.5897 5.08978 5.58126 5.13199 5.58424C5.1742 5.58722 5.21476 5.60151 5.24923 5.62554L5.41077 5.73852C5.99946 6.14856 6.70415 6.36851 7.42658 6.3677C8.14902 6.36689 8.8532 6.14536 9.44092 5.734ZM17.8077 18.5144V14.2211H20.1154V18.5144C20.1154 18.8141 19.9938 19.1014 19.7774 19.3133C19.561 19.5252 19.2676 19.6442 18.9615 19.6442C18.6555 19.6442 18.362 19.5252 18.1456 19.3133C17.9293 19.1014 17.8077 18.8141 17.8077 18.5144Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgInvoice);
export default ForwardRef;
