// @ts-nocheck
import { forwardRef } from 'react';
const SvgVideo = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23.4576 6.11084C23.7907 6.28224 24 6.62542 24 7V17C24 17.3746 23.7907 17.7178 23.4576 17.8892C23.1245 18.0606 22.7236 18.0315 22.4188 17.8137L15.4188 12.8137C15.156 12.626 15 12.323 15 12C15 11.6771 15.156 11.374 15.4188 11.1863L22.4188 6.18627C22.7236 5.96855 23.1245 5.93943 23.4576 6.11084ZM17.7205 12L22 15.0568V8.94319L17.7205 12Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 6C2.44772 6 2 6.44772 2 7V17C2 17.5523 2.44772 18 3 18H14C14.5523 18 15 17.5523 15 17V7C15 6.44772 14.5523 6 14 6H3ZM0 7C0 5.34315 1.34315 4 3 4H14C15.6569 4 17 5.34315 17 7V17C17 18.6569 15.6569 20 14 20H3C1.34315 20 0 18.6569 0 17V7Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgVideo);
export default ForwardRef;
