// @ts-nocheck
import { forwardRef } from 'react';
const SvgMusic = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.8787 14.8787C15.4413 14.3161 16.2043 14 17 14H21C21.5523 14 22 14.4477 22 15V17C22 17.7957 21.6839 18.5587 21.1213 19.1213C20.5587 19.6839 19.7957 20 19 20H17C16.2043 20 15.4413 19.6839 14.8787 19.1213C14.3161 18.5587 14 17.7957 14 17C14 16.2043 14.3161 15.4413 14.8787 14.8787ZM17 16C16.7348 16 16.4804 16.1054 16.2929 16.2929C16.1054 16.4804 16 16.7348 16 17C16 17.2652 16.1054 17.5196 16.2929 17.7071C16.4804 17.8946 16.7348 18 17 18H19C19.2652 18 19.5196 17.8946 19.7071 17.7071C19.8946 17.5196 20 17.2652 20 17V16H17ZM2.87868 16.8787C3.44129 16.3161 4.20435 16 5 16H9C9.55228 16 10 16.4477 10 17V19C10 19.7957 9.68393 20.5587 9.12132 21.1213C8.55871 21.6839 7.79565 22 7 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7957 2 19C2 18.2043 2.31607 17.4413 2.87868 16.8787ZM5 18C4.73478 18 4.48043 18.1054 4.29289 18.2929C4.10536 18.4804 4 18.7348 4 19C4 19.2652 4.10536 19.5196 4.29289 19.7071C4.48043 19.8946 4.73478 20 5 20H7C7.26522 20 7.51957 19.8946 7.70711 19.7071C7.89464 19.5196 8 19.2652 8 19V18H5Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.6464 2.23698C21.8707 2.42698 22 2.70605 22 3V15C22 15.5523 21.5523 16 21 16C20.4477 16 20 15.5523 20 15V4.18046L10 5.84713V17C10 17.5523 9.55228 18 9 18C8.44772 18 8 17.5523 8 17V5C8 4.51116 8.35341 4.09397 8.8356 4.01361L20.8356 2.01361C21.1256 1.96528 21.4221 2.04698 21.6464 2.23698Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgMusic);
export default ForwardRef;
