// @ts-nocheck
import { forwardRef } from 'react';
const SvgCast = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 5C3.73478 5 3.48043 5.10536 3.29289 5.29289C3.10536 5.48043 3 5.73478 3 6V8C3 8.55228 2.55228 9 2 9C1.44771 9 0.999999 8.55228 0.999999 8V6C0.999999 5.20435 1.31607 4.44129 1.87868 3.87868C2.44129 3.31607 3.20435 3 4 3H20C20.7956 3 21.5587 3.31607 22.1213 3.87868C22.6839 4.44129 23 5.20435 23 6V18C23 18.7957 22.6839 19.5587 22.1213 20.1213C21.5587 20.6839 20.7956 21 20 21H14C13.4477 21 13 20.5523 13 20C13 19.4477 13.4477 19 14 19H20C20.2652 19 20.5196 18.8946 20.7071 18.7071C20.8946 18.5196 21 18.2652 21 18V6C21 5.73478 20.8946 5.48043 20.7071 5.29289C20.5196 5.10536 20.2652 5 20 5H4ZM1.00613 11.9395C1.06718 11.3906 1.56164 10.9951 2.11054 11.0561C4.36697 11.3071 6.47084 12.3184 8.07622 13.9238C9.6816 15.5292 10.6929 17.633 10.9439 19.8895C11.0049 20.4384 10.6094 20.9328 10.0605 20.9939C9.51164 21.0549 9.01718 20.6594 8.95613 20.1105C8.75536 18.3054 7.94631 16.6223 6.66201 15.338C5.3777 14.0537 3.69461 13.2446 1.88946 13.0439C1.34056 12.9828 0.945078 12.4884 1.00613 11.9395ZM1.02017 15.9002C1.13053 15.359 1.65868 15.0098 2.19983 15.1202C3.3529 15.3553 4.41136 15.9244 5.24349 16.7565C6.07561 17.5886 6.64467 18.6471 6.87983 19.8002C6.99019 20.3413 6.64097 20.8695 6.09983 20.9798C5.55868 21.0902 5.03053 20.741 4.92017 20.1998C4.76339 19.4311 4.38403 18.7255 3.82927 18.1707C3.27452 17.616 2.56888 17.2366 1.80017 17.0798C1.25902 16.9695 0.909805 16.4413 1.02017 15.9002Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCast);
export default ForwardRef;
