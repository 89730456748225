import { ReactNode, useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { css } from 'styled-components';
import { Portal } from '@use-gateway/components';
import { styled } from '@use-gateway/theme';

const DropdownWrapper = styled.div`
  position: relative;
`;

interface DropdownTogglerProps {
  align?: 'left' | 'right';
}

const DropdownDrawer = styled.div<DropdownTogglerProps>`
  width: fit-content;
  min-width: 150px;

  padding: 8px;
  background: ${({ theme }) => theme.colors.light[100]};
  color: ${({ theme }) => theme.colors.dark[100]};
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.2);
  border-radius: 12px;

  ${({ align }) => {
    if (align === 'right') {
      return css`
        right: 0;
      `;
    }

    return css`
      left: 0;
    `;
  }}
`;

export const DropdownItem = styled.div`
  display: flex;
  padding: 12px 18px;
  border-radius: 12px;
  cursor: pointer;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;

  color: ${({ theme }) => theme.colors.dark[100]};

  &:hover {
    background-color: ${({ theme }) => theme.colors.light[80]};
  }
`;

const MODAL_ROOT_ID = 'modal-root';

export interface DropdownProps extends DropdownTogglerProps {
  toggler: ReactNode;
  children: ReactNode;
}

export function Dropdown({ toggler, children, align }: DropdownProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [popperEl, setPopperEl] = useState<HTMLElement | null>(null);
  const [arrowEl, setArrowEl] = useState<HTMLElement | null>(null);

  const { styles, attributes } = usePopper(anchorEl, popperEl, {
    placement: 'bottom',
    modifiers: [{ name: 'arrow', options: { element: arrowEl } }],
  });

  useEffect(() => {
    if (!anchorEl) return;

    function handleClickOutside(event: MouseEvent) {
      if (
        anchorEl &&
        popperEl &&
        !anchorEl.contains(event.target as Node) &&
        !popperEl.contains(event.target as Node)
      ) {
        setAnchorEl(null);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [anchorEl, popperEl]);

  return (
    <DropdownWrapper>
      <div onClick={(event) => setAnchorEl(event.currentTarget)}>{toggler}</div>

      {anchorEl && (
        <Portal targetId={MODAL_ROOT_ID}>
          <DropdownDrawer
            align={align}
            ref={setPopperEl}
            style={{ ...styles.popper }}
            {...attributes.popper}>
            {children}
            <div ref={setArrowEl} style={{ ...styles.arrow }} />
          </DropdownDrawer>
        </Portal>
      )}
    </DropdownWrapper>
  );
}
