// @ts-nocheck
import { forwardRef } from 'react';
const SvgFaq = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 4.63636C7.93318 4.63636 4.63636 7.93318 4.63636 12C4.63636 16.0668 7.93318 19.3636 12 19.3636C16.0668 19.3636 19.3636 16.0668 19.3636 12C19.3636 7.93318 16.0668 4.63636 12 4.63636ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.2112 8.74717C11.8303 8.68184 11.4386 8.75341 11.1054 8.94922C10.7723 9.14502 10.5191 9.45242 10.3909 9.81696C10.241 10.2432 9.77385 10.4672 9.34758 10.3173C8.92132 10.1673 8.69732 9.70021 8.84727 9.27395C9.10375 8.54486 9.60998 7.93006 10.2763 7.53846C10.9426 7.14685 11.7261 7.0037 12.4878 7.13436C13.2496 7.26502 13.9405 7.66106 14.4383 8.25234C14.9359 8.8435 15.2083 9.59167 15.2073 10.3644C15.207 11.24 14.7465 11.9092 14.2705 12.3653C13.7944 12.8215 13.2298 13.138 12.8312 13.329C12.7993 13.3442 12.7762 13.366 12.7634 13.3849C12.7574 13.3936 12.7548 13.4001 12.7537 13.4035C12.7527 13.4065 12.7527 13.4077 12.7527 13.4079V13.6364C12.7527 14.0882 12.3864 14.4546 11.9345 14.4546C11.4827 14.4546 11.1164 14.0882 11.1164 13.6364V13.4079C11.1164 12.6962 11.5652 12.121 12.1242 11.8532C12.4486 11.6978 12.8397 11.47 13.1385 11.1837C13.4373 10.8974 13.5709 10.6263 13.5709 10.3636V10.3624C13.5715 9.97598 13.4353 9.6018 13.1864 9.30616C12.9375 9.01052 12.5921 8.8125 12.2112 8.74717Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M12.8182 16.0909C12.8182 16.5428 12.4519 16.9091 12 16.9091C11.5481 16.9091 11.1818 16.5428 11.1818 16.0909C11.1818 15.639 11.5481 15.2727 12 15.2727C12.4519 15.2727 12.8182 15.639 12.8182 16.0909Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgFaq);
export default ForwardRef;
