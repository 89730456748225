// @ts-nocheck
import { forwardRef } from 'react';
const SvgToggle = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M15.9995 15.5C16.4889 15.4983 16.9726 15.3938 17.4191 15.1934C17.8656 14.9929 18.2651 14.7009 18.5916 14.3363C18.9182 13.9717 19.1645 13.5426 19.3147 13.0767C19.4649 12.6109 19.5156 12.1187 19.4635 11.632C19.3737 10.7747 18.9705 9.98086 18.3311 9.40279C17.6918 8.82471 16.8614 8.50321 15.9995 8.5C15.51 8.5017 15.0264 8.60616 14.5798 8.80662C14.1333 9.00708 13.7339 9.29907 13.4073 9.66368C13.0808 10.0283 12.8345 10.4574 12.6843 10.9233C12.5341 11.3891 12.4834 11.8813 12.5355 12.368C12.6252 13.2253 13.0285 14.0191 13.6678 14.5972C14.3072 15.1753 15.1375 15.4968 15.9995 15.5Z"
    />
    <rect
      width={18.8}
      height={10.8}
      x={2.6}
      y={6.6}
      stroke="currentColor"
      strokeWidth={1.2}
      rx={5.4}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgToggle);
export default ForwardRef;
