import { createContext, useContext, useMemo } from 'react';
import { Currency, RatesDto, WalletDto } from '@use-gateway/types';

interface WalletContextPayload {
  wallet: WalletDto;
  walletPending: boolean;
  walletRates?: RatesDto;
  setWallet: (value?: WalletDto) => void;
  fetchWallet: () => Promise<void>;
  setWalletRates: (value: RatesDto) => void;
}

const WalletContext = createContext<Partial<WalletContextPayload>>({});

export const WalletProvider = WalletContext.Provider;
export const useWallet = () => {
  const { wallet, walletPending, setWallet, walletRates, fetchWallet, setWalletRates } = useContext(
    WalletContext
  ) as WalletContextPayload;

  const clearWallet = () => setWallet(undefined);

  const totalLocal = useMemo(() => {
    if (!wallet) return 0;

    return Object.keys(wallet).reduce((acc, currency: Currency) => {
      return acc + (wallet[currency]?.local || 0);
    }, 0);
  }, [wallet]) as number;

  return {
    wallet,
    walletPending,
    totalLocal,
    walletRates,
    clearWallet,
    fetchWallet,
    setWalletRates,
  };
};
