// @ts-nocheck
import { forwardRef } from 'react';
const SvgMenu = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M5.50233 8H19.5M5.5 12.5H19.4942M5.50233 17H19.4942"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgMenu);
export default ForwardRef;
