// @ts-nocheck
import { forwardRef } from 'react';
const SvgCarretUp = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M12.723 9.24776L16.5494 13.4993C16.7231 13.6924 16.5861 14 16.3264 14L8.67361 14C8.41388 14 8.27687 13.6924 8.45062 13.4993L12.277 9.24776C12.3962 9.11535 12.6038 9.11535 12.723 9.24776Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCarretUp);
export default ForwardRef;
