// @ts-nocheck
import { forwardRef } from 'react';
const SvgUploadCloud = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_2128_24631)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.2929 11.2929C11.6834 10.9024 12.3166 10.9024 12.7071 11.2929L16.7071 15.2929C17.0976 15.6834 17.0976 16.3166 16.7071 16.7071C16.3166 17.0976 15.6834 17.0976 15.2929 16.7071L12 13.4142L8.70711 16.7071C8.31658 17.0976 7.68342 17.0976 7.29289 16.7071C6.90237 16.3166 6.90237 15.6834 7.29289 15.2929L11.2929 11.2929Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 11C12.5523 11 13 11.4477 13 12V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V12C11 11.4477 11.4477 11 12 11Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.6633 2.00857C10.0228 1.95849 11.3759 2.21724 12.621 2.76539C13.8661 3.31354 14.9707 4.13682 15.8519 5.17332C16.5633 6.01025 17.1141 6.96861 17.4791 8.00001H18C19.333 8.00089 20.6285 8.44567 21.6807 9.26415C22.7328 10.0826 23.4825 11.2282 23.8113 12.52C24.1401 13.8119 24.0294 15.1765 23.4965 16.3984C22.9637 17.6203 22.0391 18.6299 20.8687 19.268C20.3838 19.5324 19.7764 19.3536 19.512 18.8687C19.2476 18.3838 19.4264 17.7764 19.9113 17.512C20.6916 17.0866 21.308 16.4135 21.6632 15.5989C22.0185 14.7843 22.0923 13.8746 21.8731 13.0134C21.6539 12.1521 21.1541 11.3884 20.4527 10.8428C19.7512 10.2971 18.888 10.0006 17.9993 10H16.74C16.2841 10 15.8859 9.69169 15.7718 9.25032C15.507 8.22591 15.0133 7.27487 14.328 6.46869C13.6427 5.66252 12.7836 5.02219 11.8152 4.59585C10.8468 4.16952 9.79431 3.96826 8.73694 4.00722C7.67956 4.04618 6.64477 4.32433 5.71036 4.82078C4.77596 5.31722 3.96626 6.01904 3.34213 6.87346C2.718 7.72787 2.29569 8.71267 2.10694 9.75379C1.91819 10.7949 1.96793 11.8653 2.25239 12.8844C2.53686 13.9036 3.04867 14.845 3.74933 15.6378C4.11505 16.0517 4.07604 16.6836 3.66219 17.0493C3.24835 17.4151 2.61639 17.3761 2.25067 16.9622C1.34981 15.9428 0.691777 14.7324 0.326031 13.4221C-0.0397159 12.1118 -0.103656 10.7356 0.139018 9.39703C0.381692 8.05844 0.924665 6.79228 1.72712 5.69374C2.52957 4.5952 3.57061 3.69287 4.77199 3.05458C5.97337 2.41629 7.30381 2.05866 8.6633 2.00857Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.2929 11.2929C11.6834 10.9024 12.3166 10.9024 12.7071 11.2929L16.7071 15.2929C17.0976 15.6834 17.0976 16.3166 16.7071 16.7071C16.3166 17.0976 15.6834 17.0976 15.2929 16.7071L12 13.4142L8.70711 16.7071C8.31658 17.0976 7.68342 17.0976 7.29289 16.7071C6.90237 16.3166 6.90237 15.6834 7.29289 15.2929L11.2929 11.2929Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_2128_24631">
        <rect width={24} height={24} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgUploadCloud);
export default ForwardRef;
