// @ts-nocheck
import { forwardRef } from 'react';
const SvgXrpBep20 = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 64 64"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_3638_36494)">
      <path
        fill="#008CFF"
        d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z"
      />
      <g clipPath="url(#clip1_3638_36494)">
        <path
          fill="white"
          d="M45.4337 16H50.9258L39.4962 27.3169C35.357 31.413 28.6461 31.413 24.504 27.3169L13.0698 16H18.5665L27.2501 24.5968C28.5153 25.8437 30.2203 26.5427 31.9967 26.5427C33.7731 26.5427 35.4782 25.8437 36.7434 24.5968L45.4337 16Z"
        />
        <path
          fill="white"
          d="M18.4959 47.4688H13L24.5039 36.0821C28.6431 31.986 35.3539 31.986 39.4961 36.0821L51 47.4688H45.5078L36.75 38.8022C35.4848 37.5553 33.7797 36.8563 32.0033 36.8563C30.2269 36.8563 28.5219 37.5553 27.2567 38.8022L18.4959 47.4688Z"
        />
      </g>
      <g clipPath="url(#clip2_3638_36494)">
        <path
          fill="#F0B90B"
          fillRule="evenodd"
          d="M55 46C59.9709 46 64 50.0291 64 55C64 59.9709 59.9709 64 55 64C50.0291 64 46 59.9709 46 55C46 50.0291 50.0291 46 55 46Z"
          clipRule="evenodd"
        />
        <path
          fill="white"
          d="M50.9464 55L50.9529 57.3798L52.975 58.5697V59.963L49.7695 58.0829V54.3041L50.9464 55ZM50.9464 52.6202V54.007L49.7688 53.3103V51.9236L50.9464 51.2269L52.1299 51.9236L50.9464 52.6202ZM53.8195 51.9236L54.9972 51.2269L56.1806 51.9236L54.9972 52.6202L53.8195 51.9236Z"
        />
        <path
          fill="white"
          d="M51.7974 56.8865V55.4933L52.975 56.1899V57.5767L51.7974 56.8865ZM53.8195 59.0688L54.9971 59.7654L56.1805 59.0688V60.4555L54.9971 61.1522L53.8195 60.4555V59.0688ZM57.8695 51.9236L59.0471 51.2269L60.2305 51.9236V53.3103L59.0471 54.007V52.6202L57.8695 51.9236ZM59.0471 57.3798L59.0536 55L60.2313 54.3034V58.0822L57.0257 59.9623V58.569L59.0471 57.3798Z"
        />
        <path fill="white" d="M58.2025 56.8866L57.0249 57.5767V56.1899L58.2025 55.4933V56.8866Z" />
        <path
          fill="white"
          d="M58.2027 53.1134L58.2091 54.5067L56.1813 55.6966V58.0822L55.0036 58.7723L53.826 58.0822V55.6966L51.7981 54.5067V53.1134L52.9808 52.4168L54.9964 53.6124L57.0243 52.4168L58.2077 53.1134H58.2027ZM51.7974 50.7343L54.9971 48.8478L58.2027 50.7343L57.025 51.431L54.9971 50.2353L52.975 51.431L51.7974 50.7343Z"
        />
      </g>
      <rect width={17} height={17} x={46.5} y={46.5} stroke="white" rx={8.5} />
    </g>
    <defs>
      <clipPath id="clip0_3638_36494">
        <rect width={64} height={64} fill="white" />
      </clipPath>
      <clipPath id="clip1_3638_36494">
        <rect width={38} height={31.4688} fill="white" transform="translate(13 16)" />
      </clipPath>
      <clipPath id="clip2_3638_36494">
        <rect width={18} height={18} x={46} y={46} fill="white" rx={9} />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgXrpBep20);
export default ForwardRef;
