import { ReactNode } from 'react';
import { styled } from '@use-gateway/theme';

export type StatusColor = 'success' | 'warning' | 'error' | 'pending';

export interface StatusProps {
  status: StatusColor;
  children?: ReactNode;
  isColorText?: boolean;
  isNoText?: boolean;
}

export const StatusWrapper = styled.div<StatusProps>`
  position: relative;
  min-width: 0.6em;
  min-height: 0.6em;
  color: ${({ isColorText, status, theme }) => {
    if (isColorText && status === 'success') {
      return theme.colors.success[100];
    }
    if (isColorText && status === 'warning') {
      return theme.colors.warning[100];
    }
    if (isColorText && status === 'error') {
      return theme.colors.danger[100];
    }
    if (isColorText && status === 'pending') {
      return theme.colors.success[50];
    }
    return theme.colors.dark[100];
  }};

  &::before {
    content: '';
    display: ${({ isColorText }) => (isColorText ? 'none' : 'block')};
    position: absolute;
    left: ${({ isNoText }) => (isNoText ? '0' : '-1.2em')};
    top: 50%;
    transform: translateY(-50%);
    width: 0.6em;
    height: 0.6em;
    border-radius: 50%;

    background-color: ${({ status, theme }) => {
      if (status === 'success') {
        return theme.colors.success[100];
      }
      if (status === 'warning') {
        return theme.colors.warning[100];
      }
      if (status === 'error') {
        return theme.colors.danger[100];
      }
      if (status === 'pending') {
        return theme.colors.success[50];
      }
      return '#ccc';
    }};
  }
`;

export function Status({ children, status, isColorText }: StatusProps) {
  return (
    <StatusWrapper status={status} isColorText={isColorText} isNoText={!children}>
      <div>{children}</div>
    </StatusWrapper>
  );
}
