// @ts-nocheck
import { forwardRef } from 'react';
const SvgCloudOff = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_2128_24764)">
      <path
        fill="currentColor"
        d="M8.9 21C5.6 21 2.6 19.2 1 16.4C-0.2 14.3 -0.4 11.9 0.2 9.6C0.9 7.3 2.4 5.4 4.5 4.2C5 3.9 5.6 4 5.9 4.5C6.1 5 6 5.6 5.5 5.9C3.9 6.8 2.7 8.3 2.2 10.1C1.7 11.9 1.9 13.8 2.8 15.4C4 17.7 6.4 19.1 9 19H18C18.5 19 18.9 18.9 19.4 18.8C19.9 18.6 20.5 18.9 20.7 19.4C20.9 19.9 20.6 20.5 20.1 20.7C19.4 20.9 18.8 21.1 18.1 21.1H9C9 21 8.9 21 8.9 21ZM22.6 18C22.5 18 22.3 18 22.2 17.9C21.7 17.7 21.5 17.1 21.7 16.6C22.6 14.6 21.6 12.2 19.6 11.4C19.1 11.2 18.6 11.1 18 11.1H16.7C16.2 11.1 15.8 10.8 15.7 10.3C15 7.4 12.6 5.3 9.6 5C9 5 8.6 4.5 8.7 3.9C8.7 3.4 9.2 2.9 9.8 3C13.3 3.3 16.3 5.7 17.5 9H18C18.8 9 19.6 9.2 20.3 9.5C23.3 10.8 24.8 14.3 23.5 17.4C23.4 17.7 23 18 22.6 18Z"
      />
      <path
        fill="currentColor"
        d="M23 24C22.7 24 22.5 23.9 22.3 23.7L0.3 1.7C-0.1 1.3 -0.1 0.7 0.3 0.3C0.7 -0.1 1.3 -0.1 1.7 0.3L23.7 22.3C24.1 22.7 24.1 23.3 23.7 23.7C23.5 23.9 23.3 24 23 24Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_2128_24764">
        <rect width={24} height={24} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgCloudOff);
export default ForwardRef;
