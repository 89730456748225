// @ts-nocheck
import { forwardRef } from 'react';
const SvgP2P = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M12 12C13.0609 12 14.0783 11.5786 14.8284 10.8284C15.5786 10.0783 16 9.06087 16 8C16 6.93913 15.5786 5.92172 14.8284 5.17157C14.0783 4.42143 13.0609 4 12 4C10.9391 4 9.92172 4.42143 9.17157 5.17157C8.42143 5.92172 8 6.93913 8 8C8 9.06087 8.42143 10.0783 9.17157 10.8284C9.92172 11.5786 10.9391 12 12 12ZM14.6667 8C14.6667 8.70724 14.3857 9.38552 13.8856 9.88562C13.3855 10.3857 12.7072 10.6667 12 10.6667C11.2928 10.6667 10.6145 10.3857 10.1144 9.88562C9.61428 9.38552 9.33333 8.70724 9.33333 8C9.33333 7.29276 9.61428 6.61448 10.1144 6.11438C10.6145 5.61428 11.2928 5.33333 12 5.33333C12.7072 5.33333 13.3855 5.61428 13.8856 6.11438C14.3857 6.61448 14.6667 7.29276 14.6667 8ZM20 18.6667C20 20 18.6667 20 18.6667 20H5.33333C5.33333 20 4 20 4 18.6667C4 17.3333 5.33333 13.3333 12 13.3333C18.6667 13.3333 20 17.3333 20 18.6667ZM18.6667 18.6613C18.6653 18.3333 18.4613 17.3467 17.5573 16.4427C16.688 15.5733 15.052 14.6667 12 14.6667C8.94667 14.6667 7.312 15.5733 6.44267 16.4427C5.53867 17.3467 5.336 18.3333 5.33333 18.6613H18.6667Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgP2P);
export default ForwardRef;
