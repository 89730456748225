import { InvoiceStatus, Steps, TimelineItem } from '@use-gateway/types';

export function detectInvoiceStep(timeline: Array<TimelineItem>): Steps {
  if (!timeline) return Steps.Fetching;

  const lastEvent = Array.from(timeline).pop() as TimelineItem;
  const status = lastEvent.status as InvoiceStatus;
  const openLikeStatuses = [InvoiceStatus.Created, InvoiceStatus.Viewed, InvoiceStatus.Renewed];

  if (openLikeStatuses.includes(status)) return Steps.Open;
  if (status === InvoiceStatus.Paid) return Steps.Completed;
  if (status === InvoiceStatus.Expired) return Steps.Expired;
  if (status === InvoiceStatus.Resolved) return Steps.Resolved;

  return Steps.Unresolved;
}
