export enum FiatCurrency {
  USD = 'USD',
  EUR = 'EUR',
  RUB = 'RUB',
  UAH = 'UAH',
  KZT = 'KZT',
  AZN = 'AZN',
  TRY = 'TRY',
  KGS = 'KGS',
  BRL = 'BRL',
}

export enum CryptoCurrency {
  BTC = 'BTC',
  BCH = 'BCH',
  LTC = 'LTC',
  MATIC = 'MATIC',
  DOGE = 'DOGE',
  DASH = 'DASH',
  OPETH = 'OPETH',
  ETH = 'ETH',
  USDC_ERC20 = 'USDC_ERC20',
  USDT_ERC20 = 'USDT_ERC20',
  TRX = 'TRX',
  USDT_TRC20 = 'USDT_TRC20',
  USDC_TRC20 = 'USDC_TRC20',
  BNB = 'BNB',
  OPBNB = 'OPBNB',
  USDT_BEP20 = 'USDT_BEP20',
  USDC_BEP20 = 'USDC_BEP20',
  BUSD_BEP20 = 'BUSD_BEP20',
  DAI_BEP20 = 'DAI_BEP20',
  XRP_BEP20 = 'XRP_BEP20',
  TBCC_BEP20 = 'TBCC_BEP20',
}

export const StableCoinsList = [
  CryptoCurrency.USDT_TRC20,
  CryptoCurrency.USDC_TRC20,
  CryptoCurrency.USDT_BEP20,
  CryptoCurrency.BUSD_BEP20,
  CryptoCurrency.USDC_BEP20,
  CryptoCurrency.USDC_ERC20,
  CryptoCurrency.USDT_ERC20,
  CryptoCurrency.BUSD_BEP20,
  CryptoCurrency.DAI_BEP20,
];

export const BnbCoinsList = [
  CryptoCurrency.DAI_BEP20,
  CryptoCurrency.XRP_BEP20,
  CryptoCurrency.BUSD_BEP20,
  CryptoCurrency.USDC_BEP20,
  CryptoCurrency.USDT_BEP20,
  CryptoCurrency.TBCC_BEP20,
];

export const EthCoinsList = [CryptoCurrency.USDC_ERC20, CryptoCurrency.USDT_ERC20];

export const TrxCoinsList = [CryptoCurrency.USDT_TRC20, CryptoCurrency.USDC_TRC20];

export const OpBnbCoinsList = [CryptoCurrency.OPBNB];

export enum TestCoins {
  TBTC = 'TBTC',
}

export type Currency = FiatCurrency | CryptoCurrency | TestCoins;

export type TestNetworks = 'testnet';
export type NetworkName =
  | 'bitcoin'
  | 'bitcoincash'
  | 'litecoin'
  | 'polygon'
  | 'dogecoin'
  | 'dash'
  | 'optimism'
  | 'smartchain'
  | 'opsmartchain'
  | 'ethereum'
  | 'usdc_erc20'
  | 'usdt_erc20'
  | 'tron'
  | 'usdt_trc20'
  | 'usdc_trc20'
  | 'usdt_bep20'
  | 'usdc_bep20'
  | 'busd_bep20'
  | 'dai_bep20'
  | 'xrp_bep20'
  | 'tbcc_bep20'
  | TestNetworks;
export const NetworkNameMap: Record<CryptoCurrency | TestCoins, NetworkName> = {
  [CryptoCurrency.BTC]: 'bitcoin',
  [CryptoCurrency.BCH]: 'bitcoincash',
  [CryptoCurrency.LTC]: 'litecoin',
  [CryptoCurrency.MATIC]: 'polygon',
  [CryptoCurrency.DOGE]: 'dogecoin',
  [CryptoCurrency.DASH]: 'dash',
  [CryptoCurrency.OPETH]: 'optimism',
  [CryptoCurrency.ETH]: 'ethereum',
  [CryptoCurrency.USDC_ERC20]: 'usdc_erc20',
  [CryptoCurrency.USDT_ERC20]: 'usdt_erc20',
  [CryptoCurrency.TRX]: 'tron',
  [CryptoCurrency.USDT_TRC20]: 'usdt_trc20',
  [CryptoCurrency.USDC_TRC20]: 'usdc_trc20',
  [CryptoCurrency.BNB]: 'smartchain',
  [CryptoCurrency.OPBNB]: 'opsmartchain',
  [CryptoCurrency.USDT_BEP20]: 'usdt_bep20',
  [CryptoCurrency.USDC_BEP20]: 'usdc_bep20',
  [CryptoCurrency.BUSD_BEP20]: 'busd_bep20',
  [CryptoCurrency.DAI_BEP20]: 'dai_bep20',
  [CryptoCurrency.XRP_BEP20]: 'xrp_bep20',
  [CryptoCurrency.TBCC_BEP20]: 'tbcc_bep20',

  [TestCoins.TBTC]: 'testnet',
};

export const CurrencyNameMap: Record<CryptoCurrency | TestCoins, string> = {
  [CryptoCurrency.BTC]: 'Bitcoin',
  [CryptoCurrency.BCH]: 'Bitcoin Cash',
  [CryptoCurrency.LTC]: 'Litecoin',
  [CryptoCurrency.MATIC]: 'Polygon',
  [CryptoCurrency.DOGE]: 'Dogecoin',
  [CryptoCurrency.DASH]: 'Dashcoin',
  [CryptoCurrency.OPETH]: 'Ethereum <small>Optimism</small>',
  [CryptoCurrency.ETH]: 'Ethereum',
  [CryptoCurrency.USDC_ERC20]: 'USDC <small>ERC20</small>',
  [CryptoCurrency.USDT_ERC20]: 'USDT <small>ERC20</small>',
  [CryptoCurrency.TRX]: 'Tron',
  [CryptoCurrency.USDT_TRC20]: 'USDT <small>TRC20</small>',
  [CryptoCurrency.USDC_TRC20]: 'USDC <small>TRC20</small>',
  [CryptoCurrency.BNB]: 'BNB',
  [CryptoCurrency.OPBNB]: 'BNB <small>OpBNB</small>',
  [CryptoCurrency.USDT_BEP20]: 'USDT <small>BEP20</small>',
  [CryptoCurrency.USDC_BEP20]: 'USDC <small>BEP20</small>',
  [CryptoCurrency.DAI_BEP20]: 'DAI <small>BEP20</small>',
  [CryptoCurrency.BUSD_BEP20]: 'BUSD <small>BEP20</small>',
  [CryptoCurrency.XRP_BEP20]: 'Ripple <small>BEP20</small>',
  [CryptoCurrency.TBCC_BEP20]: 'TBCC <small>BEP20</small>',

  [TestCoins.TBTC]: 'Bitcoin Testnet',
};

export type PricingCurrency = 'local' | NetworkName;

export interface PricingItem {
  amount: number;
  currency: Currency;
}

export type Cryptocurrencies = Partial<Record<CryptoCurrency | TestCoins, boolean>>;
