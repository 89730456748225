// @ts-nocheck
import { forwardRef } from 'react';
const SvgLoader = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 1C12.5523 1 13 1.44772 13 2V6C13 6.55228 12.5523 7 12 7C11.4477 7 11 6.55228 11 6V2C11 1.44772 11.4477 1 12 1Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 17C12.5523 17 13 17.4477 13 18V22C13 22.5523 12.5523 23 12 23C11.4477 23 11 22.5523 11 22V18C11 17.4477 11.4477 17 12 17Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.22289 4.22289C4.61341 3.83236 5.24658 3.83236 5.6371 4.22289L8.4671 7.05289C8.85762 7.44341 8.85762 8.07658 8.4671 8.4671C8.07658 8.85762 7.44341 8.85762 7.05289 8.4671L4.22289 5.6371C3.83236 5.24658 3.83236 4.61341 4.22289 4.22289Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.5329 15.5329C15.9234 15.1424 16.5566 15.1424 16.9471 15.5329L19.7771 18.3629C20.1676 18.7534 20.1676 19.3866 19.7771 19.7771C19.3866 20.1676 18.7534 20.1676 18.3629 19.7771L15.5329 16.9471C15.1424 16.5566 15.1424 15.9234 15.5329 15.5329Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1 12C1 11.4477 1.44772 11 2 11H6C6.55228 11 7 11.4477 7 12C7 12.5523 6.55228 13 6 13H2C1.44772 13 1 12.5523 1 12Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17 12C17 11.4477 17.4477 11 18 11H22C22.5523 11 23 11.4477 23 12C23 12.5523 22.5523 13 22 13H18C17.4477 13 17 12.5523 17 12Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.4671 15.5329C8.85762 15.9234 8.85762 16.5566 8.4671 16.9471L5.6371 19.7771C5.24658 20.1676 4.61341 20.1676 4.22289 19.7771C3.83236 19.3866 3.83236 18.7534 4.22289 18.3629L7.05289 15.5329C7.44341 15.1424 8.07658 15.1424 8.4671 15.5329Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.7771 4.22289C20.1676 4.61341 20.1676 5.24658 19.7771 5.6371L16.9471 8.4671C16.5566 8.85762 15.9234 8.85762 15.5329 8.4671C15.1424 8.07658 15.1424 7.44341 15.5329 7.05289L18.3629 4.22289C18.7534 3.83236 19.3866 3.83236 19.7771 4.22289Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLoader);
export default ForwardRef;
