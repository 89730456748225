// @ts-nocheck
import { forwardRef } from 'react';
const SvgWifiOff = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_2128_24792)">
      <path
        fill="currentColor"
        d="M23 24C22.7 24 22.5 23.9 22.3 23.7L0.3 1.7C-0.1 1.3 -0.1 0.7 0.3 0.3C0.7 -0.1 1.3 -0.1 1.7 0.3L23.7 22.3C24.1 22.7 24.1 23.3 23.7 23.7C23.5 23.9 23.3 24 23 24Z"
      />
      <path
        fill="currentColor"
        d="M19 13.5C18.8 13.5 18.5 13.4 18.4 13.3C17.8 12.8 17.1 12.3 16.3 11.9C15.8 11.7 15.6 11.1 15.8 10.6C16 10.1 16.6 9.9 17.1 10.1C18 10.5 18.8 11.1 19.6 11.7C20 12.1 20.1 12.7 19.7 13.1C19.6 13.4 19.3 13.5 19 13.5Z"
      />
      <path
        fill="currentColor"
        d="M5 13.5C4.7 13.5 4.4 13.4 4.2 13.1C3.8 12.7 3.9 12 4.3 11.7C6 10.4 7.9 9.5 10 9.2C10.5 9.1 11.1 9.5 11.2 10C11.3 10.5 10.9 11.1 10.4 11.2C8.7 11.5 7 12.2 5.7 13.4C5.5 13.5 5.2 13.5 5 13.5Z"
      />
      <path
        fill="currentColor"
        d="M22.6 10C22.4 10 22.1 9.9 21.9 9.7C18.9 7 14.8 5.7 10.8 6C10.2 6 9.8 5.6 9.7 5.1C9.7 4.5 10.1 4.1 10.6 4C15.2 3.6 19.8 5.2 23.2 8.2C23.6 8.6 23.7 9.2 23.3 9.6C23.1 9.9 22.9 10 22.6 10Z"
      />
      <path
        fill="currentColor"
        d="M1.4 10C1.1 10 0.8 9.9 0.6 9.7C0.2 9.3 0.3 8.7 0.7 8.3C2.2 7 3.9 6 5.7 5.2C6.2 5 6.8 5.2 7 5.8C7.2 6.3 7 6.9 6.4 7.1C4.9 7.7 3.4 8.6 2.1 9.7C1.9 9.9 1.7 10 1.4 10Z"
      />
      <path
        fill="currentColor"
        d="M15.5 17.1C15.3 17.1 15.1 17 14.9 16.9C13.2 15.7 10.8 15.7 9.1 16.9C8.6 17.2 8 17.1 7.7 16.7C7.4 16.2 7.5 15.6 7.9 15.3C10.3 13.6 13.6 13.6 16 15.3C16.5 15.6 16.6 16.2 16.2 16.7C16.1 17 15.8 17.1 15.5 17.1Z"
      />
      <path
        fill="currentColor"
        d="M12 21C11.9 21 11.7 21 11.6 20.9C11.5 20.9 11.4 20.8 11.3 20.7C11.1 20.5 11 20.3 11 20C11 19.9 11 19.7 11.1 19.6C11.2 19.5 11.2 19.4 11.3 19.3C11.7 18.9 12.3 18.9 12.7 19.3C12.9 19.5 13 19.8 13 20C13 20.3 12.9 20.5 12.7 20.7C12.5 20.9 12.3 21 12 21Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_2128_24792">
        <rect width={24} height={24} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgWifiOff);
export default ForwardRef;
