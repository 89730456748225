import { down } from 'styled-breakpoints';
import { Breakpoints, styled } from '@use-gateway/theme';

export const Scroll = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 4px;
  margin-right: -8px;
  padding-right: 8px;
  max-height: 400px;
  width: calc(100% - 8px);

  ::-webkit-scrollbar {
    width: 16px;
  }

  ::-webkit-scrollbar-track {
    border: 6px #fff solid;
    border-radius: 16px;
    background-color: #fff;
  }

  ::-webkit-scrollbar-thumb {
    border: 6px #fff solid;
    border-radius: 16px;
    background-color: ${({ theme }) => theme.colors.light[80]};
  }

  ${down(Breakpoints.sm)} {
    width: calc(100% - 1px);
    margin-right: -4px;
    padding-right: 4px;
    max-height: 100%;
  }

  @media screen and (max-width: 360px) {
    overflow-x: auto;
  }
`;
