// @ts-nocheck
import { forwardRef } from 'react';
const SvgOpeth = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 64 64"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_2006_431)">
      <mask
        id="mask0_2006_431"
        width={64}
        height={64}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: 'luminance',
        }}>
        <path fill="white" d="M64 0H0V64H64V0Z" />
      </mask>
      <g mask="url(#mask0_2006_431)">
        <path
          fill="#627EEA"
          d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z"
        />
        <path fill="white" fillOpacity={0.602} d="M32.9961 8V25.7401L47.99 32.44L32.9961 8Z" />
        <path fill="white" d="M32.9959 8L18 32.44L32.9959 25.7401V8Z" />
        <path
          fill="white"
          fillOpacity={0.602}
          d="M32.9961 43.9362V55.9902L48.0002 35.2322L32.9961 43.9362Z"
        />
        <path fill="white" d="M32.9959 55.9902V43.9342L18 35.2322L32.9959 55.9902Z" />
        <path
          fill="white"
          fillOpacity={0.2}
          d="M32.9961 41.146L47.99 32.44L32.9961 25.744V41.146Z"
        />
        <path fill="white" fillOpacity={0.602} d="M18 32.44L32.9959 41.146V25.744L18 32.44Z" />
      </g>
      <g clipPath="url(#clip1_2006_431)">
        <mask
          id="mask1_2006_431"
          width={18}
          height={18}
          x={46}
          y={46}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: 'luminance',
          }}>
          <path fill="white" d="M64 46H46V64H64V46Z" />
        </mask>
        <g mask="url(#mask1_2006_431)">
          <path
            fill="#FF0420"
            d="M55 64C59.9706 64 64 59.9706 64 55C64 50.0294 59.9706 46 55 46C50.0294 46 46 50.0294 46 55C46 59.9706 50.0294 64 55 64Z"
          />
          <path
            fill="white"
            d="M52.3756 57.3904C51.8392 57.3904 51.4 57.2644 51.058 57.0124C50.7196 56.7568 50.5504 56.3896 50.5504 55.918C50.5504 55.8172 50.5612 55.6984 50.5828 55.5544C50.6404 55.2304 50.7232 54.8416 50.8312 54.3844C51.1372 53.146 51.9292 52.5268 53.2036 52.5268C53.5492 52.5268 53.8624 52.5844 54.136 52.7032C54.4096 52.8148 54.6256 52.9876 54.784 53.218C54.9424 53.4448 55.0216 53.7148 55.0216 54.028C55.0216 54.1216 55.0108 54.2404 54.9892 54.3844C54.9208 54.784 54.8416 55.1764 54.7444 55.5544C54.586 56.17 54.316 56.6344 53.9272 56.9404C53.542 57.2428 53.0236 57.3904 52.3756 57.3904ZM52.4728 56.4184C52.7248 56.4184 52.9372 56.3428 53.1136 56.1952C53.2936 56.0476 53.4232 55.8208 53.4988 55.5112C53.6032 55.0864 53.6824 54.7192 53.7364 54.4024C53.7544 54.3088 53.7652 54.2116 53.7652 54.1108C53.7652 53.7004 53.5528 53.4952 53.1244 53.4952C52.8724 53.4952 52.6564 53.5708 52.4764 53.7184C52.3 53.866 52.174 54.0928 52.0984 54.4024C52.0156 54.7048 51.9364 55.072 51.8536 55.5112C51.8356 55.6012 51.8248 55.6948 51.8248 55.7956C51.8212 56.2132 52.0408 56.4184 52.4728 56.4184Z"
          />
          <path
            fill="white"
            d="M55.3348 57.3256C55.2844 57.3256 55.2484 57.3112 55.2196 57.2788C55.198 57.2428 55.1908 57.2032 55.198 57.1564L56.1304 52.7644C56.1376 52.714 56.1628 52.6744 56.206 52.642C56.2456 52.6096 56.2888 52.5952 56.3356 52.5952H58.132C58.6324 52.5952 59.032 52.6996 59.3344 52.9048C59.6404 53.1136 59.7952 53.4124 59.7952 53.8048C59.7952 53.9164 59.7808 54.0352 59.7556 54.1576C59.644 54.676 59.4172 55.0576 59.0716 55.306C58.7332 55.5544 58.2688 55.6768 57.6784 55.6768H56.7676L56.458 57.1564C56.4472 57.2068 56.4256 57.2464 56.3824 57.2788C56.3428 57.3112 56.2996 57.3256 56.2528 57.3256H55.3348ZM57.7252 54.7444C57.916 54.7444 58.078 54.694 58.2184 54.5896C58.3624 54.4852 58.456 54.3376 58.5028 54.1432C58.5172 54.0676 58.5244 53.9992 58.5244 53.9416C58.5244 53.812 58.4848 53.7112 58.4092 53.6428C58.3336 53.5708 58.2004 53.5348 58.0168 53.5348H57.2068L56.9512 54.7444H57.7252Z"
          />
        </g>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2006_431">
        <rect width={64} height={64} fill="white" />
      </clipPath>
      <clipPath id="clip1_2006_431">
        <rect width={18} height={18} fill="white" transform="translate(46 46)" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgOpeth);
export default ForwardRef;
