import { useMemo } from 'react';
import { Currency, CurrencyNameMap, WalletDto } from '@use-gateway/types';

export interface AvailableCurrenciesOptions {
  mustHaveBalance?: boolean;
}
export function useAvailableCurrencies(
  wallet?: WalletDto,
  options: AvailableCurrenciesOptions = {}
) {
  const currencies = useMemo(() => {
    const available = Object.keys(wallet || {}).filter((currency) => {
      const turnedOn = wallet?.[currency];
      const hasBalance = wallet?.[currency]?.value > 0 || !options?.mustHaveBalance;

      return turnedOn && hasBalance;
    });
    const result: Partial<Record<Currency, string>> = {};

    available.forEach((currency) => {
      result[currency] = CurrencyNameMap[currency];
    });

    return result;
  }, []);

  return currencies;
}
