// @ts-nocheck
import { forwardRef } from 'react';
const SvgProfilePopover = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.0771 5.24408C10.4025 4.91864 10.9302 4.91864 11.2556 5.24408L15.4223 9.41074C15.7477 9.73618 15.7477 10.2638 15.4223 10.5893L11.2556 14.7559C10.9302 15.0814 10.4025 15.0814 10.0771 14.7559C9.75165 14.4305 9.75165 13.9028 10.0771 13.5774L13.6545 10L10.0771 6.42259C9.75165 6.09715 9.75165 5.56951 10.0771 5.24408Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 9.99984C4 9.5396 4.3731 9.1665 4.83333 9.1665H14.8333C15.2936 9.1665 15.6667 9.5396 15.6667 9.99984C15.6667 10.4601 15.2936 10.8332 14.8333 10.8332H4.83333C4.3731 10.8332 4 10.4601 4 9.99984Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgProfilePopover);
export default ForwardRef;
