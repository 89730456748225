import { ChangeEvent, ChangeEventHandler, ReactNode, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { uid } from 'uid';
import { styled } from '@use-gateway/theme';
import { styledTransition } from '@use-gateway/utils';

export const CheckboxWrapper = styled.div`
  position: relative;
  min-width: 20px;
  min-height: 20px;
  padding: 2px;

  input {
    position: absolute;
    opacity: 0;
  }
`;
export const CheckboxLabel = styled.label`
  padding-left: 32px;
  display: block;

  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  cursor: pointer;

  input:focus + &::before {
    outline-color: ${({ theme }) => theme.colors.primary};
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    border-radius: ${({ theme }) => theme.borderRadius.xs};
    outline: 2px solid;
    outline-color: transparent;

    ${styledTransition(['background-color', 'transform', 'opacity', 'outline-color'], 250)}
  }

  &::before {
    background-color: ${({ theme }) => theme.colors.light[80]};
  }

  &::after {
    transform: scale(0);
    opacity: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.75008 14.6667L3.66675 9.66675L5.50008 7.83341L8.75008 11.1667L14.9167 4.91675L16.7501 6.75008L8.75008 14.6667Z' fill='%23F6F8F9'/%3E%3C/svg%3E%0A");
  }

  ${CheckboxWrapper}:hover &::before {
    background-color: ${({ theme }) => theme.colors.light[70]};
  }

  input:checked + &::before {
    background-color: ${({ theme }) => theme.colors.primary} !important;
  }

  input:checked + &::after {
    transform: scale(1);
    opacity: 1;
  }
`;

export interface CheckboxProps {
  value?: boolean;
  children?: ReactNode;
  error?: boolean;
  onChange?: (value: boolean, e: ChangeEvent<HTMLInputElement>) => void;
  isCheckValue?: boolean;
}
export function Checkbox({ children, value, onChange, isCheckValue }: CheckboxProps) {
  const id = useMemo(() => uid(), []);
  const [innerValue, setInnerValue] = useState(value ?? false);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setInnerValue(e.currentTarget.checked);
    if (onChange) onChange(e.currentTarget.checked, e);
  };

  useEffect(() => {
    if (isCheckValue && typeof value === 'boolean') {
      setInnerValue(value);
    }
  }, [value]);

  return (
    <CheckboxWrapper>
      <input type={'checkbox'} checked={innerValue} id={id} onChange={handleChange} />
      <CheckboxLabel htmlFor={id}>{children}</CheckboxLabel>
    </CheckboxWrapper>
  );
}

export interface ControlledCheckboxProps {
  name: string;
  children?: ReactNode;
}
export function ControlledCheckbox({ name, children }: ControlledCheckboxProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <Checkbox onChange={onChange} error={!!error} value={value}>
            {children}
          </Checkbox>
        );
      }}
    />
  );
}
