import React, { useState, useEffect, Children, ReactElement } from 'react';
import { default as NextLink } from 'next/link';
import { useRouter } from 'next/router';
import escapeRegExp from 'lodash-es/escapeRegExp';

export interface LinkProps {
  children: ReactElement;
  as?: string;
  href: string;
}
export function Link({ children, ...props }: LinkProps) {
  const { asPath, isReady } = useRouter();

  const child = Children.only(children) as ReactElement;
  const childClassName = child.props.className || '';
  const [className, setClassName] = useState(childClassName);

  useEffect(() => {
    // Check if the router fields are updated client-side
    if (isReady) {
      // Dynamic route will be matched via props.as
      // Static route will be matched via props.href
      const linkPathname = new URL(props.as || props.href, location.href).pathname;

      // Using URL().pathname to get rid of query and hash
      const activePathname = new URL(asPath, location.href).pathname;

      const newClassName = new RegExp(`^${escapeRegExp(linkPathname)}`).test(activePathname)
        ? `${childClassName} active`.trim()
        : childClassName;

      if (newClassName !== className) {
        setClassName(newClassName);
      }
    }
  }, [asPath, isReady, props.href, props.as, childClassName, setClassName, className]);

  return (
    <NextLink {...props}>
      {React.cloneElement(child, {
        className: className || null,
      })}
    </NextLink>
  );
}
