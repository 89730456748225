import { CreatePaymentPayload, Paginated, PaymentResponseDto } from '@use-gateway/types';
import { Api } from '..';

const basePath = '/payments';

export async function getPayments(this: Api, page = 1, size = 25) {
  return this.get<Paginated<PaymentResponseDto>>(`${basePath}/?page=${page}&size=${size}`);
}

export async function getPayment(this: Api, id: string) {
  return this.get<PaymentResponseDto>(`${basePath}/${id}/`);
}

export async function createPayment(this: Api, payload: CreatePaymentPayload) {
  return this.post<PaymentResponseDto>(`${basePath}/`, payload);
}

export async function resolvePayment(this: Api, id: string) {
  return this.patch(`${basePath}/${id}/resolve`);
}
