// @ts-nocheck
import { forwardRef } from 'react';
const SvgEmail2 = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 25 25"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M19.7 5.00391H5.3C4.31 5.00391 3.509 5.79141 3.509 6.75391L3.5 17.2539C3.5 18.2164 4.31 19.0039 5.3 19.0039H19.7C20.69 19.0039 21.5 18.2164 21.5 17.2539V6.75391C21.5 5.79141 20.69 5.00391 19.7 5.00391ZM19.7 8.50391L12.5 12.8789L5.3 8.50391V6.75391L12.5 11.1289L19.7 6.75391V8.50391Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgEmail2);
export default ForwardRef;
