// @ts-nocheck
import { forwardRef } from 'react';
const SvgTwitter = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M8.34013 19.0176C14.7555 19.0176 18.2649 13.63 18.2649 8.96611C18.2649 8.81535 18.2649 8.66244 18.2586 8.51168C18.9418 8.01038 19.5315 7.38975 20 6.67885C19.3618 6.96406 18.6854 7.15203 17.9929 7.23667C18.7224 6.79476 19.2687 6.09946 19.5304 5.28C18.845 5.6915 18.095 5.98037 17.3129 6.13396C16.7872 5.56647 16.0915 5.19053 15.3336 5.06437C14.5757 4.9382 13.7979 5.06885 13.1207 5.43608C12.4435 5.80331 11.9046 6.38662 11.5876 7.09566C11.2706 7.80471 11.1931 8.59991 11.3672 9.35809C9.98035 9.28761 8.62362 8.92247 7.38498 8.28633C6.14635 7.6502 5.05348 6.75729 4.17725 5.66552C3.73242 6.44418 3.59667 7.36525 3.79756 8.24167C3.99845 9.11809 4.52092 9.88417 5.25887 10.3843C4.70589 10.3653 4.16505 10.2148 3.68 9.94498V9.99344C3.68095 10.8091 3.95976 11.5995 4.4693 12.2309C4.97884 12.8623 5.68786 13.2961 6.4765 13.4588C6.17715 13.5424 5.86793 13.584 5.55744 13.5826C5.33855 13.5833 5.12009 13.5628 4.90506 13.5213C5.12796 14.2234 5.56198 14.8374 6.14632 15.2771C6.73067 15.7168 7.43608 15.9602 8.16375 15.9733C6.92759 16.9573 5.40061 17.491 3.82875 17.4884C3.55178 17.4896 3.275 17.4734 3 17.44C4.59536 18.4708 6.44813 19.0182 8.34013 19.0176Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgTwitter);
export default ForwardRef;
