// @ts-nocheck
import { forwardRef } from 'react';
const SvgSkipBack = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.4332 3.09869C19.7797 3.26521 20 3.61559 20 3.99999V20C20 20.3844 19.7797 20.7348 19.4332 20.9013C19.0867 21.0678 18.6755 21.021 18.3753 20.7809L8.3753 12.7809C8.13809 12.5911 8 12.3038 8 12C8 11.6962 8.13809 11.4089 8.3753 11.2191L18.3753 3.21913C18.6755 2.97899 19.0867 2.93217 19.4332 3.09869ZM10.6008 12L18 17.9194V6.08062L10.6008 12Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5 4C5.55228 4 6 4.44772 6 5V19C6 19.5523 5.55228 20 5 20C4.44772 20 4 19.5523 4 19V5C4 4.44772 4.44772 4 5 4Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSkipBack);
export default ForwardRef;
