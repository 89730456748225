import React, { forwardRef, RefObject, useState } from 'react';
import { useFormContext, Controller, FieldError } from 'react-hook-form';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { Breakpoints } from '@use-gateway/theme';

interface TextareaWrapperProps {
  isError?: boolean;
}

const TextareaWrapper = styled.div<TextareaWrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;

  textarea {
    box-sizing: border-box;
    resize: none;
    outline: none;
    width: 100%;
    height: 300px;
    max-height: 100%;
    padding: 16px;
    border: ${({ isError, theme }) => (isError ? `2px solid ${theme.colors.danger[100]}` : 'none')};
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colors.light[80]};
    color: ${({ theme }) => theme.colors.dark[100]};
    font-size: 16px;
  }

  ${down(Breakpoints.sm)} {
    height: 200px;
  }
`;

const TextareaTitle = styled.label`
  margin-bottom: 8px;
  padding: 0 18px;
  letter-spacing: -0.01em;
`;

const TextareaPastingDisabled = styled.div`
  position: absolute;
  bottom: 24px;
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: #aaaaaa;
  letter-spacing: -0.02em;
  pointer-events: none;
`;

const TextareaError = styled.small`
  display: block;
  padding: 4px 0 0;
  color: ${({ theme }) => theme.colors.danger[100]};

  &:first-letter {
    text-transform: uppercase;
  }
`;

interface TextareaProps {
  label?: string;
  defaultValue?: string;
  disabled?: boolean;
  pastingDisabled?: boolean;
  onChange?: (
    value: string,
    originalEvent: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  error?: string | Error | FieldError;
}
export const Textarea = forwardRef(
  (
    { label, defaultValue, disabled, onChange, pastingDisabled, error }: TextareaProps,
    ref: RefObject<HTMLTextAreaElement>
  ) => {
    const [innerValue, setInnerValue] = useState(defaultValue ?? '');

    const handleTextarea: React.FormEventHandler<HTMLTextAreaElement> = (e) => {
      setInnerValue(e.currentTarget.value);
      if (onChange) onChange(e.currentTarget.value, e);
    };

    const handlePaste: React.FormEventHandler<HTMLTextAreaElement> = (e) => {
      if (pastingDisabled) {
        e.preventDefault();
      }
    };

    return (
      <TextareaWrapper isError={error ? true : false}>
        {label && <TextareaTitle>{label}</TextareaTitle>}
        <textarea
          value={innerValue}
          onChange={handleTextarea}
          onPaste={handlePaste}
          disabled={disabled}
          ref={ref}
        />
        {pastingDisabled && <TextareaPastingDisabled>Pasting is disabled!</TextareaPastingDisabled>}
        {error && (
          <TextareaError>{typeof error === 'string' ? error : error?.message}</TextareaError>
        )}
      </TextareaWrapper>
    );
  }
);

interface ControlledTextareaProps extends TextareaProps {
  name: string;
}

export function ControlledTextarea({ name, defaultValue, label }: ControlledTextareaProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value, ref }, fieldState: { error: fieldError } }) => (
        <Textarea
          label={label}
          defaultValue={value}
          onChange={(value) => onChange(value)}
          error={fieldError}
          ref={ref}
        />
      )}
    />
  );
}
