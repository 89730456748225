/* eslint-disable @typescript-eslint/ban-ts-comment */
import { RefObject, useEffect, useRef } from 'react';

let wasLoaded = false;

export function TrustBox() {
  const scriptContainer = useRef() as RefObject<HTMLDivElement>;

  useEffect(() => {
    if (wasLoaded) {
      // @ts-ignore
      window.Trustpilot?.Modules.WidgetManagement.findAndApplyWidgets();

      return;
    }

    const timeout = setTimeout(() => {
      const $script = document.createElement('script');

      $script.async = true;
      $script.setAttribute(
        'src',
        'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
      );

      scriptContainer.current?.appendChild($script);

      wasLoaded = true;
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <div
        className="trustpilot-widget"
        data-locale="en-US"
        data-template-id="5419b6a8b0d04a076446a9ad"
        data-businessunit-id="639b29b6952abbd5fc8e751e"
        data-style-height="30px"
        data-style-width="234px"
        data-theme="light"
        data-min-review-count="10"
        data-style-alignment="center">
        <a
          href="https://www.trustpilot.com/review/usegateway.net"
          target="_blank"
          rel="noopener noreferrer">
          Trustpilot
        </a>
      </div>

      {!wasLoaded && <div ref={scriptContainer} />}
    </>
  );
}
