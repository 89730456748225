// @ts-nocheck
import { forwardRef } from 'react';
const SvgQuestion = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 22 22"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2ZM0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.2581 7.02432C10.7926 6.94447 10.3138 7.03195 9.90664 7.27127C9.49944 7.51058 9.19007 7.88629 9.03333 8.33184C8.85006 8.85283 8.27915 9.12661 7.75816 8.94333C7.23717 8.76006 6.96339 8.18915 7.14667 7.66816C7.46014 6.77705 8.07887 6.02563 8.89327 5.547C9.70767 5.06837 10.6652 4.89341 11.5962 5.05311C12.5273 5.2128 13.3718 5.69686 13.9801 6.41953C14.5883 7.14205 14.9213 8.05648 14.92 9.00091C14.9197 10.0711 14.3569 10.889 13.7751 11.4464C13.1931 12.004 12.5031 12.3909 12.0159 12.6243C11.977 12.6429 11.9487 12.6696 11.933 12.6926C11.9257 12.7033 11.9225 12.7113 11.9211 12.7154C11.92 12.719 11.92 12.7205 11.92 12.7207V13C11.92 13.5523 11.4723 14 10.92 14C10.3677 14 9.92 13.5523 9.92 13V12.7208C9.92 11.8509 10.4686 11.1479 11.1518 10.8206C11.5483 10.6307 12.0263 10.3522 12.3915 10.0023C12.7567 9.65234 12.92 9.32103 12.92 9L12.92 8.99851C12.9207 8.52619 12.7542 8.06887 12.4501 7.70753C12.1459 7.34619 11.7236 7.10417 11.2581 7.02432Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M12 16C12 16.5523 11.5523 17 11 17C10.4477 17 10 16.5523 10 16C10 15.4477 10.4477 15 11 15C11.5523 15 12 15.4477 12 16Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgQuestion);
export default ForwardRef;
