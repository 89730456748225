// @ts-nocheck
import { forwardRef } from 'react';
const SvgPaperclip = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.78 2.38778C14.9838 2.38778 14.2201 2.70408 13.6571 3.26711L4.46711 12.4571C3.5288 13.3954 3.00166 14.668 3.00166 15.995C3.00166 17.322 3.5288 18.5946 4.46711 19.5329C5.40541 20.4712 6.67803 20.9983 8.005 20.9983C9.33197 20.9983 10.6046 20.4712 11.5429 19.5329L20.7329 10.3429C21.1234 9.95237 21.7566 9.95237 22.1471 10.3429C22.5376 10.7334 22.5376 11.3666 22.1471 11.7571L12.9571 20.9471C11.6437 22.2605 9.8624 22.9983 8.005 22.9983C6.1476 22.9983 4.36627 22.2605 3.05289 20.9471C1.73951 19.6337 1.00166 17.8524 1.00166 15.995C1.00166 14.1376 1.73951 12.3563 3.05289 11.0429L12.2429 1.8529C13.181 0.914798 14.4533 0.387779 15.78 0.387779C17.1067 0.387779 18.379 0.914797 19.3171 1.8529C20.2552 2.791 20.7822 4.06333 20.7822 5.39C20.7822 6.71668 20.2552 7.98901 19.3171 8.92711L10.1171 18.1171C9.55429 18.6799 8.79095 18.9961 7.995 18.9961C7.19905 18.9961 6.43571 18.6799 5.87289 18.1171C5.31008 17.5543 4.99389 16.7909 4.99389 15.995C4.99389 15.1991 5.31008 14.4357 5.87289 13.8729L14.3633 5.39248C14.7541 5.00219 15.3872 5.00256 15.7775 5.39331C16.1678 5.78407 16.1674 6.41723 15.7767 6.80753L7.28711 15.2871C7.09962 15.4748 6.99389 15.7297 6.99389 15.995C6.99389 16.2605 7.09936 16.5152 7.28711 16.7029C7.47485 16.8906 7.72949 16.9961 7.995 16.9961C8.26051 16.9961 8.51515 16.8906 8.70289 16.7029L17.9029 7.5129C18.4657 6.94991 18.7822 6.18606 18.7822 5.39C18.7822 4.59376 18.4659 3.83014 17.9029 3.26711C17.3399 2.70408 16.5762 2.38778 15.78 2.38778Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPaperclip);
export default ForwardRef;
