import { isServer } from './is-server';

export const TECHNICAL_WORKS_PAGE = '/technical-works';

export function getTechnicalWorksUrl(from: string) {
  return `${TECHNICAL_WORKS_PAGE}?from=${encodeURIComponent(from)}`;
}

export function technicalWorksRedirect(from: string) {
  if (isServer()) return;

  window.location.replace(getTechnicalWorksUrl(from));
}

export function isItTechnicalWorksPage(url: string) {
  return new RegExp(`^\\${TECHNICAL_WORKS_PAGE}`).test(url);
}
