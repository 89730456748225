// @ts-nocheck
import { forwardRef } from 'react';
const SvgMoon = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M12.1 22C11.8 22 11.5 22 11.2 22C5.7 21.5 1.7 16.6 2.2 11.1C2.6 6.3 6.4 2.5 11.2 2.1C11.6 2.1 12 2.3 12.2 2.6C12.4 2.9 12.4 3.4 12.1 3.7C11.1 5 10.8 6.6 11 8.2C11.2 9.8 12.1 11.2 13.4 12.1C15.5 13.7 18.4 13.7 20.5 12.1C20.8 11.9 21.2 11.8 21.6 12C21.9 12.2 22.1 12.6 22.1 13C21.9 15.7 20.6 18.1 18.5 19.8C16.6 21.2 14.4 22 12.1 22ZM9.3 4.4C6.4 5.4 4.4 8 4.1 11.2C3.7 15.6 6.9 19.5 11.3 19.9C13.4 20.1 15.5 19.5 17.1 18.1C18.2 17.2 19 16 19.5 14.7C17 15.6 14.2 15.2 12 13.6C10.3 12.3 9.2 10.5 8.8 8.4C8.7 7 8.9 5.7 9.3 4.4Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgMoon);
export default ForwardRef;
