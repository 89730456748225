import { ReactNode, useMemo } from 'react';
import getConfig from 'next/config';
import { ThemeProvider } from 'styled-components';
import { ProjectNames } from '@use-gateway/types';
import { changePayTheme } from './change-pay-theme';
import GlobalStyle from './globalStyles';
import { lightTheme } from './light';

export interface GatewayThemeProviderProps {
  children: ReactNode;
}
export const GatewayThemeProvider = ({ children }: GatewayThemeProviderProps) => {
  const { publicRuntimeConfig } = getConfig();
  const { PROJECT_NAME } = publicRuntimeConfig;

  const theme = useMemo(() => {
    if (PROJECT_NAME === ProjectNames.ChangePay) return changePayTheme;
    return lightTheme;
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};
