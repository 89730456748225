// @ts-nocheck
import { forwardRef } from 'react';
const SvgTbtc = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 64 64"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_3630_36590)">
      <path
        fill="#8A8A8A"
        d="M63.0323 39.7454C58.7584 56.8885 41.3954 67.3214 24.2505 63.0466C7.11242 58.7724 -3.3206 41.4085 0.955411 24.2665C5.2274 7.12149 22.5904 -3.3125 39.7305 0.961502C56.8745 5.2355 67.3065 22.6014 63.0323 39.7454Z"
      />
      <path
        fill="white"
        d="M46.1026 27.4455C46.7396 23.1876 43.4976 20.8985 39.0646 19.3716L40.5026 13.6036L36.9917 12.7286L35.5917 18.3446C34.6687 18.1145 33.7206 17.8976 32.7785 17.6826L34.1887 12.0295L30.6797 11.1545L29.2406 16.9205C28.4767 16.7465 27.7266 16.5746 26.9986 16.3936L27.0025 16.3756L22.1606 15.1665L21.2267 18.9165C21.2267 18.9165 23.8317 19.5135 23.7766 19.5506C25.1986 19.9055 25.4557 20.8466 25.4127 21.5926L23.7746 28.1636C23.8726 28.1885 23.9997 28.2246 24.1396 28.2806C24.0226 28.2516 23.8976 28.2196 23.7686 28.1885L21.4726 37.3936C21.2985 37.8256 20.8576 38.4736 19.8637 38.2276C19.8987 38.2786 17.3116 37.5906 17.3116 37.5906L15.5686 41.6096L20.1376 42.7485C20.9876 42.9615 21.8207 43.1846 22.6406 43.3945L21.1876 49.2285L24.6946 50.1035L26.1335 44.3316C27.0916 44.5915 28.0215 44.8315 28.9316 45.0576L27.4976 50.8025L31.0086 51.6775L32.4615 45.8546C38.4486 46.9876 42.9506 46.5305 44.8455 41.1156C46.3727 36.7556 44.7696 34.2406 41.6196 32.6006C43.9136 32.0715 45.6416 30.5625 46.1026 27.4455ZM38.0806 38.6946C36.9956 43.0546 29.6546 40.6975 27.2745 40.1065L29.2027 32.3775C31.5826 32.9716 39.2146 34.1476 38.0806 38.6946ZM39.1666 27.3826C38.1766 31.3485 32.0665 29.3335 30.0847 28.8395L31.8326 21.8295C33.8146 22.3236 40.1975 23.2455 39.1666 27.3826Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_3630_36590">
        <rect width={64} height={64} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgTbtc);
export default ForwardRef;
