// @ts-nocheck
import { forwardRef } from 'react';
const SvgSkipForward = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.56681 3.09869C4.91328 2.93217 5.32453 2.97899 5.6247 3.21913L15.6247 11.2191C15.8619 11.4089 16 11.6962 16 12C16 12.3038 15.8619 12.5911 15.6247 12.7809L5.6247 20.7809C5.32453 21.021 4.91328 21.0678 4.56681 20.9013C4.22035 20.7348 4 20.3844 4 20V3.99999C4 3.61559 4.22035 3.26521 4.56681 3.09869ZM6 6.08062V17.9194L13.3992 12L6 6.08062Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19 4C19.5523 4 20 4.44772 20 5V19C20 19.5523 19.5523 20 19 20C18.4477 20 18 19.5523 18 19V5C18 4.44772 18.4477 4 19 4Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSkipForward);
export default ForwardRef;
