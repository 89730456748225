// @ts-nocheck
import { forwardRef } from 'react';
const SvgLtc = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 65 64"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="#345D9D"
      d="M64.1604 31.9998C64.1604 49.6731 49.8337 64 32.1608 64C14.4875 64 0.160645 49.6731 0.160645 31.9998C0.160645 14.3268 14.4875 0 32.1608 0C49.8335 0 64.1604 14.3268 64.1604 31.9998Z"
    />
    <path
      fill="white"
      d="M30.2902 40.5482L32.3688 32.7217L37.2897 30.924L38.5137 26.3244L38.4719 26.2104L33.628 27.98L37.118 14.8385H27.2202L22.6559 31.9884L18.8453 33.3806L17.5861 38.1224L21.3939 36.7315L18.7039 46.8387H45.046L46.7349 40.5482H30.2902Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLtc);
export default ForwardRef;
