// @ts-nocheck
import { forwardRef } from 'react';
const SvgPhoneChatMessageSms = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_2128_24851)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.4583 2C11.1821 2 10.9171 2.10536 10.7218 2.29289C10.5264 2.48043 10.4167 2.73478 10.4167 3V11.382L13.0758 10.1056C13.2205 10.0361 13.3799 10 13.5417 10H21.875C22.1513 10 22.4162 9.89464 22.6116 9.70711C22.8069 9.51957 22.9167 9.26522 22.9167 9V3C22.9167 2.73478 22.8069 2.48043 22.6116 2.29289C22.4162 2.10536 22.1513 2 21.875 2H11.4583ZM9.24862 0.87868C9.83467 0.316071 10.6295 0 11.4583 0H21.875C22.7038 0 23.4987 0.316071 24.0847 0.87868C24.6708 1.44129 25 2.20435 25 3V9C25 9.79565 24.6708 10.5587 24.0847 11.1213C23.4987 11.6839 22.7038 12 21.875 12H13.7876L9.84084 13.8944C9.51794 14.0494 9.13446 14.0329 8.82736 13.8507C8.52026 13.6684 8.33333 13.3466 8.33333 13V3C8.33333 2.20435 8.66257 1.44129 9.24862 0.87868Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1.95696 2.87868C2.54301 2.31607 3.33786 2 4.16666 2H5.72916C6.30446 2 6.77083 2.44772 6.77083 3C6.77083 3.55228 6.30446 4 5.72916 4H4.16666C3.8904 4 3.62544 4.10536 3.43009 4.29289C3.23474 4.48043 3.125 4.73478 3.125 5V21C3.125 21.2652 3.23474 21.5196 3.43009 21.7071C3.62545 21.8946 3.8904 22 4.16666 22H15.625C15.9013 22 16.1662 21.8946 16.3616 21.7071C16.5569 21.5196 16.6667 21.2652 16.6667 21V15C16.6667 14.4477 17.133 14 17.7083 14C18.2836 14 18.75 14.4477 18.75 15V21C18.75 21.7957 18.4208 22.5587 17.8347 23.1213C17.2487 23.6839 16.4538 24 15.625 24H4.16666C3.33786 24 2.54301 23.6839 1.95696 23.1213C1.3709 22.5587 1.04166 21.7957 1.04166 21V5C1.04166 4.20435 1.3709 3.44129 1.95696 2.87868Z"
        clipRule="evenodd"
      />
      <ellipse cx={10.4167} cy={19} fill="currentColor" rx={1.04167} ry={1} />
    </g>
    <defs>
      <clipPath id="clip0_2128_24851">
        <rect width={25} height={24} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgPhoneChatMessageSms);
export default ForwardRef;
