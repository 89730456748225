// @ts-nocheck
import { forwardRef } from 'react';
const SvgExternalLink = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M16.6667 9.16667C16.4457 9.16667 16.2337 9.25446 16.0774 9.41074C15.9211 9.56702 15.8333 9.77899 15.8333 10V15C15.8333 15.221 15.7455 15.433 15.5893 15.5893C15.433 15.7455 15.221 15.8333 15 15.8333H5C4.77899 15.8333 4.56702 15.7455 4.41074 15.5893C4.25446 15.433 4.16667 15.221 4.16667 15V5C4.16667 4.77899 4.25446 4.56702 4.41074 4.41074C4.56702 4.25446 4.77899 4.16667 5 4.16667H10C10.221 4.16667 10.433 4.07887 10.5893 3.92259C10.7455 3.76631 10.8333 3.55435 10.8333 3.33333C10.8333 3.11232 10.7455 2.90036 10.5893 2.74408C10.433 2.5878 10.221 2.5 10 2.5H5C4.33696 2.5 3.70107 2.76339 3.23223 3.23223C2.76339 3.70107 2.5 4.33696 2.5 5V15C2.5 15.663 2.76339 16.2989 3.23223 16.7678C3.70107 17.2366 4.33696 17.5 5 17.5H15C15.663 17.5 16.2989 17.2366 16.7678 16.7678C17.2366 16.2989 17.5 15.663 17.5 15V10C17.5 9.77899 17.4122 9.56702 17.2559 9.41074C17.0996 9.25446 16.8877 9.16667 16.6667 9.16667Z"
    />
    <path
      fill="currentColor"
      d="M13.3336 4.16667H14.6503L9.40861 9.4C9.3305 9.47747 9.26851 9.56964 9.2262 9.67119C9.18389 9.77274 9.16211 9.88166 9.16211 9.99167C9.16211 10.1017 9.18389 10.2106 9.2262 10.3121C9.26851 10.4137 9.3305 10.5059 9.40861 10.5833C9.48608 10.6614 9.57824 10.7234 9.67979 10.7657C9.78134 10.8081 9.89026 10.8298 10.0003 10.8298C10.1103 10.8298 10.2192 10.8081 10.3208 10.7657C10.4223 10.7234 10.5145 10.6614 10.5919 10.5833L15.8336 5.35V6.66667C15.8336 6.88768 15.9214 7.09964 16.0777 7.25592C16.234 7.4122 16.4459 7.5 16.6669 7.5C16.888 7.5 17.0999 7.4122 17.2562 7.25592C17.4125 7.09964 17.5003 6.88768 17.5003 6.66667V3.33333C17.5003 3.11232 17.4125 2.90036 17.2562 2.74408C17.0999 2.5878 16.888 2.5 16.6669 2.5H13.3336C13.1126 2.5 12.9006 2.5878 12.7444 2.74408C12.5881 2.90036 12.5003 3.11232 12.5003 3.33333C12.5003 3.55435 12.5881 3.76631 12.7444 3.92259C12.9006 4.07887 13.1126 4.16667 13.3336 4.16667V4.16667Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgExternalLink);
export default ForwardRef;
