// @ts-nocheck
import { forwardRef } from 'react';
const SvgItalic = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9 4C9 3.44772 9.44772 3 10 3H19C19.5523 3 20 3.44772 20 4C20 4.55228 19.5523 5 19 5H10C9.44772 5 9 4.55228 9 4Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 20C4 19.4477 4.44772 19 5 19H14C14.5523 19 15 19.4477 15 20C15 20.5523 14.5523 21 14 21H5C4.44772 21 4 20.5523 4 20Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.3511 3.06367C15.8682 3.25759 16.1302 3.834 15.9363 4.35112L9.93633 20.3511C9.74241 20.8682 9.16599 21.1302 8.64887 20.9363C8.13175 20.7424 7.86975 20.166 8.06367 19.6489L14.0637 3.64887C14.2576 3.13175 14.834 2.86975 15.3511 3.06367Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgItalic);
export default ForwardRef;
