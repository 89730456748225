import { createGlobalStyle } from 'styled-components';
import { GatewayTheme } from './model';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    background-color: ${({ theme }) => (theme as GatewayTheme).colors.bg};
    color: ${({ theme }) => (theme as GatewayTheme).colors.dark[100]};
  }

  * {
    line-height: 1.25;
    letter-spacing: -0.01em;
  }

  #modal-root {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3000;

    & > div {
      display: none;

      &:last-child {
        display: block;
      }
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Nunito', sans-serif;
  }

  h1, h2, h3 {
    font-weight: 700;
  }

  h4, h5, h6 {
    font-weight: 600;
  }

  p, label {
    a {
      color: ${({ theme }) => (theme as GatewayTheme).colors.primary};
      text-decoration: none;

      &:hover {
        border-bottom: 1px currentColor dotted;
      }
    }
  }

  b, strong {
    font-weight: 500;
  }

  .toastContainer {
    z-index: 9999;

    .toast.info > .content p {
      margin-top: 2px;
      line-height: 18px;
    }
  }
`;

export default GlobalStyle;
