// @ts-nocheck
import { forwardRef } from 'react';
const SvgPieChart = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.91654 2.43004C9.13743 2.93623 8.90615 3.52564 8.39997 3.74653C7.05256 4.33452 5.8697 5.24362 4.95481 6.39436C4.03991 7.5451 3.42084 8.90243 3.1517 10.3477C2.88257 11.793 2.97158 13.2822 3.41094 14.6851C3.8503 16.088 4.62664 17.3619 5.67208 18.3955C6.71753 19.4291 8.00025 20.1908 9.4081 20.6141C10.8159 21.0375 12.3061 21.1095 13.7482 20.8238C15.1903 20.5382 16.5404 19.9037 17.6807 18.9757C18.8209 18.0477 19.7164 16.8546 20.289 15.5005C20.5041 14.9919 21.0908 14.7539 21.5995 14.969C22.1082 15.1841 22.3461 15.7708 22.131 16.2795C21.4312 17.9344 20.3367 19.3927 18.9431 20.5269C17.5495 21.6611 15.8993 22.4366 14.1367 22.7857C12.3742 23.1348 10.5529 23.0468 8.83221 22.5294C7.1115 22.0121 5.54374 21.081 4.26597 19.8178C2.9882 18.5545 2.03934 16.9975 1.50235 15.2828C0.965348 13.5681 0.856564 11.748 1.1855 9.98156C1.51444 8.21513 2.27109 6.55616 3.3893 5.1497C4.5075 3.74324 5.95322 2.63212 7.60005 1.91347C8.10623 1.69258 8.69565 1.92385 8.91654 2.43004Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.2929 1.29289C11.4804 1.10536 11.7348 1 12 1C13.4445 1 14.8749 1.28452 16.2095 1.83733C17.5441 2.39013 18.7567 3.20038 19.7782 4.22183C20.7996 5.24327 21.6099 6.4559 22.1627 7.79048C22.7155 9.12506 23 10.5555 23 12C23 12.5523 22.5523 13 22 13H12C11.4477 13 11 12.5523 11 12V2C11 1.73478 11.1054 1.48043 11.2929 1.29289ZM13 3.05573V11H20.9443C20.8505 10.1614 20.6391 9.33849 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.6615 3.3609 13.8386 3.14949 13 3.05573Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPieChart);
export default ForwardRef;
