import { useEffect, useRef } from 'react';
import { UnknownFunction } from '@use-gateway/types';

export function useClickOutside(cb: UnknownFunction) {
  const ref = useRef<HTMLElement>();

  const handler = (event: MouseEvent) => {
    if (ref.current && event.composedPath().indexOf(ref.current) === -1) {
      cb?.();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

  return ref;
}
