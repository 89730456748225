// @ts-nocheck
import { forwardRef } from 'react';
const SvgCloudSnow = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_2128_24767)">
      <path
        fill="currentColor"
        d="M20 18.6C19.6 18.6 19.2 18.4 19.1 18C18.9 17.5 19.1 16.9 19.6 16.7C20.6 16.3 21.3 15.5 21.7 14.5C22.1 13.5 22.1 12.4 21.6 11.4C21 9.90003 19.6 9.00003 18 9.00003H16.7C16.2 9.00003 15.8 8.70003 15.7 8.20003C14.7 4.50003 10.9 2.20003 7.19999 3.20003C3.49999 4.20003 1.19999 8.00003 2.19999 11.7C2.59999 13.2 3.39999 14.5 4.59999 15.4C4.99999 15.7 5.09999 16.4 4.79999 16.8C4.49999 17.2 3.79999 17.3 3.39999 17C1.89999 15.8 0.799994 14.1 0.299994 12.2C-1.00001 7.40003 1.89999 2.50003 6.69999 1.30003C11.3 0.100029 15.9 2.70003 17.4 7.00003H18C20.4 7.00003 22.5 8.40003 23.5 10.6C24.1 12.1 24.2 13.7 23.6 15.2C23 16.7 21.9 17.9 20.4 18.5C20.3 18.6 20.1 18.6 20 18.6Z"
      />
      <path
        fill="currentColor"
        d="M8 17C7.7 17 7.5 16.9 7.3 16.7C7.2 16.6 7.1 16.5 7.1 16.4C7 16.3 7 16.1 7 16C7 15.9 7 15.7 7.1 15.6C7.2 15.5 7.2 15.4 7.3 15.3C7.7 14.9 8.3 14.9 8.7 15.3C8.8 15.4 8.9 15.5 8.9 15.6C9 15.7 9 15.9 9 16C9 16.1 9 16.3 8.9 16.4C8.8 16.5 8.8 16.6 8.7 16.7C8.5 16.9 8.3 17 8 17Z"
      />
      <path
        fill="currentColor"
        d="M8 21C7.7 21 7.5 20.9 7.3 20.7C7.1 20.5 7 20.3 7 20C7 19.7 7.1 19.5 7.3 19.3C7.4 19.2 7.5 19.1 7.6 19.1C8 18.9 8.4 19 8.7 19.3C8.9 19.5 9 19.7 9 20C9 20.3 8.9 20.5 8.7 20.7C8.5 20.9 8.3 21 8 21Z"
      />
      <path
        fill="currentColor"
        d="M12 19C11.9 19 11.7 19 11.6 18.9C11.5 18.9 11.4 18.8 11.3 18.7C11.1 18.5 11 18.3 11 18C11 17.9 11 17.7 11.1 17.6C11.2 17.5 11.2 17.4 11.3 17.3C11.7 16.9 12.3 16.9 12.7 17.3C12.9 17.5 13 17.8 13 18C13 18.3 12.9 18.5 12.7 18.7C12.5 18.9 12.3 19 12 19Z"
      />
      <path
        fill="currentColor"
        d="M12 23C11.7 23 11.5 22.9 11.3 22.7C11.1 22.5 11 22.3 11 22C11 21.9 11 21.7 11.1 21.6C11.2 21.5 11.2 21.4 11.3 21.3C11.7 20.9 12.3 20.9 12.7 21.3C12.8 21.4 12.9 21.5 12.9 21.6C12.9 21.7 13 21.9 13 22C13 22.3 12.9 22.5 12.7 22.7C12.6 22.8 12.5 22.9 12.4 22.9C12.3 22.9 12.1 23 12 23Z"
      />
      <path
        fill="currentColor"
        d="M16 17C15.7 17 15.5 16.9 15.3 16.7C15.2 16.6 15.1 16.5 15.1 16.4C15.1 16.3 15 16.1 15 16C15 15.9 15 15.7 15.1 15.6C15.2 15.5 15.2 15.4 15.3 15.3C15.7 14.9 16.3 14.9 16.7 15.3C16.8 15.4 16.9 15.5 16.9 15.6C16.9 15.7 17 15.9 17 16C17 16.3 16.9 16.5 16.7 16.7C16.5 16.9 16.3 17 16 17Z"
      />
      <path
        fill="currentColor"
        d="M16 21C15.7 21 15.5 20.9 15.3 20.7C15.1 20.5 15 20.3 15 20C15 19.7 15.1 19.5 15.3 19.3C15.5 19.1 15.9 19 16.2 19C16.3 19 16.3 19 16.4 19.1C16.5 19.1 16.5 19.1 16.6 19.2C16.6 19.2 16.7 19.3 16.8 19.3C17 19.5 17.1 19.8 17.1 20C17.1 20.3 17 20.5 16.8 20.7C16.5 20.9 16.3 21 16 21Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_2128_24767">
        <rect width={24} height={24} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgCloudSnow);
export default ForwardRef;
