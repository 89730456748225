import {
  ForgotPasswordPayload,
  PaymentResponseDto,
  RegistrationPayload,
  ResetPasswordPayload,
} from '@use-gateway/types';
import { Api } from '..';

const basePath = '/auth';

export async function register(this: Api, payload: RegistrationPayload) {
  return this.post<PaymentResponseDto>(`${basePath}/register`, {
    ...payload,
  });
}
export async function forgotPassword(this: Api, payload: ForgotPasswordPayload) {
  return this.post(`${basePath}/forgot-password`, payload);
}

export async function resetPassword(this: Api, payload: ResetPasswordPayload) {
  return this.post(`${basePath}/reset-password`, payload);
}
