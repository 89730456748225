// @ts-nocheck
import { forwardRef } from 'react';
const SvgFeather = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.7071 7.29289C17.0976 7.68342 17.0976 8.31658 16.7071 8.70711L2.70711 22.7071C2.31658 23.0976 1.68342 23.0976 1.29289 22.7071C0.902369 22.3166 0.902369 21.6834 1.29289 21.2929L15.2929 7.29289C15.6834 6.90237 16.3166 6.90237 16.7071 7.29289Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.0429 3.0429C12.3563 1.72952 14.1376 0.991669 15.995 0.991669C17.8524 0.991669 19.6337 1.72952 20.9471 3.0429C22.2605 4.35628 22.9983 6.1376 22.9983 7.99501C22.9983 9.85205 22.2608 11.633 20.9479 12.9463L20.9471 12.9471L14.2082 19.7061C14.0205 19.8942 13.7657 20 13.5 20H5C4.44772 20 4 19.5523 4 19V10.5C4 10.2348 4.10536 9.98044 4.29289 9.7929L11.0429 3.0429ZM15.995 2.99167C14.668 2.99167 13.3954 3.5188 12.4571 4.45711L6 10.9142V18H13.0849L19.5318 11.5339L19.5329 11.5329C20.4712 10.5946 20.9983 9.32197 20.9983 7.99501C20.9983 6.66804 20.4712 5.39542 19.5329 4.45711C18.5946 3.5188 17.322 2.99167 15.995 2.99167Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 15C8 14.4477 8.44772 14 9 14H17C17.5523 14 18 14.4477 18 15C18 15.5523 17.5523 16 17 16H9C8.44772 16 8 15.5523 8 15Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgFeather);
export default ForwardRef;
