// @ts-nocheck
import { forwardRef } from 'react';
const SvgExchange = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 22 22"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M16.9062 2.28125H14.375C14.0644 2.28125 13.8125 2.53311 13.8125 2.84375C13.8125 3.15439 14.0644 3.40625 14.375 3.40625H16.9062C17.6816 3.40625 18.3125 4.03714 18.3125 4.8125V17.1875C18.3125 17.9629 17.6816 18.5938 16.9062 18.5938H16.0625C15.7519 18.5938 15.5 18.8456 15.5 19.1562C15.5 19.4669 15.7519 19.7188 16.0625 19.7188H16.9062C18.3019 19.7188 19.4375 18.5832 19.4375 17.1875V4.8125C19.4375 3.41682 18.3019 2.28125 16.9062 2.28125Z"
    />
    <path
      fill="currentColor"
      d="M7.0625 18.5938H4.53125C3.75589 18.5938 3.125 17.9629 3.125 17.1875V4.8125C3.125 4.03714 3.75589 3.40625 4.53125 3.40625H5.375C5.68564 3.40625 5.9375 3.15439 5.9375 2.84375C5.9375 2.53311 5.68564 2.28125 5.375 2.28125H4.53125C3.13557 2.28125 2 3.41682 2 4.8125V17.1875C2 18.5832 3.13557 19.7188 4.53125 19.7188H7.0625C7.37314 19.7188 7.625 19.4669 7.625 19.1562C7.625 18.8456 7.37314 18.5938 7.0625 18.5938Z"
    />
    <path
      fill="currentColor"
      d="M14.0938 13.5312C13.7831 13.5312 13.5312 13.7831 13.5312 14.0938V17.7983L5.77271 10.0398C5.55325 9.82007 5.19675 9.82007 4.97729 10.0398C4.75757 10.2594 4.75757 10.6156 4.97729 10.8352L12.7358 18.5938H9.03125C8.72061 18.5938 8.46875 18.8456 8.46875 19.1562C8.46875 19.4669 8.72061 19.7188 9.03125 19.7188H14.0938C14.1307 19.7188 14.1675 19.7149 14.2039 19.7076C14.2205 19.7043 14.2359 19.6981 14.2519 19.6934C14.271 19.6878 14.2904 19.6834 14.3089 19.6757C14.3276 19.668 14.3446 19.6573 14.3622 19.6477C14.3767 19.6397 14.3918 19.6333 14.4057 19.624C14.4674 19.5828 14.5204 19.5298 14.5616 19.4682C14.5707 19.4544 14.577 19.4396 14.5849 19.4252C14.5946 19.4074 14.6054 19.3903 14.6132 19.3714C14.6209 19.3529 14.6253 19.3336 14.6309 19.3146C14.6356 19.2985 14.6418 19.2831 14.6451 19.2664C14.6524 19.23 14.6562 19.1932 14.6562 19.1562V14.0938C14.6562 13.7831 14.4044 13.5312 14.0938 13.5312Z"
    />
    <path
      fill="currentColor"
      d="M7.34375 8.46875C7.65439 8.46875 7.90625 8.21689 7.90625 7.90625V4.20166L15.6648 11.9602C15.7745 12.0701 15.9186 12.125 16.0625 12.125C16.2064 12.125 16.3505 12.0701 16.4602 11.9602C16.6799 11.7406 16.6799 11.3844 16.4602 11.1648L8.70166 3.40625H12.4062C12.7169 3.40625 12.9688 3.15439 12.9688 2.84375C12.9688 2.53311 12.7169 2.28125 12.4062 2.28125H7.34375C7.30681 2.28125 7.26992 2.28513 7.23354 2.29236C7.21715 2.29562 7.20201 2.30176 7.18616 2.30642C7.16685 2.31206 7.1473 2.31653 7.12856 2.32429C7.11005 2.33196 7.09331 2.3425 7.07586 2.35203C7.06113 2.36004 7.04583 2.36655 7.03172 2.37599C6.97008 2.41719 6.91712 2.47018 6.87594 2.53182C6.86675 2.54556 6.86047 2.56044 6.85263 2.57476C6.84286 2.59256 6.8321 2.60972 6.82427 2.62864C6.81663 2.64711 6.81222 2.66639 6.80664 2.68543C6.80188 2.70153 6.79567 2.71693 6.79236 2.73359C6.78513 2.76995 6.78125 2.80683 6.78125 2.84375V7.90625C6.78125 8.21689 7.03311 8.46875 7.34375 8.46875Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgExchange);
export default ForwardRef;
