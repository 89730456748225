import { useEffect, useState } from 'react';

export function useSsrDetector() {
  const [SSR, setSSR] = useState(true);

  useEffect(() => {
    setSSR(false);
  }, []);

  return SSR;
}
