// @ts-nocheck
import { forwardRef } from 'react';
const SvgTablet = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18 21C18.5523 21 19 20.5523 19 20L19 4C19 3.44772 18.5523 3 18 3L6 3C5.44771 3 5 3.44772 5 4L5 20C5 20.5523 5.44771 21 6 21H18ZM21 20C21 21.6569 19.6569 23 18 23L6 23C4.34315 23 3 21.6569 3 20L3 4C3 2.34315 4.34315 1 6 1L18 1C19.6569 1 21 2.34315 21 4L21 20Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgTablet);
export default ForwardRef;
