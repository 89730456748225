import React, {
  forwardRef,
  ReactNode,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import copy from 'copy-to-clipboard';
import IMask from 'imask';
import { useFormContext, Controller, FieldError } from 'react-hook-form';
import { toast } from 'react-toast';
import { down } from 'styled-breakpoints';
import { css } from 'styled-components';
import { uid } from 'uid';
import {
  IconCopy,
  IconExternalLink,
  IconEyeClose,
  IconEyeOpen,
  IconSave,
} from '@use-gateway/icons';
import { Breakpoints, styled, useBreakpoints } from '@use-gateway/theme';
import { Size, UnknownFunction } from '@use-gateway/types';
import { isNumberValid, styledTransition } from '@use-gateway/utils';
import { Modal } from './modal';

//
// ~~ Styled Components
//

interface InputWrapperProps {
  linkStyle?: boolean;
  copyStyle?: boolean;
  widthIcons?: number;
  label?: boolean;
  size?: Size;
  disabled?: boolean;
  readonly?: boolean;
}

interface IconsWrapperProps {
  focus: boolean;
  multiline?: boolean;
}

const InputContainer = styled.div`
  width: 100%;
`;

const InputWrapper = styled.div<InputWrapperProps>`
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  border: none;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}

  &:hover {
    input {
      background-color: ${({ theme }) => theme.colors.light[70]};
    }
    div:before {
      display: none;
    }
  }

  input,
  textarea {
    box-sizing: border-box;
    width: 100%;
    min-height: 58px;

    padding-top: ${({ label }) => (label ? '19px' : '15px')};
    padding-right: ${({ widthIcons }) => {
      if (widthIcons) return `${16 + widthIcons}px`;
      return '16px';
    }};
    padding-bottom: ${({ label }) => (label ? '11px' : '15px')};
    padding-left: 16px;

    outline: none;
    border: 2px solid transparent;

    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    color: ${({ theme, linkStyle }) => {
      if (linkStyle) return theme.colors.accent[100];
      return theme.colors.dark[100];
    }};
    text-decoration: ${({ linkStyle }) => {
      if (linkStyle) return 'underline';
      return 'none';
    }};
    word-break: normal;

    border-radius: ${({ theme }) => theme.borderRadius.md};
    background-color: ${({ theme }) => theme.colors.light[80]};

    resize: none;
    ${styledTransition(['border, border-color, outline'], 150)}

    &:focus {
      border-color: ${({ theme }) => theme.colors.primary};
      background-color: ${({ theme }) => theme.colors.light[70]};
    }

    &:disabled {
      cursor: ${({ copyStyle }) => {
        if (copyStyle) return 'pointer';
        return 'default';
      }};
    }

    &.error {
      border-color: ${({ theme }) => theme.colors.danger[100]} !important;
    }

    &.rounded {
      border-radius: 32px;
      padding: 19px 24px 11px;

      ${({ size }) =>
        size === 'sm' &&
        css`
          border-radius: 24px;
          padding: 12px 24px;
          font-size: 14px;
          min-height: auto;
          border: none !important;
        `}
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: ${({ theme }) => theme.colors.dark[100]} !important;
      -webkit-box-shadow: 0 0 0 1000px transparent inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }
`;

export interface InputLabelProps {
  size?: Size;
  rounded?: boolean;
}
const InputLabel = styled.label<InputLabelProps>`
  position: absolute;
  top: 50%;
  left: 18px;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.42;
  color: ${({ theme }) => theme.colors.dark[80]};
  transform: translateY(-50%);
  pointer-events: none;

  small {
    font-size: 1em;
  }

  ${styledTransition(['all'], 150)}

  input:focus + &,
  input.filled + &,
  textarea:focus + &,
  textarea.filled + & {
    z-index: 10;
    top: 10px;
    transform: translateY(0);
    font-size: 9px;
    line-height: 1.33;
    color: ${({ theme }) => theme.colors.dark[40]};
  }

  input:focus + &.rounded,
  input.filled + &.rounded {
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
  }

  &.rounded {
    left: 24px;
  }
`;

const IconsWrapper = styled.div<IconsWrapperProps>`
  position: absolute;
  right: 16px;
  top: 0;
  bottom: 0;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  font-size: 20px;

  &:before {
    content: '';
    position: absolute;
    left: -40px;
    display: ${({ focus, multiline }) => (focus || multiline ? 'none' : 'block')};
    width: 40px;
    height: calc(100% - 4px);
    pointer-events: none;
    background-image: ${({ theme }) =>
      `linear-gradient(90deg, hsla(0, 0%, 100%, 0), ${theme.colors.light[80]})`};
    animation-name: smooth-transition;
    animation-duration: 0.15s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;

    @keyframes smooth-transition {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  & > *:not(.after) {
    margin-left: 10px;
    cursor: pointer;

    &:hover {
      @media (hover: hover) and (pointer: fine) {
        color: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  .after {
    margin-left: 10px;
  }
`;

const ShowWrapper = styled.div`
  width: 20px;
  height: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const InputError = styled.small`
  display: block;
  padding: 4px 0 0;
  color: ${({ theme }) => theme.colors.danger[100]};

  &:first-letter {
    text-transform: uppercase;
  }
`;

interface InputWithModalWrapperProps {
  isNonePointerEvents?: boolean;
}

const InputWithModalWrapper = styled.div<InputWithModalWrapperProps>`
  width: 100%;

  input {
    color: ${({ theme }) => theme.colors.dark[100]};
    pointer-events: ${({ isNonePointerEvents }) => (isNonePointerEvents ? 'none' : 'auto')};

    ${down('sm')} {
      pointer-events: none;
    }
  }
`;

//
// ~~ Types
//

interface CommonInputProps {
  value?: string;
  defautValue?: string;
  label?: string;
  type?: 'text' | 'password' | 'number';
  after?: ReactNode;
  mask?: any;
  disabled?: boolean;
  readonly?: boolean;
  error?: string | Error | FieldError;
  onSave?: (value: string) => void;
  copyValue?: boolean;
  linkStyle?: boolean;
  multiline?: boolean;
  className?: string;
  size?: Size;
  autocomplete?: string;
  externalLink?: string;
}

//
// ~~ Main Component
//

export interface InputProps extends CommonInputProps {
  rounded?: boolean;
  onBlur?: (
    value: string,
    originalEvent?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onChange?: (
    value: string,
    originalEvent?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

export const Input = forwardRef(
  (
    {
      label,
      value,
      defautValue,
      type,
      error,
      mask,
      disabled,
      readonly,
      rounded,
      after,
      onBlur,
      onChange,
      onSave,
      copyValue,
      linkStyle,
      multiline,
      className,
      autocomplete = 'off',
      size,
      externalLink,
    }: InputProps,
    ref: RefObject<HTMLInputElement>
  ) => {
    const id = useMemo(() => uid(), []);
    const [innerValue, setInnerValue] = useState((defautValue || value) ?? '');
    const [showValue, setShowValue] = useState(false);
    const inputType = useMemo(() => {
      if (type === 'number' || showValue) return 'text';

      return type;
    }, [showValue, type]);

    const [focus, setFocus] = useState(false);
    const [widthIcons, setWidthIcons] = useState(0);
    const iconsRef = useRef<HTMLElement>();
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const masked = useMemo(() => {
      if (mask === undefined) return null;

      return IMask.createMask({
        mask,
        blocks: {
          '+': {
            mask: /.+/,
          },
        },
      });
    }, [mask]);

    const classList = [];

    if (className) classList.push(className);
    if (innerValue) classList.push('filled');
    if (error) classList.push('error');
    if (rounded) classList.push('rounded');

    const handleInput: React.FormEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
      let value = e.currentTarget.value;

      if (masked) {
        value = masked.resolve(value);
      }

      if (type !== 'number' || isNumberValid(value)) {
        setInnerValue(value);
        if (onChange) onChange(value, e);
      }
    };

    const handleFocus: React.FormEventHandler<HTMLInputElement | HTMLTextAreaElement> = () => {
      setFocus(true);
    };

    const handleBlur: React.FormEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
      if (onBlur) onBlur(innerValue, e);
      setFocus(false);
    };

    const handleSave = () => {
      if (onSave) onSave(innerValue);
    };

    const handleCopy = () => {
      if (copyValue) {
        copy(innerValue);
        toast.info('Text has been copied!');
      }
    };

    const handleExternalLink = async () => {
      if (externalLink) window.open(externalLink, '_blank');
    };

    useEffect(() => {
      if (value && !mask) setInnerValue(value);
    }, [value]);

    useEffect(() => {
      if (iconsRef.current?.clientWidth) setWidthIcons(iconsRef.current?.clientWidth);
    }, []);

    useEffect(() => {
      if (textareaRef.current) {
        textareaRef.current.style.height = '0px';
        const scrollHeight = textareaRef.current.scrollHeight;
        textareaRef.current.style.height = scrollHeight + 'px';
      }
    }, [innerValue]);

    return (
      <InputContainer>
        <InputWrapper
          linkStyle={linkStyle}
          copyStyle={copyValue}
          widthIcons={widthIcons}
          size={size}
          disabled={disabled}
          label={Boolean(label)}>
          {multiline ? (
            <textarea
              className={classList.join(' ')}
              onChange={handleInput}
              id={id}
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={innerValue}
              ref={textareaRef as RefObject<HTMLTextAreaElement>}
              disabled={disabled}
              readOnly={readonly}
              autoComplete={autocomplete}
            />
          ) : (
            <input
              className={classList.join(' ')}
              onChange={handleInput}
              id={id}
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={innerValue}
              type={inputType}
              ref={ref}
              disabled={disabled}
              readOnly={readonly}
              autoComplete={autocomplete}
            />
          )}
          {label && (
            <InputLabel
              size={size}
              rounded={rounded}
              htmlFor={id}
              className={classList.join(' ')}
              dangerouslySetInnerHTML={{ __html: label }}
            />
          )}
          <IconsWrapper
            focus={focus}
            ref={iconsRef as RefObject<HTMLDivElement>}
            multiline={multiline}>
            {type === 'password' && (
              <ShowWrapper onClick={() => setShowValue(!showValue)}>
                {showValue ? <IconEyeOpen /> : <IconEyeClose />}
              </ShowWrapper>
            )}
            {externalLink && <IconExternalLink onClick={handleExternalLink} />}
            {onSave && <IconSave onClick={handleSave} />}
            {copyValue && <IconCopy onClick={handleCopy} />}
            {after && <div className={'after'}>{after}</div>}
          </IconsWrapper>
        </InputWrapper>
        {error && <InputError>{typeof error === 'string' ? error : error?.message}</InputError>}
      </InputContainer>
    );
  }
);

//
// ~~ Controlled component
//

export interface ControlledInputProps extends CommonInputProps {
  name: string;
}
export function ControlledInput({
  name,
  label,
  defautValue,
  type,
  mask,
  disabled,
  readonly,
  after,
  error,
  multiline,
  className,
  autocomplete,
  externalLink,
}: ControlledInputProps) {
  const { control, formState } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defautValue}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error: fieldError } }) => {
        return (
          <Input
            label={label}
            onChange={(value) => onChange(value)}
            onBlur={onBlur}
            error={error || fieldError}
            type={type}
            value={value}
            mask={mask}
            disabled={disabled ?? formState.isSubmitting}
            readonly={readonly}
            after={after}
            ref={ref}
            multiline={multiline}
            className={className}
            autocomplete={autocomplete}
            externalLink={externalLink}
          />
        );
      }}
    />
  );
}

interface ControlledInputWithModal extends InputProps {
  name: string;
  titleModal?: string;
  onNextModal?: UnknownFunction;
  onCloseModal?: UnknownFunction;
  clickSection?: ReactNode;
  adaptive?: 'full' | 'mobile';
  nextDisabled?: boolean;
}

export function ControlledInputWithModal({
  name,
  defautValue,
  label,
  type,
  error,
  mask,
  disabled,
  readonly,
  after,
  multiline,
  className,
  autocomplete,
  titleModal,
  onNextModal,
  onCloseModal,
  clickSection,
  adaptive,
  nextDisabled,
  externalLink,
}: ControlledInputWithModal) {
  const { control, formState } = useFormContext();
  const { isDown } = useBreakpoints();
  const [isModal, setIsModal] = useState(false);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defautValue}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error: fieldError } }) => {
        return (
          <>
            <InputWithModalWrapper
              onClick={() => setIsModal(true)}
              isNonePointerEvents={adaptive === 'full'}>
              {clickSection && (isDown(Breakpoints.sm) || adaptive === 'full') ? (
                <>{clickSection}</>
              ) : (
                <Input
                  label={label}
                  onChange={(value) => onChange(value)}
                  onBlur={onBlur}
                  error={error || fieldError}
                  type={type}
                  value={value}
                  mask={mask}
                  disabled={disabled ?? formState.isSubmitting}
                  readonly={readonly}
                  after={after}
                  ref={ref}
                  multiline={multiline}
                  className={className}
                  autocomplete={autocomplete}
                  externalLink={externalLink}
                />
              )}
            </InputWithModalWrapper>
            {isModal && (isDown(Breakpoints.sm) || adaptive === 'full') && (
              <Modal
                title={titleModal}
                onClose={() => {
                  setIsModal(false);
                  if (onCloseModal) onCloseModal();
                }}
                onNext={() => {
                  setIsModal(false);
                  if (onNextModal) onNextModal();
                }}
                nextDisabled={nextDisabled}
                nextText={'Save'}>
                <Input
                  label={label}
                  onChange={(value) => onChange(value)}
                  onBlur={onBlur}
                  error={error || fieldError}
                  type={type}
                  value={value}
                  mask={mask}
                  disabled={disabled ?? formState.isSubmitting}
                  readonly={readonly}
                  after={after}
                  ref={ref}
                  multiline={multiline}
                  className={className}
                  autocomplete={autocomplete}
                  externalLink={externalLink}
                />
              </Modal>
            )}
          </>
        );
      }}
    />
  );
}
