// @ts-nocheck
import { forwardRef } from 'react';
const SvgIdeaLightBulb = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 0C7.02972 0 3 4.02972 3 9C3 12.5356 5.03936 15.5874 8 17.0587V18V20C8 21.6569 9.34315 23 11 23H13C14.6569 23 16 21.6569 16 20V18V17.0587C18.9606 15.5874 21 12.5356 21 9C21 4.02972 16.9703 0 12 0ZM14 17V16.413C14 16.0057 14.2471 15.639 14.6246 15.4861C17.192 14.4464 19 11.9348 19 9C19 5.13428 15.8657 2 12 2C8.13428 2 5 5.13428 5 9C5 11.9348 6.80798 14.4464 9.37537 15.4861C9.75292 15.639 10 16.0057 10 16.413V17H14ZM10 19V20C10 20.5523 10.4477 21 11 21H13C13.5523 21 14 20.5523 14 20V19H10Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 6C10.3433 6 9 7.34328 9 9C9 9.55228 8.55228 10 8 10C7.44772 10 7 9.55228 7 9C7 6.23872 9.23872 4 12 4C12.5523 4 13 4.44772 13 5C13 5.55228 12.5523 6 12 6Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgIdeaLightBulb);
export default ForwardRef;
