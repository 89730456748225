import { useEffect, useState } from 'react';
import { down } from 'styled-breakpoints';
import { Stack } from '@bedrock-layout/stack';
import { api, ResponseError } from '@use-gateway/api';
import { ErrorMessage, Message } from '@use-gateway/components';
import { IconCongratulations } from '@use-gateway/icons';
import { Breakpoints, styled } from '@use-gateway/theme';
import { UnknownFunction } from '@use-gateway/types';
import { styledTransition } from '@use-gateway/utils';
import imgWalletBtn from './../assets/wallet-btn.svg';
import { Link } from '../link';
import { Modal, ModalCongratulationsImg, ModalText } from '../modal';
import { Textarea } from '../textarea';

const CreateWalletWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 26px;
  padding: 24px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.light[80]};
  box-sizing: border-box;

  &:last-child {
    margin-bottom: 0;
  }

  ${down(Breakpoints.sm)} {
    padding-bottom: 26px;
    background-color: transparent;
  }
`;

const CreateWalletText = styled.p`
  width: 100%;
  max-width: 580px;
  margin: 0 0 36px;
  padding: 0;
  text-align: center;
  line-height: 1.52;
  font-size: 18px;
  letter-spacing: -0.02em;

  &:last-child {
    margin-bottom: 0;
  }
`;

const CreateWalletBtn = styled.button`
  width: 100%;
  max-width: 154px;
  margin: 0 0 68px;
  padding: 24px;
  border: none;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.accent[100]};
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.btn};
  cursor: pointer;
  ${styledTransition(['transform'])}

  &:hover {
    transform: scale(1.01);
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const CreateWalletBtnImg = styled.div`
  width: 48px;
  height: 45px;
  margin: 0 auto 8px;
  background-image: url(${imgWalletBtn});
  background-repeat: no-repeat;
  background-size: 100% auto;
`;

interface WalletCreateProps {
  onCreated: UnknownFunction;
}

export function WalletCreate({ onCreated }: WalletCreateProps) {
  const steeps = [
    { steep: 0, title: 'Create wallet', btn: 'Next' },
    { steep: 1, title: 'Get my seed phrase', btn: 'Show seed phrase' },
    { steep: 2, title: 'Your seed phrase', btn: 'I’ve written it down' },
    { steep: 3, title: 'Seed phrase confirmation', btn: 'Verify seed phrase' },
    { steep: 4, title: 'Congratulations', btn: 'Wallet' },
  ];
  const [words, setWords] = useState('');
  const [steepCurrent, setSteepCurrent] = useState(0);
  const [checkWord, setWordCheck] = useState('');
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [modal, setModal] = useState(false);

  const [createPending, setCreatePending] = useState(false);
  const [errorCreateWallet, setErrorCreateWallet] = useState<ResponseError | null>(null);

  const createWallet = async () => {
    if (createPending) return;

    setCreatePending(true);
    await api
      .createWallet()
      .then((res) => {
        const { seed } = res;
        setWords(seed);
        setSteepCurrent(2);
        setErrorCreateWallet(null);
      })
      .catch(setErrorCreateWallet);
    setCreatePending(false);
  };

  const [errorCheckSeed, setErrorCheckSeed] = useState<ResponseError | null>(null);

  const checkSeed = async (seed: string) => {
    await api
      .checkSeed({ seed: seed })
      .then(() => {
        setSteepCurrent(4);
        setErrorCheckSeed(null);
      })
      .catch(setErrorCheckSeed);
  };

  useEffect(() => {
    if (!modal) {
      if (steepCurrent === 4) {
        onCreated(true);
      } else {
        setSteepCurrent(0);
        setWordCheck('');
      }
    }
    if (steepCurrent === 3) {
      const a = words;
      const b = checkWord.trim().toLowerCase();
      if (a.toString() === b.toString()) {
        setDisabledBtn(false);
      } else {
        setDisabledBtn(true);
      }
    } else {
      setDisabledBtn(false);
    }
  }, [modal, steepCurrent, checkWord]);

  return (
    <CreateWalletWrapper>
      <CreateWalletText>
        To get payments from your customers you can create your own wallet protected with
        seed-phrase.
      </CreateWalletText>
      <CreateWalletBtn onClick={() => setModal(true)}>
        <CreateWalletBtnImg />
        Create wallet
      </CreateWalletBtn>
      <CreateWalletText>
        Or you can set up the auto-withdrawal of income to your existing wallet in the
        <Link href="/settings/gateway/">
          <a> Gateway settings.</a>
        </Link>
      </CreateWalletText>
      {modal && (
        <Modal
          title={steeps[steepCurrent].title}
          onBack={() => {
            steepCurrent === 0 ? setModal(false) : setSteepCurrent((prev) => prev - 1);
          }}
          backText={steepCurrent === 0 ? 'Cancel' : 'Back'}
          backVisible={steepCurrent === 4 ? false : true}
          onNext={() => {
            if (steepCurrent === 0 || steepCurrent === 2) {
              setSteepCurrent((prev) => prev + 1);
            }
            if (steepCurrent === 1) {
              createWallet();
            }
            if (steepCurrent === 3) {
              checkSeed(checkWord);
            }
            if (steepCurrent === 4) {
              setModal(false);
              onCreated(true);
            }
          }}
          nextText={steeps[steepCurrent].btn}
          nextDisabled={disabledBtn}
          onClose={setModal}>
          {steepCurrent === 0 && (
            <ModalText>
              <p>When customer pay you their crypto funds are sending to your wallet.</p>
              <p>Wallet includes all your crypto addresses to accept payments.</p>
              <p>
                You have complete control of your wallet. We can’t sign transactions without seed
                phrase.
              </p>
            </ModalText>
          )}
          {steepCurrent === 1 && (
            <>
              <ModalText>
                <p>To set up your wallet you need seed phrase. It consists of 12 words.</p>
                <p>No one can get access to your wallet without seed phrase.</p>
                <p>
                  Your seed phrase acts like the key to your wallet. You should store it in secure
                  place!
                </p>
              </ModalText>

              {errorCreateWallet && (
                <Message variant={'error'} onClose={() => setErrorCreateWallet(null)}>
                  <ErrorMessage error={errorCreateWallet.message} />
                </Message>
              )}
            </>
          )}
          {steepCurrent === 2 && (
            <Stack gutter={'lg'}>
              <Textarea
                defaultValue={words}
                label={'Please write down your seed phrase'}
                disabled
              />
              <ModalText>Your will confirm this phrase on the next screen</ModalText>
            </Stack>
          )}
          {steepCurrent === 3 && (
            <>
              <Textarea
                label="Type your seed phrase here:"
                defaultValue={checkWord}
                onChange={setWordCheck}
              />

              {errorCheckSeed && (
                <>
                  <br />
                  <Message variant={'error'} onClose={() => setErrorCheckSeed(null)}>
                    <ErrorMessage error={errorCheckSeed.message} />
                  </Message>
                </>
              )}
            </>
          )}
          {steepCurrent === 4 && (
            <>
              <ModalCongratulationsImg>
                <IconCongratulations />
              </ModalCongratulationsImg>
              <ModalText>
                <p>Now you can start accept payments on your new wallet!</p>
                <p>
                  You can manage payment methods in the
                  <Link href="/settings/gateway/">
                    <a> Gateway settings</a>
                  </Link>
                </p>
              </ModalText>
            </>
          )}
        </Modal>
      )}
    </CreateWalletWrapper>
  );
}
