// @ts-nocheck
import { forwardRef } from 'react';
const SvgDeposit = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M5.11538 8.92308C4.95217 8.92308 4.79565 8.85824 4.68024 8.74284C4.56483 8.62743 4.5 8.4709 4.5 8.30769V4.61538C4.5 4.45217 4.56483 4.29565 4.68024 4.18024C4.79565 4.06483 4.95217 4 5.11538 4H19.8846C20.0478 4 20.2044 4.06483 20.3198 4.18024C20.4352 4.29565 20.5 4.45217 20.5 4.61538V8.30769C20.5 8.4709 20.4352 8.62743 20.3198 8.74284C20.2044 8.85824 20.0478 8.92308 19.8846 8.92308"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M16.1924 7.07695H8.80777C8.4679 7.07695 8.19238 7.35247 8.19238 7.69234V16.3077C8.19238 16.6476 8.4679 16.9231 8.80777 16.9231H16.1924C16.5323 16.9231 16.8078 16.6476 16.8078 16.3077V7.69234C16.8078 7.35247 16.5323 7.07695 16.1924 7.07695Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M12.5005 13.8462C13.5201 13.8462 14.3466 13.0197 14.3466 12.0001C14.3466 10.9805 13.5201 10.1539 12.5005 10.1539C11.4808 10.1539 10.6543 10.9805 10.6543 12.0001C10.6543 13.0197 11.4808 13.8462 12.5005 13.8462Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M8.19238 20H16.8078"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgDeposit);
export default ForwardRef;
