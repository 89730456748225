import styled from 'styled-components';
import { Question } from '@use-gateway/components';

const QuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 754px;
  margin: 0 auto;

  & > * {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export interface QuestionModel {
  id: number;
  isOpen: boolean;
  question: string;
  answer: string;
}

interface QuestionsProps {
  questions: QuestionModel[];
  setQuestions: any;
}

export function Questions({ questions, setQuestions }: QuestionsProps) {
  const handleOpen = (id: number | string) => {
    setQuestions((prevState: QuestionModel[]) =>
      prevState.map((question) =>
        question.id === id
          ? { ...question, isOpen: !question.isOpen }
          : { ...question, isOpen: false }
      )
    );
  };

  return (
    <QuestionsWrapper>
      {questions.map((question) => (
        <Question
          key={question.id}
          question={question.question}
          answer={question.answer}
          isOpen={question.isOpen}
          setIsOpen={() => handleOpen(question.id)}
        />
      ))}
    </QuestionsWrapper>
  );
}
