import { ShortSystemStatus, SystemStatus, SystemStatusDto } from '@use-gateway/types';

export function getShortSystemStatus(systemStatus: SystemStatus): ShortSystemStatus {
  if (!systemStatus) return 'offline';

  const keys = Object.keys(systemStatus) as Array<keyof SystemStatusDto>;
  const online = keys.filter((key) => systemStatus[key]);

  if (online.length === keys.length) return 'online';
  if (online.length === 0) return 'offline';

  return 'warning';
}
