import { ReactNode } from 'react';
import { css } from 'styled-components';
import { styled } from '@use-gateway/theme';
import { BaseButton, ButtonContext, ButtonProps } from './button';

export interface ButtonsGroupProps extends ButtonProps {
  children?: ReactNode;
}

const BaseButtonsGroup = styled.div<ButtonsGroupProps>`
  display: flex;

  ${BaseButton} {
    ${({ theme, rounded }) => {
      const borderRadius = rounded ? theme.borderRadius.xl : theme.borderRadius.md;

      return css`
        position: relative;
        padding: 16px !important;
        border-radius: 0;

        &:first-child {
          border-top-left-radius: ${borderRadius};
          border-bottom-left-radius: ${borderRadius};
        }

        &:last-child {
          border-top-right-radius: ${borderRadius};
          border-bottom-right-radius: ${borderRadius};

          &::after {
            display: none !important;
          }
        }

        &::after {
          content: '';
          position: absolute;
          display: block;
          left: 100%;
          top: 0;
          bottom: 0;
          width: 0.5px;
          background-color: ${({ theme }) => theme.colors.light[100]};
          opacity: 0.5;
          z-index: 1;
        }
      `;
    }};
  }
`;

export function ButtonsGroup({
  variant,
  compact,
  disabled,
  rounded,
  children,
  align,
}: ButtonsGroupProps) {
  return (
    <BaseButtonsGroup rounded={rounded}>
      <ButtonContext.Provider
        value={{
          variant: variant ?? 'primary',
          compact: compact ?? false,
          rounded: rounded ?? false,
          disabled,
          align,
        }}>
        {children}
      </ButtonContext.Provider>
    </BaseButtonsGroup>
  );
}
