// @ts-nocheck
import { forwardRef } from 'react';
const SvgCarretLeft = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M9.24777 12.277L13.4993 8.45062C13.6924 8.27687 14 8.41388 14 8.67361L14 16.3264C14 16.5861 13.6924 16.7231 13.4993 16.5494L9.24776 12.723C9.11535 12.6038 9.11535 12.3962 9.24777 12.277Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCarretLeft);
export default ForwardRef;
