// @ts-nocheck
import { forwardRef } from 'react';
const SvgOpbnb = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 2496 2496"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_1_2)">
      <path
        fill="black"
        fillRule="evenodd"
        d="M1248 0C1937.3 0 2496 558.7 2496 1248C2496 1937.3 1937.3 2496 1248 2496C558.7 2496 0 1937.3 0 1248C0 558.7 558.7 0 1248 0Z"
        clipRule="evenodd"
      />
      <path
        fill="#F0B90B"
        d="M487.065 1247.87L488.283 1694.6L867.871 1917.97V2179.51L266.135 1826.59V1117.24L487.065 1247.87ZM487.065 801.139V1061.46L266 930.692V670.368L487.065 539.597L709.213 670.368L487.065 801.139ZM1026.39 670.368L1247.46 539.597L1469.61 670.368L1247.46 801.139L1026.39 670.368Z"
      />
      <path
        fill="#F0B90B"
        d="M646.806 1602.01V1340.47L867.871 1471.24V1731.56L646.806 1602.01ZM1026.39 2011.65L1247.46 2142.42L1469.61 2011.65V2271.97L1247.46 2402.74L1026.39 2271.97V2011.65ZM1786.65 670.368L2007.72 539.597L2229.86 670.368V930.692L2007.72 1061.46V801.139L1786.65 670.368ZM2007.72 1694.6L2008.93 1247.87L2230 1117.1V1826.46L1628.26 2179.38V1917.84L2007.72 1694.6Z"
      />
      <path fill="#F0B90B" d="M1849.19 1602.01L1628.13 1731.56V1471.24L1849.19 1340.47V1602.01Z" />
      <path
        fill="#F0B90B"
        d="M1849.19 893.735L1850.41 1155.28L1469.74 1378.64V1826.46L1248.68 1956.01L1027.61 1826.46V1378.64L646.941 1155.28V893.735L868.954 762.964L1247.32 987.413L1627.99 762.964L1850.14 893.735H1849.19ZM646.806 447.137L1247.46 93L1849.19 447.137L1628.13 577.908L1247.46 353.459L867.871 577.908L646.806 447.137Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_2">
        <rect width={2496} height={2496} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgOpbnb);
export default ForwardRef;
