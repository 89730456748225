// @ts-nocheck
import { forwardRef } from 'react';
const SvgDash = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 64 64"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_3630_36496)">
      <path
        fill="#008CE7"
        d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z"
      />
      <path
        fill="white"
        d="M38.1721 16.0081H23.6201L22.4161 22.7421L35.5401 22.7621C42.0021 22.7621 43.9201 25.1081 43.8581 29.0021C43.8301 30.9981 42.9601 34.3741 42.5921 35.4621C41.5981 38.3821 39.5501 41.7061 31.8741 41.6961L19.1181 41.6881L17.9141 48.4301H32.4281C37.5461 48.4301 39.7261 47.8321 42.0281 46.7701C47.1361 44.4141 50.1781 39.3681 51.4001 32.7821C53.2121 22.9821 50.9521 16.0081 38.1701 16.0081H38.1721Z"
      />
      <path
        fill="white"
        d="M31.6141 31.5961C32.0881 29.6261 32.2381 28.8361 32.2381 28.8361H17.3461C13.5381 28.8361 12.9941 31.3161 12.6321 32.8161C12.1581 34.7781 12.0081 35.5781 12.0081 35.5781H26.9021C30.7081 35.5781 31.2521 33.0981 31.6141 31.5961Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_3630_36496">
        <rect width={64} height={64} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgDash);
export default ForwardRef;
