// @ts-nocheck
import { forwardRef } from 'react';
const SvgVideoOff = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_2128_24658)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3 6C2.73478 6 2.48043 6.10536 2.29289 6.29289C2.10536 6.48043 2 6.73478 2 7V17C2 17.2652 2.10536 17.5196 2.29289 17.7071C2.48043 17.8946 2.73478 18 3 18H14C14.2652 18 14.5196 17.8946 14.7071 17.7071C14.8946 17.5196 15 17.2652 15 17V16.4142L4.58579 6H3ZM0.87868 4.87868C1.44129 4.31607 2.20435 4 3 4H5C5.26522 4 5.51957 4.10536 5.70711 4.29289L16.7071 15.2929C16.8946 15.4804 17 15.7348 17 16V17C17 17.7957 16.6839 18.5587 16.1213 19.1213C15.5587 19.6839 14.7957 20 14 20H3C2.20435 20 1.44129 19.6839 0.87868 19.1213C0.316071 18.5587 0 17.7956 0 17V7C0 6.20435 0.316071 5.44129 0.87868 4.87868ZM14 6H10.66C10.1077 6 9.66 5.55228 9.66 5C9.66 4.44772 10.1077 4 10.66 4H14C14.7956 4 15.5587 4.31607 16.1213 4.87868C16.6839 5.44129 17 6.20435 17 7V9.92579L17.1045 10.0303L22.4139 6.18975C22.7183 5.96958 23.1204 5.93857 23.4549 6.10947C23.7895 6.28037 24 6.62434 24 7V17C24 17.5523 23.5523 18 23 18C22.4477 18 22 17.5523 22 17V8.95752L17.5861 12.1503C17.1882 12.4381 16.6402 12.3944 16.2929 12.0471L15.2929 11.0471C15.1054 10.8596 15 10.6052 15 10.34V7C15 6.73478 14.8946 6.48043 14.7071 6.29289C14.5196 6.10536 14.2652 6 14 6Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L23.7071 22.2929C24.0976 22.6834 24.0976 23.3166 23.7071 23.7071C23.3166 24.0976 22.6834 24.0976 22.2929 23.7071L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_2128_24658">
        <rect width={24} height={24} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgVideoOff);
export default ForwardRef;
