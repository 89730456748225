// @ts-nocheck
import { forwardRef } from 'react';
const SvgCircleCheckFull = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.3107 8.04291C17.7012 8.43343 17.7012 9.0666 17.3107 9.45712L11.1036 15.9571C10.713 16.3476 10.0799 16.3476 9.68935 15.9571L6.68935 12.9571C6.29882 12.5666 6.29882 11.9334 6.68935 11.5429C7.07987 11.1524 7.71304 11.1524 8.10356 11.5429L10.3965 13.8358L15.8965 8.04291C16.287 7.65238 16.9201 7.65238 17.3107 8.04291Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCircleCheckFull);
export default ForwardRef;
