import { ReactNode } from 'react';
import { down } from 'styled-breakpoints';
import { styled } from '@use-gateway/theme';
import { styledTransition } from '@use-gateway/utils';

const TabsWrapper = styled.div`
  display: flex;
  justify-content: stretch;
  margin-bottom: 20px;

  ${down('sm')} {
    position: sticky;
    top: -5px;
    background-color: ${({ theme }) => theme.colors.light[100]}bb;
    backdrop-filter: blur(5px);
    margin: -5px -5px 16px;
    z-index: 1;
  }
`;

export const Tab = styled.div`
  width: 100%;

  a {
    position: relative;

    display: block;
    display: flex;
    justify-content: center;

    width: 100%;
    height: 100%;
    padding: 4px;
    box-sizing: border-box;

    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.6;

    color: ${({ theme }) => theme.colors.light[40]};
    text-decoration: none;

    ${styledTransition(['color'])}

    ${down('sm')} {
      font-size: 16px;
      padding: 2px;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 100%;
      height: 2px;

      ${styledTransition(['all'])}

      ${down('md')} {
        height: 1px;
      }
    }

    &::before {
      left: 10px;
      right: 10px;
      background-color: ${({ theme }) => theme.colors.light[60]};

      ${down('md')} {
        left: 1px;
        right: 1px;
      }
    }

    &::after {
      left: 50%;
      right: 50%;
      opacity: 0;
      background-color: ${({ theme }) => theme.colors.dark[100]};
    }

    &:hover,
    &.active {
      color: ${({ theme }) => theme.colors.dark[100]};

      &::after {
        left: 10px;
        right: 10px;
        opacity: 1;

        ${down('md')} {
          height: 1px;
          left: 1px;
          right: 1px;
        }
      }
    }
  }
`;

export interface TabsProps {
  children: ReactNode;
}
export function Tabs({ children }: TabsProps) {
  return <TabsWrapper>{children}</TabsWrapper>;
}
