// @ts-nocheck
import { forwardRef } from 'react';
const SvgZapOff = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_2128_24699)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.3975 1.08241C13.8049 1.25888 14.0471 1.68274 13.9924 2.12326L13.4024 6.87326C13.3343 7.42134 12.8348 7.81045 12.2867 7.74237C11.7387 7.6743 11.3495 7.17481 11.4176 6.62674L11.5873 5.26091L11.3387 5.55967C10.9854 5.98418 10.3548 6.04193 9.93033 5.68865C9.50582 5.33537 9.44807 4.70485 9.80135 4.28033L12.2313 1.36033C12.5153 1.01912 12.9902 0.905944 13.3975 1.08241Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14.66 10C14.66 9.44771 15.1077 9 15.66 9H21C21.3882 9 21.7413 9.22467 21.9058 9.5763C22.0703 9.92794 22.0164 10.343 21.7676 10.641L19.3376 13.551C18.9836 13.9749 18.353 14.0316 17.929 13.6776C17.5051 13.3236 17.4484 12.693 17.8024 12.269L18.8621 11H15.66C15.1077 11 14.66 10.5523 14.66 10Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.64018 7.23178C9.06446 7.58534 9.12179 8.21591 8.76822 8.64018L5.13504 13H12C12.2868 13 12.5599 13.1232 12.7497 13.3382C12.9395 13.5532 13.0279 13.8394 12.9923 14.124L12.4153 18.7395L15.2318 15.3598C15.5853 14.9355 16.2159 14.8782 16.6402 15.2318C17.0645 15.5853 17.1218 16.2159 16.7682 16.6402L11.7682 22.6402C11.484 22.9812 11.009 23.0941 10.6018 22.9173C10.1946 22.7405 9.95266 22.3165 10.0077 21.876L10.8672 15H3C2.61198 15 2.25899 14.7755 2.09441 14.4242C1.92983 14.0728 1.98338 13.6579 2.23178 13.3598L7.23178 7.35982C7.58534 6.93554 8.21591 6.87821 8.64018 7.23178Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L23.7071 22.2929C24.0976 22.6834 24.0976 23.3166 23.7071 23.7071C23.3166 24.0976 22.6834 24.0976 22.2929 23.7071L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_2128_24699">
        <rect width={24} height={24} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgZapOff);
export default ForwardRef;
