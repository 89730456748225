import { useCallback } from 'react';
import { ErrorScreen } from './error-screen';

export function Error500() {
  const reloadPage = useCallback(() => window.location.reload(), []);

  return (
    <ErrorScreen code={500} title={'Something went wrong...'}>
      <p>Our developers were automatically informed about the problem.</p>
      <p>
        You can try to <a onClick={reloadPage}>refresh page</a> or go to <a href={'/'}>main page</a>
        .
      </p>
    </ErrorScreen>
  );
}
