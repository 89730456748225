import styled from 'styled-components';
import { Textarea } from '.';

const WordListWrapper = styled.div`
  margin-bottom: 32px;
`;

const WordListTitle = styled.label`
  display: block;
  margin-bottom: 18px;
  padding: 0 18px;
  letter-spacing: -0.01em;
`;

const WordListItems = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px 10px;
`;

const WordListItem = styled.input`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  resize: none;
  width: 100%;
  height: 40px;
  border: none;
  margin: 0;
  padding: 0;
  text-align: center;
  &:disabled {
    background-color: ${({ theme }) => theme.colors.light[80]};
    color: ${({ theme }) => theme.colors.dark[100]};
    font-weight: 500;
    font-size: 14px;
  }
`;

interface WordListProps {
  title?: string;
  words: string;
}
export function WordList({ title, words }: WordListProps) {
  const arrWords: string[] = words.split(' ');

  return (
    <WordListWrapper>
      {title && <WordListTitle>{title}</WordListTitle>}
      <Textarea defaultValue={words} label={title} disabled />
      <WordListItems>
        {arrWords.map((word, index) => (
          <WordListItem key={index} value={`${index + 1} ${word}`} type="text" disabled />
        ))}
      </WordListItems>
    </WordListWrapper>
  );
}
