// @ts-nocheck
import { forwardRef } from 'react';
const SvgNavigation = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22.7071 1.29289C23.0059 1.59172 23.0847 2.04615 22.9037 2.42808L13.9037 21.4281C13.7229 21.8098 13.3218 22.0368 12.9015 21.9951C12.4811 21.9535 12.1323 21.6523 12.0299 21.2425L10.1754 13.8246L2.75746 11.9701C2.3477 11.8677 2.04648 11.5189 2.00486 11.0985C1.96325 10.6782 2.1902 10.2771 2.57191 10.0963L21.5719 1.09626C21.9538 0.915345 22.4083 0.994058 22.7071 1.29289ZM5.95335 10.7076L11.2425 12.0299C11.6008 12.1194 11.8806 12.3992 11.9701 12.7575L13.2924 18.0466L19.8976 4.10238L5.95335 10.7076Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgNavigation);
export default ForwardRef;
