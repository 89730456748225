export enum Steps {
  Fetching = 'Fetching',
  Open = 'Opened',
  Viewed = 'Viewed',
  Renewed = 'Renewed',
  Pending = 'Pending',
  Completed = 'Completed',
  Unresolved = 'Unresolved',
  Resolved = 'Resolved',
  Expired = 'Expired',
}
