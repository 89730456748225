import { createContext, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import * as Sentry from '@sentry/nextjs';
import { api } from '@use-gateway/api';
import { UnknownFunction, UserDto } from '@use-gateway/types';
import { useWallet } from './wallet-provider';

interface AuthContext {
  user: UserDto | null;
  setUser: (value: UserDto | null) => void;
  refetch: () => Promise<void>;
}

const AuthContext = createContext<AuthContext | null>(null);

export const AuthProvider = AuthContext.Provider;
export const useAuth = () => {
  const { replace } = useRouter();
  const { clearWallet } = useWallet();
  const { user, refetch, setUser } = useContext(AuthContext) as AuthContext;
  const hasAuth = !!user;

  const logout = async () => {
    Sentry.setUser(null);
    setUser(null);
    clearWallet();
    await api.logout();

    setTimeout(() => replace('/login'));
  };

  const onHasAuth = (cb: UnknownFunction) => {
    useEffect(() => {
      if (user) cb(user);
    }, [user]);
  };

  const onHasNoAuth = (cb: UnknownFunction) => {
    useEffect(() => {
      if (!user) {
        cb();
        return () => null;
      }

      api.setUnauthorizedCallback(() => {
        logout();
        cb();
      });
      return () => api.setUnauthorizedCallback(undefined);
    }, [user]);
  };

  return {
    user,
    hasAuth,
    onHasAuth,
    onHasNoAuth,
    logout,
    refetch: refetch as () => Promise<void>,
  };
};
