import {
  CheckSeedPayload,
  CryptoCurrency,
  GetFeesDto,
  GetMaxDto,
  RatesDto,
  WalletCreationDto,
  WalletDto,
} from '@use-gateway/types';
import { Api } from '..';

const basePath = '/wallets';

export async function createWallet(this: Api) {
  return this.post<WalletCreationDto>(`${basePath}/`);
}

export async function checkSeed(this: Api, payload: CheckSeedPayload) {
  return this.post(`${basePath}/check-seed`, payload);
}

export async function getBalance(this: Api) {
  return this.get<WalletDto>(`${basePath}/get_balance`);
}

export async function getWalletRates(this: Api) {
  return this.get<RatesDto>(`${basePath}/get_rates`);
}

export async function getFees(
  this: Api,
  cryptocurrency: CryptoCurrency,
  amount: number,
  net_fee_multiplier?: number
) {
  const query = new URLSearchParams();
  query.set('amount', amount.toString());
  if (net_fee_multiplier) {
    query.set('net_fee_multiplier', net_fee_multiplier.toString());
  }

  return this.get<GetFeesDto>(`${basePath}/get_fees/${cryptocurrency}?${query.toString()}`);
}

export async function getMaxCryptoCurrency(
  this: Api,
  cryptocurrency: CryptoCurrency,
  net_fee_multiplier?: number
) {
  const query = new URLSearchParams();
  if (net_fee_multiplier) {
    query.set('net_fee_multiplier', net_fee_multiplier.toString());
  }

  return this.get<GetMaxDto>(`${basePath}/get_max_amount/${cryptocurrency}?${query.toString()}`);
}
