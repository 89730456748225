// @ts-nocheck
import { forwardRef } from 'react';
const SvgAffiliate = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={1.2}
      d="M6.11103 16.4L12 6.2L17.889 16.4H6.11103Z"
    />
    <circle cx={12} cy={6} r={2.4} fill="currentColor" stroke="currentColor" strokeWidth={1.2} />
    <circle cx={6} cy={17} r={2.4} fill="currentColor" stroke="currentColor" strokeWidth={1.2} />
    <circle cx={18} cy={17} r={2.4} fill="currentColor" stroke="currentColor" strokeWidth={1.2} />
  </svg>
);
const ForwardRef = forwardRef(SvgAffiliate);
export default ForwardRef;
