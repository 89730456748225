// @ts-nocheck
import { forwardRef } from 'react';
const SvgUnderline = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6 2C6.55228 2 7 2.44772 7 3V10C7 11.3261 7.52678 12.5979 8.46447 13.5355C9.40215 14.4732 10.6739 15 12 15C13.3261 15 14.5979 14.4732 15.5355 13.5355C16.4732 12.5979 17 11.3261 17 10V3C17 2.44772 17.4477 2 18 2C18.5523 2 19 2.44772 19 3V10C19 11.8565 18.2625 13.637 16.9497 14.9497C15.637 16.2625 13.8565 17 12 17C10.1435 17 8.36301 16.2625 7.05025 14.9497C5.7375 13.637 5 11.8565 5 10V3C5 2.44772 5.44772 2 6 2Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 21C3 20.4477 3.44772 20 4 20H20C20.5523 20 21 20.4477 21 21C21 21.5523 20.5523 22 20 22H4C3.44772 22 3 21.5523 3 21Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgUnderline);
export default ForwardRef;
