import styled from 'styled-components';
import { Checkbox, Status } from '@use-gateway/components';

const ItemWrapper = styled.div`
  cursor: pointer;
`;

const Item = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 224px;
  padding: 12px;
  pointer-events: none;

  label {
    font-size: 14px;
  }
`;

interface DropdownStatusProps {
  isUnresolved?: boolean;
  setIsUnresolved: (status?: boolean) => void;
}

export function StatusDropdown({ isUnresolved, setIsUnresolved }: DropdownStatusProps) {
  const handleStatus = (value: boolean) => {
    value === isUnresolved ? setIsUnresolved(undefined) : setIsUnresolved(value);
  };

  return (
    <div>
      <ItemWrapper onClick={() => handleStatus(false)}>
        <Item>
          <Checkbox value={isUnresolved === false} isCheckValue>
            Resolved/Completed
          </Checkbox>
          <Status status={'success'} />
        </Item>
      </ItemWrapper>

      <ItemWrapper onClick={() => handleStatus(true)}>
        <Item>
          <Checkbox value={isUnresolved === true} isCheckValue>
            Unresolved
          </Checkbox>
          <Status status={'error'} />
        </Item>
      </ItemWrapper>
    </div>
  );
}
