import { createContext, useContext } from 'react';
import { PaymentResponseDto, Steps } from '@use-gateway/types';

export interface PaymentContext {
  payment: PaymentResponseDto;
  step: Steps;
  formattedDate: string;
  finalRate: string | null;
}

export const PaymentContext = createContext<PaymentContext | null>(null);

export const usePayment = () => {
  const { payment, formattedDate, step, finalRate } = useContext(PaymentContext) as PaymentContext;

  return { payment, formattedDate, step, finalRate };
};
