// @ts-nocheck
import { forwardRef } from 'react';
const SvgBch = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 320 320"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_18_8)">
      <path
        fill="#0AC18E"
        d="M160 320C248.366 320 320 248.366 320 160C320 71.6344 248.366 0 160 0C71.6344 0 0 71.6344 0 160C0 248.366 71.6344 320 160 320Z"
      />
      <path
        fill="white"
        d="M209.908 106.274C201.868 88.0406 183.391 84.1421 160.771 87.9188L153.502 59.7361L136.365 64.1624L143.512 92.264C139.005 93.401 134.375 94.3756 129.787 95.6751L122.639 67.7361L105.502 72.1624L112.771 100.345C109.076 101.401 78.1724 109.32 78.1724 109.32L82.883 127.675C82.883 127.675 95.4719 124.142 95.35 124.426C102.335 122.599 105.624 126.091 107.167 129.381L127.147 206.619C127.391 208.853 126.985 212.67 122.193 213.97C122.477 214.132 109.726 217.178 109.726 217.178L111.594 238.579C111.594 238.579 142.213 230.741 146.233 229.726L153.584 258.234L170.721 253.807L163.37 225.096C168.081 224 172.67 222.863 177.137 221.685L184.446 250.234L201.584 245.807L194.233 217.34C220.629 210.924 239.269 194.274 235.452 168.812C233.015 153.462 216.243 140.873 202.314 139.452C210.883 131.858 215.228 120.772 209.908 106.274ZM201.665 173.482C205.076 198.701 170.03 201.787 158.457 204.832L148.386 167.107C160 164.061 195.898 151.269 201.665 173.482ZM180.548 122.112C184.162 144.528 154.193 147.127 144.528 149.604L135.35 115.371C145.056 112.975 173.198 101.36 180.548 122.112Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_18_8">
        <rect width={320} height={320} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgBch);
export default ForwardRef;
