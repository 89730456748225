// @ts-nocheck
import { forwardRef } from 'react';
const SvgVolume2 = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.4332 4.09871C11.7797 4.26523 12 4.6156 12 5.00001V19C12 19.3844 11.7797 19.7348 11.4332 19.9013C11.0867 20.0678 10.6755 20.021 10.3753 19.7809L5.64922 16H2C1.44772 16 1 15.5523 1 15V9.00001C1 8.44772 1.44772 8.00001 2 8.00001H5.64922L10.3753 4.21914C10.6755 3.979 11.0867 3.93219 11.4332 4.09871ZM10 7.08063L6.62469 9.78088C6.44738 9.92273 6.22707 10 6 10H3V14H6C6.22707 14 6.44738 14.0773 6.62469 14.2191L10 16.9194V7.08063Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.363 4.22278C18.7536 3.83231 19.3867 3.83241 19.7772 4.22299C21.8394 6.2858 22.9979 9.08319 22.9979 12C22.9979 14.9168 21.8394 17.7142 19.7772 19.777C19.3867 20.1676 18.7536 20.1677 18.363 19.7772C17.9724 19.3867 17.9723 18.7536 18.3628 18.363C20.05 16.6752 20.9979 14.3865 20.9979 12C20.9979 9.61352 20.05 7.32474 18.3628 5.63699C17.9723 5.24641 17.9724 4.61324 18.363 4.22278ZM14.833 7.75278C15.2236 7.36231 15.8567 7.36241 16.2472 7.75299C17.372 8.87816 18.0039 10.404 18.0039 11.995C18.0039 13.586 17.372 15.1118 16.2472 16.237C15.8567 16.6276 15.2236 16.6277 14.833 16.2372C14.4424 15.8467 14.4423 15.2136 14.8328 14.823C15.5827 14.0729 16.0039 13.0556 16.0039 11.995C16.0039 10.9343 15.5827 9.9171 14.8328 9.16699C14.4423 8.77641 14.4424 8.14324 14.833 7.75278Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgVolume2);
export default ForwardRef;
