// @ts-nocheck
import { forwardRef } from 'react';
const SvgMessageCircle = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20 11.5C20 6.06979 14.0662 2.30985 9.15085 4.7926L9.14706 4.79452L9.14706 4.79451C5.52859 6.60296 3.95644 11.2139 5.79259 14.8492C5.91281 15.0871 5.933 15.3633 5.84868 15.6162L4.58114 19.4189L8.38377 18.1513C8.63671 18.067 8.91286 18.0872 9.15085 18.2074C14.0074 20.6605 20.0139 16.841 20 11.5026L20 11.5ZM22 11.4989C21.9992 4.61379 14.5263 -0.161468 8.25103 3.00646C3.78429 5.24015 1.77605 10.7956 3.82398 15.3658L2.05131 20.6838C1.93154 21.0431 2.02506 21.4393 2.29289 21.7071C2.56072 21.9749 2.95689 22.0685 3.31622 21.9487L8.63396 20.1761C14.7713 22.9323 22.0163 18.1596 22 11.4989Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgMessageCircle);
export default ForwardRef;
