import { ReactNode } from 'react';
import { styled } from '@use-gateway/theme';
import { Logo } from './logo';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  width: calc(100% - 32px);
  max-width: 328px;
  height: 100vh;
  margin: 0 auto;

  a img {
    display: block;
    margin: 0 auto;
  }

  a {
    cursor: pointer;
  }

  p {
    font-size: 16px;
    font-weight: 300;
    color: #8e90a7;
    margin: 0.5em 0;
  }
`;

const Code = styled.span`
  font-family: 'Nunito', sans-serif;
  padding: 42px 0;
  margin: 0;
  font-weight: 500;
  font-size: 64px;
  color: ${({ theme }) => theme.colors.dark[100]};
  text-align: center;
`;

const Header = styled.h1`
  font-family: 'Nunito', sans-serif;
  font-size: 20px;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.dark[100]};
  margin: 0.5em 0;
`;

export interface ErrorScreenProps {
  code: string | number;
  title: string;
  children?: ReactNode;
}
export function ErrorScreen({ code, title, children }: ErrorScreenProps) {
  return (
    <Wrapper>
      <Logo />
      <Code>{code.toString()}</Code>
      <Header>{title.toUpperCase()}</Header>
      {children}
    </Wrapper>
  );
}
