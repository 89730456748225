import { useEffect, useRef, useState } from 'react';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { down } from 'styled-breakpoints';
import {
  IconApi,
  IconArrowMenuLeft,
  IconChevronRight,
  IconEye,
  IconEyeOff,
  IconGuide,
  IconNews,
  IconRequest,
  IconRequestMenu,
  IconSettings,
  IconSignOut,
  IconX,
} from '@use-gateway/icons';
import { styled } from '@use-gateway/theme';
import { CommonEvents, FiatCurrency, ProjectNames, UnknownFunction } from '@use-gateway/types';
import {
  getGuideUrl,
  price,
  useAuth,
  useEventBus,
  useNoticeable,
  useWallet,
} from '@use-gateway/utils';
import { Link } from '../link';
import { LogoMini } from '../logo-mini';

const MobileHeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2500;

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 76px;
  padding: 0 16px;

  background-color: ${({ theme }) => theme.colors.light[100]};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.03);
`;

const MobileHeaderCenter = styled.div`
  box-sizing: border-box;
  position: absolute;
  left: 60px;
  right: 60px;

  display: flex;
  justify-content: center;
  width: calc(100% - 120px);
`;

const BurgerMenu = styled.button`
  position: relative;
  display: flex;
  z-index: 2500;

  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.dark[100]};
  font-size: 24px;

  svg {
    min-width: 24px;
  }
`;

interface HeaderNavProps {
  isActive: boolean;
}

const HeaderNav = styled.nav<HeaderNavProps>`
  box-sizing: border-box;
  overflow-y: auto;
  z-index: 2250;
  position: fixed;
  top: 76px;
  bottom: 0;
  left: ${({ isActive }) => (isActive ? '0' : '-150%')};

  width: 100%;
  height: calc(100vh - 63px);
  padding: 24px 16px;

  background-color: ${({ theme }) => theme.colors.light[100]};
  transition: 0.3s;

  hr {
    height: 1px;
    border: 0;
    border-top: 1px solid #d2d5da;
    margin: 24px 0;
    padding: 0;
  }
`;

interface NavItemProps {
  isActive?: boolean;
}

const NavItem = styled.div<NavItemProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    font-size: 24px;
  }

  & > a {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: ${({ isActive }) => (isActive ? '56px' : '40px')};
    padding: 0 24px;
    border-radius: 10px;
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.colors.light[80] : 'transparent'};
    color: ${({ theme }) => theme.colors.dark[100]};
    text-decoration: none;
    transition: 0.3s;

    &:active,
    &.active {
      color: ${({ theme }) => theme.colors.primary};
    }

    label {
      display: flex;
      align-items: center;
      font-size: 18px;

      svg {
        margin-right: 16px;
        color: ${({ theme, isActive }) =>
          isActive ? theme.colors.info[100] : theme.colors.dark[100]};
      }
    }

    & > svg {
      color: ${({ theme, isActive }) =>
        isActive ? theme.colors.info[100] : theme.colors.dark[100]};
      transform: ${({ isActive }) => (isActive ? 'rotate(90deg)' : 'none')};
    }
  }

  ul {
    display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
    flex-direction: column;
    list-style: none;
    padding: 0;

    li {
      box-sizing: border-box;
      width: 100%;
      margin-bottom: 4px;

      a {
        box-sizing: border-box;
        display: block;
        width: 100%;
        padding: 8px 60px;
        color: ${({ theme }) => theme.colors.dark[100]};
        text-decoration: none;

        &.active {
          color: ${({ theme }) => theme.colors.info[100]};
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const CountNews = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  min-width: 18px;
  padding: 2px 4px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.info[100]};
  font-size: 9px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.light[100]};
`;

const MoneyTotal = styled.div`
  display: flex;
  align-items: center;
  font-size: 30px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.dark[100]};

  svg {
    margin-left: 12px;
    font-size: 24px;
    color: #cccccc;
  }
`;

const Total = styled.div`
  overflow: hidden;
  max-width: 190px;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${down('xs')} {
    max-width: 164px;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  h2 {
    margin: 0 0 4px;
    font-weight: 500;
    font-size: 24px;
    line-height: 1;
  }

  h4 {
    margin: 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 1;
    color: ${({ theme }) => theme.colors.info[100]};
  }
`;

const MenuBack = styled.div`
  font-size: 24px;
  line-height: 1;
`;

interface MobilePanelHeaderProps {
  title?: string;
  subtitle?: string;
  onBack?: UnknownFunction;
  isClose?: boolean;
}

const { publicRuntimeConfig } = getConfig();
const { PROJECT_NAME } = publicRuntimeConfig;

export function MobilePanelHeader({ title, subtitle, onBack, isClose }: MobilePanelHeaderProps) {
  const { user } = useAuth();
  const { subscribe } = useEventBus();
  const { wallet } = useWallet();
  const { logout } = useAuth();
  const { newsCount, toggleWidget } = useNoticeable();
  const { push, pathname } = useRouter();

  const [total, setTotal] = useState(0);
  const [isShowBalance, setIsShowBalance] = useState(
    localStorage.getItem('hiddenBalance') ? false : true
  );
  const [expended, setExpended] = useState(false);
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  subscribe(CommonEvents.openMobMenu, () => setExpended(true));

  const closeNav = () => {
    setExpended(false);
    setActiveItem(null);
  };

  useEffect(() => {
    let count = 0;
    for (const key in wallet) {
      if (wallet[key]) count = count + wallet[key].local;
    }
    setTotal(count);
  }, [wallet]);

  useEffect(() => {
    if (isShowBalance) {
      const container = containerRef.current;

      if (container) {
        const maxWidth = container?.clientWidth;
        let fontSize = 30;
        while (container?.scrollWidth > maxWidth && fontSize > 16) {
          fontSize -= 1;
          container.style.fontSize = `${fontSize}px`;
        }
      }
    }
  }, [containerRef.current, isShowBalance]);

  useEffect(() => {
    if (expended) {
      if (pathname.split('/')[1] === 'settings') {
        setActiveItem('settings');
      }
    }
  }, [expended]);

  return (
    <>
      <MobileHeaderWrapper>
        {onBack && !expended ? (
          <MenuBack onClick={onBack}>
            <IconArrowMenuLeft />
          </MenuBack>
        ) : (
          <LogoMini />
        )}
        <MobileHeaderCenter>
          {title ? (
            <Title>
              <h2>{title}</h2>
              {subtitle && <h4>{subtitle}</h4>}
            </Title>
          ) : (
            <MoneyTotal>
              {isShowBalance ? (
                <Total ref={containerRef}>
                  {price({
                    currency: user?.local_currency as FiatCurrency,
                    amount: total,
                  })}
                </Total>
              ) : (
                <>* * *</>
              )}
              {isShowBalance ? (
                <div
                  onClick={() => {
                    setIsShowBalance(false);
                    localStorage.setItem('hiddenBalance', 'true');
                  }}>
                  <IconEye />
                </div>
              ) : (
                <div
                  onClick={() => {
                    setIsShowBalance(true);
                    localStorage.removeItem('hiddenBalance');
                  }}>
                  <IconEyeOff />
                </div>
              )}
            </MoneyTotal>
          )}
        </MobileHeaderCenter>
        {expended && (
          <BurgerMenu onClick={closeNav}>
            <IconX />
          </BurgerMenu>
        )}
        {!expended && isClose && (
          <BurgerMenu onClick={() => push('/')}>
            <IconX />
          </BurgerMenu>
        )}
      </MobileHeaderWrapper>

      <HeaderNav isActive={expended}>
        {PROJECT_NAME === ProjectNames.UseGateway && (
          <NavItem>
            <a
              onClick={() => {
                toggleWidget();
                closeNav();
              }}>
              <label>
                <IconNews />
                What’s new?
              </label>
              {!!newsCount && <CountNews>{newsCount > 99 ? '99+' : newsCount}</CountNews>}
            </a>
          </NavItem>
        )}
        <NavItem
          isActive={activeItem === 'settings'}
          onClick={() => {
            activeItem === 'settings' ? setActiveItem(null) : setActiveItem('settings');
          }}>
          <a>
            <label>
              <IconSettings />
              Settings
            </label>
            <IconChevronRight />
          </a>
          <ul>
            <li>
              <Link href="/settings/account/">
                <a onClick={closeNav}>Account</a>
              </Link>
            </li>
            <li>
              <Link href="/settings/gateway/">
                <a onClick={closeNav}>Gateway</a>
              </Link>
            </li>
            <li>
              <Link href="/settings/security/">
                <a onClick={closeNav}>Security</a>
              </Link>
            </li>
            <li>
              <Link href="/settings/notifications/">
                <a onClick={closeNav}>Notifications</a>
              </Link>
            </li>
          </ul>
        </NavItem>
        <NavItem>
          <Link href="/requests/">
            <a onClick={closeNav}>
              <label>
                <IconRequest />
                Requests
              </label>
            </a>
          </Link>
        </NavItem>
        <hr />
        {PROJECT_NAME === ProjectNames.UseGateway && (
          <NavItem>
            <a href={getGuideUrl()} target={'_blank'} rel="noreferrer" onClick={closeNav}>
              <label>
                <IconGuide />
                Guide
              </label>
            </a>
          </NavItem>
        )}
        {PROJECT_NAME === ProjectNames.UseGateway && (
          <NavItem>
            <a
              href={'https://docs.usegateway.net/'}
              target={'_blank'}
              rel="noreferrer"
              onClick={closeNav}>
              <label>
                <IconApi />
                API
              </label>
            </a>
          </NavItem>
        )}
        {PROJECT_NAME === ProjectNames.UseGateway && (
          <NavItem>
            <a href={'mailto:support@usegateway.group'} onClick={closeNav}>
              <label>
                <IconRequestMenu />
                Submit a request
              </label>
            </a>
          </NavItem>
        )}
        <NavItem>
          <a onClick={logout}>
            <label>
              <IconSignOut />
              Exit
            </label>
          </a>
        </NavItem>
      </HeaderNav>
    </>
  );
}
