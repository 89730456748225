import { Steps, TimelineItem } from '@use-gateway/types';

export function detectStep(timeline: Array<TimelineItem>): Steps {
  if (!timeline) return Steps.Fetching;

  const lastEvent = Array.from(timeline).pop();

  if (lastEvent?.status === Steps.Pending) return Steps.Pending;
  if (lastEvent?.status === Steps.Open) return Steps.Open;
  if (lastEvent?.status === Steps.Viewed) return Steps.Open;
  if (lastEvent?.status === Steps.Renewed) return Steps.Open;
  if (lastEvent?.status === Steps.Completed) return Steps.Completed;
  if (lastEvent?.status === Steps.Expired) return Steps.Expired;
  if (lastEvent?.status === Steps.Resolved) return Steps.Resolved;

  return Steps.Unresolved;
}
