import { ErrorResponse } from '@use-gateway/types';
import { httpCodes } from '@use-gateway/utils';

export class ResponseError extends Error {
  fields?: Record<string, Error>;
  response?: Response;
  data?: ErrorResponse;
  code?: number;

  constructor(message?: string, options?: { cause?: Error }) {
    super(message, options);
  }

  async addResponse(response: Response) {
    this.response = response.clone();
    this.data = await response.json();
    this.code = response.status;

    if (typeof this.data?.detail === 'string') {
      this.message = this.data?.detail || httpCodes[this.code];
    } else {
      this.message = httpCodes[this.code];
      this.fields = {};

      this.data?.detail.forEach((field) => {
        const location = Array.from(field.loc).slice(1).join('.');
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.fields![location] = new Error(field.msg);
      });
    }
  }
}
