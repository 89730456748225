import { useCallback } from 'react';
import Link from 'next/link';
import { down } from 'styled-breakpoints';
import { Stack } from '@bedrock-layout/stack';
import { Button, Logo } from '@use-gateway/components';
import { styled } from '@use-gateway/theme';
import imageTechnical from './assets/technical-works.png';
import imageTechnicalX2 from './assets/technical-works@2x.png';
import imageTechnicalWebp from './assets/technical-works@2x.webp';

const TechnicalWorksWrapper = styled.div`
  width: calc(100% - 64px);
  max-width: 1200px;
  margin: 0 auto;

  ${down('sm')} {
    width: calc(100% - 16px);
  }
`;

const TechnicalWorksHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 18px 0 32px;
`;

const TechnicalWorksContent = styled.main`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;

  width: fit-content;
  height: fit-content;
  padding: 30px 0;
  margin: auto;

  ${down('sm')} {
    top: 60px;
  }

  img {
    width: 280px;
    height: 280px;
    margin-bottom: 56px;

    ${down('sm')} {
      width: 240px;
      height: 240px;
    }
  }

  p {
    width: fit-content;
    margin: 0 auto 32px;
    font-size: 20px;
    line-height: 1.3;
    text-align: center;

    ${down('sm')} {
      font-size: 18px;
      line-height: 1.44;
    }
  }

  a {
    text-decoration: none;
  }

  button {
    max-width: 260px;
    margin: 0 auto;
  }
`;

export interface TechnicalWorksProps {
  from?: string;
}
export function TechnicalWorks({ from }: TechnicalWorksProps) {
  const refresh = useCallback(() => {
    if (from) {
      window.location.replace(from);
    }
  }, []);

  return (
    <TechnicalWorksWrapper>
      <TechnicalWorksHeader>
        <Logo />
      </TechnicalWorksHeader>
      <TechnicalWorksContent>
        <picture>
          <source type="image/webp" srcSet={imageTechnicalWebp.src} />
          <img
            src={imageTechnical.src}
            srcSet={`${imageTechnical.src}, ${imageTechnicalX2.src} 2x`}
            alt=""
          />
        </picture>
        <p>
          Technical work <br /> is underway on the platform.
        </p>
        <Stack gutter={'lg'}>
          {from && <Button onClick={refresh}>Try again</Button>}
          <Link href={'/'}>
            <Button variant={'secondary'}>Back to Home</Button>
          </Link>
        </Stack>
      </TechnicalWorksContent>
    </TechnicalWorksWrapper>
  );
}
