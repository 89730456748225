import { useState } from 'react';
import { AppProps } from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import { ToastContainer } from 'react-toast';
import { Partytown } from '@builder.io/partytown/react';
import {
  BadConnectionAlert,
  CookieModal,
  ErrorBoundary,
  ModalRoot,
  PrivacyPolicyModal,
  TermsOfServiceModal,
} from '@use-gateway/components';
import { Breakpoints, GatewayThemeProvider, useBreakpoints } from '@use-gateway/theme';
import { CommonEvents } from '@use-gateway/types';
import {
  useEventBus,
  LandingProvider,
  partytownResolver,
  NoticeableProvider,
} from '@use-gateway/utils';

import 'normalize.css/normalize.css';

const { publicRuntimeConfig } = getConfig();
const { GOOGLE_TAG, RUNTIME_ENV } = publicRuntimeConfig;

function UseGateway({ Component, pageProps }: AppProps) {
  const { subscribe } = useEventBus();
  const { isDown } = useBreakpoints();

  const [privacyModal, setPrivacyModal] = useState(false);
  const [termsModal, setTermsModal] = useState(false);
  const [cookieModal, setCookieModal] = useState(false);
  const [notification, setNotification] = useState(true);
  const [connectionAlert, setConnectionAlert] = useState(false);

  subscribe(CommonEvents.openTermsOfService, () => setTermsModal(true));
  subscribe(CommonEvents.openPrivacyPolicy, () => setPrivacyModal(true));
  subscribe(CommonEvents.openCookiePolicy, () => setCookieModal(true));
  subscribe(CommonEvents.badConnection, () => setConnectionAlert(true));

  return (
    <GatewayThemeProvider>
      <ErrorBoundary>
        <Head>
          <title>Start accept crypto lightning fast | UseGateway</title>
          <meta
            name="description"
            content="UseGateway allows business to satisfy two essential needs: to receive crypto payments from clients for goods or services and to top up clients account."
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `
              !function(){'use strict';var e=['debug','destroy','do','help','identify','is','off','on','ready','render','reset','safe','set'];if(window.noticeable)console.warn('Noticeable SDK code snippet loaded more than once');else{var n=function(e){return function(){var n=Array.prototype.slice.call(arguments);return n.unshift(e),t.push(n),t}},t=window.noticeable=window.noticeable||[];!function(){for(var o=0;o<e.length;o++){var r=e[o];t[r]=n(r)}}(),function(){var e=document.createElement('script');e.async=!0,e.src='https://sdk.noticeable.io/l.js';var n=document.head;n.insertBefore(e,n.firstChild)}()}}();

                `,
            }}
          />

          {/* Google tag (gtag.js) - Google Analytics */}
          {GOOGLE_TAG && (
            <>
              <Partytown
                debug={process.env.NODE_ENV !== 'production'}
                forward={['dataLayer.push']}
                resolveUrl={partytownResolver}
              />
              <script
                async
                type="text/partytown"
                src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_TAG}`}
              />
              <script
                type="text/partytown"
                dangerouslySetInnerHTML={{
                  __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());

                    gtag('config', '${GOOGLE_TAG}');
                  `,
                }}
              />
              <script
                id="ze-snippet"
                src="https://static.zdassets.com/ekr/snippet.js?key=9c84ba3f-0671-46cf-af46-5bbd75e4f3fc"
                async
              />
            </>
          )}
        </Head>
        <NoticeableProvider>
          <LandingProvider value={{ notification, setNotification }}>
            {termsModal && <TermsOfServiceModal onClose={() => setTermsModal(false)} />}
            {privacyModal && <PrivacyPolicyModal onClose={() => setPrivacyModal(false)} />}
            {cookieModal && <CookieModal onClose={() => setCookieModal(false)} />}
            {connectionAlert && <BadConnectionAlert />}
            <Component {...pageProps} />
          </LandingProvider>
        </NoticeableProvider>
        <ToastContainer
          delay={10000}
          position={isDown(Breakpoints.md) ? 'bottom-center' : 'bottom-left'}
        />
        <ModalRoot />
      </ErrorBoundary>
    </GatewayThemeProvider>
  );
}

UseGateway.getInitialProps = async function () {
  return {
    runtimeEnv: RUNTIME_ENV,
  };
};

export default appWithTranslation(UseGateway);
