// @ts-nocheck
import { forwardRef } from 'react';
const SvgUsdcTrc20 = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 64 64"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_4494_65196)">
      <g clipPath="url(#clip1_4494_65196)">
        <path
          fill="#2775CA"
          d="M32 64C49.7334 64 64 49.7334 64 32C64 14.2666 49.7334 0 32 0C14.2666 0 0 14.2666 0 32C0 49.7334 14.2666 64 32 64Z"
        />
        <path
          fill="white"
          d="M40.8002 37.0666C40.8002 32.4 38.0002 30.8 32.4002 30.1335C28.4002 29.6 27.6002 28.5335 27.6002 26.6666C27.6002 24.7997 28.9336 23.6 31.6002 23.6C34.0002 23.6 35.3336 24.4 36.0002 26.4C36.1336 26.8 36.5336 27.0666 36.9336 27.0666H39.0667C39.6002 27.0666 40.0002 26.6666 40.0002 26.1335V26C39.4667 23.0666 37.0667 20.8 34.0002 20.5335V17.3335C34.0002 16.8 33.6002 16.4 32.9336 16.2666H30.9336C30.4002 16.2666 30.0002 16.6666 29.8667 17.3335V20.4C25.8667 20.9335 23.3336 23.6 23.3336 26.9335C23.3336 31.3335 26.0002 33.0666 31.6002 33.7335C35.3336 34.4 36.5336 35.2 36.5336 37.3335C36.5336 39.4669 34.6667 40.9335 32.1336 40.9335C28.6667 40.9335 27.4667 39.4666 27.0667 37.4666C26.9336 36.9335 26.5336 36.6666 26.1336 36.6666H23.8667C23.3336 36.6666 22.9336 37.0666 22.9336 37.6V37.7335C23.4667 41.0666 25.6002 43.4666 30.0002 44.1335V47.3335C30.0002 47.8666 30.4002 48.2666 31.0667 48.4H33.0667C33.6002 48.4 34.0002 48 34.1336 47.3335V44.1335C38.1336 43.4666 40.8002 40.6666 40.8002 37.0666V37.0666Z"
        />
        <path
          fill="white"
          d="M25.1997 51.0666C14.7997 47.3335 9.46623 35.7335 13.3331 25.4666C15.3331 19.8666 19.7331 15.6001 25.1997 13.6001C25.7331 13.3335 25.9997 12.9335 25.9997 12.2666V10.4001C25.9997 9.86662 25.7331 9.46662 25.1997 9.3335C25.0662 9.3335 24.7997 9.3335 24.6662 9.46662C11.9997 13.4666 5.06623 26.9335 9.06623 39.6001C11.4662 47.0666 17.1997 52.8001 24.6662 55.2001C25.1997 55.4666 25.7331 55.2001 25.8662 54.6666C25.9997 54.5335 25.9997 54.4001 25.9997 54.1335V52.2666C25.9997 51.8666 25.5997 51.3335 25.1997 51.0666ZM39.3331 9.46662C38.7997 9.20006 38.2662 9.46662 38.1331 10.0001C37.9997 10.1335 37.9997 10.2666 37.9997 10.5335V12.4001C37.9997 12.9335 38.3997 13.4666 38.7997 13.7335C49.1997 17.4666 54.5331 29.0666 50.6662 39.3335C48.6662 44.9335 44.2662 49.2001 38.7997 51.2001C38.2662 51.4666 37.9997 51.8666 37.9997 52.5335V54.4001C37.9997 54.9335 38.2662 55.3335 38.7997 55.4666C38.9331 55.4666 39.1997 55.4666 39.3331 55.3335C51.9997 51.3335 58.9331 37.8666 54.9331 25.2001C52.5331 17.6001 46.6662 11.8666 39.3331 9.46662V9.46662Z"
        />
      </g>
      <g clipPath="url(#clip2_4494_65196)">
        <path
          fill="#FF060A"
          d="M63.7288 57.1773C62.5267 61.9988 57.6433 64.933 52.8213 63.7307C48.0012 62.5286 45.0669 57.6451 46.2696 52.8239C47.4711 48.0018 52.3544 45.0673 57.1751 46.2693C61.9968 47.4714 64.9308 52.3556 63.7288 57.1773Z"
        />
        <path
          fill="white"
          d="M60.1984 53.6079C59.6713 53.121 58.9421 52.3775 58.3482 51.8501L58.3131 51.8255C58.2546 51.7785 58.1887 51.7417 58.1181 51.7165V51.7165C56.6861 51.4494 50.0217 50.2031 49.8917 50.2189C49.8553 50.224 49.8204 50.2372 49.7898 50.2576L49.7564 50.2839C49.7153 50.3257 49.6841 50.3762 49.665 50.4316L49.6562 50.4544V50.5792V50.5986C50.4065 52.6886 53.3688 59.5352 53.9522 61.1418C53.9873 61.2508 54.0541 61.4582 54.1788 61.4688H54.2069C54.2737 61.4688 54.5583 61.0926 54.5583 61.0926C54.5583 61.0926 59.6467 54.9192 60.1615 54.2618C60.2281 54.1808 60.287 54.0937 60.3372 54.0017C60.35 53.9296 60.344 53.8555 60.3196 53.7865C60.2953 53.7175 60.2535 53.656 60.1984 53.6079V53.6079ZM55.8638 54.3269L58.0355 52.5251L59.3093 53.6993L55.8638 54.3269ZM55.0204 54.2091L51.2815 51.1435L57.3309 52.2597L55.0204 54.2091ZM55.3578 55.0124L59.1846 54.3954L54.8096 59.6688L55.3578 55.0124ZM50.7737 51.4494L54.7077 54.7892L54.1384 59.6723L50.7737 51.4494Z"
        />
      </g>
      <rect width={17} height={17} x={46.5} y={46.5} stroke="white" rx={8.5} />
    </g>
    <defs>
      <clipPath id="clip0_4494_65196">
        <rect width={64} height={64} fill="white" />
      </clipPath>
      <clipPath id="clip1_4494_65196">
        <rect width={64} height={64} fill="white" />
      </clipPath>
      <clipPath id="clip2_4494_65196">
        <rect width={18} height={18} x={46} y={46} fill="white" rx={9} />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgUsdcTrc20);
export default ForwardRef;
