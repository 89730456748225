// @ts-nocheck
import { forwardRef } from 'react';
const SvgBadWifi = ({ title, titleId, ...props }, ref) => (
  <svg
    id="_x38_6_Wifi_Error"
    width="1em"
    height="1em"
    enableBackground="new 0 0 128 128"
    viewBox="0 0 128 128"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g>
      <path d="m126.1 29.9c-16.6-16.6-38.6-25.7-62.1-25.7s-45.5 9.1-62.1 25.7c-2.5 2.6-2.5 6.8 0 9.3 2.6 2.5 6.8 2.5 9.3 0 14-14.1 32.8-21.8 52.7-21.8s38.7 7.7 52.7 21.8c1.3 1.3 2.9 1.9 4.7 1.9s3.4-.7 4.7-1.9c2.6-2.6 2.6-6.8.1-9.3z" />
      <path d="m20.5 48.5c-2.5 2.6-2.5 6.8 0 9.3 2.6 2.5 6.8 2.5 9.3 0 18.9-18.8 49.4-18.8 68.2 0 1.3 1.3 2.9 1.9 4.7 1.9 1.7 0 3.5-.6 4.7-1.9 2.5-2.6 2.5-6.8 0-9.3-24-24-62.9-24-86.9 0z" />
      <path d="m64 56.9c-9.3 0-18.2 3.7-24.8 10.3-2.5 2.6-2.5 6.8 0 9.3 2.6 2.5 6.8 2.5 9.3 0 4.2-4.2 9.6-6.4 15.4-6.4s11.4 2.3 15.4 6.4c1.3 1.3 2.9 1.9 4.7 1.9 1.6 0 3.4-.6 4.7-1.9 2.5-2.6 2.5-6.8 0-9.3-6.5-6.6-15.4-10.3-24.7-10.3z" />
      <path d="m70.1 81.9c-2.8-4.5-9.4-4.5-12.1 0l-19.4 30.9c-3 4.9.4 11 6.1 11h38.8c5.6 0 9-6.3 6.1-11zm-6.1 37.2c-1.2 0-2.3-1-2.3-2.3s1-2.3 2.3-2.3c1.2 0 2.3 1 2.3 2.3 0 1.2-1 2.3-2.3 2.3zm1.8-10c0 1-.8 1.7-1.7 1.7-1 0-1.8-.8-1.8-1.7l-1-19.4v-.1c0-1.5 1.2-2.6 2.8-2.8 1.5 0 2.8 1.3 2.6 2.9z" />
    </g>
  </svg>
);
const ForwardRef = forwardRef(SvgBadWifi);
export default ForwardRef;
