import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { IconChevronRight } from '@use-gateway/icons';
import { UnknownFunction } from '@use-gateway/types';

interface QuestionState {
  isOpen: boolean;
}

const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  box-shadow: 6px 6px 40px #eaebf4;
  font-family: 'Nunito', sans-serif;

  b {
    font-weight: 700;
  }

  p {
    margin: 0 0 24px;
    line-height: 1.6;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul,
  ol {
    margin: 0 0 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  li {
    margin-bottom: 10px;
    line-height: 1.6;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const QuestionLabel = styled.label<QuestionState>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${({ isOpen }) => (isOpen ? '36px 24px 16px' : '16px 24px')};
  font-weight: 500;
  font-size: 22px;
  line-height: 20px;
  color: ${({ isOpen }) => (isOpen ? '#485AFF' : '#000000')};
  transition: 0.3s;
  cursor: pointer;

  ${down('sm')} {
    padding: 16px 24px;
    font-size: 18px;
  }
`;

const QuestionToggle = styled.div<QuestionState>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-left: 24px;
  color: ${({ isOpen }) => (isOpen ? '#485AFF' : '#b2b3c2')};
  transform: ${({ isOpen }) => (isOpen ? 'rotate(90deg)' : 'rotate(0deg)')};
  transition: 0.3s;
`;

const Content = styled.div<QuestionState>`
  box-sizing: border-box;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  padding: 8px 114px 24px 24px;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.6;
  transition: 0.3s;

  ${down('sm')} {
    padding: 0 24px 24px;
  }

  a {
    color: ${({ theme }) => theme.colors.info[100]};
    text-decoration: none;

    &:hover {
      border-bottom: 1px currentColor dotted;
    }
  }

  h4 {
    margin: 0 0 8px;
    color: ${({ theme }) => theme.colors.info[100]};
  }

  table {
    overflow: hidden;
    margin-bottom: 24px;
    border-radius: 12px;
    border-collapse: collapse;

    thead {
      th {
        padding: 4px 12px;
        font-family: 'Nunito', sans-serif;
        font-weight: 700;
        font-size: 12px;
        line-height: 2.29;
      }
    }

    tbody {
      td {
        padding: 0 12px;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        line-height: 1.96;
      }
    }

    tr {
      background-color: ${({ theme }) => theme.colors.light[80]};
      border-bottom: 1px solid #fff;

      span {
        font-weight: 700;
        color: ${({ theme }) => theme.colors.info[100]};
        line-height: 1.9;
      }
    }
  }
`;

interface QuestionProps {
  isOpen: boolean;
  setIsOpen: UnknownFunction;
  question: string;
  answer: string;
}

export function Question({ isOpen, setIsOpen, question, answer }: QuestionProps) {
  return (
    <QuestionWrapper>
      <QuestionLabel isOpen={isOpen} onClick={() => setIsOpen()}>
        {question}
        <QuestionToggle isOpen={isOpen}>
          <IconChevronRight />
        </QuestionToggle>
      </QuestionLabel>
      <Content isOpen={isOpen} dangerouslySetInnerHTML={{ __html: answer }} />
    </QuestionWrapper>
  );
}
