import { createContext, MouseEventHandler, ReactNode, useContext } from 'react';
import { css } from 'styled-components';
import { styled } from '@use-gateway/theme';
import { Size } from '@use-gateway/types';
import { styledTransition } from '@use-gateway/utils';

export type ButtonVariants = 'primary' | 'secondary' | 'outline' | 'darker' | 'link' | 'white';

export interface ButtonBaseProps {
  variant?: ButtonVariants;
  disabled?: boolean;
  compact?: boolean;
  rounded?: boolean;
  size?: Size;
  align?: 'center' | 'left' | 'right';
}

export interface ButtonProps extends ButtonBaseProps {
  children?: ReactNode;
  type?: 'button' | 'submit' | 'reset';
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
}

export const BaseButton = styled.button<ButtonProps>`
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ align }) => {
    if (align === 'left') return 'flex-start';
    if (align === 'right') return 'flex-end';
    return 'center';
  }};
  gap: 4px;

  white-space: nowrap;
  overflow: hidden;

  width: 100%;
  /* min-width: fit-content; */
  padding: 16px 32px;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border: none;

  font-weight: 400;
  font-size: 16px;

  outline: none;

  ${({ theme, size }) => {
    if (size === 'sm') {
      return css`
        padding: 12px 16px !important;
      `;
    }
    if (size === 'xs') {
      return css`
        padding: 4px 18px !important;
        border-radius: ${theme.borderRadius.xs};
      `;
    }

    return '';
  }}

  ${({ theme, variant }) => {
    if (variant === 'secondary') {
      return css`
        color: ${theme.colors.dark[100]};
        background-color: ${theme.colors.light[70]};

        &:hover {
          box-shadow: 0 4px 24px ${theme.colors.light[70]}59;
        }
      `;
    }

    if (variant === 'outline') {
      return css`
        color: ${theme.colors.primary};
        background-color: ${theme.colors.light[100]};
        border: 1px solid ${theme.colors.primary};

        &:hover {
          color: ${theme.colors.light[100]};
          background-color: ${theme.colors.primary};
          box-shadow: 0 4px 24px ${theme.colors.primary}59;
        }
      `;
    }

    if (variant === 'darker') {
      return css`
        color: ${theme.colors.dark[100]};
        background-color: #3b4ce7;

        &:hover {
          box-shadow: 0 4px 24px #3b4ce759;
        }
      `;
    }

    if (variant === 'link') {
      return css`
        color: ${theme.colors.primary};
        background-color: transparent;
      `;
    }

    if (variant === 'white') {
      return css`
        color: ${theme.colors.dark[100]};
        background-color: #fff;

        &:hover {
          box-shadow: 0 4px 24px ${theme.colors.light[70]};
        }
      `;
    }

    return css`
      color: ${theme.colors.btn};
      background-color: ${theme.colors.primary};

      &:hover {
        box-shadow: 0 4px 24px ${theme.colors.primary}59;
      }
    `;
  }}

  cursor: pointer;

  ${styledTransition(['color', 'box-shadow', 'background-color', 'opacity'])}

  &:hover {
    img,
    svg {
      transform: scale(1.1);
    }
  }

  &:active {
    transform: scale(0.98);
  }

  ${({ compact }) =>
    compact
      ? css`
          width: auto;
          padding: 10px 16px;
          font-size: 14px;
        `
      : ''}

  ${({ rounded }) =>
    rounded
      ? css`
          border-radius: 32px;
        `
      : ''}

  small {
    display: inline-block;
    align-self: flex-start;
    margin-bottom: auto;
    margin-left: 0.5ch;
    font-size: 0.75em;
  }

  img,
  svg {
    ${styledTransition(['transform'])}

    width: 1.25em;
    min-width: 1.25em;
    height: auto;
    margin-top: -0.25em;
    margin-bottom: -0.25em;

    ${({ align }) => {
      if (align === 'left') {
        return css`
          margin-right: 0.5em;
          margin-left: -16px;
        `;
      }

      return css`
        margin-left: -0.25em;
        margin-right: 0.25em;
        margin-right: 0.5ch;
      `;
    }}
  }

  ${({ disabled }) =>
    disabled === true &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

export const ButtonContext = createContext<ButtonBaseProps>({});

export function Button(props: ButtonProps) {
  const { variant, compact, disabled, rounded, size, align } = useContext(ButtonContext);

  return (
    <BaseButton
      variant={variant ?? props.variant}
      compact={compact ?? props.compact}
      rounded={rounded ?? props.rounded}
      size={size ?? props.size}
      align={align ?? props.align}
      type={props.type}
      disabled={props.disabled ?? disabled}
      onClick={props.onClick}
      className={props.className}>
      {props.children}
    </BaseButton>
  );
}
