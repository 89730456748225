// @ts-nocheck
import { forwardRef } from 'react';
const SvgUsdt = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 64 64"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="#50AF95"
      d="M31.9999 64C49.6732 64 63.9999 49.6731 63.9999 32C63.9999 14.3269 49.6732 0 31.9999 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 31.9999 64Z"
    />
    <path
      fill="white"
      d="M35.4106 34.9532V34.948C35.1895 34.9635 34.0472 35.0303 31.5057 35.0303C29.4736 35.0303 28.0485 34.9738 27.5443 34.948V34.9532C19.7296 34.6085 13.9007 33.2503 13.9007 31.6195C13.9007 29.9937 19.7347 28.6303 27.5443 28.2856V33.6001C28.0536 33.6362 29.52 33.7237 31.5418 33.7237C33.9701 33.7237 35.1843 33.6207 35.4106 33.6001V28.2908C43.2099 28.6407 49.0234 29.9989 49.0234 31.6247C49.0234 33.2503 43.2048 34.6085 35.4106 34.9583V34.9532ZM35.4106 27.7404V22.9866H46.2917V15.7378H16.6686V22.9866H27.5495V27.7404C18.7057 28.1468 12.0588 29.8959 12.0588 31.995C12.0588 34.094 18.7109 35.8433 27.5495 36.2549V51.4935H35.4158V36.2549C44.244 35.8485 50.8807 34.0991 50.8807 32.0001C50.8807 29.9063 44.244 28.1519 35.4158 27.7455"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgUsdt);
export default ForwardRef;
