// @ts-nocheck
import { forwardRef } from 'react';
const SvgHash = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 9C3 8.44771 3.44772 8 4 8H20C20.5523 8 21 8.44771 21 9C21 9.55228 20.5523 10 20 10H4C3.44772 10 3 9.55228 3 9Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 15C3 14.4477 3.44772 14 4 14H20C20.5523 14 21 14.4477 21 15C21 15.5523 20.5523 16 20 16H4C3.44772 16 3 15.5523 3 15Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.1104 2.00611C10.6593 2.0671 11.0549 2.56152 10.9939 3.11043L8.99388 21.1104C8.93289 21.6593 8.43848 22.0549 7.88957 21.9939C7.34066 21.9329 6.94513 21.4385 7.00612 20.8896L9.00612 2.88957C9.06711 2.34066 9.56152 1.94512 10.1104 2.00611Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.1104 2.00611C16.6593 2.0671 17.0549 2.56152 16.9939 3.11043L14.9939 21.1104C14.9329 21.6593 14.4385 22.0549 13.8896 21.9939C13.3407 21.9329 12.9451 21.4385 13.0061 20.8896L15.0061 2.88957C15.0671 2.34066 15.5615 1.94512 16.1104 2.00611Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgHash);
export default ForwardRef;
