// @ts-nocheck
import { forwardRef } from 'react';
const SvgPhoneOff = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_2128_24753)">
      <path
        fill="currentColor"
        d="M5.2 13.8C5.4 13.8 5.6 13.7 5.7 13.6C6.2 13.3 6.3 12.7 6 12.2C4.4 9.8 3.4 7 3.1 4.1C3.1 3.8 3.2 3.6 3.3 3.4C3.5 3.2 3.8 3 4.1 3H7.1C7.6 3 8 3.4 8.1 3.9C8.2 4.9 8.5 6 8.9 6.9C9 7.3 8.9 7.7 8.7 8L7.4 9.2C7 9.6 7 10.2 7.4 10.6C7.8 11 8.4 11 8.8 10.6L10.1 9.3C10.9 8.5 11.2 7.2 10.8 6.1C10.5 5.3 10.3 4.4 10.2 3.5C9.9 2.1 8.6 1 7.1 1H4.1C4 1 3.9 1 3.8 1C3 1.1 2.3 1.5 1.8 2.1C1.3 2.7 1.1 3.5 1.1 4.3C1.4 7.5 2.6 10.7 4.3 13.4C4.5 13.6 4.9 13.8 5.2 13.8Z"
      />
      <path
        fill="currentColor"
        d="M23.7 0.3C23.3 -0.1 22.7 -0.1 22.3 0.3L0.3 22.3C-0.1 22.7 -0.1 23.3 0.3 23.7C0.5 23.9 0.7 24 1 24C1.3 24 1.5 23.9 1.7 23.7L7.9 17.5C8.8 18.3 9.7 19 10.7 19.6C13.4 21.4 16.6 22.5 19.8 22.8C19.9 22.8 20 22.8 20.1 22.8C20.9 22.8 21.7 22.5 22.2 21.9C22.7 21.3 23.1 20.6 23.1 19.8V16.8C23.1 15.3 22 14 20.5 13.8C19.6 13.7 18.7 13.5 17.9 13.2C16.8 12.8 15.6 13.1 14.7 13.9L14 14.6C13.4 14.2 12.8 13.7 12.2 13.2L23.7 1.7C24.1 1.3 24.1 0.7 23.7 0.3ZM13.6 16.8C14 17 14.5 17 14.8 16.6L16.1 15.3C16.4 15 16.8 14.9 17.2 15.1C18.2 15.5 19.2 15.7 20.2 15.9C20.7 16 21.1 16.4 21.1 16.9V19.9C21.1 20.2 21 20.4 20.8 20.6C20.6 20.8 20.4 20.9 20 20.9C17.1 20.6 14.3 19.6 11.8 18C10.9 17.5 10.1 16.8 9.4 16.1L10.8 14.7C11.6 15.5 12.6 16.2 13.6 16.8Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_2128_24753">
        <rect width={24} height={24} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgPhoneOff);
export default ForwardRef;
