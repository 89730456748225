import { styled } from '@use-gateway/theme';
import { UserDto } from '@use-gateway/types';
import noAvatar from './assets/no-avatar.svg';

const AvatarImage = styled.div`
  width: 48px;
  height: 48px;
`;

export interface AvatarProps {
  user: UserDto | null;
}
export function Avatar({ user }: AvatarProps) {
  return <AvatarImage style={{ backgroundImage: `url("${noAvatar}")` }} />;
}
