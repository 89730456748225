import { down } from 'styled-breakpoints';
import { api } from '@use-gateway/api';
import { Pagination, Spiner } from '@use-gateway/components';
import { Breakpoints, styled, useBreakpoints } from '@use-gateway/theme';
import { WithdrawalsEvents } from '@use-gateway/types';
import { useEventBus, usePaginated } from '@use-gateway/utils';
import { WithdrawalsRow } from './row';
import imgNoHistory from '../assets/no-history.svg';
import { TableCell, TableContent, TableHeader, TableWrapper } from '../table';

const WithdrawalsTableTitle = styled.div`
  width: 100%;
  min-width: 100%;
  grid-column: 1/9;
  margin-bottom: -10px;
  padding: 10px 20px 0;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.dark[100]};

  ${down('sm')} {
    display: none;
  }
`;

const NoHistory = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 30px;

  p {
    margin: 20px 0 26px;
    padding: 0 8px;
    text-align: center;
    font-size: 18px;
    letter-spacing: -0.02em;
  }
`;

const NoHistoryImg = styled.div`
  width: 90px;
  height: 85px;
  background-image: url(${imgNoHistory});
  background-repeat: no-repeat;
  background-size: 100% auto;
`;

export function WithdrawalsTable() {
  const { mapBreakpoints } = useBreakpoints();
  const { isUp } = useBreakpoints();
  const columns = mapBreakpoints({ xs: 24, sm: 8 });
  const { subscribe } = useEventBus();

  const {
    items: withdrawals,
    fetchItems: fetchWithdrawals,
    itemsOffset,
    itemsTotal,
    itemsOnPage,
    pending,
    canGoPrev,
    canGoNext,
    goPrev,
    goNext,
    setPage,
  } = usePaginated((page, size) => api.getWithdrawals(page, size), 10);

  subscribe(WithdrawalsEvents.withdrawalWasCreated, () => {
    setPage(1);
    fetchWithdrawals();
  });

  return (
    <>
      <TableWrapper>
        {isUp(Breakpoints.xs) && (
          <TableHeader columns={columns}>
            <WithdrawalsTableTitle>Withdrawals</WithdrawalsTableTitle>
            <TableCell span={isUp(Breakpoints.sm) ? 1 : 6}>Date</TableCell>

            {isUp(Breakpoints.sm) ? (
              <TableCell span={3} style={{ justifyContent: 'center' }}>
                Status
              </TableCell>
            ) : (
              <TableCell span={9} style={{ justifyContent: 'flex-end', padding: '6px 16px' }}>
                Amount
              </TableCell>
            )}

            {isUp(Breakpoints.sm) ? (
              <TableCell span={3} style={{ justifyContent: 'flex-end' }}>
                Amount
              </TableCell>
            ) : (
              <TableCell span={6} style={{ justifyContent: 'flex-start', padding: '6px 16px' }}>
                Status
              </TableCell>
            )}

            <TableCell
              span={isUp(Breakpoints.sm) ? 1 : 3}
              style={{
                justifyContent: isUp(Breakpoints.sm) ? 'center' : 'flex-end',
                paddingRight: isUp(Breakpoints.sm) ? 'auto' : 0,
              }}>
              Link
            </TableCell>
          </TableHeader>
        )}
        <TableContent loading={pending}>
          {pending && <Spiner />}
          {!withdrawals.length ? (
            <NoHistory>
              <NoHistoryImg />
              <p>Soon here would be your withdrawals history!</p>
            </NoHistory>
          ) : (
            withdrawals.map((withdrawal) => (
              <WithdrawalsRow key={withdrawal.id} withdrawal={withdrawal} />
            ))
          )}
        </TableContent>
      </TableWrapper>

      {!!withdrawals.length && (
        <Pagination
          current={`${itemsOffset + 1}-${itemsOffset + itemsOnPage}`}
          total={itemsTotal}
          onGoPrev={goPrev}
          onGoNext={goNext}
          canGoPrev={canGoPrev}
          canGoNext={canGoNext}
        />
      )}
    </>
  );
}
