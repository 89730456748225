import { ErrorScreen } from './error-screen';

export function Error404() {
  return (
    <ErrorScreen code={404} title={'LOOKS LIKE YOU’RE LOST'}>
      <p>The page you are looking for not availiable!</p>
      <p>
        <a href={'/'}>GO TO HOME</a>
      </p>
    </ErrorScreen>
  );
}
