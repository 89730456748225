// @ts-nocheck
import { forwardRef } from 'react';
const SvgUsdtBep20 = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 64 64"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="#50AF95"
      d="M31.9999 64C49.6732 64 63.9999 49.6731 63.9999 32C63.9999 14.3269 49.6732 0 31.9999 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 31.9999 64Z"
    />
    <path
      fill="white"
      d="M35.4106 34.9532V34.948C35.1895 34.9635 34.0472 35.0303 31.5057 35.0303C29.4736 35.0303 28.0485 34.9738 27.5443 34.948V34.9532C19.7296 34.6085 13.9007 33.2503 13.9007 31.6195C13.9007 29.9937 19.7347 28.6303 27.5443 28.2856V33.6001C28.0536 33.6362 29.52 33.7237 31.5418 33.7237C33.9701 33.7237 35.1843 33.6207 35.4106 33.6001V28.2908C43.2099 28.6407 49.0234 29.9989 49.0234 31.6247C49.0234 33.2503 43.2048 34.6085 35.4106 34.9583V34.9532ZM35.4106 27.7404V22.9866H46.2917V15.7378H16.6686V22.9866H27.5495V27.7404C18.7057 28.1468 12.0588 29.8959 12.0588 31.995C12.0588 34.094 18.7109 35.8433 27.5495 36.2549V51.4935H35.4158V36.2549C44.244 35.8485 50.8807 34.0991 50.8807 32.0001C50.8807 29.9063 44.244 28.1519 35.4158 27.7455"
    />
    <g clipPath="url(#clip0_3630_36494)">
      <path
        fill="#F0B90B"
        fillRule="evenodd"
        d="M55 46C59.9709 46 64 50.0291 64 55C64 59.9709 59.9709 64 55 64C50.0291 64 46 59.9709 46 55C46 50.0291 50.0291 46 55 46Z"
        clipRule="evenodd"
      />
      <path
        fill="white"
        d="M50.9464 55L50.9529 57.3798L52.975 58.5697V59.963L49.7695 58.0829V54.3041L50.9464 55ZM50.9464 52.6202V54.007L49.7688 53.3103V51.9236L50.9464 51.2269L52.1299 51.9236L50.9464 52.6202ZM53.8195 51.9236L54.9972 51.2269L56.1806 51.9236L54.9972 52.6202L53.8195 51.9236Z"
      />
      <path
        fill="white"
        d="M51.7974 56.8865V55.4933L52.975 56.1899V57.5767L51.7974 56.8865ZM53.8195 59.0688L54.9971 59.7654L56.1805 59.0688V60.4555L54.9971 61.1522L53.8195 60.4555V59.0688ZM57.8695 51.9236L59.0471 51.2269L60.2305 51.9236V53.3103L59.0471 54.007V52.6202L57.8695 51.9236ZM59.0471 57.3798L59.0536 55L60.2313 54.3034V58.0822L57.0257 59.9623V58.569L59.0471 57.3798Z"
      />
      <path fill="white" d="M58.2025 56.8866L57.0249 57.5767V56.1899L58.2025 55.4933V56.8866Z" />
      <path
        fill="white"
        d="M58.2027 53.1134L58.2091 54.5067L56.1813 55.6966V58.0822L55.0036 58.7723L53.826 58.0822V55.6966L51.7981 54.5067V53.1134L52.9808 52.4168L54.9964 53.6124L57.0243 52.4168L58.2077 53.1134H58.2027ZM51.7974 50.7343L54.9971 48.8478L58.2027 50.7343L57.025 51.431L54.9971 50.2353L52.975 51.431L51.7974 50.7343Z"
      />
    </g>
    <rect width={17} height={17} x={46.5} y={46.5} stroke="white" rx={8.5} />
    <defs>
      <clipPath id="clip0_3630_36494">
        <rect width={18} height={18} x={46} y={46} fill="white" rx={9} />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgUsdtBep20);
export default ForwardRef;
