import { down } from 'styled-breakpoints';
import { css } from 'styled-components';
import { Column, Columns } from '@bedrock-layout/columns';
import { Inline } from '@bedrock-layout/inline';
import { Stack } from '@bedrock-layout/stack';
import { Breakpoints, styled } from '@use-gateway/theme';
import { Align } from '@use-gateway/types';
import { styledTransition } from '@use-gateway/utils';

export const TableWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
  background-color: ${({ theme }) => theme.colors.light[100]};

  border: 1px solid ${({ theme }) => theme.colors.light[80]};
  box-shadow: 0 12px 98px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;

  font-size: 14px;
  line-height: 1.42;
  letter-spacing: -0.01em;

  color: ${({ theme }) => theme.colors.dark[100]};

  ${down(Breakpoints.sm)} {
    box-sizing: border-box;
    margin-top: 0;
    height: 100%;
    background-color: transparent;
  }
`;

export const TableHeader = styled(Columns)`
  background-color: ${({ theme }) => theme.colors.light[90]};
  font-weight: 500;

  ${down('sm')} {
    margin-bottom: 8px;
    padding: 0 16px;
    background-color: transparent;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.light[40]};
  }
`;

interface TableCellProps {
  clickable?: boolean;
  align?: Align;
}

export const TableCell = styled(Column)<TableCellProps>`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  padding: 16px 20px 10px;

  ${({ clickable }) =>
    clickable
      ? css`
          cursor: pointer;
        `
      : ''}

  ${({ align }) => {
    if (align === 'center') return 'justify-content: center;';
    if (align === 'end') return 'justify-content: flex-end;';

    return '';
  }}

  button {
    border: none;
    background-color: transparent;
    padding: 4px 6px;
    margin-top: -4px;
    margin-left: -6px;
    margin-right: 4px;
    color: ${({ theme }) => theme.colors.primary};
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    cursor: pointer;

    ${styledTransition(['color', 'background-color'])}

    &:hover {
      color: ${({ theme }) => theme.colors.light[100]};
      background-color: ${({ theme }) => theme.colors.primary};
    }

    &:disabled {
      background-color: transparent;
      color: #aaa;
      cursor: default;
    }
  }

  &.name {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${down(Breakpoints.sm)} {
    padding: 6px;
  }
`;

interface TableContent {
  loading: boolean;
}

export const TableContent = styled.div<TableContent>`
  position: relative;

  ${({ loading }) =>
    loading
      ? css`
          &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            backdrop-filter: blur(10px);
            z-index: 1;
          }
        `
      : ''}

  .spiner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 2;
  }

  ${down(Breakpoints.md)} {
    overflow-y: auto;
    max-height: calc(100% - 36px);
  }

  ${down(Breakpoints.xs)} {
    max-height: calc(100%);
  }
`;

export const TableRow = styled(Columns)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.light[80]};
  color: ${({ theme }) => theme.colors.dark[100]};

  ${styledTransition(['color', 'background-color'])}

  &:hover {
    background-color: #${({ theme }) => theme.colors.light[90]};
  }

  &:last-child {
    border-bottom: none;

    ${TableCell} {
      padding-bottom: 16px;
    }
  }
`;

export const TableCard = styled(Stack)`
  position: relative;
  z-index: 1;

  margin: 8px 0;
  padding: 14px 16px;
  border: 1px solid ${({ theme }) => theme.colors.light[80]};
  background-color: ${({ theme }) => theme.colors.light[100]};
  border-radius: 10px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);

  &:first-child {
    margin-top: 0;
  }

  ${down('md')} {
    box-shadow: none;
  }
`;

export const TableMobileName = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  font-size: 16px;
  font-weight: 500;

  svg {
    margin-left: 0.5em;
    font-size: 20px;
  }
`;

interface TableMobileStatusProps {
  unresolved?: boolean;
  pending?: boolean;
}
export const TableMobileStatus = styled.div<TableMobileStatusProps>`
  display: flex;
  justify-content: flex-end;
  margin-left: 12px;
  width: min-content;

  font-weight: 500;
  color: ${({ theme, unresolved, pending }) => {
    if (unresolved) return theme.colors.danger[100];
    if (pending) return theme.colors.warning[100];
    return theme.colors.success[100];
  }};
`;

export const TableMobileMeta = styled(Stack)`
  font-size: 14px;
  color: #848893;
`;

export const TableMobileActions = styled(Inline)`
  height: 100%;
  justify-content: flex-end;
  align-items: flex-end;
`;
