import getConfig from 'next/config';
import { IconSettings, IconSignOut, IconApi, IconGuide } from '@use-gateway/icons';
import { styled } from '@use-gateway/theme';
import { ProjectNames } from '@use-gateway/types';
import { getGuideUrl, styledTransition, useAuth } from '@use-gateway/utils';
import { Avatar } from '../avatar';
import { Link } from '../link';
import { Logo } from '../logo';

const PanelHeaderWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 18px 0 32px;
`;

const UserWidgetHeader = styled.div`
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 245px;
  padding: 12px 12px 12px 24px;

  ${styledTransition(['background-color'])}
  transition-delay: 1s;

  z-index: 1;

  label {
    max-width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({ theme }) => theme.colors.dark[100]};
  }
`;

const UserWidgetContent = styled.div`
  display: flex;
  flex-direction: column;

  padding: 12px 0;
  margin-left: 24px;
  margin-right: 24px;
  border-top: #ccc 1px solid;
`;

const UserWidgetLink = styled.a`
  display: flex;
  align-items: center;

  padding: 8px 16px;
  margin: 0 -16px;

  border-radius: ${({ theme }) => theme.borderRadius.md};

  color: ${({ theme }) => theme.colors.dark[100]};
  text-decoration: none;
  cursor: pointer;

  ${styledTransition(['background-color'])}

  svg {
    font-size: 24px;
  }

  div {
    width: 24px;
    height: 24px;
    margin-right: 16px;
    padding: 7px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.light[80]};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.light[60]};

    svg {
      color: ${({ theme }) => theme.colors.light[100]};
    }

    div {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const UserWidget = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  min-height: 64px;
  overflow: hidden;

  ${styledTransition(['box-shadow'])}
`;

const UserWidgetWrapper = styled.div`
  height: 64px;
  z-index: 500;

  &:hover {
    z-index: 1500;

    ${UserWidget} {
      background-color: ${({ theme }) => theme.colors.light[100]};
      box-shadow: 12px 12px 98px rgba(0, 0, 0, 0.18);
    }

    ${UserWidgetHeader} {
      transition-delay: 0s;
      background-color: ${({ theme }) => theme.colors.light[100]};
    }

    ${UserWidgetContent} {
      opacity: 1;
      margin-top: 0;
      pointer-events: all;
    }
  }

  ${UserWidgetContent} {
    margin-top: -100%;
    opacity: 0;
    pointer-events: none;

    transition: margin-top 300ms ease-in-out, opacity 750ms ease-in-out;
  }
`;

const { publicRuntimeConfig } = getConfig();
const { PROJECT_NAME } = publicRuntimeConfig;

export function PanelHeader() {
  const { user, logout } = useAuth();

  return (
    <PanelHeaderWrapper>
      <Logo />
      {user && (
        <UserWidgetWrapper>
          <UserWidget>
            <UserWidgetHeader>
              <label>{user.email}</label>
              <Avatar user={user} />
            </UserWidgetHeader>
            <UserWidgetContent>
              <Link href="/settings/">
                <UserWidgetLink>
                  <div>
                    <IconSettings />
                  </div>
                  Settings
                </UserWidgetLink>
              </Link>
              {PROJECT_NAME === ProjectNames.UseGateway && (
                <UserWidgetLink href={getGuideUrl()} target={'_blank'}>
                  <div>
                    <IconGuide />
                  </div>
                  Guide
                </UserWidgetLink>
              )}
              {PROJECT_NAME === ProjectNames.UseGateway && (
                <UserWidgetLink href={'https://docs.usegateway.net/'} target={'_blank'}>
                  <div>
                    <IconApi />
                  </div>
                  API
                </UserWidgetLink>
              )}
              <UserWidgetLink onClick={logout}>
                <div>
                  <IconSignOut />
                </div>
                Exit
              </UserWidgetLink>
            </UserWidgetContent>
          </UserWidget>
        </UserWidgetWrapper>
      )}
    </PanelHeaderWrapper>
  );
}
