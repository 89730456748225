import React, { useEffect, useMemo, useState } from 'react';
import { default as NumberFormat, NumberFormatValues } from 'react-number-format';
import { IconColorChange } from '@use-gateway/icons';
import { styled } from '@use-gateway/theme';
import { Currency, UnknownFunction } from '@use-gateway/types';
import { getMoneyDecemals, styledTransition } from '@use-gateway/utils';
import { CurrencyIcon } from './currency-icon';

interface InputCryptoWrapperProps {
  countSymbols: number;
}

const InputCryptoWrapper = styled.div<InputCryptoWrapperProps>`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 70px;

  input {
    box-sizing: border-box;
    width: 100%;
    height: 70px;
    padding-left: 16px;
    padding-right: ${({ countSymbols }) => `${countSymbols * 16 + 40}px`};
    border: 2px solid transparent;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.colors.light[80]};
    color: ${({ theme }) => theme.colors.dark[100]};
    text-align: right;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    letter-spacing: -0.01em;
    outline: none;

    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    ${styledTransition(['all'], 150)}

    &:focus {
      border-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const InputCryptoImg = styled.div`
  align-self: center;
  width: 36px;
  height: 36px;
  margin-right: 18px;

  svg {
    width: 36px;
    height: 36px;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const InputCurrency = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 16px;
  display: flex;
  align-items: center;
  height: 70px;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  letter-spacing: -0.01em;
`;

const InputCryptoRightItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

interface InputCryptoAmountMaxProps {
  isActive: boolean;
}

const InputCryptoAmountMax = styled.button<InputCryptoAmountMaxProps>`
  width: content-box;
  height: max-content;
  margin: 0 0 0 auto;
  padding: 0;
  border: none;
  background: transparent;
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme, isActive }) => (isActive ? theme.colors.accent[100] : theme.colors.dark[100])};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.accent[100]};
  }
`;

const InputCryptoChange = styled.div`
  display: flex;
  margin-left: 8px;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.accent[100]};
  }

  span {
    display: block;
    width: 30px;
    margin-top: 12px;
    text-align: center;
  }

  svg {
    width: auto;
    height: 24px;
  }

  small {
    display: none;
  }
`;

interface InputCryptoProps {
  cryptoCurrency: Currency;
  fiatCurrency: Currency;
  defaultValue: number;
  isFiat: boolean;
  toggleIsFiat: UnknownFunction;
  onChange: (value: number) => void;
  max: number;
  isMaxMode: boolean;
  setIsMaxMode: (value: boolean) => void;
  rate: number;
}

export function InputCrypto({
  cryptoCurrency,
  fiatCurrency,
  defaultValue,
  isFiat,
  toggleIsFiat,
  onChange,
  max,
  isMaxMode,
  setIsMaxMode,
  rate,
}: InputCryptoProps) {
  const safeCryptoCurrency = cryptoCurrency.split('_')[0];
  const countSymbols = isFiat ? 3 : safeCryptoCurrency.length;
  const [innerValue, setInnerValue] = useState(defaultValue.toString());

  const decemals = useMemo(() => {
    return getMoneyDecemals(isFiat ? fiatCurrency : cryptoCurrency);
  }, [isFiat]);

  const setValueSafe = (value: number, cb?: (safeValue: number) => void) => {
    if (!isNaN(value)) {
      setInnerValue(`${value}`);
      if (cb) cb(value);
    }
  };

  const handleInput = (values: NumberFormatValues) => {
    const value = values.floatValue || 0;
    setValueSafe(value, onChange);
  };

  const handleFocus: React.FormEventHandler<HTMLInputElement> = () => {
    setIsMaxMode(false);
  };

  const handleBtnMax = () => {
    if (isFiat) {
      setValueSafe(max / rate, onChange);
    } else {
      setValueSafe(max, onChange);
    }
    setIsMaxMode(true);
  };

  useEffect(() => {
    if (isFiat) {
      setValueSafe(parseFloat(innerValue) / rate, onChange);
    } else {
      setValueSafe(parseFloat(innerValue) * rate, onChange);
    }
  }, [isFiat]);

  useEffect(() => {
    if (isMaxMode) {
      handleBtnMax();
    }
  }, [isMaxMode, max, rate, isFiat]);

  return (
    <InputCryptoWrapper countSymbols={countSymbols}>
      <InputCryptoImg>
        <CurrencyIcon currency={cryptoCurrency} />
      </InputCryptoImg>

      <InputWrapper>
        <NumberFormat
          type="text"
          value={innerValue.toString()}
          onValueChange={handleInput}
          decimalScale={decemals}
          allowNegative={false}
          allowedDecimalSeparators={[',', '.']}
          onFocus={handleFocus}
          autoFocus
        />
        {!isFiat ? (
          <>
            {safeCryptoCurrency.length < 5 && <InputCurrency>{safeCryptoCurrency}</InputCurrency>}
          </>
        ) : (
          <InputCurrency>{fiatCurrency}</InputCurrency>
        )}
      </InputWrapper>
      <InputCryptoRightItems>
        <InputCryptoAmountMax isActive={isMaxMode} onClick={handleBtnMax}>
          MAX
        </InputCryptoAmountMax>
        <InputCryptoChange onClick={toggleIsFiat}>
          <IconColorChange />
          {!isFiat ? <span>{fiatCurrency}</span> : <span>{safeCryptoCurrency}</span>}
        </InputCryptoChange>
      </InputCryptoRightItems>
    </InputCryptoWrapper>
  );
}
