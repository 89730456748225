import { ReactNode, useEffect, useRef } from 'react';
import { css } from 'styled-components';
import {
  IconAlertCircle,
  IconAlertTriangle,
  IconCheckCircle,
  IconInfo,
  IconXCircle,
} from '@use-gateway/icons';
import { styled } from '@use-gateway/theme';
import { UnknownFunction } from '@use-gateway/types';
import { styledTransition } from '@use-gateway/utils';

export interface MessageProps {
  children?: ReactNode;
  variant?: 'info' | 'success' | 'warning' | 'error';
  onClose?: UnknownFunction;
}

const MessageWrapper = styled.div<MessageProps>`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 16px 16px 16px 48px;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  color: ${({ theme }) => theme.colors.btn};
  text-align: left;

  ${({ theme, variant }) => {
    if (variant === 'success') {
      return css`
        background-color: ${theme.colors.success[80]};
      `;
    }

    if (variant === 'warning') {
      return css`
        background-color: ${theme.colors.warning[80]};
      `;
    }

    if (variant === 'error') {
      return css`
        background-color: ${theme.colors.danger[100]};
      `;
    }

    return css`
      background-color: ${theme.colors.info[80]};
    `;
  }}

  span {
    display: block;

    &:first-letter {
      text-transform: uppercase;
    }
  }
`;

const MessageIcon = styled.div`
  position: absolute;
  top: 16px;
  bottom: 16px;
  left: 16px;
  font-size: 20px;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const MessageClose = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  font-size: 20px;
  opacity: 0.5;

  cursor: pointer;

  ${styledTransition(['opacity'], 150)}

  &:hover {
    opacity: 1;
  }
`;

export function Message({ variant, children, onClose }: MessageProps) {
  const myRef = useRef<HTMLDivElement>(null);

  const scrollToElement = () => {
    if (myRef.current) {
      myRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  useEffect(() => {
    scrollToElement();
  }, []);

  return (
    <MessageWrapper variant={variant} ref={myRef}>
      {onClose && (
        <MessageClose onClick={onClose}>
          <IconXCircle />
        </MessageClose>
      )}
      <MessageIcon>
        {(() => {
          if (variant === 'success') return <IconCheckCircle />;
          if (variant === 'warning') return <IconAlertTriangle />;
          if (variant === 'error') return <IconAlertCircle />;
          return <IconInfo />;
        })()}
      </MessageIcon>
      {children}
    </MessageWrapper>
  );
}
