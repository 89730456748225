// @ts-nocheck
import { forwardRef } from 'react';
const SvgBatteryCharging = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.8321 6.55468C12.1384 6.09516 12.0142 5.47429 11.5547 5.16793C11.0952 4.86158 10.4743 4.98576 10.168 5.44528L6.16795 11.4453C5.96338 11.7521 5.94431 12.1467 6.11833 12.4718C6.29235 12.797 6.63121 13 7 13H11.1315L8.16795 17.4453C7.8616 17.9048 7.98577 18.5257 8.4453 18.832C8.90483 19.1384 9.5257 19.0142 9.83205 18.5547L13.8321 12.5547C14.0366 12.2478 14.0557 11.8533 13.8817 11.5281C13.7077 11.203 13.3688 11 13 11H8.86852L11.8321 6.55468ZM3 6.99998C2.73478 6.99998 2.48043 7.10534 2.29289 7.29288C2.10536 7.48041 2 7.73477 2 7.99998V16C2 16.2652 2.10536 16.5196 2.29289 16.7071C2.48043 16.8946 2.73478 17 3 17H5C5.55228 17 6 17.4477 6 18C6 18.5523 5.55228 19 5 19H3C2.20435 19 1.44129 18.6839 0.87868 18.1213C0.316071 17.5587 0 16.7956 0 16V7.99998C0 7.20434 0.316071 6.44127 0.87868 5.87866C1.44129 5.31606 2.20435 4.99998 3 4.99998H6.19C6.74228 4.99998 7.19 5.4477 7.19 5.99998C7.19 6.55227 6.74228 6.99998 6.19 6.99998H3ZM14 5.99998C14 5.4477 14.4477 4.99998 15 4.99998H17C17.7957 4.99998 18.5587 5.31606 19.1213 5.87866C19.6839 6.44127 20 7.20433 20 7.99998V16C20 16.7956 19.6839 17.5587 19.1213 18.1213C18.5587 18.6839 17.7957 19 17 19H13.81C13.2577 19 12.81 18.5523 12.81 18C12.81 17.4477 13.2577 17 13.81 17H17C17.2652 17 17.5196 16.8946 17.7071 16.7071C17.8946 16.5196 18 16.2652 18 16V7.99998C18 7.73477 17.8946 7.48041 17.7071 7.29288C17.5196 7.10534 17.2652 6.99998 17 6.99998H15C14.4477 6.99998 14 6.55227 14 5.99998ZM23 9.99998C23.5523 9.99998 24 10.4477 24 11V13C24 13.5523 23.5523 14 23 14C22.4477 14 22 13.5523 22 13V11C22 10.4477 22.4477 9.99998 23 9.99998Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgBatteryCharging);
export default ForwardRef;
