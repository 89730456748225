// @ts-nocheck
import { forwardRef } from 'react';
const SvgPackage = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.5559 2.34508C11.5556 2.34523 11.5553 2.34538 11.555 2.34553L3.55722 6.34442L3.555 6.34553C3.3884 6.42832 3.24819 6.55594 3.15014 6.71405C3.05213 6.87211 3.00014 7.05436 3 7.24034C3 7.24027 3 7.2404 3 7.24034V16.767C2.99871 16.9533 3.04949 17.1363 3.14667 17.2954C3.2437 17.4541 3.38308 17.5827 3.54916 17.6665C3.54889 17.6664 3.54943 17.6667 3.54916 17.6665L11.5472 21.6656C11.6861 21.7351 11.8397 21.7714 11.995 21.7714C12.1504 21.7714 12.3036 21.7352 12.4425 21.6657L20.4428 17.6656L20.445 17.6645C20.6116 17.5817 20.7518 17.4541 20.8499 17.2959C20.9479 17.1378 20.9999 16.9555 21 16.7695V7.24053C20.9999 7.05449 20.9479 6.87216 20.8499 6.71405C20.7518 6.55594 20.6116 6.42832 20.445 6.34553L20.4428 6.34442L12.445 2.34553C12.4447 2.34538 12.4444 2.34523 12.4441 2.34508C12.3061 2.27666 12.1541 2.24106 12 2.24106C11.8459 2.24106 11.6939 2.27666 11.5559 2.34508ZM12.89 1.45L13.335 0.554467C12.9202 0.34833 12.4632 0.241058 12 0.241058C11.5368 0.241058 11.0798 0.34833 10.665 0.554467L10.6628 0.55557L2.665 4.55447C2.66459 4.55467 2.66418 4.55487 2.66377 4.55508C2.16449 4.80346 1.74432 5.18609 1.45042 5.66003C1.15628 6.13436 1.0003 6.68134 1 7.23947L1 16.7569C0.996833 17.3146 1.14923 17.8623 1.44011 18.3383C1.73166 18.8153 2.15072 19.2015 2.65003 19.453L2.65279 19.4544L10.6525 23.4543C11.0693 23.6628 11.5289 23.7714 11.995 23.7714C12.461 23.7714 12.9205 23.6629 13.3372 23.4544C13.3373 23.4544 13.3371 23.4545 13.3372 23.4544L21.335 19.4555C21.3353 19.4554 21.3357 19.4552 21.336 19.455C21.8354 19.2067 22.2556 18.824 22.5496 18.35C22.8437 17.8756 22.9997 17.3287 23 16.7705V7.24C22.9997 6.68187 22.8437 6.13436 22.5496 5.66003C22.2557 5.18611 21.8355 4.80349 21.3363 4.5551C21.3359 4.55489 21.3354 4.55468 21.335 4.55447L13.3372 0.55557L12.89 1.45Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.42558 5.71278C1.67256 5.21881 2.27324 5.01858 2.76722 5.26557L12 9.88196L21.2328 5.26557C21.7268 5.01858 22.3274 5.21881 22.5744 5.71278C22.8214 6.20676 22.6212 6.80744 22.1272 7.05442L12.4472 11.8944C12.1657 12.0352 11.8343 12.0352 11.5528 11.8944L1.87279 7.05442C1.37881 6.80744 1.17859 6.20676 1.42558 5.71278Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 10C12.5523 10 13 10.4477 13 11V22.76C13 23.3123 12.5523 23.76 12 23.76C11.4477 23.76 11 23.3123 11 22.76V11C11 10.4477 11.4477 10 12 10Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.10558 3.05278C6.35256 2.5588 6.95324 2.35858 7.44722 2.60557L17.4472 7.60557C17.9412 7.85256 18.1414 8.45323 17.8944 8.94721C17.6474 9.44119 17.0468 9.64141 16.5528 9.39442L6.55279 4.39442C6.05881 4.14744 5.85859 3.54676 6.10558 3.05278Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPackage);
export default ForwardRef;
