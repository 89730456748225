import styled from 'styled-components';
import { Button, Portal } from '@use-gateway/components';
import { IconBadWifi } from '@use-gateway/icons';

const MODAL_ROOT_ID = 'modal-root';

const BadConnectionAlertWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 64px 0;
  overflow-y: auto;

  backdrop-filter: blur(3px);
  z-index: 2500;
`;

const BadConnectionAlertCard = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  width: 360px;
  max-width: calc(100% - 32px);
  height: fit-content;

  overflow: hidden;
  padding: 32px;
  background: ${({ theme }) => theme.colors.light[100]};
  box-sizing: border-box;

  box-shadow: 12px 12px 98px rgba(0, 0, 0, 0.18);
  border-radius: 32px;

  h3 {
    text-align: center;
  }

  svg {
    width: 64px;
    height: 64px;
    margin: 16px auto;
    fill: #e51212;
  }
`;

export function BadConnectionAlert() {
  const reload = () => {
    location.reload();
  };

  return (
    <Portal targetId={MODAL_ROOT_ID}>
      <BadConnectionAlertWrapper>
        <BadConnectionAlertCard>
          <h3>Bad connection</h3>
          <IconBadWifi />
          <p>Check your internet connection and try again</p>
          <Button onClick={reload}>Try again</Button>
        </BadConnectionAlertCard>
      </BadConnectionAlertWrapper>
    </Portal>
  );
}
