import { WithdrawalResponseDpo, Paginated, SendToPayload } from '@use-gateway/types';
import { Api } from '..';

const basePath = '/withdrawals';

export async function getWithdrawals(this: Api, page = 1, size = 25) {
  return this.get<Paginated<WithdrawalResponseDpo>>(`${basePath}/?page=${page}&size=${size}`);
}

export async function sendTo(this: Api, payload: SendToPayload) {
  return this.post(`${basePath}/`, payload);
}
