import { ApiKeyResponse } from '@use-gateway/types';
import { Api } from '..';

const basePath = '/apikeys/';

export async function getApiKeys(this: Api) {
  return this.get<ApiKeyResponse>(`${basePath}`);
}
export async function changeApiKey(this: Api) {
  return this.patch<ApiKeyResponse>(`${basePath}`, {});
}
