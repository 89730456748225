// @ts-nocheck
import { forwardRef } from 'react';
const SvgP2P2 = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 25 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M20.8354 3.4541L20.5375 8.54539L15.4462 8.24748"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M10.3449 4.36491C11.6819 3.84008 13.1358 3.7256 14.5312 4.03528C15.9267 4.34496 17.204 5.06557 18.2092 6.11019L20.5381 8.54539"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M4.75089 19.0548L5.51172 14.0119L10.5546 14.7727"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M5.51172 14.012L7.69081 16.6617C8.63196 17.799 9.88205 18.6396 11.2903 19.0823C12.1055 19.3386 12.9536 19.4551 13.7993 19.4313"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M13.7998 20V19.4156C13.7998 18.3307 14.2476 17.2902 15.0446 16.5231C15.8416 15.7559 16.9226 15.325 18.0498 15.325M18.0498 15.325C19.177 15.325 20.258 15.7559 21.055 16.5231C21.852 17.2902 22.2998 18.3307 22.2998 19.4156V20M18.0498 15.325C18.6939 15.325 19.3116 15.0787 19.7671 14.6403C20.2225 14.202 20.4784 13.6074 20.4784 12.9875C20.4784 12.3675 20.2225 11.773 19.7671 11.3346C19.3116 10.8962 18.6939 10.65 18.0498 10.65C17.4057 10.65 16.788 10.8962 16.3325 11.3346C15.8771 11.773 15.6212 12.3675 15.6212 12.9875C15.6212 13.6074 15.8771 14.202 16.3325 14.6403C16.788 15.0787 17.4057 15.325 18.0498 15.325Z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.2}
      d="M3.59961 12.35V11.7656C3.59961 10.6807 4.04738 9.64026 4.84441 8.87312C5.64144 8.10598 6.72244 7.675 7.84961 7.675M7.84961 7.675C8.97678 7.675 10.0578 8.10598 10.8548 8.87312C11.6518 9.64026 12.0996 10.6807 12.0996 11.7656V12.35M7.84961 7.675C8.49371 7.675 9.11142 7.42873 9.56687 6.99036C10.0223 6.552 10.2782 5.95744 10.2782 5.3375C10.2782 4.71756 10.0223 4.123 9.56687 3.68464C9.11142 3.24627 8.49371 3 7.84961 3C7.20551 3 6.5878 3.24627 6.13235 3.68464C5.6769 4.123 5.42104 4.71756 5.42104 5.3375C5.42104 5.95744 5.6769 6.552 6.13235 6.99036C6.5878 7.42873 7.20551 7.675 7.84961 7.675Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgP2P2);
export default ForwardRef;
