import { Link } from '../link';
import { Tab, Tabs } from '../tabs';

export function SettingsTabs() {
  return (
    <Tabs>
      <Tab>
        <Link href="/settings/account/">
          <a>Account</a>
        </Link>
      </Tab>
      <Tab>
        <Link href="/settings/gateway/">
          <a>Gateway</a>
        </Link>
      </Tab>
      <Tab>
        <Link href="/settings/security/">
          <a>Security</a>
        </Link>
      </Tab>
      <Tab>
        <Link href="/settings/notifications/">
          <a>Notifications</a>
        </Link>
      </Tab>
    </Tabs>
  );
}
