// @ts-nocheck
import { forwardRef } from 'react';
const SvgAperture = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.8099 7.13402C14.2882 6.85783 14.8998 7.02166 15.176 7.49993L20.916 17.4399C21.1922 17.9182 21.0283 18.5298 20.5501 18.806C20.0718 19.0822 19.4602 18.9183 19.184 18.4401L13.444 8.50007C13.1678 8.02181 13.3317 7.4102 13.8099 7.13402Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.69 8C8.69 7.44772 9.13771 7 9.69 7H21.17C21.7223 7 22.17 7.44772 22.17 8C22.17 8.55228 21.7223 9 21.17 9H9.69C9.13771 9 8.69 8.55228 8.69 8Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.6201 1.19402C14.0983 1.4702 14.2622 2.08181 13.986 2.56007L8.24598 12.5001C7.9698 12.9783 7.35819 13.1422 6.87993 12.866C6.40166 12.5898 6.23783 11.9782 6.51402 11.4999L12.254 1.55993C12.5302 1.08166 13.1418 0.917834 13.6201 1.19402Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.44993 5.19402C3.92819 4.91783 4.5398 5.08166 4.81598 5.55993L10.556 15.4999C10.8322 15.9782 10.6683 16.5898 10.1901 16.866C9.71181 17.1422 9.1002 16.9783 8.82402 16.5001L3.08402 6.56007C2.80783 6.08181 2.97166 5.4702 3.44993 5.19402Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.83 16C1.83 15.4477 2.27772 15 2.83 15H14.31C14.8623 15 15.31 15.4477 15.31 16C15.31 16.5523 14.8623 17 14.31 17H2.83C2.27772 17 1.83 16.5523 1.83 16Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.1201 11.134C17.5983 11.4102 17.7622 12.0218 17.486 12.5001L11.746 22.4401C11.4698 22.9183 10.8582 23.0822 10.3799 22.806C9.90166 22.5298 9.73783 21.9182 10.014 21.4399L15.754 11.4999C16.0302 11.0217 16.6418 10.8578 17.1201 11.134Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAperture);
export default ForwardRef;
