// @ts-nocheck
import { forwardRef } from 'react';
const SvgWifi = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M5.00001 13.5C4.70001 13.5 4.40001 13.4 4.20001 13.1C3.80001 12.7 3.90001 12 4.30001 11.7C8.80001 8.00001 15.2 8.00001 19.7 11.7C20.1 12.1 20.2 12.7 19.8 13.1C19.4 13.5 18.8 13.6 18.4 13.2C14.7 10.1 9.30001 10.1 5.60001 13.2C5.50001 13.5 5.20001 13.5 5.00001 13.5Z"
    />
    <path
      fill="currentColor"
      d="M1.40001 10C1.10001 10 0.800007 9.90001 0.600007 9.70001C0.200007 9.30001 0.300007 8.70001 0.700007 8.30001C7.10001 2.70001 16.8 2.70001 23.2 8.30001C23.6 8.70001 23.7 9.30001 23.3 9.70001C22.9 10.1 22.3 10.2 21.9 9.80001C16.2 4.80001 7.70001 4.80001 2.10001 9.80001C1.90001 9.90001 1.70001 10 1.40001 10Z"
    />
    <path
      fill="currentColor"
      d="M15.5 17.1C15.3 17.1 15.1 17 14.9 16.9C13.2 15.7 10.8 15.7 9.10001 16.9C8.60001 17.2 8.00001 17.1 7.70001 16.7C7.40001 16.2 7.50001 15.6 7.90001 15.3C10.3 13.6 13.6 13.6 16 15.3C16.5 15.6 16.6 16.2 16.2 16.7C16.1 17 15.8 17.1 15.5 17.1Z"
    />
    <path
      fill="currentColor"
      d="M12 21C11.9 21 11.7 21 11.6 20.9C11.5 20.9 11.4 20.8 11.3 20.7C11.1 20.5 11 20.3 11 20C11 19.9 11 19.7 11.1 19.6C11.2 19.5 11.2 19.4 11.3 19.3C11.7 18.9 12.3 18.9 12.7 19.3C12.9 19.5 13 19.8 13 20C13 20.3 12.9 20.5 12.7 20.7C12.5 20.9 12.3 21 12 21Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgWifi);
export default ForwardRef;
