// @ts-nocheck
import { forwardRef } from 'react';
const SvgDoge = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 64 64"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_3630_36495)">
      <circle cx={32} cy={32} r={32} fill="white" />
      <path
        fill="#C2A633"
        d="M32.768 21.088H28.1958V30.1021H35.3891V33.8762H28.1958V42.8896H32.992C34.2243 42.8896 43.1091 43.0288 43.0953 32.4026C43.0816 21.7763 34.478 21.088 32.768 21.088Z"
      />
      <path
        fill="#C2A633"
        d="M32 0C14.3267 0 0 14.3267 0 32C0 49.6733 14.3267 64 32 64C49.6733 64 64 49.6733 64 32C64 14.3267 49.6733 0 32 0ZM33.2573 49.2832H21.6685V33.8765H17.5834V30.1024H21.6682V14.6947H31.6144C33.9674 14.6947 49.5523 14.2061 49.5523 32.2781C49.5523 50.649 33.2576 49.2832 33.2576 49.2832H33.2573Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_3630_36495">
        <rect width={64} height={64} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgDoge);
export default ForwardRef;
