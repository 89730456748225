import { useEffect, useState } from 'react';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { api } from '@use-gateway/api';
import { Breakpoints } from '@use-gateway/theme';
import { CryptoCurrency, CurrencyNameMap, FiatCurrency, TestCoins } from '@use-gateway/types';
import { price, useAuth, useWallet } from '@use-gateway/utils';
import { WalletRow } from './wallet-row';
import { CurrencyIcon } from '../currency-icon';
import { Scroll } from '../scroll';

const WalletWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.light[80]};
  border-radius: 12px;
  box-shadow: 0 12px 98px rgba(0, 0, 0, 0.06);

  ${down(Breakpoints.sm)} {
    box-sizing: border-box;
  }
`;

const WalletHeader = styled.div`
  padding-top: 10px;
  background-color: ${({ theme }) => theme.colors.light[90]};

  ${down(Breakpoints.sm)} {
    display: none;
  }
`;

const WalletHeaderInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 34px;
  width: 100%;
`;

const WalletTitle = styled.h3`
  box-sizing: border-box;
  width: 100%;
  padding: 0 20px;
  font-family: 'Nunito', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px !important;
  line-height: 1 !important;
  color: ${({ theme }) => theme.colors.dark[100]};
`;

const WalletTotal = styled.div`
  width: 150px;
  min-width: 150px;
  font-family: 'Nunito', sans-serif;
  font-size: 32px;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.dark[100]};

  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;
`;

const WalletHeaderLabels = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 220px 120px;
  padding: 8px 20px 10px;
  font-weight: 500;
  font-size: 14px;
`;

const AmountLabel = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ActionLabel = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 8px;
`;

export function WalletTab() {
  const { user } = useAuth();
  const { wallet, walletRates, setWalletRates } = useWallet();
  const [total, setTotal] = useState(0);
  const currencies = Object.keys(wallet);

  const getRates = async () => {
    await api
      .getWalletRates()
      .then((res) => {
        setWalletRates(res);
      })
      .catch(console.warn);
  };

  useEffect(() => {
    let count = 0;
    for (const key in wallet) {
      if (wallet[key]) count = count + wallet[key].local;
    }
    setTotal(count);
  }, [wallet]);

  useEffect(() => {
    getRates();

    const interval = setInterval(() => {
      getRates();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <WalletWrapper>
      <WalletHeader>
        <WalletHeaderInfo>
          <WalletTitle>Total balance</WalletTitle>
          <WalletTotal>
            {price({
              amount: total,
              currency: user?.local_currency as FiatCurrency,
            })}
          </WalletTotal>
        </WalletHeaderInfo>
        <WalletHeaderLabels>
          <div>Сryptocurrency</div>
          <AmountLabel>Price / Change 1d</AmountLabel>
          <AmountLabel>Amount</AmountLabel>
          <ActionLabel>Action</ActionLabel>
        </WalletHeaderLabels>
      </WalletHeader>

      <Scroll>
        {currencies
          .filter((currency: CryptoCurrency | TestCoins) => wallet[currency])
          .sort((a: string, b: string) => wallet[b].local - wallet[a].local)
          .map((currency: CryptoCurrency | TestCoins) => {
            const rate = walletRates ? walletRates[currency] : undefined;
            return (
              <WalletRow
                title={CurrencyNameMap[currency] ?? currency}
                key={currency}
                currency={currency}
                wallet={wallet}
                icon={<CurrencyIcon currency={currency} />}
                rate={rate}
              />
            );
          })}
      </Scroll>
    </WalletWrapper>
  );
}
