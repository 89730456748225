import getConfig from 'next/config';
import { load as fingerprintLoad } from '@fingerprintjs/fingerprintjs';
import { LoginPayload, LoginResponseDto, UnknownFunction } from '@use-gateway/types';
import { isServer, technicalWorksRedirect } from '@use-gateway/utils';
import AES from 'crypto-js/aes';
import encUTF8 from 'crypto-js/enc-utf8';
import * as apiKeys from './methods/api-keys';
import * as auth from './methods/auth';
import * as constants from './methods/constants';
import * as deposits from './methods/deposits';
import * as exchangers from './methods/exchangers';
import * as invoices from './methods/invoices';
import * as p2p from './methods/p2p';
import * as payments from './methods/payments';
import * as rates from './methods/rates';
import * as systemStatus from './methods/system-status';
import * as transactions from './methods/transactions';
import * as users from './methods/users';
import * as wallet from './methods/wallet';
import * as webhooks from './methods/webhooks';
import * as withdrawalsRequest from './methods/withdrawal-requests';
import * as withdrawals from './methods/withdrawals';
import { ResponseError } from './utils/response-error';

export * from './utils/response-error';

const { publicRuntimeConfig } = getConfig();

const API_URL = publicRuntimeConfig.API_URL;

let visitorId: string;
let accessToken: string;

export class Api {
  _baseURL = '';
  _unauthorizedCallback?: UnknownFunction;

  headers: Record<string, string> = {
    'Content-Type': 'application/json',
  };

  constructor(baseURL: string) {
    this._baseURL = baseURL;

    this.bindAllMethods();
  }

  bindAllMethods() {
    const methods = Object.getOwnPropertyNames(Api.prototype).filter(
      (key) => key !== 'constructor'
    );
    methods.forEach((key) => {
      this[key] = this[key].bind(this);
    });
  }

  // ~~ Service methods

  log(path: string, ...args: Array<unknown>) {
    console.log(`[API]: "${path}"`, ...args);
  }

  error(path: string, ...args: Array<unknown>) {
    console.error(`[API]: "${path}"`, ...args);
  }

  setUnauthorizedCallback(cb: UnknownFunction | undefined) {
    this._unauthorizedCallback = cb;
  }

  // ~~ Initialization

  async init() {
    await fingerprintLoad()
      .then((fp) => fp.get())
      .then((result) => {
        visitorId = result.visitorId;
        loadToken();
      });
  }

  // ~~ System Status

  getSystemStatus = systemStatus.getSystemStatus;

  // ~~ Auth

  register = auth.register;

  async login(this: Api, payload: LoginPayload) {
    await this.postUrlEncoded<LoginResponseDto>(`/auth/jwt/login`, payload).then((res) => {
      accessToken = res.access_token;
      storeToken();
    });
  }

  async logout(this: Api) {
    await this.post<any>(`/auth/jwt/logout`).catch(() => null);

    accessToken = '';
    storeToken();
  }

  forgotPassword = auth.forgotPassword;
  resetPassword = auth.resetPassword;

  // ~~ Users

  getCurrnetUser = users.getCurrnetUser;
  patchCurrnetUser = users.patchCurrnetUser;

  // ~~ Withdrawals

  getWithdrawals = withdrawals.getWithdrawals;
  sendTo = withdrawals.sendTo;

  // ~~ Withdrawal Requests

  getWithdrawalRequests = withdrawalsRequest.getWithdrawalRequests;
  createWithdrawalCryptoRequest = withdrawalsRequest.createWithdrawalCryptoRequest;
  createWithdrawalFiatRequest = withdrawalsRequest.createWithdrawalFiatRequest;
  getWithdrawalRequest = withdrawalsRequest.getWithdrawalRequest;
  withdrawalRequestApprove = withdrawalsRequest.withdrawalRequestApprove;
  withdrawalRequestDecline = withdrawalsRequest.withdrawalRequestDecline;
  payWithdrawalRequest = withdrawalsRequest.payWithdrawalRequest;

  // ~~ Rates

  getRates = rates.getRates;
  getRate = rates.getRate;

  // ~~ Wallet

  createWallet = wallet.createWallet;
  checkSeed = wallet.checkSeed;
  getBalance = wallet.getBalance;
  getWalletRates = wallet.getWalletRates;
  getFees = wallet.getFees;
  getMaxCryptoCurrency = wallet.getMaxCryptoCurrency;

  // ~~ Constants

  getMinDeposits = constants.getMinDeposits;
  getWebhookEvents = constants.getWebhookEvents;
  getAvailableCryptocurrencies = constants.getAvailableCryptocurrencies;

  // ~~ Exchangers

  getExchangers = exchangers.getExchangers;
  getExchangerCountries = exchangers.getExchangerCountries;
  getExchangerMethods = exchangers.getExchangerMethods;

  // ~~ Invoices

  createInvoice = invoices.createInvoice;
  getInvoice = invoices.getInvoice;
  getInvoiceView = invoices.getInvoiceView;
  getInvoices = invoices.getInvoices;
  renewInvoice = invoices.renewInvoice;
  cancelInvoice = invoices.cancelInvoice;
  resolveInvoice = invoices.resolveInvoice;

  // ~~ P2P

  createP2P = p2p.createP2P;
  getP2P = p2p.getP2P;
  retrievesP2PAddress = p2p.retrievesP2PAddress;

  // ~~ Payments

  createPayment = payments.createPayment;
  getPayment = payments.getPayment;
  getPayments = payments.getPayments;
  resolvePayment = payments.resolvePayment;

  // ~~ Deposits

  getDeposits = deposits.getDeposits;
  getDeposit = deposits.getDeposit;
  createDeposit = deposits.createDeposit;
  resolveDeposit = deposits.resolveDeposit;

  // ~~ Transactions

  getTransactions = transactions.getTransactions;
  getTransactionsReport = transactions.getTransactionsReport;

  // ~~ Webhooks

  getWebhooks = webhooks.getWebhooks;
  createWebhook = webhooks.createWebhook;
  getWebhook = webhooks.getWebhook;
  updateWebhook = webhooks.updateWebhook;
  deleteWebhook = webhooks.deleteWebhook;
  rotateWebhookSecret = webhooks.rotateWebhookSecret;
  testWebhook = webhooks.testWebhook;

  // ~~ API Keys

  getApiKeys = apiKeys.getApiKeys;
  changeApiKey = apiKeys.changeApiKey;

  //
  // ~~ Basic methods
  //

  /**
   * @throws {Response}
   */
  async fetch<T>(path: string, init?: (RequestInit & { raw?: boolean }) | undefined): Promise<T> {
    return await fetch(this._baseURL + path, {
      headers: {
        ...this.headers,
        ...getWrappedAuthHeader(),
      },
      ...init,
    }).then(async (res) => {
      if (init?.raw) return res;

      const clone = res.clone();
      const data = await res.json().catch((e) => {
        if (!['DELETE'].includes(init?.method as string)) {
          throw e;
        }
      });

      if (res.status >= 200 && res.status <= 299) {
        return data;
      } else {
        if (this.catchTechnicalWorks(res)) return;

        const error = new ResponseError(undefined);
        await error.addResponse(clone);

        if (error.code === 401 && this._unauthorizedCallback) {
          this._unauthorizedCallback();
        }

        throw error;
      }
    });
  }

  async get<T>(path: string, options?: RequestInit) {
    return await this.fetch<T>(path, {
      ...(options || {}),
      method: 'GET',
    });
  }

  async post<T>(path: string, payload: any = {}, options?: RequestInit) {
    return await this.fetch<T>(path, {
      ...(options || {}),
      body: JSON.stringify(payload),
      method: 'POST',
    });
  }

  async put<T>(path: string, payload: any = {}, options?: RequestInit) {
    return await this.fetch<T>(path, {
      ...(options || {}),
      body: JSON.stringify(payload),
      method: 'PUT',
    });
  }

  async patch<T>(path: string, payload: any = {}, options?: RequestInit) {
    return await this.fetch<T>(path, {
      ...(options || {}),
      body: JSON.stringify(payload),
      method: 'PATCH',
    });
  }

  async delete<T>(path: string, options?: RequestInit) {
    return await this.fetch<T>(path, {
      ...(options || {}),
      method: 'DELETE',
    });
  }

  async postUrlEncoded<T>(path: string, payload: any, options?: RequestInit) {
    return await this.fetch<T>(path, {
      ...(options || {}),
      headers: {
        ...this.headers,
        ...getWrappedAuthHeader(),
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(payload),
      method: 'POST',
    });
  }

  catchTechnicalWorks(res: Response) {
    const isTechnicalWorksStatus = res.status === 509;

    if (isTechnicalWorksStatus && !isServer()) {
      const url = window.location.pathname + window.location.search;
      technicalWorksRedirect(url);
    }

    return isTechnicalWorksStatus;
  }
}

export const api = new Api(`${API_URL}/v1`);

function storeToken() {
  if (accessToken) {
    const encryptedToken = AES.encrypt(accessToken, visitorId).toString();
    localStorage.setItem('token', encryptedToken);
  } else {
    localStorage.setItem('token', '');
  }
}

function loadToken() {
  const encryptedToken = localStorage.getItem('token');

  try {
    accessToken = AES.decrypt(encryptedToken as string, visitorId).toString(encUTF8);
  } catch (error) {
    console.warn(error);
  }
}

function getWrappedAuthHeader(): any {
  if (accessToken) {
    return {
      Authorization: `Bearer ${accessToken}`,
    };
  }

  return {};
}
