// @ts-nocheck
import { forwardRef } from 'react';
const SvgGuide = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M22.5 21.45L20.4 19.35C20.775 18.75 21 18 21 17.25C21 15.15 19.35 13.5 17.25 13.5C15.15 13.5 13.5 15.15 13.5 17.25C13.5 19.35 15.15 21 17.25 21C18 21 18.75 20.775 19.35 20.4L21.45 22.5L22.5 21.45ZM15 17.25C15 15.975 15.975 15 17.25 15C18.525 15 19.5 15.975 19.5 17.25C19.5 18.525 18.525 19.5 17.25 19.5C15.975 19.5 15 18.525 15 17.25ZM6 12H13.5V13.5H6V12ZM6 7.5H15V9H6V7.5Z"
    />
    <path
      fill="currentColor"
      d="M10.5 20.775L6.6 18.675C4.35 17.55 3 15.225 3 12.75V3H18V11.25H19.5V3C19.5 2.175 18.825 1.5 18 1.5H3C2.175 1.5 1.5 2.175 1.5 3V12.75C1.5 15.825 3.15 18.6 5.85 20.025L10.5 22.5V20.775Z"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgGuide);
export default ForwardRef;
