// @ts-nocheck
import { forwardRef } from 'react';
const SvgDroplet = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.9996 1.69C12.2649 1.68989 12.5195 1.79525 12.7071 1.9829L18.3669 7.64266C18.3669 7.64274 18.3668 7.64259 18.3669 7.64266C19.626 8.90107 20.4839 10.5048 20.8316 12.2507C21.1794 13.9967 21.0015 15.8066 20.3205 17.4515C19.6395 19.0964 18.4859 20.5023 17.0057 21.4915C15.5256 22.4807 13.7853 23.0087 12.005 23.0087C10.2247 23.0087 8.48442 22.4807 7.00425 21.4915C5.52408 20.5023 4.37053 19.0964 3.68951 17.4515C3.0085 15.8066 2.83061 13.9967 3.17836 12.2507C3.52605 10.505 4.38358 8.90161 5.64252 7.64326C5.64272 7.64306 5.64232 7.64346 5.64252 7.64326L11.2923 1.98352C11.4797 1.79571 11.7342 1.69012 11.9996 1.69ZM12.0006 4.10484L7.05687 9.05734C6.07744 10.0361 5.4103 11.2834 5.13983 12.6414C4.86936 13.9994 5.00772 15.4071 5.5374 16.6864C6.06708 17.9658 6.96428 19.0593 8.11553 19.8287C9.26677 20.5981 10.6203 21.0087 12.005 21.0087C13.3897 21.0087 14.7432 20.5981 15.8945 19.8287C17.0457 19.0593 17.9429 17.9658 18.4726 16.6864C19.0023 15.4071 19.1406 13.9994 18.8702 12.6414C18.5997 11.2834 17.9325 10.0361 16.9531 9.05734L12.0006 4.10484Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgDroplet);
export default ForwardRef;
