// @ts-nocheck
import { forwardRef } from 'react';
const SvgCloudDrizzle = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#clip0_2128_24015)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8 18C8.55228 18 9 18.4477 9 19V21C9 21.5523 8.55228 22 8 22C7.44772 22 7 21.5523 7 21V19C7 18.4477 7.44772 18 8 18Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8 12C8.55228 12 9 12.4477 9 13V15C9 15.5523 8.55228 16 8 16C7.44772 16 7 15.5523 7 15V13C7 12.4477 7.44772 12 8 12Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M16 18C16.5523 18 17 18.4477 17 19V21C17 21.5523 16.5523 22 16 22C15.4477 22 15 21.5523 15 21V19C15 18.4477 15.4477 18 16 18Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M16 12C16.5523 12 17 12.4477 17 13V15C17 15.5523 16.5523 16 16 16C15.4477 16 15 15.5523 15 15V13C15 12.4477 15.4477 12 16 12Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 20C12.5523 20 13 20.4477 13 21V23C13 23.5523 12.5523 24 12 24C11.4477 24 11 23.5523 11 23V21C11 20.4477 11.4477 20 12 20Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 14C12.5523 14 13 14.4477 13 15V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V15C11 14.4477 11.4477 14 12 14Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.08238 0.0466067C9.50126 -0.0978736 10.9342 0.0969202 12.263 0.614932C13.5918 1.13294 14.7785 1.95934 15.7252 3.02601C16.498 3.89668 17.0928 4.90709 17.4793 6.00002H18.0012C19.3783 6.00164 20.713 6.47697 21.7812 7.34622C22.8494 8.21547 23.586 9.42573 23.8675 10.7738C24.1489 12.122 23.958 13.5258 23.3269 14.7499C22.6957 15.9739 21.6627 16.9435 20.4012 17.496C19.8953 17.7176 19.3056 17.4871 19.084 16.9812C18.8624 16.4753 19.0929 15.8856 19.5988 15.664C20.4398 15.2957 21.1285 14.6493 21.5493 13.8333C21.9701 13.0172 22.0973 12.0813 21.9097 11.1826C21.7221 10.2838 21.231 9.47699 20.5189 8.89749C19.8068 8.31807 18.9172 8.00119 17.9992 8.00002C17.9991 8.00002 17.9993 8.00002 17.9992 8.00002H16.74C16.284 8.00002 15.8858 7.69155 15.7718 7.25005C15.4944 6.17601 14.9658 5.18326 14.2294 4.35363C13.4931 3.524 12.5701 2.88125 11.5366 2.47835C10.503 2.07545 9.38856 1.92394 8.28498 2.03632C7.18141 2.14869 6.12035 2.52173 5.18925 3.12468C4.25814 3.72763 3.48367 4.54323 2.92965 5.50425C2.37564 6.46528 2.05795 7.54421 2.00277 8.65211C1.94759 9.76002 2.15651 10.8652 2.61229 11.8765C3.06807 12.8878 3.75768 13.7763 4.62426 14.4688C5.05572 14.8136 5.12598 15.4428 4.78121 15.8743C4.43644 16.3057 3.80719 16.376 3.37574 16.0312C2.26156 15.1409 1.37492 13.9985 0.788915 12.6983C0.202908 11.398 -0.0656946 9.97708 0.00524859 8.55263C0.0761918 7.12818 0.484649 5.74098 1.19695 4.50538C1.90926 3.26978 2.90501 2.22115 4.10214 1.44593C5.29927 0.670704 6.6635 0.191087 8.08238 0.0466067Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="clip0_2128_24015">
        <rect width={24} height={24} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
const ForwardRef = forwardRef(SvgCloudDrizzle);
export default ForwardRef;
