// @ts-nocheck
import { forwardRef } from 'react';
const SvgLogo = ({ title, titleId, ...props }, ref) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 36 36"
    ref={ref}
    aria-labelledby={titleId}
    {...props}>
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.0636 6.03117e-07C12.774 0.222078 8.54857 1.97136 5.27208 5.24786C0.00265472 10.5173 -1.31669 18.241 1.31404 24.7394C4.29772 24.0494 7.4337 22.851 10.2333 20.6732C13.7036 17.9737 15.5827 13.9755 16.605 10.8532C16.7742 10.3364 16.9963 8.95649 17.0455 8.11176L17.0636 6.03117e-07ZM5.152 30.5825C5.1918 30.6231 5.23182 30.6635 5.27208 30.7037C6.73155 32.1632 8.37929 33.3196 10.1371 34.1731C12.9278 32.7335 15.0488 30.6192 16.4091 27.84C16.9813 26.671 17.0455 24.6581 17.0455 23.3853L17.1141 12.8426C15.8224 20.4254 11.3064 25.8481 8.26288 28.4347C7.17721 29.3574 6.13131 30.0539 5.152 30.5825ZM25.8805 34.1645C27.6317 33.3119 29.2733 32.1583 30.7279 30.7037C30.769 30.6626 30.8099 30.6213 30.8505 30.5799C29.8575 30.0483 28.7952 29.3442 27.6919 28.4065C24.6829 25.8492 20.2484 20.2961 18.9 12.8426L18.9547 23.3853C18.9547 24.0217 18.9937 26.5258 19.5911 27.84C20.5334 29.9132 22.9643 32.5803 25.8805 34.1645ZM34.6926 24.7231C31.6934 24.0351 28.5373 22.8354 25.7215 20.645C22.3081 17.9898 20.4356 13.9669 19.4017 10.8468C19.2308 10.3313 18.9378 9.0097 18.9547 8.11176L18.9364 5.17625e-06C23.2261 0.222102 27.4515 1.97139 30.7279 5.24786C35.9929 10.5129 37.3145 18.2281 34.6926 24.7231Z"
      clipRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLogo);
export default ForwardRef;
