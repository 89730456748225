import { ReactNode } from 'react';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toast';
import { styled } from '@use-gateway/theme';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin: 6px 0;
  color: ${({ theme }) => theme.colors.dark[100]};
`;

const Content = styled.div`
  width: 100%;
`;

const Value = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  div {
    width: 100%;
    max-width: calc(100% - 1em - 18px - 4ch);
    overflow-wrap: break-word;
  }
`;

const Label = styled.div`
  font-size: 14px;
  line-height: 1.42;
  color: ${({ theme }) => theme.colors.dark[20]};
  margin: 0 0 6px;
`;

const Copy = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.primary};
  justify-self: flex-end;
  margin-left: auto;
  cursor: pointer;
`;

export interface CopyValueProps {
  value: string;
  label?: string;
  children?: ReactNode;
  className?: string;
}
export function CopyValue({ value, label, children, className }: CopyValueProps) {
  const copyValue = () => {
    copy(value);
    toast.info('Text has been copied!');
  };

  return (
    <Wrapper>
      <Content>
        <Label>{label}</Label>
        <Value>
          <div className={className}>{children ? children : value}</div>
          <Copy onClick={copyValue}>Copy</Copy>
        </Value>
      </Content>
    </Wrapper>
  );
}
